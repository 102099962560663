import axios, { AxiosError } from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Button,
  Grid,
  Header,
  Message,
  Loader,
  Segment,
  Select,
  DropdownProps,
  Input,
  Dropdown,
  DropdownItemProps,
} from 'semantic-ui-react'
import moment from 'moment'

type TParams = { hash: string }
interface IData {
  name: string
  email: string
  hash: string
  beginning: number
  expiration: number
  tenant: string
  shipName?: string
}

const URL = 'https://jstm1estza.execute-api.us-east-2.amazonaws.com/v0'

export const ApproveCode = ({ match }: RouteComponentProps<TParams>) => {
  const [success, setSuccess] = useState<boolean>()
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<IData>()
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios(`${URL}/verification/${match.params.hash}`)
        setData(result.data)
      } catch (err) {
        setError(true)
      }
    }
    fetchData()
  }, [match.params.hash])
  const handleApprove = async ({ nfcId, device, piers }: SubmitData) => {
    try {
      setLoading(true)
      await axios.post(URL + '/verification/' + match.params.hash + '/activate', {
        hash: match.params.hash,
        name: data?.name,
        nfcId,
        device,
        piers,
      })
      setSuccess(true)
    } catch (err) {
      const errors = err as Error | AxiosError<any>
      setSuccess(false)
      setLoading(false)
      if (!axios.isAxiosError(errors)) {
        setErrorMsg(errors.message)
        return
      }
      setErrorMsg(errors?.response?.data || 'Erro desconhecido')
    }
  }
  if (!match.params.hash) return null
  return (
    <Grid textAlign="center" style={{ height: '100vh', background: '#f7f7f7' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 500 }}>
        <Content
          data={data}
          handleClick={handleApprove}
          success={success}
          error={error}
          isLoading={isLoading}
          errorMsg={errorMsg}
        />
      </Grid.Column>
    </Grid>
  )
}

type SubmitData = {
  nfcId: string
  device: Device
  piers: string[]
}

interface IContent {
  handleClick: (submitData: SubmitData) => void
  success: boolean | undefined
  error: boolean
  isLoading: boolean
  data: IData | undefined
  errorMsg: string
}

type Device = boolean | number | string | undefined | (boolean | number | string)[]

const marginStyle = {
  marginBottom: 10,
}

const Content = ({ handleClick, success, data, error, isLoading, errorMsg }: IContent) => {
  const [device, setDevice] = useState<Device>()
  const [nfcId, setNfcId] = useState('')
  const [piers, setPiers] = useState<string[]>([])
  const [piersOptions, setPiersOptions] = useState<DropdownItemProps[]>([])
  const isDisabled = isLoading || !device || !nfcId.length

  useEffect(() => {
    const fetchData = async (tenant: string) => {
      const piers = await getPierOptions(tenant)
      setPiersOptions(piers)
    }
    if (data) {
      fetchData(data.tenant)
    }
  }, [data])

  const handleChangeDevice = (e: any, data: DropdownProps) => {
    setDevice(data.value)
  }
  const handleApprove = () => {
    handleClick({ nfcId, device, piers })
  }
  const handleChangePier = (_e: unknown, data: DropdownProps) => {
    setPiers(data.value as string[])
  }
  if (error) return <ErrorBox />
  if (!data) return <Loader active />
  if (success) return <SuccessBox name={data.name} />
  return (
    <Fragment>
      <Header as="h2" color="teal" textAlign="center" content="Autorização de acesso" />
      <Segment>
        <div style={marginStyle}>
          <Header sub>Nome</Header>
          <span>{data.name}</span>
        </div>
        <div style={marginStyle}>
          <Header sub>Email</Header>
          <span>{data.email}</span>
        </div>
        <div style={marginStyle}>
          <Header sub>Data da visita</Header>
          <span>{moment(Number(data.beginning)).format('lll')}</span>
        </div>
        <div style={marginStyle}>
          <Header sub>Dispositivo</Header>
          <Select placeholder="Selecione o dispositivo" options={options} onChange={handleChangeDevice} />
        </div>
        <div style={marginStyle}>
          <Header sub>Número Crachá (NFC)</Header>
          <Input
            placeholder="ID do cartão"
            value={nfcId}
            onChange={(_e, data) => {
              setNfcId(data.value)
            }}
          />
        </div>
        {data.shipName && (
          <div style={marginStyle}>
            <Header sub>Berço</Header>
            <Dropdown
              placeholder="Selecione o berço"
              multiple
              selection
              options={piersOptions}
              onChange={handleChangePier}
            />
          </div>
        )}
        <Button basic color="green" onClick={handleApprove} loading={isLoading} disabled={isDisabled}>
          Autorizar
        </Button>
      </Segment>
      {errorMsg.length > 0 && <Message negative header="Erro" content={errorMsg} />}
    </Fragment>
  )
}

const SuccessBox = ({ name }: { name: string }) => (
  <Message positive header="Código ativado" content={`O código de acesso de ${name} foi ativado.`} />
)

const ErrorBox = () => <Message negative header="Link inválido" content="Verifique se o link inserido está correto." />

const options = [
  { key: 'app', value: 'app', text: 'Aplicativo' },
  { key: 'smartbadge', value: 'smartbadge', text: 'Smartbadge' },
]

const getPiers = async (tenant: string): Promise<PierData[]> => {
  const result = await axios.get(
    `https://uiefu6b723cgfwaxhj6gojdehu0ntwsg.lambda-url.us-east-2.on.aws/?tenant=${tenant}`
  )
  return result.data
}

async function getPierOptions(tenant: string): Promise<DropdownItemProps[]> {
  const piers = await getPiers(tenant)
  if (!piers.length) return []
  return piers
    .sort((a, b) => a.groupName.localeCompare(b.groupName))
    .map((pier) => ({ key: pier.ids, value: pier.ids, text: pier.groupName }))
}

interface PierData {
  groupName: string
  ids: string
}
