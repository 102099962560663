/*  Author: Bruno Melo
    Type: Const Store
    Description: All initial/empty info from store;
    To do list: -Checar se está completo
*/

import { Roles } from 'Utils/rbac'

type LoginStore = {
  empresa: string
  email: string
  perfil: Roles
  favorites: any
  favFields: any
  followMe: any
  refMap?: any
  section?: string
  id_usuario?: number
  id_empresa?: number
  id_perfil?: number
  language?: string
  currentRelease?: string
  connection?: string
  mapLayers: string[]
  preferences: LoginPreferences
  cudLoading: boolean
  cudSuccess: boolean
}

interface LoginPreferences {
  [key: string]: any // TODO deal with this 'any'
}

interface NormalizedObjects<T> {
  byId: { [id: string]: T | undefined }
  allIds: string[]
  groups: string[]
  current?: any
  loading?: boolean
  wip?: {}
  hasError?: boolean
  errors?: {}
  success?: boolean
  cudLoading: boolean
  cudSuccess: boolean
}

export interface ModuleObject {
  id: string
  name: string
  info: any
  status: true
  default: boolean
  schemas: unknown
  createdAt: string
  createdBy: string
  moduleType: 'fences' | 'refs' | 'units' | 'types' | 'reports'
  groups?: string[]
}

type ModulesStore = {
  fences: NormalizedObjects<ModuleObject>
  types: NormalizedObjects<ModuleObject>
  refs: NormalizedObjects<ModuleObject>
  routes: NormalizedObjects<ModuleObject>
  wip: any
  units: NormalizedObjects<ModuleObject>
  tables?: NormalizedObjects<ModuleObject>
  reports?: NormalizedObjects<ModuleObject>
  cudLoading: boolean
  cudSuccess: boolean
}

type RulesActions = 'platformNotification' | 'toSendEmail' | 'app'

interface RulesItem {
  id: string
  notificationProfileId: string
  name: string
  tenant: string
  status: 'on' | 'off'
  action: RulesActions[]
  createdBy: string
  description: string
  message: string
  scope: string
  sourceType: 'assetGroup' | 'asset'
  targetType: 'fenceGroup' | 'fence'
  sendTo: string
  createdAt: string
  updatedAt: string
  targetId: string[]
  sourceId: string[]
  editable: boolean | null
}

export interface MessageItem {
  id: string
  label: string
  type: 'generic' | 'app' | 'web' | 'email' | 'smartphone'
  title: string | null
  body: string | null
  extra?: string | null
  tags?: string | null
}

type RulesStore = NormalizedObjects<RulesItem>
type MessageStore = NormalizedObjects<MessageItem>
type SensorsStore = {
  id: string
  name: string
  modelId: number
  assets: string[]
  model: 'Smartphone' | 'Smartbadge'
  info: null
  status: boolean
  mackey: string | null
  mackeyble: string | null
  uuid: string | null
  state: number
  createdAt: string
  cudLoading: boolean
  cudSuccess: boolean
}
export interface RootStore {
  assets: any //TODO: add types,
  measures: any
  login: LoginStore
  toggle: any //TODO: add types,
  projects: any //TODO: add types,
  people: any //TODO: add types,
  indoor: any //TODO: add types,
  filters: any //TODO: add types,
  history: any //TODO: add types,
  notification: any //TODO: add types,
  system: any //TODO: add types,
  sensors: NormalizedObjects<SensorsStore> //TODO: add types,
  rules: RulesStore
  messages: MessageStore
  interactions: any //TODO: add types,
  dashboardStellantis: any //TODO: add types,
  dashboards: any //TODO: add types,
  notificationProfiles: any //TODO: add types,
  cards: object //TODO: add types (probably object)
  tables: any //TODO: add types,
  modules: ModulesStore
  locationHist: any
  tenants: {
    available: unknown[]
    current: object
  }
}

let initialStore: RootStore = {
  assets: {
    byId: {},
    allIds: [],
    groups: [],
    current: {},
    allActiveIds: [],
    allActivePeopleIds: [],
    success: false, // 'Read' here
    cudSuccess: false, // CRUD without 'Read' ↑
    loading: false,
    cudLoading: false, // check above ↑
  },
  measures: {
    allIds: [],
    byId: {},
    current: {
      allCategories: [],
      byCategory: {},
    },
    loadingCategories: false,
    loadingCategoryMeasures: false,
    cudLoading: false,
    cudSuccess: false,
  },
  login: {
    empresa: '',
    email: '',
    perfil: '',
    favorites: [],
    favFields: [],
    followMe: [],
    connection: 'connected',
    mapLayers: [],
    preferences: {
      routesPanels: {
        allIds: [
          'people',
          'types',
          'interactions',
          'assets',
          'associations',
          'logouts',
          'devices',
          'firefighter',
          'measuresHist',
          'locationHist',
          'units',
          'fences',
          'refs',
          'messages',
          'notificationProfiles',
          'notifications',
          'rules',
          'phases',
          'labelingTool',
          'insights',
          'preferences',
          'motionChart',
          'reports',
          'manageandsecurity',
        ],
        byId: {
          people: {
            id: 'people',
            icon: 'user',
            label: 'Pessoas',
            hiddenColumns: ['id', 'location', 'info.matricula', 'info.createdBy', 'info.createdAt'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/people',
          },
          types: {
            id: 'types',
            icon: 'user md',
            label: 'Atribuições',
            hiddenColumns: ['id', 'updatedAt', 'info.description'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/types',
          },
          interactions: {
            id: 'interactions',
            icon: 'group',
            label: 'Interações',
            hiddenColumns: [],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/interactions',
          },
          assets: {
            id: 'assets',
            icon: 'car',
            label: 'Ativos',
            hiddenColumns: ['location', 'info.matricula', 'info.createdBy', 'info.createdAt'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/assets',
          },
          associations: {
            id: 'associations',
            icon: 'connectdevelop',
            label: 'Associações',
            hiddenColumns: ['assetId', 'deviceId'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/associations',
          },
          logouts: {
            id: 'logouts',
            icon: 'log out',
            label: 'App Logouts',
            hiddenColumns: [],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/logouts',
          },
          devices: {
            id: 'devices',
            icon: 'microchip',
            label: 'Dispositivos',
            hiddenColumns: [
              'mackey',
              'mackeyble',
              'info.destino',
              'info.saida',
              'info.modo',
              'info.connectivity',
              'comments',
              'info.devaddr',
              'info.responsavel',
              'info.model_info.fw_version',
              'info.mac',
            ],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/devices',
          },
          firefighter: {
            id: 'firefighter',
            icon: 'fire extinguisher',
            label: 'Brigadistas',
            hiddenColumns: [],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/firefighter',
          },
          measuresHist: {
            id: 'measuresHist',
            icon: 'history',
            label: 'Medidas',
            type: 'permanent',
            filters: [
              {
                id: 'relativeTime',
                icon: 'calendar',
                title: 'Intervalo',
                description: 'Selecione o Intervalo de Tempo',
                value: [],
              },
              { id: 'asset', icon: 'user', title: 'Pessoa/Ativo', description: 'Selecione o Ativo', value: [] },
              { id: 'category', icon: 'sitemap', title: 'Categoria', description: 'Selecione a Categoria', value: [] },
              { id: 'measure', icon: 'info circle', title: 'Medida', description: 'Selecione a Medida', value: [] },
            ],
            route: '/measuresHist',
          },
          fences: {
            id: 'fences',
            icon: 'th',
            label: 'Perímetros',
            hiddenColumns: ['id'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/fences',
          },
          locationHist: {
            id: 'locationHist',
            icon: 'history',
            label: 'Histórico de Locais',
            hiddenColumns: ['assetId', 'deviceId'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/locationHist',
          },

          units: {
            id: 'units',
            icon: 'map',
            label: 'Plantas',
            hiddenColumns: ['id'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/units',
          },
          refs: {
            id: 'refs',
            icon: 'map marker alternate',
            label: 'Referências',
            hiddenColumns: ['id', 'info.properties.description'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/refs',
          },
          messages: {
            id: 'messages',
            icon: 'envelope',
            label: 'Mensagens',
            hiddenColumns: ['status', 'extra', 'updatedBy', 'updatedFrom', 'createdBy', 'createdFrom'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/messages',
          },
          notificationProfiles: {
            id: 'notificationProfiles',
            icon: 'warning',
            label: 'Perfis',
            hiddenColumns: ['vibration', 'description', 'id'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/notificationProfiles',
          }, // vibrations is not listed as a possible column for this table
          notifications: {
            id: 'notifications',
            icon: 'bell outline',
            label: 'Notificações',
            hiddenColumns: ['id'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/notifications',
          },
          rules: {
            id: 'rules',
            icon: 'alarm',
            label: 'Regras',
            hiddenColumns: ['description', 'message', 'sendTo'],
            groupedValuesColumns: [],
            type: 'permanent',
            route: '/rules',
          },
          phases: {
            id: 'phases',
            icon: 'configure',
            label: 'Fases',
            type: 'permanent',
            route: '/phases',
          },
          labelingTool: {
            id: 'labelingTool',
            icon: 'line graph',
            label: 'Coleta de Dados',
            type: 'permanent',
            route: '/labelingTool',
          },
          insights: {
            id: 'insights',
            icon: 'dashboard',
            label: 'Insights',
            hiddenColumns: [],
            type: 'permanent',
            route: '/insights',
          },
          manageandsecurity: {
            id: 'manageandsecurity',
            icon: 'dashboard',
            label: 'Dashboard',
            hiddenColumns: [],
            type: 'permanent',
            route: '/manageandsecurity',
          },
          manualYamazumi: {
            id: 'manualYamazumi',
            icon: 'dashboard',
            label: 'manualYamazumi',
            defaultLabel: 'default_manualYamazumi',
            type: 'permanent',
            route: '/manualYamazumi',
          },
          automaticYamazumi: {
            id: 'automaticYamazumi',
            icon: 'dashboard',
            label: 'automaticYamazumi',
            defaultLabel: 'default_automaticYamazumi',
            type: 'permanent',
            route: '/automaticYamazumi',
          },
          cycleTimeMeasurement: {
            id: 'cycleTimeMeasurement',
            icon: 'dashboard',
            label: 'cycleTimeMeasurement',
            defaultLabel: 'default_cycleTimeMeasurement',
            type: 'permanent',
            route: '/cycleTimeMeasurement',
          },
          motionChart: {
            id: 'motionChart',
            icon: 'dashboard',
            label: 'Movimentos',
            defaultLabel: 'default_motionChart',
            type: 'permanent',
            route: '/motionChart',
          },
          preferences: {
            id: 'preferences',
            icon: 'settings',
            label: 'Preferências',
            defaultLabel: 'default_preferences',
            type: 'permanent',
            route: '/preferences',
          },
          reports: {
            id: 'reports',
            icon: 'download',
            label: 'Relatórios',
            defaultLabel: 'default_reports',
            type: 'permanent',
            route: '/reports',
          },
        },
      },
      center: { lat: -12.46876, lng: -62.797851 },
      zoom: 4,
      mapLayers: [],
      refs: [],
      fences: [],
      routes: [],
      indoor: [],
    },
    cudLoading: false,
    cudSuccess: false,
  },
  tenants: {
    available: [],
    current: {},
  },
  toggle: {
    confirmScreen: false,
    fencesEditionMode: false,
    HistMoreThan1Day: false,
    historyFlag: true,
    loadingScreen: false,
    mapDragging: true,
    markerNew: false,
    missingStatsBarSave: false,
    missingStatsBarSaveGeoJson: false,
    modalScreen: false,
    refreshFencesDashboard: false,
    showConfig: false,
    showDashboard: false,
    showDiscreteAntpath: false,
    showDividerAntpath: true,
    showDropdownNotification: false,
    showFitBounds: false,
    showHeatMap: false,
    showHistoryCalendar: false,
    showInnerMap: false,
    showIntegerAntpath: false,
    showPolyline: true,
    showRawHist: false,
    showRouteByProximity: true,
    showStaticFilter: false,
    showStatsBar: false,
    showStatsBarImage: false,
    sideBar: false,
    updateIndoor: false,
    displayingMap: false,
    uploadModal: false,
    offline: false,
  },
  projects: [],
  people: {
    cudLoading: false,
    cudSuccess: false,
  },
  indoor: {
    items: [],
    current: {},
    cudLoading: false,
    cudSuccess: false,
  },
  filters: {
    searchValue: '',
    searchField: '',
    searchText: '',
    timeslider: {
      min: 0,
      max: 0,
      total: 1,
    },
    search: [],
    result: [],
  },
  history: {
    byId: {},
    allIds: [],
    allAssetsFilteredIndexes: [],
    current: [],
    allDataIds: [],
    popup: {},
    loading: false,
    loadingCategories: false,
    loadingCategoryMeasures: false,
    success: false,
    error: false,
    type: undefined,
    slider: {
      min: 0,
      max: 0,
    },
    size: 0,
  },
  notification: {
    byId: {},
    allIds: [],
    array: [],
    assetIds: [],
    currentPage: 0,
    isFetching: true,
    initialFetch: true,
    allActiveNotifications: [],
    cudLoading: false,
    cudSuccess: false,
    assetsInEmergency: [],
  },
  system: {
    confirm: {},
    modal: {},
  },
  modules: {
    fences: {
      byId: {},
      allIds: [],
      groups: [],
      current: {},
      cudLoading: false,
      cudSuccess: false,
    },
    refs: {
      byId: {},
      allIds: [],
      groups: [],
      current: {},
      cudLoading: false,
      cudSuccess: false,
    },
    reports: {
      byId: {},
      allIds: [],
      groups: [],
      current: {},
      cudLoading: false,
      cudSuccess: false,
    },
    routes: {
      byId: {},
      allIds: [],
      groups: [],
      current: {},
      cudLoading: false,
      cudSuccess: false,
    },
    types: {
      byId: {},
      allIds: [],
      groups: [],
      current: {},
      cudLoading: false,
      cudSuccess: false,
    },
    units: {
      byId: {},
      allIds: [],
      groups: [],
      current: {},
      cudLoading: false,
      cudSuccess: false,
    },
    wip: {},
    cudLoading: false,
    cudSuccess: false,
  },
  sensors: {
    byId: {},
    allIds: [],
    groups: [],
    cudLoading: false,
    cudSuccess: false,
  },
  rules: {
    byId: {},
    allIds: [],
    groups: [],
    loading: false,
    wip: {},
    errors: {},
    cudLoading: false,
    cudSuccess: false,
  },
  messages: {
    byId: {},
    allIds: [],
    groups: [],
    loading: false,
    cudLoading: false,
    cudSuccess: false,
  },
  interactions: {
    byId: {},
    allIds: [],
    cudLoading: false,
    cudSuccess: false,
  },
  dashboardStellantis: {
    charts: {},
    cards: {},
  },
  dashboards: {
    allIds: ['rules', 'people', 'fences', 'activity', 'firefighter'],
    byId: {
      rules: {
        id: '0',
        name: 'Regras',
        description: 'Estatísticas gerais sobre Regras',
        createdBy: 'luis@phygitall.rio',
        createdAt: '2021-05-20T16:54:17.173Z',
        selected: [],
        status: 'on',
        type: 'default',
        updateInterval: 30,
        params: {
          category: 'rulesAnalytics',
        },
        resource: 'assets/measures',
        cards: {
          byId: {},
        },
      },
      fences: {
        id: '1',
        name: 'Perímetros',
        description: 'Estatísticas gerais sobre Perímetros',
        createdBy: 'luis@phygitall.rio',
        createdAt: '2021-07-22T17:14:17.173Z',
        selected: [],
        status: 'on',
        updateInterval: 30,
        params: {
          category: 'fencesLocation',
        },
        byTime: {},
      },
      activity: {
        id: '2',
        name: 'Dashboard',
        description: 'Estatísticas gerais sobre os Ciclos de Operação',
        createdBy: 'luis@phygitall.rio',
        createdAt: '2021-06-22T17:14:17.173Z',
        status: 'on',
        updateInterval: 30,
      },
      people: {
        id: '3',
        name: 'Pessoas',
        description: 'Dados sobre as pessoas atualmente na planta',
        createdBy: 'bruno@phygitall.rio',
        createdAt: '2022-02-01T13:14:17.173Z',
      },
      firefighter: {
        id: '4',
        name: 'Brigadistas',
        description: 'Status de Presença dos Brigadistas',
        createdBy: 'matheus@phygitall.rio',
        createdAt: '2022-01-27T16:30:00.000Z',
        status: 'on',
        updateInterval: 30,
      },
    },
    currentId: 'fences',
    filter: '',
    loading: false,
    timeAgo: 'realTime',
  },
  notificationProfiles: {
    byId: {},
    allIds: [],
    loading: true,
    cudLoading: false,
    cudSuccess: false,
  },
  cards: {
    byId: {
      devices: {
        title: 'devicesTotal',
        typesIds: ['app', 'sb'],
        typesTotal: {
          app: {
            counter: 0,
            icon: 'mobile alternate',
            color: 'black',
            label: 'APP',
            tooltip: 'appCardDevicesTip',
            size: 'mini',
          },
          sb: {
            counter: 0,
            icon: 'mobile',
            color: 'black',
            label: 'S.B.',
            tooltip: 'sbCardDevicesTip',
            size: 'mini',
          },
        },
        statusIds: [
          {
            order: 0,
            id: 'inUse',
            label: 'inUse',
            tooltip: 'deviceInUse',
            color: 'blue',
            icon: 'device-full',
          },
          {
            order: 1,
            id: 'charging',
            label: 'charging',
            tooltip: 'deviceCharging',
            color: '#FFBF00',
            icon: 'device-charging',
          },
          {
            order: 2,
            id: 'available',
            label: 'available',
            tooltip: 'deviceAvailable',
            color: 'green',
            icon: 'device-discharge',
          },
          {
            order: 3,
            id: 'disconnected',
            label: 'disconnected',
            tooltip: 'deviceDisconnnected',
            color: 'red',
            icon: 'device-unknown',
          },
        ],
        statusCounter: {
          inUse: { app: 0, sb: 0 },
          charging: { app: '----', sb: 0 },
          available: { app: '----', sb: 0 },
          disconnected: { app: 0, sb: 0 },
        },
      },
    },
    allIds: ['lives', 'devices'],
    loading: true,
  },
  tables: {
    allIds: [
      'people',
      'peopleType',
      'associations',
      'devices',
      'assets',
      'fences',
      'indoor',
      'refs',
      'rules',
      'messages',
      'notificationProfiles',
      'insights',
      'firefighter',
      'locationHist',
      'logouts',
      'measuresHist',
      'interactions',
      'units',
      'labelingTool',
      'motionChart',
      'reports',
      'manageandsecurity',
    ],
    byId: {
      people: {
        label: 'Pessoas',
        icon: 'user',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'groups', 'columns', 'delete', 'export', 'refresh'],
      },
      types: {
        label: 'Atribuições',
        icon: 'user md',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'groups', 'columns', 'delete', 'export', 'refresh'],
      },
      assets: {
        label: 'Ativos',
        icon: 'car',
        autoUpdate: true,
        loading: false,
        showModal: false,
        allActivePeopleIds: [],
        menuButtons: ['new', 'edit', 'groups', 'columns', 'delete', 'export'],
      },
      locationHist: {
        label: 'locationHist',
        icon: 'history',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['columns', 'export', 'refresh'],
      },
      logouts: {
        label: 'Logout',
        icon: 'log out',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['columns', 'export', 'refresh'],
      },
      devices: {
        label: 'Dispositivos',
        icon: 'microchip',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['provision', 'groups', 'columns', 'import', 'export', 'refresh'],
      },
      firefighter: {
        label: 'Brigadistas',
        icon: 'fire extinguisher',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['export', 'refresh', 'toggle fullscreen'],
      },
      measuresHist: {
        data: [],
        label: 'measuresHist',
        icon: 'history',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['histFilter', 'export', 'refresh', 'save', 'viewOptions'],
        categoriesOptions: [],
        assetsOptions: [],
        measuresOptions: [],
        selectedRelativeTime: [],
        selectedAsset: [],
        selectedCategory: [],
        selectedMeasures: [],
        showSaveModal: false,
        filters: [
          {
            id: 'relativeTime',
            iconName: 'calendar',
            title: 'Intervalo',
            description: 'Selecione o Intervalo de Tempo',
          },
          { id: 'asset', iconName: 'user', title: 'Pessoa/Ativo', description: 'Selecione o Ativo' },
          { id: 'category', iconName: 'sitemap', title: 'Categoria', description: 'Selecione a Categoria' },
          { id: 'measure', iconName: 'info circle', title: 'Medida', description: 'Selecione a Medida' },
        ],
        currentFilterStep: 0,
        filterStepLoading: undefined,
        filterStepError: undefined,
      },
      units: {
        label: 'Plantas',
        icon: 'map',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'groups', 'columns', 'delete', 'export', 'refresh'],
      },
      indoor: {
        label: 'Plantas',
        icon: 'map',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'groups', 'columns', 'delete', 'export', 'refresh'],
      },
      refs: {
        label: 'Referências',
        icon: 'map marker alternate',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['groups', 'columns', 'delete', 'export', 'refresh'],
      },
      messages: {
        label: 'Mensagens',
        icon: 'envelope',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'columns', 'delete', 'import', 'export'],
      },
      notificationProfiles: {
        label: 'Perfis',
        icon: 'warning',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'columns', 'delete', 'export', 'refresh'],
      },
      rules: {
        label: 'Regras',
        icon: 'alarm',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['new', 'edit', 'columns', 'delete', 'export'],
      },
      fences: {
        label: 'Perímetros',
        icon: 'th',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['groups', 'rules', 'columns', 'delete', 'import', 'export', 'refresh'],
      },
      insights: {
        label: 'Insights',
        icon: 'dashboard',
        autoUpdate: true,
        loading: false,
        showModal: false,
        // insights is somewhat complex, so I'll deal with the menuButtons later
        // menuButtons: ['export', 'time', 'refresh'],
      },
      manageandsecurity: {
        label: 'Dashboard',
        icon: 'dashboard',
        autoUpdate: false,
        loading: true,
        showModal: false,
        menuButtons: ['toggle fullscreen'],
      },
      reports: {
        label: 'Relatórios',
        icon: 'download',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: null, // the reports tab only has menuButtons when a report has been requested
        fetchLoading: false,
        fetchSuccess: false,
        showDateModal: false,
        reportType: '',
        reportData: '',
        reportColumns: [],
      },
      interactions: {
        label: 'Interações',
        icon: 'group',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['columns', 'export', 'refresh'],
      },
      notifications: {
        byId: {},
        allIds: [],
        label: 'Notifications',
        autoUpdate: true,
        loading: false,
        menuButtons: ['columns', 'calendar', 'export'],
      },
      preferences: {
        label: 'Preferências',
        icon: 'settings',
        showModal: false,
        menuButtons: [],
      },

      // the next tabs are not present in the PhygitallDevs tenant
      manualYamazumi: {
        label: 'manualYamazumi',
        icon: 'dashboard',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['export', 'time', 'shift', 'carModel'],
      },
      automaticYamazumi: {
        label: 'automaticYamazumi',
        icon: 'dashboard',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['export', 'time', 'shift', 'carModel'],
      },
      cycleTimeMeasurement: {
        label: 'cycleTimeMeasurement',
        icon: 'dashboard',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: [], // this tab actually had all the buttons, but I think it can be empty for now
      },
      labelingTool: {
        label: 'Ferramenta de Coleta',
        icon: 'info',
        loading: false,
        showModal: false,
      },
      motionChart: {
        label: 'Movimentos',
        icon: 'dashboard',
        autoUpdate: true,
        loading: false,
        showModal: false,
        menuButtons: ['toggle fullscreen', 'refresh', 'aiModel'],
      },
    },
    current: { autoUpdate: true, tab: '' },
    error: null,
  },
  locationHist: [],
}

export default initialStore
