import request, { urlBase } from 'Apis/rest'
import axios from 'axios'

export const fetchRefs = () => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  const res = await request.get('/refs', {
    headers: {
      Authorization: 'Bearer ' + jwt,
    },
  })
  dispatch({ type: 'refsInitialStore', payload: res.data })
}

export const fetchRefType = () => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  const res = await axios.get(urlBase + '/modules/refs/type', {
    headers: {
      Authorization: 'Bearer ' + jwt,
    },
  })
  dispatch({ type: 'refTypesInitialStore', payload: res.data })
}
