import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import m from 'moment'
import ReactTable from 'Utils/reactTable'
import { fetchMeasuresHistory } from 'Store/actions/tables-action'
import { useTranslation } from 'react-i18next'
import TableSpan from 'Components/SidebarMenu/TableSpan'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import LineChartView from '../LineChartView'

const MeasuresHistTemplateContent = ({ tableName }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const filters = useSelector((state) => {
    return state.login.preferences.routesPanels.byId[tableName].filters
  })
  const measuresHistData = useObjSelector((state) => state.tables.byId.measuresHist.data)

  const selectedPreferenceMeasures = useObjSelector((state) => {
    return state.login.preferences.routesPanels.byId[tableName].filters.find((e) => e.id === 'measure').value
  })
  const selectedPanelMeasures = useObjSelector((state) => state.tables.byId[tableName]?.selectedMeasures)
  const selectedMeasures = selectedPanelMeasures?.length > 0 ? selectedPanelMeasures : selectedPreferenceMeasures
  const loading = useSelector((state) => state.tables.byId.measuresHist.loading)
  const currentTableView = useSelector((state) => state.tables.byId[tableName]?.view)

  const [currentSort, setCurrentSort] = useState('time')
  const [currentGroup, setCurrentGroup] = useState([])

  useEffect(() => {
    dispatch(fetchMeasuresHistory(filters))
  }, [dispatch, filters])

  const fetchAction = () => {
    dispatch(fetchMeasuresHistory(filters))
  }

  const columns = useMemo(
    () => [
      {
        Header: t('assetName'),
        accessor: 'assetName',
        aggregate: 'uniqueCount',
        Cell: ({ value }) => <TableSpan value={value} width="200px" />,
        Aggregated: ({ value }) => `${value} nomes`,
      },

      {
        Header: t('category'),
        accessor: 'category',
        Cell: ({ value }) => t(value),
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} categorias`,
      },
      ...selectedMeasures.map((each) => ({
        Header: t(each),
        accessor: each,
        aggregate: each === 'Caixas_levantadas' ? 'max' : 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${each === 'Caixas_levantadas' ? '(total)' : 'medidas'}`,
      })),
      {
        id: 'day',
        Header: t('day'),
        accessor: (originalRow) => m(originalRow.time).format('ll'),
        Cell: ({ value }) => <TableSpan value={value} width="160px" />,
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} dias`,
        disableGlobalFilter: true,
      },
      {
        id: 'time',
        Header: t('time'),
        accessor: 'time',
        Cell: ({ value }) => <TableSpan value={m(value).format('HH:mm:ss')} capitalize width="160px" />,
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} horários`,
        disableGlobalFilter: true,
      },
    ],
    [t, selectedMeasures]
  )

  return !currentTableView || currentTableView === 'table' ? (
    <ReactTable
      table={tableName}
      tableName={tableName}
      setCurrentSort={setCurrentSort}
      currentSort={currentSort}
      columns={columns}
      data={measuresHistData}
      fetchAction={fetchAction}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      menuButtons={['export', 'refresh', 'viewOptions']}
      showPlaceholders={loading}
    />
  ) : (
    <LineChartView tableName={tableName} fetchAction={fetchAction} />
  )
}

export default MeasuresHistTemplateContent
