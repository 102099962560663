import React, { useEffect, useState } from 'react'
import ReactTable from 'Utils/reactTable'
import { ReportsColumns, ReportsColumnsCustom } from './columns'
import { useTranslation } from 'react-i18next'
import SelectDateModal from './SelectDateModal'
import { RootStore } from 'Store/initialStore'
import { useSelector, useDispatch } from 'react-redux'
//import ReportsRoutes from './ReportsRoutes'
import { useRouteMatch, Link, Redirect } from 'react-router-dom'
import { Breadcrumb } from 'semantic-ui-react'

const lowerCaseStyle = {
  textTransform: 'none',
}

const BreadcrumbReports = (props: any) => (
  <Breadcrumb size={'big'}>
    {props.home && (
      <Breadcrumb.Section>
        <Link to={'/app/reports'}>{props.home}</Link>
      </Breadcrumb.Section>
    )}
    {props.home && <Breadcrumb.Divider icon="right chevron" />}
    <Breadcrumb.Section active style={props.lowercase ? lowerCaseStyle : null}>
      {props.current}
    </Breadcrumb.Section>
  </Breadcrumb>
)

const tableName = 'reports'

const ReportsContent = () => {
  const reportsAllIds = useSelector((state: RootStore) => state.modules?.reports?.allIds || [])
  const reportsByIds = useSelector((state: RootStore) => state.modules?.reports?.byId || [])

  const reportsIds = reportsAllIds.map((idR: any) => reportsByIds[idR].info.id)
  // using this to make a clone of the presenceByFences report
  reportsIds.push('presenceByFencesGrouped')

  const { t } = useTranslation()
  const dispatch = useDispatch()
  let match = useRouteMatch()
  const id = match?.params?.id

  const [currentSort, setCurrentSort] = React.useState('label')
  const tableLabel = id ? (
    <BreadcrumbReports home={t('Reports')} current={t(id + '_label')} lowercase />
  ) : (
    <BreadcrumbReports current={t('Reports')} />
  )

  const showDate = useSelector((state: RootStore) => state.tables.byId.reports.showDateModal)

  const cols = useSelector((state: RootStore) => state.tables.byId.reports.reportColumns || [])
  const rows = useSelector((state: RootStore) => state.tables.byId.reports.reportPayload || [])
  const type = useSelector((state: RootStore) => state.tables.byId.reports.reportType || '')
  const reportViewForm = useSelector((state: RootStore) => state.tables.byId.reports.reportViewForm || '')

  useEffect(() => {
    // check if got data and clear it
    if (!id && reportViewForm) {
      dispatch({
        type: 'FETCH_REPORT_CLEAR_STATE',
      })
    }
    if (id && !showDate && type !== id) {
      // show modal
      dispatch({
        type: 'SELECT_DATE_REPORTS_OPEN',
        payload: id,
        id: 'preview',
      })
    }
  }, [reportViewForm, showDate, id, type, dispatch])

  const columns = React.useMemo(
    () => (id ? ReportsColumnsCustom(t, cols) : ReportsColumns(t, dispatch, match)),
    [t, cols, id, dispatch, match]
  )
  const data = id
    ? rows
        .filter((v: any, i: number) => i !== 0)
        .map((v: any, i: number) => {
          let row = {}
          v.forEach((vi: any, j: number) => {
            row['col' + j] = typeof vi === 'number' ? vi.toFixed(0) : vi
          })
          return row
        })
    : reportsIds.map((id) => ({
        label: t(id + '_label'),
        description: t(id + '_description'),
        resource: id,
        preview: id,
      }))

  // preset the first column to be group when using that cloned report
  const [currentGroup, setCurrentGroup] = useState(id === 'presenceByFencesGrouped' ? ['col0'] : [''])
  if (id && reportsIds.length > 0 && !reportsIds.find((e) => e === id)) return <Redirect to="/app/reports" />

  return (
    <>
      <ReactTable
        table={tableName}
        tableLabel={tableLabel}
        columns={columns}
        data={data}
        menuButtons={id ? ['export', 'filter'] : []}
        setCurrentSort={setCurrentSort}
        currentSort={currentSort}
        currentGroup={currentGroup}
        setCurrentGroup={setCurrentGroup}
      />

      {showDate && <SelectDateModal open={showDate} />}
    </>
  )
}

export default ReportsContent
