import { TableColumn } from '../types'
import { TFunction } from 'i18next'

export const CycleTimeMeasurementColumns = (t: TFunction) => {
  return [
    {
      Header: t('phase'),
      accessor: 'phase',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} nomes`,
    },
    {
      Header: t('trigger'),
      accessor: 'trigger',
    },
    {
      Header: t('operation'),
      accessor: 'operation',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} nomes`,
    },
    {
      Header: t('startTime'),
      accessor: 'startTime',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} nomes`,
    },
    {
      Header: t('endTime'),
      accessor: 'endTime',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} nomes`,
    },
    {
      Header: t('duration'),
      accessor: 'duration',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} nomes`,
    },
    {
      Header: t('cycle'),
      accessor: 'cycle',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} nomes`,
    },
  ] as TableColumn[]
}
