import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { Input, Form, Dropdown /*Divider, Header */ } from 'semantic-ui-react'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import colors from 'Utils/theme/colors'
import icons from 'Utils/theme/icons'
import { RootStore } from 'Store/initialStore'
import { insertDynamicData, updateDynamicData } from 'Store/actions/modules-action'
import { GenericFormProps } from './types/GenericFormProps'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type ErrorForm = {
  name: boolean
}

type FieldsState = {
  code: string
  name: string
  table: string | undefined
  color: string | number | boolean | (string | number | boolean)[] | undefined
  icon: string | number | boolean | (string | number | boolean)[] | undefined
  description: string
}

const Label = styled.label`
  text-transform: capitalize !important;
`
const Span = styled.span`
  text-transform: capitalize !important;
`

const TypesForm: React.FC<GenericFormProps> = ({
  tableLabel,
  table,
  handleSave,
  handleCancel,
  addOpen,
  confirmAction,
  setAddOpen,
  editId,
}) => {
  const { byId } = useSelector((state: RootStore) => state.modules.types, shallowEqual)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (confirmAction === 'update') {
      setFields({
        code: byId[editId]?.info?.code || '',
        name: byId[editId]?.info?.label || '',
        table: byId[editId]?.info?.table || 'assets.people',
        color: byId[editId]?.info?.color || '',
        icon: byId[editId]?.info?.icon || '',
        description: byId[editId]?.info?.description || '',
      })
    }
  }, [confirmAction, byId, editId])

  const [fields, setFields] = useState<FieldsState>({
    code: '',
    name: '',
    table: 'assets.people',
    description: '',
    color: '',
    icon: '',
  })

  const [changes, setChanges] = useState({})

  const [errorObj, setErrorObj] = useState<ErrorForm>({ name: false })

  /* Função que salva as informação no banco dependendo se insert ou update*/
  const handleChange = (fields: FieldsState, name: string, value: string) => {
    if (confirmAction === 'insert' && setFields) setFields({ ...fields, [name]: value })
    if (confirmAction === 'update' && setFields) {
      setFields({ ...fields, [name]: value })
      setChanges({ ...changes, [name]: value })
    }
  }

  function checkForm({ name }: { name: string }) {
    if (!name) {
      setErrorObj({ ...errorObj, name: !name })
      return
    }
    if (confirmAction === 'update') {
      let params = {
        id: editId,
        ...fields,
      }
      dispatch(updateDynamicData(params, 'types'))
    } else {
      let data = {
        name: fields.name,
        type: 16,
        info: {
          code: fields.code,
          label: fields.name,
          table: fields.table,
          color: fields.color,
          icon: fields.icon,
          description: fields.description,
        },
      }
      dispatch(insertDynamicData(data, 'types'))
    }
    setAddOpen(false)
  }
  /* As tabelas atualmente habilitadas a ter tipos de atribuições, manter coerência com a tabela Atribuições C:\Users\bruno\Documents\projetos\ygi\src\Components\SidebarMenu\Contents\PeopleType\index.js*/
  const tables = [
    {
      key: 't1',
      text: t('People'),
      value:
        'assets.people' /*, label: { color: 'green', empty: true, circular: true }, icon: { name: 'user', circular: true }*/,
    },
    {
      key: 't2',
      text: t('Assets'),
      value:
        'assets.assets' /*, label: { color: 'blue', empty: true, circular: true }, icon: { name: 'car', circular: true }*/,
    },
    {
      key: 't3',
      text: t('Fences'),
      value:
        'modules.fences' /*, label: { color: 'teal', empty: true, circular: true }, icon: { name: 'user', circular: true }*/,
    },
    {
      key: 't4',
      text: t('Refs'),
      value:
        'modules.refs' /*, label: { color: 'purple', empty: true, circular: true , icon: { name: 'marker', circular: true }} */,
    },
    {
      key: 't5',
      text: t('Routes'),
      value:
        'modules.routes' /*, label: { color: 'orange', empty: true, circular: true } , icon: { name: 'user', circular: true }*/,
    },
  ]
  //if (!formObj?.name) return null

  return (
    <ModalTemplate
      onClose={handleCancel}
      open={addOpen}
      icon={undefined}
      size="small"
      header={
        confirmAction === 'insert' ? (
          <Span>{`${t('toAdd')} ${t('Assignments')}`}</Span>
        ) : (
          <Span>{`${t('toEdit')} ${t('Assignments')}`}</Span>
        )
      }
      onSave={() => {
        checkForm(fields)
      }}
      onCancel={handleCancel}
    >
      <Form>
        <Form.Group>
          <Form.Field required={false} width={3}>
            <Label>{t('code')}</Label>
            <Input
              name={'code'}
              fluid
              value={fields.code}
              min="0"
              onChange={(e, { name, value }) => handleChange(fields, name, value.slice(0, 8))}
            />
          </Form.Field>
          <Form.Field width={8} required={false}>
            <Label>{t('name')}</Label>
            <Input
              placeholder={t('newName')}
              name={'name'}
              fluid
              value={fields.name}
              onChange={(e, { name, value }) => setFields({ ...fields, [name]: value })}
            />
          </Form.Field>
          {/*Input da tabela referente a essa atribuição. Padrão: tabela Pessoas.*/}
          <Form.Field required={true} width={4}>
            <Label>{t('tables')}</Label>
            <Dropdown
              placeholder={t('selectTable')}
              search
              selection
              value={fields.table}
              options={tables}
              onChange={(e, { value }) => setFields({ ...fields, table: value?.toString() })}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field required={false} width={6}>
            <Label>{t('color')}</Label>
            <Dropdown
              placeholder={t('noColor')}
              search
              selection
              value={fields.color}
              options={colors.sort((a, b) =>
                a.text && b.text ? a.text.toLowerCase().localeCompare(b.text.toLowerCase()) : 1
              )}
              onChange={(e, { value }) => setFields({ ...fields, color: value })}
            />
          </Form.Field>
          {
            <Form.Field required={false} width={4}>
              <Label>{t('icon')}</Label>
              <Dropdown
                placeholder={t('noIcon')}
                search
                selection
                value={fields.icon}
                options={icons.sort((a, b) =>
                  a.text && b.text ? a.text.toLowerCase().localeCompare(b.text.toLowerCase()) : 1
                )}
                onChange={(e, { value }) => setFields({ ...fields, icon: value })}
              />
            </Form.Field>
          }
        </Form.Group>

        <Form.Field required={false}>
          <Label>{t('description')}</Label>
          <Input
            name={'description'}
            fluid
            value={fields.description}
            onChange={(e, { name, value }) => setFields({ ...fields, [name]: value })}
          />
        </Form.Field>
      </Form>
    </ModalTemplate>
  )
}

export default TypesForm
