/*  Author: Luís Mourão
    Type: action
    Description: action from modules store
    To do list: Incluir Update
*/
/*global localStorage*/
import request, { urlBase, URL_BASE } from 'Apis/rest'
import axios from 'axios'

export const fetchModules = (token) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let tokenParam = ''
  if (token) tokenParam = '?nextToken=' + token
  else dispatch({ type: 'FETCH_MODULES_START' })
  await axios
    .get(URL_BASE + '/modules/all' + tokenParam, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    .then((resp) => {
      //console.log('resposta',resp)
      const { data } = resp
      dispatch({ type: 'FETCH_MODULES', payload: data.modules })
      dispatch({
        type: 'indoorInitialStore',
        payload: [{ indoor: data.modules.filter((m) => m.moduleType === 'units') }],
      })
      if (data.nextToken) dispatch(fetchModules(data.nextToken))
    })
}

export const saveGroup = (table, groupName, selected, actionType) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  const body = { groupName, selected, moduleType: table, associate: actionType !== 'delete' }
  const headers = { headers: { Authorization: 'Bearer ' + jwt } }
  const authorization = { Authorization: 'Bearer ' + jwt }

  if (actionType !== 'delete')
    request
      .post(`/core/v0/modules/${table}/groups`, body, headers)
      .then(dispatch({ type: 'UPDATE_MODULE_GROUPS', payload: { moduleType: table, groupName, selected } }))
      .then(() =>
        axios.post(`https://node-red.phygitall.com.br/fencesChanged`, authorization, { headers: authorization })
      )
  else
    axios
      .post(urlBase + `/modules/${table}/groups`, body, headers)
      .then(dispatch({ type: 'REMOVE_MODULE_GROUPS', payload: { moduleType: table, groupName, selected } }))
      .then(() =>
        axios.post(`https://node-red.phygitall.com.br/fencesChanged`, authorization, { headers: authorization })
      )
}

export const insertModule = (geojson, company, name, email, type) => ({
  type: 'server/insere/modulo',
  fields: {
    nome: `'${name}'`,
    type: `'${type}'`,
    empresa: `'${company}'`,
    info: `'${geojson}'`,
    login: `'${email}'`,
  },
})

export const updateModule = (modules) => {
  return {
    type: 'server/atualiza/modulo',
    where: ['id_modulo IN (' + modules + ')'],
  }
}

export const editModule =
  ({ id, field, value }, type = 'fences') =>
  async (dispatch) => {
    const body = {
      id,
      field,
      value,
    }
    const jwt = localStorage.getItem('id_token')
    try {
      dispatch({ type: 'EDIT_MODULE_START', body })
      axios
        .put(`${urlBase}/modules/${type}`, body, {
          headers: { Authorization: 'Bearer ' + jwt },
        })
        .then((res) => dispatch({ type: 'EDIT_MODULE_FINISH', res }))
    } catch (err) {
      console.error(err)
      dispatch({ type: 'EDIT_MODULE_FAILURE', err })
    }
  }

export const deleteModules = (modules) => {
  return {
    type: 'server/deleta/modulo',
    where: ['id_modulo IN (' + modules + ')'],
  }
}

export const setCurrentModule = (id, payload) => ({
  type: 'SET_CURRENT_MODULE',
  id,
  payload,
})

export const createModule =
  ({ moduleType, name, info, login }) =>
  async (dispatch) => {
    const jwt = localStorage.getItem('id_token')
    dispatch({ type: 'CREATE_MODULE_REQUEST', payload: { isLoading: true, moduleType, name, createdBy: login } })
    try {
      const res = await request.post(
        `core/v0/${moduleType}`,
        {
          name,
          info: JSON.stringify(info),
          createdBy: login,
        },
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        }
      )
      dispatch({
        type: 'CREATE_MODULE_SUCCESSFULL',
        payload: { ...res.data.fences, isLoading: false, success: res.data.success, moduleType },
        id: res.data.fences.id,
      })
    } catch (err) {
      console.error(err)
      dispatch({ type: 'CREATE_MODULE_FAILURE', payload: { moduleType, isLoading: false, success: false } })
    }
  }

export const fecthDynamicData = (params) => async (dispatch) => {
  try {
    const jwt = localStorage.getItem('id_token')
    const { fecthAction, fetchResponse } = params
    const res = await request.get(fecthAction, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    dispatch({ type: fetchResponse, payload: res.data })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'DYNAMIC_FETCH_FAILURE', payload: params })
  }
}

export const insertDynamicData = (data, type) => async (dispatch) => {
  dispatch({ type: 'CREATE_MODULE_REQUEST', payload: { moduleTypes: type } })
  const jwt = localStorage.getItem('id_token')
  const body = data
  try {
    const res = await request.post('/core/v0/modules/' + type, body, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    dispatch({ type: 'CREATE_MODULE_SUCCESSFULL', payload: res.data })
  } catch (err) {
    dispatch({ type: 'CREATE_MODULE_FAILURE', payload: err })
  }
}

export const updateDynamicData = (params, type, action, auth) => async (dispatch) => {
  try {
    const jwt = auth || localStorage.getItem('id_token')
    const body = params
    const res = await axios.put(action || urlBase + '/modules/' + type, body, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    dispatch({ type: params.response || 'DYNAMIC_UPDATE_SUCCESS', payload: res?.data, id: res?.data?.id })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'DYNAMIC_UPDATE_FAILURE', payload: params })
  }
}

export const deleteDynamicData = (params, moduleType, auth) => async (dispatch) => {
  try {
    const jwt = auth || localStorage.getItem('id_token')
    axios
      .delete(urlBase + `/modules/${moduleType}/?ids=${params.ids.toString()}`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then(
        dispatch({
          type: params.response || 'DYNAMIC_DELETE_SUCCESS',
          payload: { ...params, moduleType: moduleType },
          id: params.ids,
        })
      )
  } catch (err) {
    console.error(err)
    dispatch({ type: 'DYNAMIC_DELETE_FAILURE', payload: { params: params, err: err } })
  }
}

export const insertStarted = (params) => {
  return {
    type: 'MODULE_INSERT_STARTED',
    payload: params,
  }
}

export const insertStopped = (params) => {
  return {
    type: 'MODULE_INSERT_STOPPED',
    payload: params,
  }
}
export const updateWiP = (params) => ({
  type: 'MODULE_UPDATE_WIP',
  payload: params,
})

export const clearWiP = (params) => ({
  type: 'MODULE_CLEAR_WIP',
  payload: params,
})

export const fetchModuleChanges = (id, moduleType) => async (dispatch) => {
  try {
    const jwt = localStorage.getItem('id_token')

    const res = await request.get(urlBase + `/modules/${moduleType}/changes?id=${id}`, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    dispatch({ type: 'MODULE_FETCH_CHANGES_SUCCESS', payload: res.data.body })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'MODULE_FETCH_CHANGES_FAILURE', payload: err })
  }
}

export const clearModuleChanges = (params) => ({
  type: 'MODULE_FETCH_CHANGES_CLEAR',
  payload: params,
})
