import { FILTER_HISTORY } from '../actions/history-action'

import initialStore from 'Store/initialStore'
import deepCopy from 'Utils/deepCopy'
import { showGenericError } from 'Utils/notifications/common'
import { concat } from 'lodash'

export default function historyReducer(state = initialStore.history, { type, payload, assetId, category, flag }) {
  let allIds = []
  let byId = {}

  switch (type) {
    case FILTER_HISTORY:
      state.fHist = payload ? payload : []
      return { ...state }
    case 'FETCH_HISTORY_START':
      return { ...initialStore.history, ...payload, loading: true, success: undefined, type: state.type }
    case 'FETCH_HISTORY_FAILURE':
      showGenericError()
      return { ...state, loading: false, success: false, error: payload, type: undefined }
    case 'FETCH_HISTORY_SUCCESS': {
      let allAssetsFilteredIndexes = []
      let filteredSize = 0
      allIds = Object.keys(payload.byId)
      Object.entries(payload.byId).forEach((eachEntrie) => {
        const allIndexes = eachEntrie[1].allIndexes
        const allFilteredIndexes = allIndexes.reduce((prev, current) => {
          if (eachEntrie[1].byIndex[current].info.coords?.lat) {
            prev = [...prev, current]
          }
          return prev
        }, [])
        allAssetsFilteredIndexes = concat(allAssetsFilteredIndexes, allFilteredIndexes)
        const lastIndex = allFilteredIndexes[allFilteredIndexes.length - 1]
        byId[eachEntrie[0]] = {
          ...eachEntrie[1],
          ...eachEntrie[1].byIndex[lastIndex],
          allFilteredIndexes,
        }
        filteredSize += allFilteredIndexes.length
      })
      allAssetsFilteredIndexes = allAssetsFilteredIndexes.sort((a, b) => a - b)
      const sliderMin = 0
      const sliderMax = filteredSize - 1 || 0

      return {
        ...state,
        allIds,
        allAssetsFilteredIndexes,
        byId,
        loading: false,
        success: true,
        slider: {
          min: sliderMin,
          max: sliderMax,
          filteredMin: allAssetsFilteredIndexes[sliderMin],
          filteredMax: allAssetsFilteredIndexes[sliderMax],
        },
        size: payload.size,
        initial: true,
        filteredSize,
      }
    }

    case 'EMPTY_HISTORY':
      return initialStore.history

    case 'SLIDER_CHANGE':
      byId = deepCopy(state.byId)
      const filteredMin = state.allAssetsFilteredIndexes[payload.min]
      const filteredMax = state.allAssetsFilteredIndexes[payload.max]
      state.allIds.forEach((eachId) => {
        const currentIndex = state.byId[eachId].allFilteredIndexes
          .reverse()
          .find((eachIndex) => eachIndex >= filteredMin && eachIndex <= filteredMax)
        if (typeof currentIndex === 'number') {
          Object.keys(state.byId[eachId].info || {}).forEach((eachCat) => {
            byId[eachId].info[eachCat] = eachCat === 'time' ? null : {}
          })
          byId[eachId].info = { ...byId[eachId].info, ...byId[eachId].byIndex[currentIndex].info }
        } else byId[eachId].info = null
      })
      return { ...state, byId, slider: { ...payload, filteredMin, filteredMax } }
    case 'FETCH_POPUP_START':
      return { ...state, popup: {} }
    case 'FETCH_POPUP_SUCCESS':
      return { ...state, popup: { current: payload, success: true } }
    case 'FETCH_POPUP_FAILURE':
      return { ...state, popup: { success: false } }

    case 'CHANGE_HIST_TYPE':
      return { ...state, type: payload }

    case 'FETCH_HIST_CATEGORIES_START':
      return { ...state, loadingCategories: true, currentAsset: assetId }

    case 'FETCH_HIST_CATEGORIES_SUCCESS':
      if (!state.byId[assetId.info]) {
        byId = deepCopy(state.byId)
        byId[assetId].info = { ...payload, ...state.byId[assetId].info }
      }
      return { ...state, loadingCategories: false, byId }

    case 'FETCH_HIST_CATEGORY_START':
      if (state.byId[assetId]) {
        byId = deepCopy(state.byId)
        byId[assetId].currentCategory = category
      }
      return { ...state, loadingCategoryMeasures: category !== 'coords' && payload.load, byId }

    case 'FETCH_HIST_CATEGORY_SUCCESS':
      byId = deepCopy(state.byId)
      byId[assetId].info[category] = payload[category]
      return { ...state, byId, loadingCategoryMeasures: false }

    case 'CHANGE_HIST_FLAG':
      return { ...state, [flag]: payload }

    default:
      return state
  }
}
