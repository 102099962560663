import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from 'Utils/reactTable'
import { fetchModules, saveGroup } from 'Store/actions/modules-action'
import { deleteDynamicData, updateDynamicData } from 'Store/actions/modules-action'
import { InputTemplate } from 'Utils/components/InputTemplate.js'
import colors from 'Utils/theme/colors'
import { threeColumns } from 'Utils/threeColumns'
import { saveColumnsPreference } from 'Store/actions/login-action'
//import risks from 'Utils/theme/risks'

export const RefsContent = () => {
  const { t } = useTranslation()
  const tableName = 'refs'
  const [currentSort, setCurrentSort] = React.useState('info.properties.label')
  //const empresa = useSelector((state) => state.login.empresa)
  const tableLabel = 'Referências'
  const refsStr = useSelector((state) => JSON.stringify(state.modules.refs))

  const dispatch = useDispatch()
  const fetchAction = () => dispatch(fetchModules())
  const groupAction = (groupName, selected, action) => dispatch(saveGroup('refs', groupName, selected, action))
  const deleteAction = (ids) => dispatch(deleteDynamicData(ids, 'refs'))

  const [theseColumns, setTheseColumns] = React.useState([])
  // let hiddenColumns = ['id', 'info.properties.description']
  const hiddenColumns = useSelector((state) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns)
  // let hiddenButtons = ['rules', 'import', 'edit', 'new', 'fullscreen', 'toggle fullscreen', 'calendar']

  const groupedValuesColumns = useSelector(
    (state) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const columns = React.useMemo(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} ids`,
      },
      {
        Header: t('info.properties.label'),
        accessor: 'info.properties.label',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} referências`,
        Cell: (props) => (
          <InputTemplate
            value={props.value || '<<sem nome>>'}
            id={props?.cell?.row?.values?.id}
            action={(id, field, value) => dispatch(updateDynamicData({ id: id, field: field, value: value }, 'refs'))}
            field={'info.properties.label'}
            table={tableName}
          />
        ),
      },
      {
        Header: t('description'),
        accessor: 'info.properties.description',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} descrições`,
        Cell: (props) => (
          <InputTemplate
            value={props.value || '<<sem descrição>>'}
            id={props?.cell?.row?.values?.id}
            action={(id, field, value) => dispatch(updateDynamicData({ id: id, field: field, value: value }, 'refs'))}
            field={'info.properties.description'}
            table={tableName}
          />
        ),
      },
      {
        Header: t('groups'),
        accessor: 'groupsText',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'grupos'}`,
      },
      {
        Header: t('color'),
        accessor: 'info.properties.color',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('colors')}`,
        Cell: (props) => (
          <InputTemplate
            options={colors}
            value={props.value || 'blue'}
            id={props?.cell?.row?.values?.id}
            action={(id, field, value) => dispatch(updateDynamicData({ id: id, field: field, value: value }, 'refs'))}
            field={'info.properties.color'}
            table={tableName}
          />
        ),
      },

      ...threeColumns(t),
    ],
    [t, dispatch]
  )

  const data = React.useMemo(() => {
    const refs = JSON.parse(refsStr)
    return refs?.allIds?.map((id) => {
      let ref = refs?.byId[id]
      return {
        ...refs,
        info: {
          ...ref?.info,
          label: ref?.info?.properties?.label || ref.name,
        },
        groupsText: ref?.groups
          ?.filter((g) => !g.startsWith('R0'))
          ?.sort()
          ?.join(', '),
        rulesText: ref?.groups
          ?.filter((g) => g.startsWith('R0'))
          ?.sort()
          ?.join(', '),
      }
    })
  }, [refsStr])

  return (
    <ReactTable
      table={tableName}
      tableLabel={tableLabel}
      hiddenColumns={hiddenColumns}
      columns={columns}
      data={data}
      theseColumns={theseColumns}
      setTheseColumns={setTheseColumns}
      // hiddenButtons={hiddenButtons}
      fetchAction={fetchAction}
      groupAction={groupAction}
      deleteAction={deleteAction}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      setCurrentSort={setCurrentSort}
      currentSort={currentSort}
    />
  )
}
