import React from 'react'
import { Header, Menu, Grid } from 'semantic-ui-react'
import { LogTable } from 'Components/Settings'
import { fetchLog } from 'Store/actions/access-control-action'
import { connect } from 'react-redux'

const SideBar = () => {
  const [activeItem, setActiveItem] = React.useState('logs')
  const handleClick = (e, { name }) => setActiveItem(name)
  return (
    <Grid.Column width={4}>
      <Menu pointing vertical>
        <Menu.Item name="general settings" active={activeItem === 'general settings'} onClick={handleClick} />
        <Menu.Item name="access-control" active={activeItem === 'logs'} onClick={handleClick} />
        <Menu.Item name="users" active={activeItem === 'users'} onClick={handleClick} />
        <Menu.Item name="projects" active={activeItem === 'projects'} onClick={handleClick} />
        <Menu.Item name="members" active={activeItem === 'members'} onClick={handleClick} />
      </Menu>
    </Grid.Column>
  )
}

class AccessControlView extends React.Component {
  componentDidMount = () => {
    let obj = {
      page: 1,
      per_page: 5,
    }
    this.props.fetchLog('Ambev', obj)
  }
  render() {
    return (
      <Grid>
        <SideBar />
        <Grid.Column width={12}>
          <Header>Logs</Header>
          <LogTable entries={this.props.entries} />
        </Grid.Column>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    entries: state.accessControl?.log,
  }
}

export default connect(mapStateToProps, { fetchLog })(AccessControlView)
