import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Icon, Grid } from 'semantic-ui-react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { toggleAsset } from 'Store/actions/assets-action'

export const AssetItem = ({ clickItem, assetDetails, assetId, iconItem }) => {
  const dispatch = useDispatch()
  const name = assetDetails?.name
  const showState = assetDetails?.invisible
  return (
    <Dropdown.Item value={assetId} key={assetId}>
      <DropdownGridItem
        // style={{backgroundColor: 'red'}}
        key={assetId}
      >
        <DropdownGridName
          width={14}
          onClick={() => clickItem(assetId)}
          title={name}
          key={assetId}
          // style={{backgroundColor: 'orange'}}
        >
          {iconItem ? (
            <>
              {iconItem} {name}
            </>
          ) : (
            name
          )}
        </DropdownGridName>

        <ItemEyeGrid width={2} style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            onClick={() => dispatch(toggleAsset(!showState, [assetId]))}
            name={showState ? 'eye slash' : 'eye'}
            size="small"
          />
        </ItemEyeGrid>
      </DropdownGridItem>
    </Dropdown.Item>
  )
}

AssetItem.propTypes = {
  clickItem: PropTypes.func.isRequired,
  assetName: PropTypes.string,
  assetId: PropTypes.string.isRequired,
}

const DropdownGridItem = styled(Grid)``

const ItemEyeGrid = styled(Grid.Column)`
  padding: 10px !important;
  right: '3px';
`

export const DropdownGridName = styled(Grid.Column)`
  top: 2px;
  position: relative;
  padding-left: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex !important;
  align-content: center;
  align-items: center;
`
