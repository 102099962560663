import React, { memo, useState, useEffect } from 'react'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import { useSelector } from 'react-redux'
import { FeatureGroup, Pane, Polygon, GeoJSON, Circle } from 'react-leaflet'
import { useCanAccess } from 'Utils/rbac'
import CustomTooltip from 'Utils/components/CustomTooltip'
import styled from 'styled-components'

const PolygonStyle = styled(Polygon)`
  ${(props) => {
    return ['Tiplam', 'TiplamTest'].includes(props.empresa) ? 'cursor: grab !important' : ''
  }};
`

export const RenderFence = ({ id, click }) => {
  const geoJson = useSelector((state) => state.modules[moduleType]?.byId[id]?.info)
  const moduleType = 'fences'
  const zIndex = useSelector((state) => state.modules[moduleType]?.byId[id]?.info?.properties?.zIndex)

  const typeId = useSelector((state) => state.modules[moduleType]?.byId[id]?.info?.properties?.type)
  const typeData = useSelector((state) => state.modules.types?.byId[parseInt(typeId)]?.info) || {}
  const geometry = useSelector((state) => state.modules[moduleType]?.byId[id]?.info?.geometry) || []

  const typeGeometry = geometry?.type

  const coordinates = geometry?.coordinates
  const tooltipText = useSelector((state) => state.modules[moduleType]?.byId[id]?.info?.properties?.label) || ''
  const color = useSelector((state) => state.modules[moduleType]?.byId[id]?.info?.properties?.color)
  const currentId = useSelector((state) => state.modules[moduleType]?.current?.id)
  const inner = useSelector((state) => state.toggle.showInnerMap)
  const currentInner = useSelector((state) => state.indoor.current?.inner)
  const canAccess = useCanAccess('WRITE')
  const empresa = useSelector((state) => state.login.empresa)
  const fencesEditionMode = useSelector((state) => state.toggle.fencesEditionMode)

  let coords = []
  if (typeGeometry === 'Polygon')
    coords = coordinates[0].map((e) =>
      inner ? innerPosition([e[1], e[0]], 'array', currentInner?.center, currentInner?.bearing) : [e[1], e[0]]
    )
  if (typeGeometry === 'Point')
    coords = inner
      ? innerPosition([coordinates[1], coordinates[0]], 'array', currentInner?.center, currentInner?.bearing)
      : [coordinates[1], coordinates[0]]

  const statsbarId = useSelector((state) => state?.system?.statsbar?.id)
  const editionMode = useSelector((state) => state.modules[moduleType]?.wip?.editMode)
  const idTooltipText = (canAccess ? `${id} - ` : '') + tooltipText
  const [tooltipState, setTooltipState] = useState(idTooltipText)
  const [permanentState, setPermanentState] = useState(false)

  useEffect(() => {
    if (statsbarId === id) {
      setPermanentState(true)
      if (editionMode) setTooltipState(`(Editando) ` + idTooltipText)
      else setTooltipState(idTooltipText)
    }
  }, [id, statsbarId, editionMode, idTooltipText])

  return (
    <FeatureGroup
      key={id}
      onClick={(data) => {
        click(data, id)
      }}
      clickable={true}
      onmouseover={() => setPermanentState(true)}
      onmouseout={() => id !== statsbarId && setPermanentState(false)}

      //onmouseout={() => (id !== statsbarId) && setPermanentState(false)}
    >
      <Pane name={'pane#' + id} style={{ zIndex: zIndex || 220 }}>
        {typeGeometry === 'Polygon' && (
          <PolygonStyle
            fillOpacity={id === currentId ? 0.5 : 0.2}
            empresa={empresa}
            key={id + fencesEditionMode}
            weight={1}
            interactive={empresa !== 'Tiplam' || fencesEditionMode}
            positions={coords}
            color={typeData?.color ? typeData?.color : color ? color : 'blue'}
          >
            <Pane name={'panett#' + id} style={{ zIndex: zIndex + 1 || 221 }}>
              <CustomTooltip permanent={id === statsbarId || permanentState} direction="bottom">
                {tooltipState}
              </CustomTooltip>
            </Pane>
          </PolygonStyle>
        )}
        {typeGeometry === 'MultiPolygon' && (
          <GeoJSON
            fillOpacity={id === currentId ? 0.5 : 0.2}
            key={id + fencesEditionMode}
            data={geoJson}
            interactive={empresa !== 'Tiplam' || fencesEditionMode}
            color={typeData?.color ? typeData?.color : color ? color : 'blue'}
          >
            <Pane name={'panett#' + id} style={{ zIndex: zIndex + 1 || 221 }}>
              <CustomTooltip permanent={permanentState} direction="bottom">
                {tooltipState}
              </CustomTooltip>
            </Pane>
          </GeoJSON>
        )}
        {typeGeometry === 'Point' && (
          <Circle
            fillOpacity={id === currentId ? 0.5 : 0.2}
            key={id + fencesEditionMode}
            radius={geometry.mRadius}
            interactive={empresa !== 'Tiplam' || fencesEditionMode}
            center={coords}
            color={typeData?.color ? typeData?.color : color ? color : 'blue'}
          >
            <Pane name={'panett#' + id} style={{ zIndex: zIndex + 1 || 221 }}>
              <CustomTooltip permanent={permanentState} direction="bottom">
                {tooltipState}
              </CustomTooltip>
            </Pane>
          </Circle>
        )}
      </Pane>
    </FeatureGroup>
  )
}

export default memo(RenderFence)
