import React, { useEffect } from 'react'
//import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import ReactTable from 'Utils/reactTable'
//import { fetchModules, saveGroup } from 'Store/actions/modules-action'
//import { fetchRules, clearRules, subscribeRuleChange, subscribeRuleDelete } from 'Store/actions/rules-action'
import { RootStore } from 'Store/initialStore'
import { MessageColumns } from './columns'
import { deleteMessage, fetchMessages } from 'Store/actions/messages-action'
import { useTranslation } from 'react-i18next'
import { saveColumnsPreference } from 'Store/actions/login-action'

const tableName = 'messages'

export const MessagesContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [currentSort, setCurrentSort] = React.useState('label')
  const tableLabel = 'Mensagens'
  const byId = useSelector((state: RootStore) => state.messages.byId, shallowEqual)
  const allIds = useSelector((state: RootStore) => state.messages.allIds, shallowEqual)

  useEffect(() => {
    dispatch(fetchMessages())
    /* const subChange: any = dispatch(subscribeMessagesChange())
    const subDelete: any = dispatch(subscribeMessagesDelete())
    return () => {
      subChange.unsubscribe()
      subDelete.unsubscribe()
      dispatch(clearMessagess())
    } */
  }, [dispatch])

  const fetchAction = () => dispatch(fetchMessages())
  const deleteAction = (ids: { ids: string[] }) => dispatch(deleteMessage(ids))

  // let hiddenColumns = ['status', 'extra', 'updatedBy', 'updatedFrom', 'createdBy', 'createdFrom']
  const hiddenColumns = useSelector(
    (state: RootStore) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns
  )
  const groupedValuesColumns = useSelector(
    (state: RootStore) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const columns = React.useMemo(() => MessageColumns(t), [t])

  const rows = allIds

  const data = React.useMemo(() => rows.map((id) => byId[id]), [rows, byId])

  return (
    <ReactTable
      table={tableName}
      tableLabel={tableLabel}
      hiddenColumns={hiddenColumns}
      columns={columns}
      data={data}
      fetchAction={fetchAction}
      deleteAction={deleteAction}
      setCurrentSort={setCurrentSort}
      currentSort={currentSort}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
    />
  )
}
