import axios, { AxiosError } from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Button, Grid, Header, Message, Loader, Segment } from 'semantic-ui-react'

type TParams = { hash: string }
interface IData {
  name: string
  nfcId: string
  position: string
  sector: string
  registrationId: number
  email: string
  company: string
  onBoarding: string
  cetifications: string
}

export const RegistrationCode = ({ match }: RouteComponentProps<TParams>) => {
  const [success, setSuccess] = useState<boolean>()
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<IData>()
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios(`${URL}/registration/${match.params.hash}`)
        setData(result.data)
      } catch (err) {
        setError(true)
      }
    }
    fetchData()
  }, [match.params.hash])
  const handleApprove = async () => {
    try {
      setLoading(true)
      await axios.post(URL + '/registration/' + match.params.hash + '/activate', {
        hash: match.params.hash,
        name: data?.name,
        cardId: data?.nfcId,
      })
      setSuccess(true)
    } catch (err) {
      const errors = err as Error | AxiosError<any>
      setSuccess(false)
      setLoading(false)
      if (!axios.isAxiosError(errors)) {
        setErrorMsg(errors.message)
        return
      }
      setErrorMsg(errors?.response?.data || 'Erro desconhecido')
    }
  }
  if (!match.params.hash) return null
  return (
    <Grid textAlign="center" style={{ height: '100vh', background: '#f7f7f7' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 500 }}>
        <Content
          data={data}
          handleClick={handleApprove}
          success={success}
          error={error}
          isLoading={isLoading}
          errorMsg={errorMsg}
        />
      </Grid.Column>
    </Grid>
  )
}

interface IContent {
  handleClick: () => void
  success: boolean | undefined
  error: boolean
  isLoading: boolean
  errorMsg: string
  data: IData | undefined
}

const Content = ({ handleClick, success, data, error, isLoading, errorMsg }: IContent) => {
  if (error) return <ErrorBox />
  if (!data) return <Loader active />
  if (success) return <SuccessBox name={data.name} />
  return (
    <Fragment>
      <Header as="h2" color="teal" textAlign="center" content="Autorização de Cadastro" />
      <Segment>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Nome</Header>
          <span>{data.name}</span>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Empresa</Header>
          <span>{data.company}</span>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Email</Header>
          <span>{data.email}</span>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Matricula</Header>
          <span>{data.registrationId}</span>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Número Crachá (NFC)</Header>
          <span>{data.nfcId}</span>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Cargo</Header>
          <span>{data.position}</span>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Setor</Header>
          <span>{data.sector}</span>
        </div>
        {data.cetifications && (
          <div style={{ marginBottom: 10 }}>
            <Header sub>Treinamento</Header>
            <span>{data.cetifications}</span>
          </div>
        )}
        <div style={{ marginBottom: 10 }}>
          <Header sub>Ambientação</Header>
          <span>{data.onBoarding}</span>
        </div>
        <Button basic color="green" onClick={handleClick} loading={isLoading} disabled={isLoading}>
          Autorizar
        </Button>
      </Segment>
      {errorMsg.length > 0 && <Message negative header="Erro" content={errorMsg} />}
    </Fragment>
  )
}

const SuccessBox = ({ name }: { name: string }) => (
  <Message positive header="Código ativado" content={`O código de acesso de ${name} foi ativado.`} />
)

const ErrorBox = () => <Message negative header="Link inválido" content="Verifique se o link inserido está correto." />

const URL = 'https://jstm1estza.execute-api.us-east-2.amazonaws.com/v0'
