import axios from 'axios'
import { urlBase, URL_BASE } from './rest'

const tenantAuth = {
  endpoint: 'https://api.vli-logistica.com.br',
  clientId: '84aa0e19-2662-31a7-99ca-8d4c67a20798',
}
const userInfoIntegration = {
  getUserInfoByNfc: async (nfcId, ts) => {
    const accessToken = await getAuthToken()
    return axios.get(
      `${tenantAuth.endpoint}/proteca-a-vida/api/v1/users/detail/nfc?nfcid=${nfcId}&ts=${ts}&photo=true&registration=123`,
      {
        headers: {
          client_id: tenantAuth.clientId,
          access_token: accessToken,
        },
      }
    )
  },
  getLatestDate: async (assetId, prevDate) => {
    const token = localStorage.getItem('id_token')
    const newDate = await axios.get(
      `${URL_BASE}/assets/details/${assetId}/latestTime?before=${new Date(prevDate).setUTCHours(0, 0, 0, 0)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return newDate.data.latestActiveTime
  },
}

const getAuthToken = async () => {
  const token = localStorage.getItem('at_integration')
  const expiration = JSON.parse(localStorage.getItem('at_integration_expiration'))
  if (token && new Date(expiration) > new Date()) {
    return token
  }
  const apiToken = await getAuthTokenAsync()
  localStorage.setItem('at_integration', apiToken.data.access_token)
  localStorage.setItem('at_integration_expiration', apiToken.data.expires_in * 1000 + new Date().getTime())
  return apiToken.data.access_token
}

const getAuthTokenAsync = () => {
  const idToken = localStorage.getItem('id_token')
  return axios.post(`${urlBase}/integrations/token`, {
    header: {
      Authorization: 'Bearer ' + idToken,
    },
  })
}
export default userInfoIntegration
