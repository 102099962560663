import axios from 'axios'
import { urlBase } from 'Apis/rest'
export const fetchModels = () => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  const res = await axios.get(urlBase + '/devices/models', {
    headers: {
      Authorization: 'Bearer ' + jwt,
    },
  })
  dispatch({ type: 'modelsInitialStore', payload: res.data })
}
