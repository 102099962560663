import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { Input, Form, Message } from 'semantic-ui-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
//import schema from 'graphql/schema.json'
import { GenericFormProps } from './types/GenericFormProps'
import { provisionDevice, clearSensorStates, fetchDevices } from 'Store/actions/sensors-action'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'

// copying this from TypesForm.jsx
type FieldsState = {
  serial: string
}

type ErrorForm = {
  serial: boolean
}
const DeviceProvisionForm: React.FC<GenericFormProps> = ({ handleCancel, addOpen, confirmAction, setAddOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const loading = useSelector((state: RootStore) => state.sensors.cudLoading)
  const errorMessage = useSelector((state: RootStore) => state.sensors.errors) as string
  const success = useSelector((state: RootStore) => state.sensors.success)

  // maybe it doesn't have to contain '<FieldsState>' ?
  const [fields, setFields] = useState<FieldsState>({
    serial: '',
  })
  // const [formObj, setFormObj] = useState({})
  const [errorObj, setErrorObj] = useState<ErrorForm>({ serial: false })

  const onSave = useCallback(() => {
    if (success) {
      setAddOpen(false)
      dispatch(clearSensorStates())
      dispatch(fetchDevices())
      return
    }
    if (!fields.serial) {
      setErrorObj({ ...errorObj, serial: true })
      return
    }
    dispatch(provisionDevice(fields))
    //setAddOpen(false)
  }, [fields, errorObj, dispatch, setAddOpen, success])

  return (
    <ModalTemplate
      onClose={handleCancel}
      open={addOpen}
      saveContent={success ? t('Close') : t('lblsave')}
      icon={undefined}
      size="small"
      loading={loading}
      header={<span>{`Provisionar dispositivos`}</span>}
      onSave={onSave}
      onCancel={handleCancel}
    >
      <Form>
        <p>
          Provisionar um dispositivo significa que ele será adicionado a esta empresa e poderá ser utilizado para coleta
          de dados.
        </p>
        <p>Não é possível provisionar dispositivos que não estejam no tenant da Phygitall.</p>
        <Form.Group>
          <Form.Field width="10" required={true} error={errorObj.serial}>
            <label>Serial</label>
            <Input
              name="serial"
              fluid
              type="number"
              value={fields.serial}
              onChange={(e, { name, value }) => setFields({ ...fields, [name]: value })}
            />
          </Form.Field>
        </Form.Group>
        {<WarningMessage error={errorMessage} />}
        {<SuccessMessage success={success} />}
      </Form>
    </ModalTemplate>
  )
}

const WarningMessage = ({ error }: { error: string }) => {
  if (error) {
    return <Message negative>{error}</Message>
  }
  return null
}

const SuccessMessage = ({ success }: { success?: boolean }) => {
  if (success) {
    return <Message positive>Dispositivo provisionado com sucesso!</Message>
  }
  return null
}

export default DeviceProvisionForm
