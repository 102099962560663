const colorOptions = [
  { key: 'color1', text: 'Amarelo', value: 'yellow', label: { color: 'yellow', empty: true, circular: true } },
  { key: 'color2', text: 'Azul', value: 'blue', label: { color: 'blue', empty: true, circular: true } },
  { key: 'color3', text: 'Azul Claro', value: 'teal', label: { color: 'teal', empty: true, circular: true } },
  { key: 'color5', text: 'Cinza', value: 'grey', label: { color: 'grey', empty: true, circular: true } },
  { key: 'color6', text: 'Laranja', value: 'orange', label: { color: 'orange', empty: true, circular: true } },
  { key: 'color7', text: 'Marrom', value: 'brown', label: { color: 'brown', empty: true, circular: true } },
  { key: 'color8', text: 'Preto', value: 'black', label: { color: 'black', empty: true, circular: true } },
  { key: 'color9', text: 'Rosa', value: 'pink', label: { color: 'pink', empty: true, circular: true } },
  { key: 'color10', text: 'Roxo', value: 'purple', label: { color: 'purple', empty: true, circular: true } },
  { key: 'color11', text: 'Verde', value: 'green', label: { color: 'green', empty: true, circular: true } },
  { key: 'color12', text: 'Verde Oliva', value: 'olive', label: { color: 'olive', empty: true, circular: true } },
  { key: 'color13', text: 'Vermelho', value: 'red', label: { color: 'red', empty: true, circular: true } },
  { key: 'color14', text: 'Violeta', value: 'violet', label: { color: 'violet', empty: true, circular: true } },
]
export default colorOptions
