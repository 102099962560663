import React, { useEffect, useState } from 'react'
import { logError } from 'Utils/analytics'
import App from 'App'
import io from 'socket.io-client'
import { urlServer } from 'Utils/url'
import createSocketIoMiddleware from 'redux-socket.io'
import { setMiddleware } from 'Store/middleware/dynamic'
import { saveCurrentRelease, loadLogin, fetchLoginPreferences, setLoginConnection } from 'Store/actions/login-action'
import { fetchActiveAssets, fetchAssets, fetchAssetsType, fetchAssociations } from 'Store/actions/assets-action'
import { fetchModels } from 'Store/actions/models-action'
import { fetchRefType } from 'Store/actions/refs-action'
import { fetchModules } from 'Store/actions/modules-action'
import { fetchNotifications } from 'Store/actions/notification-action'
import VERSION from 'Utils/version'
import { useDispatch, useSelector } from 'react-redux'
import { switchLanguage } from 'Resources/Locale/i18n'
import { fetchTenants } from 'Store/actions/tenant-action'
import { useMsal } from '@azure/msal-react'
import { createImageFromInitials } from 'Utils/components/ImageInitials'
import { loginRequest, userSecurityGroup } from 'Views/Authentication/authConfig'
// import { saveLoginPreferences } from 'Store/actions/login-action'
import { Redirect } from 'react-router-dom'
import { subscribeNotificationChange } from 'Store/actions/notification-action'

const RELOAD_ACTIVE_ASSET_TIME = 30000

export const LoadingApp = ({ auth }) => {
  const dispatch = useDispatch()
  const msal = useMsal()
  const language = useSelector((state) => state.login.language)
  const userInfo = handleUser(auth.userProfile, msal)
  const [profile, setProfile] = useState(userInfo)
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    let subNotification
    if (profile?.empresa) {
      subNotification = dispatch(subscribeNotificationChange(profile?.empresa, profile?.email))
    }
    return () => {
      subNotification && subNotification.unsubscribe()
    }
  }, [dispatch, profile])

  useEffect(() => {
    dispatch(fetchActiveAssets())
    const id = setInterval(() => {
      dispatch(fetchActiveAssets())
    }, RELOAD_ACTIVE_ASSET_TIME)
    return () => clearInterval(id)
  }, [dispatch])

  useEffect(() => {
    window.addEventListener('online', () => {
      dispatch(setLoginConnection('reconnected'))
      // document.getElementById('ContainerMainContent').style.filter = 'grayscale(0)'
      console.log('reconnected')
    })
  }, [dispatch])

  useEffect(() => {
    window.onoffline = () => {
      console.log('The network connection has been lost.')
      dispatch(setLoginConnection('disconnected'))
    }
  }, [dispatch])

  useEffect(() => switchLanguage(language), [language])
  useEffect(() => {
    if (!flag) return
    let assetType = dispatch(fetchAssetsType())
    let models = dispatch(fetchModels())
    let refTypes = dispatch(fetchRefType())
    let assets = dispatch(fetchAssets())
    let modules = dispatch(fetchModules())
    let messages = dispatch(fetchNotifications())
    let associations = dispatch(fetchAssociations())

    //let notificationNum = dispatch(fetchNotificationCount())
    Promise.all([
      assetType,
      models,
      refTypes,
      modules,
      messages,
      associations,
      assets, //, notificationNum
    ])
      //.then(() => {
      //todo: arranjar um lugar melhor para selecionar a lingua que vem do banco
      //Promise.all([assets])
      //.then(() => dispatch(setFalse('loadingScreen')))
      .catch((err) => {
        alert('Verifique sua conexão com a Internet e tente novamente')
        //window.location.reload()
        console.error(err)
      })
    //})
  }, [dispatch, flag])

  useEffect(() => {
    const { getProfile } = auth
    let userInfoSafe = profile
    ;(async () => {
      if (!userInfoSafe) {
        try {
          const myProfile = await getProfile()
          userInfoSafe = handleUser(myProfile)
          setProfile(userInfoSafe)
          return
        } catch (e) {
          logError(e)
          return
        }
      }
      if (!userInfoSafe) return
      if (userInfoSafe.auth === 'msal') {
        await requestAccessToken(msal.accounts, msal.instance)
      }
      setFlag(true)
      dispatch(saveCurrentRelease(VERSION, userInfoSafe.email))
      dispatch(loadLogin(userInfoSafe))

      let socket = io(urlServer() + '/' + userInfoSafe.empresa)
      let socketIoMiddleware = createSocketIoMiddleware(socket, 'server/')
      setMiddleware(socketIoMiddleware)
      dispatch(fetchTenants()).then(() => dispatch(fetchLoginPreferences()))
    })()
  }, [msal.accounts, msal.instance, dispatch, auth, profile])
  // calls the isAuthenticated method in authentication service

  if (profile?.perfil === 'NA') {
    return <Redirect to="/not-authorized" />
  }
  return <div id="app-container">{profile && <App profile={profile} />}</div>
}

const handleUser = (userAuth0, msal) => {
  if (userAuth0) {
    let empresa = userAuth0['http://plataforma.phygitall.com.br/empresa']
    let perfil = userAuth0['http://plataforma.phygitall.com.br/perfil'] ?? 'POC'
    let section = userAuth0['http://plataforma.phygitall.com.br/section'] ?? 'default'
    const email = userAuth0.email ?? userAuth0.name
    const picture = userAuth0.picture ?? createImageFromInitials(200, userAuth0.name, '#0B563B')
    return { empresa, perfil, section, email, picture, auth: 'auth0' }
  } else if (msal.accounts.length > 0) {
    const acc = msal.accounts[0]
    const roles = acc.idTokenClaims['roles']
    return {
      empresa: 'Tiplam',
      perfil: userSecurityGroup(roles),
      section: 'default',
      email: acc.username,
      name: acc.name,
      picture: createImageFromInitials(200, acc.name ?? 'Fulano', '#0B563B'),
      auth: 'msal',
    }
  }
}

async function requestAccessToken(acc, instance) {
  const request = {
    ...loginRequest,
    account: acc[0],
  }
  // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  try {
    const response = await instance.acquireTokenSilent(request)
    console.log(new Date().getTime(), response)
    localStorage.setItem('id_token', response.accessToken)
  } catch (err) {
    console.error(err)
    const response = await instance.acquireTokenPopup(request)
    localStorage.setItem('id_token', response.accessToken)
  }
}
