/*  Author: Luís Mourão
    Type: Smart
	Description: Render Marker from Real Time				 
    To do list: 
    - Retirar lógica do FollowMe			
*/

import React, {
  memo,
  useState,
  useEffect,
  // , useRef
} from 'react'
import PopupGrid from 'Components/Map/PopupMarker/PopupGrid'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Tooltip } from 'react-leaflet'
import CustomMarker from 'Libs/CustomMarker'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import { icones } from 'Resources/Icons/iconsLeaflet'
import MissingSignal from 'Utils/components/MissingSignals'
import ErrorBoundary from 'Utils/ErrorBoundary/ErrorBoundary'
import PopupFallback from 'Utils/ErrorBoundary/Fallbacks/PopupFallback'
import { fetchCategories } from 'Store/actions/measures-action'
import { fetchHistCategories } from 'Store/actions/history-action'
import { setCurrentAsset } from 'Store/actions/assets-action'

const RenderMarker = ({ assetId, mode = 'measures' }) => {
  const dispatch = useDispatch()
  // const isInitialMount = useRef(true)

  const assetType = useSelector((state) => state.assets.byId[assetId]?.info?.type)
  const assetName = useSelector((state) => state.assets.byId[assetId]?.name)
  const assetTypeColor = useSelector((state) => state.modules.types.byId[assetType]?.info?.color)
  const assetTypeIcon = useSelector((state) => state.modules.types.byId[assetType]?.info?.icon)
  const lat = useSelector((state) => state[mode].byId[assetId]?.info?.coords?.lat || state.tenants.current?.info?.lat)
  const lng = useSelector((state) => state[mode].byId[assetId]?.info?.coords?.lng || state.tenants.current?.info?.lng)
  const inner = useSelector((state) => state.toggle.showInnerMap)
  const gpsLat = useSelector((state) => state.measures.byId[assetId]?.info?.gps?.lat)
  const tenant = useSelector((state) => state.tenants.current)
  const currentInner = useSelector((state) => state.indoor.current?.inner)
  const loginProfile = useSelector((state) => state.login.perfil)
  const helpBool = useSelector((state) => state.notification.assetIds?.includes(assetId))
  const assetsInEmergency = useSelector((state) => state.notification.assetsInEmergency, shallowEqual)

  const [bounce, setBounce] = useState(false)

  useEffect(() => {
    // if (isInitialMount.current) {
    //   isInitialMount.current = false
    // } else {
    if (assetsInEmergency.includes(assetId)) {
      setBounce(true)
    } else setBounce(false)
    // }
  }, [assetsInEmergency, assetId])

  function markerClick() {
    if (mode === 'measures') {
      dispatch(fetchCategories(assetId))
    } else {
      dispatch(fetchHistCategories(assetId))
    }
    dispatch(setCurrentAsset(assetId))
  }

  let signals = []
  if (gpsLat === 0) signals = ['map marker alternate']
  if (helpBool) signals = [...signals, 'emergency']

  let icon = icones({ icon: assetTypeIcon, color: assetTypeColor })

  //Critérios Genéricos
  if (!assetId) {
    console.error('Marker with id asset null')
    return null
  }
  if (!icon) {
    console.error('Marker of asset ', assetId, ' without icon defined')
    return null
  }
  if (!lat || !lng) {
    return null
  }

  if (!assetName) {
    console.warn(`Asset ${assetId} without name`)
    return null
  }

  //return null

  let pos = inner ? innerPosition({ lat, lng }, 'marker', currentInner?.center, currentInner?.bearing) : { lat, lng }

  let splitName = assetName.split(' ')
  let tootipText =
    splitName[0] === splitName[splitName.length - 1]
      ? splitName[0]
      : splitName[0] + ' ' + splitName[splitName.length - 1]

  return (
    pos.lat &&
    pos.lng && (
      <CustomMarker key={mode + assetId} position={pos} icon={icon} onClick={markerClick} opacity={1} bounce={bounce}>
        {!tenant.features?.includes('anonymize') && (
          <Tooltip opacity={0.7} permanent direction="bottom">
            {' '}
            {signals.length > 0 && <MissingSignal signals={signals} />}
            {loginProfile === 'DEV' && <span>{assetId} - </span>}
            {tootipText?.replace('Smartbadge', 'SB').replace('Desconhecido', 'Desc')}
          </Tooltip>
        )}
        <ErrorBoundary FallbackComponent={PopupFallback}>
          <PopupGrid signals={signals} assetId={assetId} key={assetId} mode={mode} />
        </ErrorBoundary>
      </CustomMarker>
    )
  )
}

export default memo(RenderMarker)
