import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Segment, Dropdown, DropdownProps, DropdownItemProps, Form } from 'semantic-ui-react'
import { RootStore } from 'Store/initialStore'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import {
  // fetchUsersByFences,
  fetchLogoutsByTime,
  fetchDevicesByTime,
  fetchFenceRulesByTime,
  fetchFirefightersByTime,
  // fetchPeopleTypeByTime,
  // fetchRulesTypeByTime,
  fetchPresenceByFences,
  fetchReports,
  fetchUsersByFences,
  fetchNearMissesByPerimeter,
} from 'Store/actions/tables-action'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import exportReport from 'Utils/exportReport'
import m from 'moment'
import { clearRules, fetchRules } from 'Store/actions/rules-action'

const SelectDateModal = ({ open }: { open: boolean }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const reportType = useSelector((state: RootStore) => state.tables.byId.reports.reportType)
  const reportViewForm = useSelector((state: RootStore) => state.tables.byId.reports.reportViewForm)
  const assetsIds = useSelector((state: RootStore) => state.assets.allIds)
  const assetsById = useSelector((state: RootStore) => state.assets.byId)

  const [relativeContent, setRelativeContent] = useState(true)
  const [nonRelativeContent, setNonRelativeContent] = useState(false)

  // control relative time; toggle green color
  const [relativeTimeString, setRelativeTimeString] = useState('1d')

  // control absolute time
  const [startTimeString, setStartTimeString] = useState('')
  const [endTimeString, setEndTimeString] = useState('')

  // this can probably be coded in an easier way (anyway, shamelessly stolen from SO)
  const getCurrentDate = () => {
    let d = new Date()
    const offset = d.getTimezoneOffset()
    d = new Date(d.getTime() - offset * 60 * 1000)
    return d.toISOString().split('T')[0]
  }

  const fetchSuccess = useSelector((state: RootStore) => state.tables.byId.reports.fetchSuccess)

  // for the fetchRules??
  const tenant = useSelector((state: RootStore) => state.login.empresa)

  // used when fetching data AFTER selecting the date
  const loadingRequest = useSelector((state: RootStore) => state.tables.byId.reports.fetchLoading)

  // access report data fetched from the back-end
  const reportData = useObjSelector((state: RootStore) => state.tables.byId.reports.reportData)

  // for the dropdown
  const peopleTypesAllIds = useSelector((state: RootStore) => state.modules.types.allIds)
  const peopleTypesById = useSelector((state: RootStore) => state.modules.types.byId)
  const [peopleTypeID, setPeopleTypeID] = useState('0')

  const rulesTypesAllIds = useSelector((state: RootStore) => state.rules.allIds)
  const rulesTypesById = useSelector((state: RootStore) => state.rules.byId)
  const [rulesTypeID, setRulesTypeID] = useState('0')

  const perimetersTypesAllIds = useSelector((state: RootStore) => state.modules.fences.allIds)
  const perimetersTypesById = useSelector((state: RootStore) => state.modules.fences.byId)
  const [perimeterTypeID, setPerimeterTypeID] = useState('')

  const [assetId, setAssetId] = useState('')

  // PLEASE REFACTOR THIS PLEASE PUT IT IN THE INITIALSTORE OR SOMETHING
  const severityLevelAllIds = [0, 10, 20, 30, 40, 50, 60]
  const severityLevelById = {
    '0': {
      id: '0',
      name: t('severityLevel_any'),
    },
    '10': {
      id: '10',
      name: t('severityLevel_10'),
    },
    '20': {
      id: '20',
      name: t('severityLevel_20'),
    },
    '30': {
      id: '30',
      name: t('severityLevel_30'),
    },
    '40': {
      id: '40',
      name: t('severityLevel_40'),
    },
    '50': {
      id: '50',
      name: t('severityLevel_50'),
    },
    '60': {
      id: '60',
      name: t('severityLevel_60'),
    },
  }
  const [severityLevel, setSeverityLevel] = useState('0')

  const handleDate = () => {
    dispatch({
      type: 'FETCH_REPORT_START',
    })
    // fixing 'grouping' on 'day' for now
    const fetchArgument = {
      startTime: startTimeString + ' 00:00:00',
      endTime: endTimeString + ' 00:00:00',
      relativeTime: relativeTimeString,
      grouping: 'day',
      assetId: assetId,
      fenceId: perimeterTypeID,
    }

    // TODO: refactor this switch into something more readable and less monkey brain
    switch (reportType) {
      case 'usersByFences':
        dispatch(fetchUsersByFences(fetchArgument, relativeContent))
        // dispatch(changeCurrentDashboardTime(relativeTimeString || '1d'))
        // dispatch(fetchDashboard('fences', null, true))
        break
      case 'logoutsByTime':
        dispatch(fetchLogoutsByTime(fetchArgument, relativeContent))
        break
      case 'devicesByTime':
        dispatch(fetchDevicesByTime(fetchArgument, relativeContent))
        break
      case 'fenceRulesByTime':
        dispatch(fetchFenceRulesByTime(fetchArgument, relativeContent))
        break
      case 'firefightersByTime':
        dispatch(fetchFirefightersByTime(fetchArgument, relativeContent))
        break
      case 'peopleTypeByTime':
        dispatch(fetchReports(fetchArgument, relativeContent, reportType, peopleTypeID))
        break
      case 'rulesTypeByTime':
        dispatch(fetchReports(fetchArgument, relativeContent, reportType, rulesTypeID))
        break
      case 'presenceByFencesGrouped': // this is that cloned (bodged) report
      case 'presenceByFences':
        dispatch(fetchPresenceByFences(fetchArgument, relativeContent))
        break
      case 'nearMissesByPerimeter':
        dispatch(fetchNearMissesByPerimeter(fetchArgument, relativeContent, severityLevel, perimeterTypeID))
        break
      default:
        dispatch(fetchReports(fetchArgument, relativeContent, reportType))
        break
    }
  }

  const handleCancel = () => {
    // clear date selection
    // close the modal
    dispatch({
      type: 'SELECT_DATE_REPORTS_CLOSE',
    })
  }

  const handleClose = () => {
    // close the modal
    dispatch({
      type: 'SELECT_DATE_REPORTS_CLOSE',
    })
  }

  const handlePeopleTypeIDChange = (_e: any, data: DropdownProps) => {
    if (typeof data.value !== 'undefined') setPeopleTypeID(data.value.toString())
  }

  const handleRulesTypeIDChange = (_e: any, data: DropdownProps) => {
    if (typeof data.value !== 'undefined') setRulesTypeID(data.value.toString())
  }

  const handlePerimeterTypeIDChange = (_e: any, data: DropdownProps) => {
    if (typeof data.value !== 'undefined') setPerimeterTypeID(data.value.toString())
  }

  const handleSeverityLevelChange = (_e: any, data: DropdownProps) => {
    if (typeof data.value !== 'undefined') setSeverityLevel(data.value.toString())
  }

  const handleAssetNameChange = (_e: any, data: DropdownProps) => {
    if (typeof data.value !== 'undefined') setAssetId(data.value.toString())
  }

  const handleRegistrationChange = (_e: any, data: DropdownProps) => {
    const assetId = data.options?.find((option) => option.value === data.value)?.assetId
    if (typeof assetId !== 'undefined') setAssetId(assetId.toString())
  }

  const nonRelativeTimeContent = (
    <Segment.Group>
      <Segment>{t('selectDate_initial')}</Segment>
      <Segment>
        <Input onChange={(e, d) => setStartTimeString(d.value)}>
          <input
            type="date"
            id="start"
            name="date-start"
            value={startTimeString}
            min={'2022-01-01'}
            max={getCurrentDate()}
          />
        </Input>
      </Segment>

      <Segment>{t('selectDate_final')}</Segment>
      <Segment>
        <Input onChange={(e, d) => setEndTimeString(d.value)}>
          <input
            type="date"
            id="start"
            name="date-start"
            value={endTimeString}
            min={'2022-01-01'}
            max={getCurrentDate()}
          />
        </Input>
      </Segment>
    </Segment.Group>
  )

  const relativeTimeContent = (
    <Segment compact>
      <Button
        primary={relativeTimeString === '1d'}
        onClick={() => {
          setRelativeTimeString('1d')
        }}
      >
        {t('1_day')}
      </Button>
      <Button
        primary={relativeTimeString === '7d'}
        onClick={() => {
          setRelativeTimeString('7d')
        }}
      >
        {t('7_days')}
      </Button>
      <Button
        primary={relativeTimeString === '30d'}
        onClick={() => {
          setRelativeTimeString('30d')
        }}
      >
        {t('30_days')}
      </Button>
    </Segment>
  )

  const peopleTypeContent = (
    <Form.Field>
      <label>{t('select_date_modal_person_type_placeholder')}</label>
      <Dropdown
        search
        selection
        options={[
          ...peopleTypesAllIds
            .filter((tp) => peopleTypesById[tp]?.info?.table === 'assets.people')
            .map((e) => ({
              key: peopleTypesById[e]?.id?.toString(),
              text: peopleTypesById[e]?.name,
              value: peopleTypesById[e]?.id?.toString(),
            }))
            .sort(sortTextByNames),
        ]}
        onChange={handlePeopleTypeIDChange}
      />
    </Form.Field>
  )

  const rulesTypeContent = (
    <Form.Field>
      <label>{t('select_date_modal_rule_type_placeholder')}</label>
      <Dropdown
        search
        selection
        options={[
          ...rulesTypesAllIds
            .map((e) => ({
              key: rulesTypesById[e]?.id?.toString(),
              text: rulesTypesById[e]?.name,
              value: rulesTypesById[e]?.id?.toString(),
            }))
            .sort(sortTextByNames),
        ]}
        onChange={handleRulesTypeIDChange}
      />
    </Form.Field>
  )

  const fencesContent = (
    <Form.Field>
      <label>{t('select_date_modal_nearMisses_perimeter_placeholder')}</label>
      <Dropdown
        search
        selection
        clearable
        options={[
          ...perimetersTypesAllIds
            .map((e) => ({
              key: perimetersTypesById[e]?.id?.toString(),
              text: perimetersTypesById[e]?.name,
              value: perimetersTypesById[e]?.id?.toString(),
            }))
            .sort(sortTextByNames),
        ]}
        onChange={handlePerimeterTypeIDChange}
      />
    </Form.Field>
  )

  const severityLevelContent = (
    <Dropdown
      placeholder={t('select_date_modal_nearMisses_severityLevel_placeholder')}
      search
      selection
      options={[
        ...severityLevelAllIds
          .map((e) => ({
            key: severityLevelById[e]?.id?.toString(),
            text: severityLevelById[e]?.name,
            value: severityLevelById[e]?.id?.toString(),
          }))
          .sort(sortTextByNames),
      ]}
      onChange={handleSeverityLevelChange}
    />
  )

  const assetNameContent = (
    <Form.Field>
      <label>{t('name')}</label>
      <Dropdown
        search
        selection
        clearable
        options={[
          ...assetsIds
            .filter((tp: string) => assetsById[tp]?.idType === 2 && !assetsById[tp]?.auto)
            .map((e: string) => ({
              key: assetsById[e]?.id?.toString(),
              text: assetsById[e]?.name,
              value: assetsById[e]?.id?.toString(),
            }))
            .sort(sortTextByNames),
        ]}
        onChange={handleAssetNameChange}
      />
    </Form.Field>
  )

  const registrationContent = (
    <Form.Field>
      <label>{t('RegistrationId')}</label>
      <Dropdown
        search
        selection
        clearable
        options={[
          ...assetsIds
            .filter(
              (tp: string) =>
                assetsById[tp]?.idType === 2 && !assetsById[tp]?.auto && assetsById[tp]?.info?.registrationId
            )
            .map((e: string) => ({
              key: assetsById[e]?.info?.registrationId,
              text: assetsById[e]?.info?.registrationId,
              value: assetsById[e]?.info?.registrationId,
              assetId: e,
            }))
            .sort(sortTextByNames),
        ]}
        onChange={handleRegistrationChange}
      />
    </Form.Field>
  )

  useEffect(
    function fetchRulesOnLoad() {
      if (tenant) {
        dispatch(fetchRules())
      }
      return () => {
        dispatch(clearRules())
      }
    },
    [tenant, dispatch]
  )

  useEffect(() => {
    // check if got data and export it
    if (fetchSuccess) {
      if (reportViewForm) {
        dispatch({
          type: 'SELECT_DATE_REPORTS_CLOSE',
        })
      } else {
        exportReport(reportData, `Relatório_${t(reportType)}_${m().format('YYYYMMDD')}`, t)
        // try to clear the flags and not FREAKING REDOWNLOAD THE REPORT
        dispatch({
          type: 'FETCH_REPORT_CLEAR_STATE',
        })
      }
    }
  }, [fetchSuccess, reportData, reportType, reportViewForm, t, dispatch])

  return (
    <ModalTemplate
      open={open}
      onClose={handleClose}
      header={<span>{t('selectDate_header')}</span>}
      loading={loadingRequest}
      saveContent={reportViewForm ? t('generateReport') : t('Download')}
      onSave={handleDate}
      cancelContent={t('Cancel')}
      onCancel={handleCancel}
    >
      <Form>
        {shouldRenderFilter(reportType, ['firefightersByTime', 'presenceByFences']) && assetNameContent}
        {shouldRenderFilter(reportType, ['presenceByFences']) && registrationContent}
        {shouldRenderFilter(reportType, ['presenceByFences']) && fencesContent}
        {shouldRenderFilter(reportType, ['firefightersByTime']) && peopleTypeContent}
        {shouldRenderFilter(reportType, ['peopleTypeByTime']) && peopleTypeContent}
        {shouldRenderFilter(reportType, ['rulesTypeByTime']) && rulesTypeContent}
        {shouldRenderFilter(reportType, ['nearMisses']) && severityLevelContent}
        <Button.Group>
          <Button
            primary={relativeContent}
            onClick={() => {
              setNonRelativeContent(false)
              setRelativeContent(true)
            }}
          >
            {t('selectDate_relativeTime')}
          </Button>
          <Button
            primary={nonRelativeContent}
            onClick={() => {
              setRelativeContent(false)
              setNonRelativeContent(true)
            }}
          >
            {t('selectDate_nonRelativeTime')}
          </Button>
        </Button.Group>
      </Form>

      {relativeContent && relativeTimeContent}
      {nonRelativeContent && nonRelativeTimeContent}
    </ModalTemplate>
  )
}

export default SelectDateModal

const sortTextByNames = (a: DropdownItemProps, b: DropdownItemProps) => {
  if (a.text && b.text) {
    // @ts-ignore
    return a.text.toLowerCase().localeCompare(b.text.toLowerCase())
  }
  return 1
}

const shouldRenderFilter = (filter: string, filters: string[]) => {
  if (filters.length === 0) {
    return true
  }
  return filters.includes(filter)
}
