import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Dropdown, Form, Icon, Input, Popup } from 'semantic-ui-react'
import { ModuleObject } from 'Store/initialStore'
import { ModalTemplate } from '../components/ModalTemplate'

export interface FieldsPeopleState {
  id: string
  name: string
  description: string
  code: string
  sensor: string
  type: string
  cardId: string
  registrationId: string
  sector: string
  position: string
  email: string
  company: string
  onBoarding: string
  certifications: string
  supervisor: string
  master: boolean
}

interface PeopleFormProps {
  cudLoading: boolean
  noType: string
  handleSave: (fields: FieldsPeopleState) => void
  typesAllIds: string[]
  typesById: { [id: string]: ModuleObject | undefined }
  handleCancel: () => void
  addOpen: boolean
  table: string
  confirmAction: string
  row: any
}

const PeopleForm = (props: PeopleFormProps) => {
  const { t } = useTranslation()
  const { addOpen, row, confirmAction, cudLoading, handleSave, handleCancel, table, noType, typesAllIds, typesById } =
    props

  const [fields, setFields] = useState<FieldsPeopleState>({
    id: row?.id || '',
    name: row?.name || '',
    description: row?.info?.description || '',
    code: row?.info?.code || '',
    sensor: row?.sensor || '',
    type: row?.info?.type || '',
    cardId: row?.info?.cardId || '',
    registrationId: row?.info?.registrationId || '',
    sector: row?.info?.sector || '',
    position: row?.info?.position || '',
    email: row?.info?.email || '',
    company: row?.info?.company || '',
    onBoarding: row?.info?.onBoarding || '',
    certifications: row?.info?.certifications || '',
    supervisor: row?.info?.supervisor || '',
    master: row?.info?.master || false,
  })

  const [errors, setErrors] = useState<Partial<FieldsPeopleState>>({
    name: '',
    cardId: '',
  })

  const handleChange = (fields: FieldsPeopleState, name: string, value: string | boolean) => {
    setFields({ ...fields, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  //validate form fields
  const validate = (fields: FieldsPeopleState) => {
    let hasError = false
    if (!fields.name) {
      setErrors({ ...errors, name: 'Nome é obrigatório' })
      hasError = true
    }
    if (!fields.cardId) {
      setErrors({ ...errors, cardId: 'Card ID é obrigatório' })
      hasError = true
    }
    return hasError
  }
  const handleSaveForm = (fields: FieldsPeopleState) => {
    if (!validate(fields)) {
      handleSave(fields)
    }
  }
  return (
    <ModalTemplate
      onClose={handleCancel}
      open={addOpen}
      loading={cudLoading}
      size="small"
      header={
        confirmAction === 'insert' ? <span>{`Adicionar ${t(table)}`} </span> : <span>{`Editar ${t(table)}`}</span>
      }
      onSave={() => {
        handleSaveForm(fields)
      }}
      onCancel={handleCancel}
    >
      <Form>
        <Form.Group>
          <Form.Field required={false} width={3}>
            <label>{t('code')}</label>
            <Input
              name={'code'}
              fluid
              value={fields.code}
              min="0"
              onChange={(_e, { name, value }) => handleChange(fields, name, value.slice(0, 8))}
            />
          </Form.Field>
          <Form.Field width="13" required={true} error={errors.name}>
            <label>{'Nome'}</label>
            <Input
              placeholder="Novo"
              name={'name'}
              fluid
              value={fields.name}
              onChange={(_e, { name, value }) => handleChange(fields, name, value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field required={false}>
          <label>{'Descrição'}</label>
          <Input
            name={'description'}
            fluid
            value={fields.description}
            onChange={(_e, { name, value }) => handleChange(fields, name, value)}
          />
        </Form.Field>

        <Form.Group>
          {/* <Form.Field required={false} width={8}>
                <label>{'Dispositivo Associado'}</label>
                <Dropdown
                  defaultValue={fields.sensor}
                  placeholder={'<<Sem dispositivo>>'}
                  search
                  selection
                  options={[
                    { key: 'none', text: '<<Sem dispositivo>>', value: '' },
                    ...sensorsAllIds
                      .map((e) => ({
                        key: sensorsById[e]?.id,
                        text: sensorsById[e]?.name,
                        value: sensorsById[e]?.id,
                      }))
                      .sort((a, b) => (a.text && b.text ? a.text.toLowerCase().localeCompare(b.text.toLowerCase()) : 1)),
                  ]}
                  onChange={(e, { value }) => handleChange(fields, 'sensor', value as string)}
                />
              </Form.Field>*/}
          <Form.Field required={false} width={5}>
            <label>{t('Assignment')}</label>
            <Dropdown
              defaultValue={fields.type}
              placeholder={noType}
              search
              selection
              options={[
                {
                  key: 'noType',
                  text: noType,
                  value: '',
                },
                ...typesAllIds
                  .filter((tp) => typesById[tp]?.info?.table === 'assets.people')
                  .map((e) => ({
                    key: typesById[e]?.id,
                    text: typesById[e]?.info?.label,
                    value: typesById[e]?.id,
                  }))
                  .sort((a, b) => (a.text && b.text ? a.text.toLowerCase().localeCompare(b.text.toLowerCase()) : 1)),
              ]}
              onChange={(e, { value }) => handleChange(fields, 'type', value as string)}
            />
          </Form.Field>
          <Form.Field required={false} width={2}>
            <label>{'Ambientação'}</label>
            <Dropdown
              name={'onBoarding'}
              fluid
              defaultValue={fields.onBoarding}
              selection
              options={[
                { key: 'true', text: 'Sim', value: 'Sim' },
                { key: 'false', text: 'Não', value: 'Não' },
              ]}
              onChange={(e, { name, value }) => handleChange(fields, name, value as string)}
              //type={'date'}
            />
          </Form.Field>
          <Form.Field required={false} width={4} error={errors.cardId}>
            <label>
              {'NFC'}
              <Popup
                trigger={<Icon name="question circle outline" />}
                content="Número decimal encontrado na parte inferior do cartão"
                position="top center"
              />
              <Checkbox
                label="Mestre"
                checked={fields.master}
                onChange={(e, { checked }) => handleChange(fields, 'master', checked ? true : false)}
              />
              <Popup
                trigger={<Icon name="question circle outline" />}
                content="O cartão mestre permite que o usuário libere todos os smartbadges do totem"
                position="top center"
              />
            </label>
            <Input
              name={'cardId'}
              fluid
              value={fields.cardId}
              onChange={(_e, { name, value }) => handleChange(fields, name, value)}
              type={'number'}
            />
          </Form.Field>
          <Form.Field required={false} width={4}>
            <label>{'Matrícula'}</label>
            <Input
              name={'registrationId'}
              fluid
              value={fields.registrationId}
              onChange={(_e, { name, value }) => handleChange(fields, name, value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field required={false} width={11}>
            <label>{'E-mail'}</label>
            <Input
              name={'email'}
              type={'email'}
              fluid
              value={fields.email}
              onChange={(_e, { name, value }) => handleChange(fields, name, value)}
            />
          </Form.Field>
          <Form.Field required={false} width={5}>
            <label>{'Empresa'}</label>
            <Input
              name={'company'}
              fluid
              value={fields.company}
              onChange={(_e, { name, value }) => handleChange(fields, name, value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field required={false}>
          <label>{'Cargo'}</label>
          <Input
            name={'position'}
            fluid
            value={fields.position}
            onChange={(_e, { name, value }) => handleChange(fields, name, value)}
          />
        </Form.Field>
        <Form.Field required={false}>
          <label>{'Setor'}</label>
          <Input
            name={'sector'}
            fluid
            value={fields.sector}
            onChange={(_e, { name, value }) => handleChange(fields, name, value)}
          />
        </Form.Field>

        <Form.Field required={false}>
          <label>{'Treinamentos'}</label>
          <Input
            name={'certifications'}
            fluid
            value={fields.certifications}
            onChange={(_e, { name, value }) => handleChange(fields, name, value)}
          />
        </Form.Field>
      </Form>
      <p>Você pode incluir vários treinamentos separando-os por vírgula. Exemplo: NR-35, NR-10, outros .</p>
    </ModalTemplate>
  )
}
export default React.memo(PeopleForm)
