import React, { Suspense, Fragment } from 'react'
import { Route, Router, Redirect } from 'react-router-dom'
import Home from './Home'
import Callback from 'Callback/Callback'
import Auth from 'Libs/Auth'
import history from 'Utils/history'
import { logError } from 'Utils/analytics'
import { LoadingScreen } from 'Utils/LoadingScreen'
import Setting from 'Views/settings/settingRoutes'
import { VerificationCode } from './Views/external/VerificationCode'
import { ApproveCode } from './Views/external/ApproveCode'
import { Login } from './Views/Authentication/Login.jsx'
import { RegistrationCode } from 'Views/external/RegistrationCode'
import { FullscreenFirefightersContent } from 'Components/SidebarMenu/Contents/Firefighters/fullscreen'
import { NotAuthorized } from 'Views/NotAuthorized'
import { OnboardingGuests } from 'Views/external/OnboardingGuest'

/*  Author: Felipe Pegado
    Type: Dumb
    Description:  first component to authenticate (auth0); 
                  routes adresses control;
    To do list: -Organizar e definir melhor os caminhos
                -Trocar para jsx

*/

const auth = new Auth()

const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication()
  } else {
    alert('Sua sessão expirou!')
    console.log(nextState)
    logError('Auth issue')
  }
}

const Routes = () => (
  <Suspense fallback={<LoadingScreen carregando />}>
    <Router history={history}>
      <Fragment>
        <Route exact path="/login" render={(props) => <Login auth={auth} {...props} />} />
        <Route
          path="/authorize"
          render={(props) => {
            auth.login()
            return <Callback {...props} />
          }}
        />
        <Route
          path="/callback"
          render={(props) => {
            handleAuthentication(props)
            return <Callback {...props} />
          }}
        />
        <Route
          path="/settings"
          render={(props) => (!auth.isAuthenticated() ? <Redirect to="/" /> : <Setting auth={auth} {...props} />)}
        />
        <Route path="/external/verification/code/:hash" render={(props) => <VerificationCode {...props} />} />
        <Route path="/external/onboarding/request-token" render={(props) => <OnboardingGuests {...props} />} />
        <Route path="/external/approval/code/:hash" render={(props) => <ApproveCode {...props} />} />
        <Route path="/external/registration/code/:hash" render={(props) => <RegistrationCode {...props} />} />
        <Route
          path="/firefighters"
          render={(props) => {
            return <FullscreenFirefightersContent {...props} />
          }}
        />

        <Route path="/not-authorized" render={() => <NotAuthorized />} />
        <Route exact path="/" render={() => <Redirect to="/app" />} />
        <Route path="/app" render={(props) => <Home auth={auth} {...props} />} />
      </Fragment>
    </Router>
  </Suspense>
)

export default Routes
