import React /*, { useState }*/ from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  Cell,
  // ReferenceLine,
  ResponsiveContainer,
} from 'recharts'
import { semanticColors } from 'Utils/colorsFormat.js'

export const BarChartActive = (props) => {
  const data = [
    { name: 'Moega', value: 13, fill: semanticColors('yellow') },
    { name: 'Ármazem 5', value: 7, fill: semanticColors('orange') },
    { name: 'Ármazem 4', value: 4, fill: semanticColors('red') },
  ]

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        textAlign: 'left',
        fontSize: '14px',
        backgroundColor: 'white',
        borderRadius: '8px',
        paddingTop: '30px',
      }}
    >
      <span style={{ top: '20px', left: '25px', position: 'absolute', fontWeight: '700' }}>
        {props.title || 'Acesso Mobile Área Classificada'}
      </span>
      <ResponsiveContainer>
        <BarChart width={700} height={800} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis />
          <Tooltip />
          <Bar dataKey="value" />
          {data.map((entry, index) => (
            <Cell cursor="pointer" fill={entry.fill} key={`cell-${index}`} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default BarChartActive
