/////////////////////////////////////////
// this is all just for the upload button
// copying from TenantLogo.jsx
import request from 'Apis/rest'

export const uploadImage = (data) => {
  const fd = new FormData()
  // this is the actual image data
  fd.append('File', data)

  return fd
}

export const removeUrl = async () => {
  const jwt = localStorage.getItem('id_token')
  try {
    await request.delete('/core/v0/?', {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
  } catch (err) {
    console.error(err)
    alert('Erro ao deletar a imagem')
  }
}

export const handleSelect = (ev) => {
  const file = ev.target.files && ev.target.files[0]
  if (!file) return
  if (!/^image\//.test(file.type)) {
    alert('Imagem inválida')
    return
  }
  //uploadImage(file)
  return file
}
export const handleEdit = (fileRef) => {
  fileRef.current && fileRef.current.click()
}
