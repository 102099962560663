import React, { useMemo, useEffect } from 'react'
import { Segment, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setDashboardFilter } from 'Store/actions/dashboards-action'
import debounce from 'lodash.debounce'

const ModalFilter = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dashboardFilter = useSelector((state) => state?.dashboards?.filter || '')
  const debouncedChangeHandler = useMemo(
    () => debounce((a, { value }) => dispatch(setDashboardFilter(value)), 300),
    [dispatch]
  )

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  return (
    <Segment style={{ minWidth: '175px', border: 'none' }}>
      <Input
        autoFocus
        icon="search"
        iconPosition="left"
        size="large"
        value={dashboardFilter}
        onChange={debouncedChangeHandler}
        placeholder={t('Search')}
        style={{ width: '20vw', minWidth: '130px' }}
      />
    </Segment>
  )
}

export default ModalFilter
