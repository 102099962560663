import React from 'react'
import { Dropdown, Icon, Popup } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { changeHistType } from 'Store/actions/history-action'
import { useDispatch, useSelector } from 'react-redux'
import { setTrue } from 'Store/actions/toggle-action'

const DropdownHistory = ({ ...props }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const success = useSelector((state) => state.history.success)
  return (
    <Dropdown
      pointing="top right"
      {...props}
      trigger={
        <Popup
          inverted
          size="tiny"
          trigger={<Icon name="history" size="large" style={{ color: '#4b72b2' }} />}
          content={t('History')}
        />
      }
      icon={null}
    >
      <Dropdown.Menu>
        <Dropdown.Item
          key="track"
          onClick={() => {
            if (!success) dispatch(setTrue('showHistoryCalendar'))
            dispatch(changeHistType('antpath'))
          }}
          icon="shuffle"
          text={t('Track')}
        />
        <Dropdown.Item
          key="fire"
          onClick={() => {
            dispatch(setTrue('showHistoryCalendar'))
            dispatch(changeHistType('heatmap'))
          }}
          icon="fire"
          text={t('HeatMap')}
        />
        <Dropdown.Item
          key="discrete"
          onClick={() => {
            if (!success) dispatch(setTrue('showHistoryCalendar'))
            dispatch(changeHistType('discrete'))
          }}
          icon="point"
          text={t('HistorySliderDiscrete')}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DropdownHistory
