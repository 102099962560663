import React from 'react'
import RenderRoute from 'Components/Map/MapModules/RenderRoute.js'
import RenderCreationRoute from 'Components/Map/MapModules/RenderCreationRoute.js'

import { useSelector } from 'react-redux'

export const MapRoutes = ({ ids, click }) => {
  const moduleType = 'routes'
  const wip = useSelector((state) => state.modules[moduleType].wip)
  const preferences = useSelector((state) => state.login.preferences[moduleType])
  return (
    <>
      {wip?.geoJson && <RenderCreationRoute wip={wip} click={click} />}
      {ids
        ?.filter((f) => !preferences[f]?.invisible)
        .map((id) => {
          let moduleId = id?.toString()
          if (!moduleId) {
            console.error('Module without id!')
            return null
          }
          return <RenderRoute key={moduleId} id={moduleId} click={click} />
        })}
    </>
  )
}
