import auth0 from 'auth0-js'
import history from 'Utils/history'

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: 'phygitall.auth0.com',
    clientID: 'ZZLq-tFeBDaYGq3HLhJGY6pig3q21wLk',
    redirectUri: window.location.origin + '/callback',
    audience: 'https://phygitall.auth0.com/api/v2/',
    responseType: 'token id_token',
    scope: 'openid profile email update:current_user_metadata',
  })

  userProfile
  tokenRenewalTimeout

  constructor() {
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.getAccessToken = this.getAccessToken.bind(this)
    this.getProfile = this.getProfile.bind(this)
    this.scheduleRenewal()
  }

  login() {
    this.auth0.authorize()
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
        history.replace('/')
      } else if (err) {
        history.replace('/')
        console.log(err)
      }
    })
  }

  setSession(authResult) {
    // Set the time that the Access Token will expire at
    //const scopes = authResult.scope || this.requestedScopes || '';
    let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())
    localStorage.setItem('access_token', authResult.accessToken)
    localStorage.setItem('id_token', authResult.idToken)
    localStorage.setItem('expires_at', expiresAt)
    this.scheduleRenewal()
    //socket.emit('accessToken', {token: authResult.accessToken});
    //localStorage.setItem('scopes', JSON.stringify(scopes));
    // navigate to the home route
    history.replace('/')
  }

  getAccessToken() {
    const accessToken = localStorage.getItem('access_token')
    if (!accessToken) {
      //this.auth0.authorize()
      console.error('No access token found')
    }
    return accessToken
  }

  getProfile() {
    return new Promise((resolve, reject) => {
      let accessToken = this.getAccessToken()
      this.auth0.client.userInfo(accessToken, (err, profile) => {
        if (profile) {
          this.userProfile = profile
          localStorage.setItem('user_id', profile.sub)
          resolve(profile)
        } else reject(err)
      })
    })
  }

  logout() {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    this.userProfile = null
    clearTimeout(this.tokenRenewalTimeout)
    // navigate to the home route
    history.replace('/')
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // Access Token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    return new Date().getTime() < expiresAt
  }
  renewToken() {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.log(err)
      } else {
        this.setSession(result)
      }
    })
  }
  renewTokenPromise = () => {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, result) => {
        if (err) {
          console.log(err)
          reject(err)
        } else {
          this.setSession(result)
          resolve(result)
        }
      })
    })
  }

  scheduleRenewal() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    const delay = expiresAt - Date.now()
    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken()
      }, delay)
    }
  }
}
/*
  userHasScopes(scopes) {
  const grantedScopes = JSON.parse(localStorage.getItem('scopes')).split(' ');
  return scopes.every(scope => grantedScopes.includes(scope));
}
*/
