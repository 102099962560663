import React from 'react'
import { useSelector } from 'react-redux'
import { Image } from 'semantic-ui-react'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { useTranslation } from 'react-i18next'

//id=e.id fenceLabel = {label} iconClick = {iconClick}
export const FencesEmergencyButton = (props) => {
  const assetsIds = useSelector((state) => state.assets.allActiveIds || [])
  //const assets = useSelector((state) => state.assets.byId)
  const [emergencyMode, setEmergencyMode] = React.useState(props.currentMode || false)
  const [openModal, setOpenModal] = React.useState(false)
  const [imagePath, setImagePath] = React.useState('./images/')
  const { t } = useTranslation()

  // use image name to get tooltip
  const iconTooltip = {
    // the three icons on the top
    'default lightning': t('lightningWarning'),
    'default rain': t('emergency'),
    'default arrow right': t('exitWarning'),
    // icons on the list
    'selected lightning': t('lightningWarning'),
    'selected rain': t('emergency'),
    'selected arrow right': t('exitWarning'),
  }

  return (
    <>
      {!emergencyMode ? (
        <Image
          src={imagePath + props.icon + '.svg'}
          size="small"
          style={{ width: '30px', heigth: '30px', display: 'inline', cursor: 'pointer', paddingRight: '3px' }}
          title={iconTooltip[props.icon]}
          onClick={() => {
            setOpenModal(!openModal)
          }}
          onMouseEnter={() => {
            setImagePath('./images/hover ')
          }}
          onMouseLeave={() => {
            setImagePath('./images/')
          }}
        />
      ) : (
        <Image
          src={`./images/hover ${props.icon}.svg`}
          size="small"
          style={{ width: '30px', heigth: '30px', display: 'inline', cursor: 'pointer' }}
          name={emergencyMode ? t('stop') : props.icon}
          title={emergencyMode ? t('Stop') : props.title}
          onClick={() => {
            setOpenModal(!openModal)
          }}
        />
      )}
      {
        <ModalTemplate
          header={'Modo Emergência: ' + props.title}
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSave={() => {
            props.iconClick(props.id, 'fences', assetsIds, emergencyMode, props.emergencyType)
            setEmergencyMode(!emergencyMode)
            setOpenModal(false)
          }}
          saveContent="Enviar"
          onCancel={() => setOpenModal(false)}
        >
          Tem certeza que deseja {emergencyMode ? 'parar' : 'anunciar a emergência:'} {props.title + ' '}
          {props.fenceLabel === 'Total' ? 'Total ' : 'no(a) ' + props.fenceLabel}?{' '}
        </ModalTemplate>
      }
    </>
  )
}

export default FencesEmergencyButton
