import React, { useState, useEffect } from 'react'
import { Checkbox } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
//import  Avatar  from 'Utils/components/Avatar';
//import TimeSince from 'Utils/components/TimeSince';
//import m from 'moment'
import ReactTable from 'Utils/reactTable'
import { clearDevices, fetchDevices, updateDeviceById } from 'Store/actions/sensors-action'
import { colorFormat } from 'Utils/colorsFormat'
import getSafe from 'Utils/getSafe.js'
//import { Checkbox } from 'semantic-ui-react'
import { threeColumns } from 'Utils/threeColumns'
import { DateTime } from 'Utils/reactTable/components/DateTime'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import { saveColumnsPreference } from 'Store/actions/login-action'

export const DevicesContent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const tableName = 'devices'
  const devices = useObjSelector((state) => state.sensors)
  const loading = useSelector((state) => state.sensors.loading)

  const [currentSort, setCurrentSort] = useState('createdAt')

  const fetchAction = () => dispatch(fetchDevices())

  useEffect(() => {
    dispatch(fetchDevices())
    return () => {
      dispatch(clearDevices())
    }
  }, [dispatch])

  const hiddenColumns = useSelector((state) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns)
  const groupedValuesColumns = useSelector(
    (state) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const columns = React.useMemo(() => {
    /* const updateAction = (params) => dispatch(updateDevice(params))*/

    return [
      //{Header:t('Icon'),accessor:'icon',disableGlobalFilter:true,Cell:({value})=>value?<Image bordered src={value} circular centered size='mini'/>:<Icon bordered circular name={'sensor'} />},
      {
        Header: t('uniqueID'),
        accessor: 'id',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} ${t('ids')}`,
      },

      {
        Header: t('Serial Number'),
        accessor: 'alias',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} SN's`,
      },
      {
        Header: t('Device'),
        accessor: 'name',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} ${t('devices')}`,
      },
      {
        Header: t('Model'),
        accessor: 'model',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('models')}`,
      },
      {
        Header: t('LastSeen'),
        accessor: 'info.status.lastSeen',
        /*
        aggregate:'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('snrs')}`,
        Cell: ({ value }) => {
          if (!value) return <span> N/A </span>
          return <span title={value}>{value}</span>
        },
        */
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} hour`,
        Cell: ({ value }) => <DateTime value={value} />,
      },
      ...threeColumns(t),
      {
        Header: t('Assets'),
        accessor: 'assets',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('assets')}`,
      },
      {
        Header: t('Destiny'),
        accessor: 'info.destino',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('destinations')}`,
      },
      {
        Header: t('ExitDate'),
        accessor: 'info.saida',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('days')}`,
      },
      {
        Header: t('Mode'),
        accessor: 'info.modo',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('modes')}`,
      },
      {
        Header: t('Connectivity'),
        accessor: 'info.connectivity',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('modes')}`,
      },
      {
        Header: t('Devaddr'),
        accessor: 'info.devaddr',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('keys')}`,
      },

      {
        Header: t('Rate'),
        accessor: 'info.recorrencia',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('rates')}`,
      },
      {
        Header: t('FwVersion'),
        accessor: 'info.fwVersion',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('versions')}`,
      },
      {
        Header: t('Mac'),
        accessor: 'info.mac',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('macs')}`,
      },
      {
        Header: t('Battery'),
        accessor: 'info.battery.lastBatLevel',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('macs')}`,
        Cell: ({ value }) => {
          let color = 'green'
          if (getSafe(() => value.split('%')[0], false) < 20) color = 'red'
          return <span style={{ color: colorFormat(color) }}>{value}</span>
        },
      },
      {
        Header: t('RSSI'),
        accessor: 'info.status.rssi',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('rssis')}`,
      },
      {
        Header: t('SNR'),
        accessor: 'info.status.snr',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('snrs')}`,
      },
      {
        Header: t('Mackey'),
        accessor: 'mackey',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('keys')}`,
      },
      {
        Header: t('Mackeyble'),
        accessor: 'mackeyble',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('keys')}`,
      },
      {
        Header: t('DevEUI LoRa'),
        accessor: 'info.deveui',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('keys')}`,
      },
      {
        Header: t('Manager'),
        accessor: 'info.responsavel',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('managers')}`,
      },
      {
        Header: t('comments'),
        accessor: 'comments',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('keys')}`,
      },
      {
        Header: t ? t('Status') : 'Status',
        accessor: 'status',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('status')}`,
        Cell: (props) => {
          const id = props.row.original.id
          const onChangeToggle = (e, { checked }) => {
            dispatch(updateDeviceById({ id: id, status: checked }))
          }
          return <Checkbox key={id} toggle checked={props.value} onChange={onChangeToggle} />
        },
      },
    ]
  }, [t, dispatch])

  const data = React.useMemo(
    () =>
      devices.allIds?.map((id) => {
        return devices?.byId[id]
      }),
    [devices]
  )

  return (
    <ReactTable
      table={tableName}
      hiddenColumns={hiddenColumns}
      // hiddenButtons={hiddenButtons}
      columns={columns}
      data={data}
      showPlaceholders={loading}
      fetchAction={fetchAction}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      setCurrentSort={setCurrentSort}
      currentSort={currentSort}
    />
  )
}
