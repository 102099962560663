import React from 'react'
import { useSelector } from 'react-redux'
import Loading from 'Utils/components/Loading'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import DashboardPanels from 'Components/Cards/DashboardPanels'

const Container = styled('div')`
  height: 100%;
  overflow-y: auto;
  padding: 5px;
`

export const DashboardManageAndSecurity = () => {
  const { t } = useTranslation()
  const tenant = useSelector((state) => state.tenants.current)
  const hasDataCollectionFeat = tenant?.features?.includes('data_collection')
  const loading = useSelector((state) =>
    !hasDataCollectionFeat ? state.dashboards.loading : !state.toggle.showDashboard
  )

  return !loading ? (
    <Container>
      <DashboardPanels />
    </Container>
  ) : (
    <div style={{ marginTop: '35vh' }}>
      <Loading />
      <h4 style={{ textAlign: 'center' }}>{t('loading_icon_message')}</h4>
    </div>
  )
}
