import React from 'react'
import { Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export const NotAuthorized = () => (
  <Grid textAlign="center" style={{ height: '100vh', background: '#f7f7f7', padding: 30 }} verticalAlign="middle">
    <div style={{ textAlign: 'center' }}>
      <h2 className="mb-1">
        Acesso negado{' '}
        <span role="img" aria-label="Padlock">
          🔐
        </span>
      </h2>
      <p className="mb-2">Você não tem permissão suficiente para utilizar esse app</p>{' '}
      <Link to="/login">Voltar para o início</Link>
    </div>
  </Grid>
)
