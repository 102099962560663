import React from 'react'
import { Card } from 'semantic-ui-react'
import styled from 'styled-components'
import { ResponsiveContainer } from 'recharts'
import CardKPI from './CardKPI'

const possibleColors = ['red', '#336EAE', 'green']

/*const txtmetaPdB = 15
const txtresultPdB = 7

const metaQtdAPP = 6
const resultQtdAPP = 9*/

const fontSize = '14px'

/*const txtmeta = '00:45:00'
const txtresult = '00:57:00'*/

const txt1 = '00:45:00'
const txtres1 = '00:57:00'

const CardContent = styled(Card.Content)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const CardVLI = styled(Card)`
  display: flex;
  flex-direction: column;
  align-content: left;
  justify-content: left;
  align-items: left;
  text-align: left;
  height: 14vh;
`

const CardHeader = styled(Card.Header)`
  font-size: ${fontSize} !important;
  height: 36px;
  overflow: auto;
  font-weight: 700;
`

const CardByRuleMAS = ({ options }) => {
  const numberOfCards = 3
  let cardsColors = []
  for (let i = 0; i < numberOfCards; i++) {
    cardsColors.push(possibleColors[possibleColors.length])
  }
  // txtresult > txtmeta ? cardsColors[2] : txtresult === txtresult ? cardsColors[1] : cardsColors[0]

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        textAlign: 'left',
        fontSize: '14px',
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '30px',
        paddingTop: '60px',
      }}
    >
      <span style={{ top: '20px', left: '25px', position: 'absolute', fontWeight: '700' }}>{"KPI's"}</span>
      <ResponsiveContainer>
        <Card.Group itemsPerRow={numberOfCards}>
          <CardVLI
            style={{
              borderLeft: `0.6vw red solid`,
              height: '100px',
            }}
          >
            <CardContent>
              <CardHeader>Tempo Abandono de Área</CardHeader>
              <Card.Description /*style={{ display: 'flex', flexDirection: 'column' }}*/ textAlign="left">
                {' '}
                <span>Meta: {txt1}</span>
                <br />
                <span style={{ color: 'red' }}>Resultado: {txtres1}</span>
              </Card.Description>
            </CardContent>
          </CardVLI>
          <CardVLI
            style={{
              borderLeft: `0.6vw solid green`,
              height: '100px',
            }}
          >
            <CardContent style={{ margin: '0%' }}>
              <CardHeader>Tempo Médio de Leituras Mensagens EPI</CardHeader>
              <Card.Description /*style={{ display: 'flex', flexDirection: 'column' }}*/ textAlign="left">
                {' '}
                <span>Meta: {'00:05:00'}</span>
                <br />
                <span
                  style={{
                    color: `green`,
                  }}
                >
                  Resultado: {'00:02:00'}
                </span>
              </Card.Description>
            </CardContent>
          </CardVLI>

          <CardKPI options={options} />

          <CardVLI
            style={{
              borderLeft: `0.6vw green solid`,
              height: '100px',
            }}
          >
            <CardContent>
              <CardHeader>Quantidade de QAs</CardHeader>
              <Card.Description /*style={{ display: 'flex', flexDirection: 'column' }}*/ textAlign="left">
                {' '}
                <span>Meta: {0}</span>
                <br />
                <span style={{ color: 'green' }}>Resultado: {0}</span>
              </Card.Description>
            </CardContent>
          </CardVLI>
          <CardVLI
            style={{
              borderLeft: `0.6vw green solid`,
              height: '100px',
            }}
          >
            <CardContent>
              <CardHeader>Acesso Mobile Área Classificada</CardHeader>
              <Card.Description /*style={{ display: 'flex', flexDirection: 'column' }}*/ textAlign="left">
                {' '}
                <span>Meta: {0}</span>
                <br />
                <span style={{ color: 'green' }}>Resultado: {0}</span>
              </Card.Description>
            </CardContent>
          </CardVLI>
          <CardVLI
            style={{
              borderLeft: `0.6vw green solid`,
              height: '100px',
            }}
          >
            <CardContent>
              <CardHeader>Quantidade de Acidentes</CardHeader>
              <Card.Description /*style={{ display: 'flex', flexDirection: 'column' }}*/ textAlign="left">
                {' '}
                <span>Meta: {0}</span>
                <br />
                <span style={{ color: 'green' }}>Resultado: {0}</span>
              </Card.Description>
            </CardContent>
          </CardVLI>
        </Card.Group>
      </ResponsiveContainer>
    </div>
  )
}

export default CardByRuleMAS
