import React, { memo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Marker, FeatureGroup } from 'react-leaflet'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import { icones } from 'Resources/Icons/iconsLeaflet'
import CustomTooltip from 'Utils/components/CustomTooltip'
import { toggle, setTrue } from 'Store/actions/toggle-action'
import { setCurrentStatsBar } from 'Store/actions/system-action'
import { useCanAccess } from 'Utils/rbac'
import { updateWiP } from 'Store/actions/modules-action'

/*  Author: Bruno Melo
    Type: Smart
    Description: Render Marker from References
    To do list: 
    - 			
*/

const RenderRefMarker = ({ moduleId }) => {
  const moduleType = 'refs'
  const dispatch = useDispatch()
  const color = useSelector((state) => state.modules[moduleType]?.byId[moduleId]?.info?.properties?.color)
  const statsbarId = useSelector((state) => state?.system?.statsbar?.id)
  const wipId = useSelector((state) => state.modules[moduleType]?.wip?.id)
  const info = useSelector((state) => state.modules[moduleType]?.byId[moduleId]?.info)
  let geo = Object.assign({}, info.geometry)
  const lat = useSelector((state) => state.modules[moduleType]?.byId[moduleId]?.info?.geometry?.coordinates[1])
  const lng = useSelector((state) => state.modules[moduleType]?.byId[moduleId]?.info?.geometry?.coordinates[0])
  const inner = useSelector((state) => state.toggle.showInnerMap)
  const currentInner = useSelector((state) => state.indoor.current?.inner)

  const markerClick = () => {
    //activate the toggle
    if (moduleId === statsbarId && !editionMode) {
      dispatch(toggle('showStatsBar'))
      setPermanentState(false)
    } else dispatch(setTrue('showStatsBar'))
    dispatch(setCurrentStatsBar(moduleId, 'modules', moduleType))
    //alert('Aqui eu abro o sidebar do Módulo com id ' + moduleId)
  }
  const tooltipText = useSelector((state) => state.modules[moduleType]?.byId[moduleId]?.info?.properties?.label)
  const canAccess = useCanAccess('WRITE')
  let position = inner
    ? innerPosition([lng, lat], 'ref', currentInner?.center, currentInner?.bearing)
    : { lat: lat, lng: lng }

  const typeId = useSelector((state) => state.modules[moduleType]?.byId[moduleId]?.info?.properties?.type)

  const typeData = useSelector((state) => state.modules.types?.byId[parseInt(typeId)]?.info)

  const dragEnd = (data) => {
    geo.coordinates = []
    geo.coordinates[1] = data.target._latlng.lat
    geo.coordinates[0] = data.target._latlng.lng
    dispatch(
      updateWiP({
        moduleType: moduleType,
        id: moduleId,
        geometry: geo,
      })
    )
  }

  //Tooltip custom (it shows Selected, it shows when editing)
  const editionMode = useSelector((state) => state.modules[moduleType]?.wip?.editMode)
  const idTooltipText = canAccess ? moduleId + ' - ' + tooltipText : tooltipText
  const [tooltipState, setTooltipState] = useState(idTooltipText)
  const [permanentState, setPermanentState] = useState(true)

  useEffect(() => {
    if (moduleId === statsbarId) {
      setPermanentState(true)
      if (editionMode) setTooltipState(`(Editando) ` + idTooltipText)
      else setTooltipState(idTooltipText)
    } else setPermanentState(false)
  }, [moduleId, statsbarId, editionMode, idTooltipText])

  return (
    <FeatureGroup key={'refFeatureGroup' + moduleId} onClick={() => markerClick()}>
      <Marker
        key={'refMarkerGroup' + moduleId}
        position={position}
        icon={icones({
          color: typeData?.color ? typeData.color : color ? color : 'black',
          icon: typeData?.icon ? typeData.icon : null,
        })}
        draggable={moduleId === wipId && editionMode === true}
        ondragend={dragEnd}
        onmouseover={() => setPermanentState(true)}
        onmouseout={() => moduleId !== statsbarId && setPermanentState(false)}
      >
        <CustomTooltip permanent={permanentState} direction="bottom">
          {tooltipState}
        </CustomTooltip>
      </Marker>
    </FeatureGroup>
  )
}

export default memo(RenderRefMarker)
