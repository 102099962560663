import React, { Component } from 'react'
import { Dropdown, Icon, Grid, Label } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { sliderChange, fetchHistory } from 'Store/actions/history-action'
import { setTrue, setFalse } from 'Store/actions/toggle-action'
import { fetchInteractions } from 'Store/actions/interactions-action'
import { fetchDashboard } from 'Store/actions/dashboards-action'
import { editModule } from 'Store/actions/modules-action'
import { saveLoginPreferences, fetchLoginPreferences } from 'Store/actions/login-action'

const DropdownStyled = styled(Dropdown)`
  color: #4b72b2;
  // background-color: orange;
`
const DropdownGridHeader = styled(Grid)`
  width: 217px;
`
// const DropdownGridHeader = styled(Grid)`
//   width: 217px;
//   padding-left: 10px;
// `
const DropdownHeader = styled(Dropdown.Header)`
  font-size: 1.33em;
  font-weight: 800;
`
//const DropdownGridItem = styled(Grid)`width: 200px; padding: 10px !important;`;
// const DropdownGridName = styled(Grid.Column)`
//   padding: 10px;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   padding-left: 14px;
//   padding-right: 0;
// `

const DropdownGridName = styled(Grid.Column)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

class DropdownDevs extends Component {
  state = {
    open: false,
    hideAll: false,
  }

  showAlert() {
    alert('Coloque a função desejada no arquivo DropdownDevs')
  }

  fetchNotifications() {
    // this.props.fetchTenantInteractions()
  }

  updateModule() {
    this.props.editModule({ color: 'green' })
  }

  bruno() {}

  showUploadModal() {
    this.props.setTrue('uploadModal')
  }

  fetchHistory() {
    this.props.fetchHistory({
      startTime: '2021-10-07T13:13:00.000Z',
      endTime: '2021-10-07T13:15:00.000Z',
      category: 'antpath',
    })
  }

  render() {
    const { open } = this.state

    const sortMapDevs = () => {
      let devs = [
        { name: 'Bruno', click: () => this.bruno() },
        { name: 'Felipe', click: () => this.showAlert() },
        { name: 'Matheus', click: () => this.showUploadModal() },
        {
          name: 'Luis',
          click: () => {
            this.props.saveLoginPreferences()
          },
        },
      ]
      return devs.map((spaceType, j) => (
        <Dropdown.Item key={j} className="header" content={spaceType.name} icon="file" onClick={spaceType.click} />
      ))
    }

    return (
      <DropdownStyled
        icon={{
          children: (
            <Label circular size="mini" color="green" floating>
              1
            </Label>
          ),
          name: 'download',
          size: 'large',
          onClick: () => this.setState({ open: !open }),
        }}
        //direction='left'
        pointing="top right"
        onBlur={() => this.setState({ open: false })}
        open={open}
        //disabled={routesList.length ? false : true}
        title={'Relatórios'}
      >
        <Dropdown.Menu>
          <DropdownGridHeader className="header">
            <DropdownGridName className="header" width={13}>
              <DropdownHeader icon="star" content="Relatórios" />
            </DropdownGridName>
            <Grid.Column className="header" width={3} textAlign="right">
              <Icon
                onClick={() => {
                  const rows = [
                    ['info', 'ativo', 'tempo'],
                    ['{"lat":-23.20232,"lng":-40.8492839}', 'Smartbadge180', '2021-07-23 11:37:00-03'],
                    ['{"lat":-23.20232,"lng":-40.8492439}', 'Smartbadge180', '2021-07-23 11:36:00-03'],
                    ['{"lat":-23.20252,"lng":-40.8492839}', 'Smartbadge180', '2021-07-23 11:35:00-03'],
                    ['{"lat":-23.20232,"lng":-40.8492839}', 'Smartbadge180', '2021-07-23 11:34:00-03'],
                  ]

                  let csvContent = 'dat a:text/csv;charset=utf-8,'

                  rows.forEach(function (rowArray) {
                    let row = rowArray.join(',')
                    csvContent += row + '\r\n'
                  })

                  var encodedUri = encodeURI(csvContent)
                  var link = document.createElement('a')
                  link.setAttribute('href', encodedUri)
                  link.setAttribute('download', 'report.csv')
                  document.body.appendChild(link)

                  link.click()
                }}
                name={'download'}
                title={'Realiza download do relatório'}
                size="small"
                style={{ fontSize: '1.50em', cursor: 'pointer' }}
              />
            </Grid.Column>
          </DropdownGridHeader>
          <Dropdown.Menu scrolling>
            {/* <Input icon='search' iconPosition='left' name='search' /> */}
            {sortMapDevs()}
          </Dropdown.Menu>
        </Dropdown.Menu>
      </DropdownStyled>
    )
  }
}
const mapDispatchToProps = {
  setTrue,
  sliderChange,
  setFalse,
  fetchInteractions,
  fetchDashboard,
  editModule,
  fetchHistory,
  saveLoginPreferences,
  fetchLoginPreferences,
}

const mapStateToProps = (state) => ({
  refMap: state.login.refMap,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DropdownDevs))
