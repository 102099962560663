import axios from 'axios'
import { urlBase } from 'Apis/rest'

export const fetchTenants = () => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  try {
    const res = await axios.get(urlBase + '/tenants', {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    dispatch({ type: 'FETCH_TENANTS', payload: res.data })
  } catch (err) {
    console.error(err)
  }
}

export const pickTenant = (payload) => ({
  type: 'PICK_TENANT',
  payload,
})
