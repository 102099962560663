import React from 'react'
import { TableColumn } from '../types'
import { TFunction } from 'i18next'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import moment from 'moment'

export const ReportsColumns = (t: TFunction, dispatch: any, match: any) => {
  const prepareForDate = (value: string) => {
    dispatch({
      type: 'SELECT_DATE_REPORTS_OPEN',
      payload: value,
    })
  }
  return [
    {
      Header: t('ReportName'),
      accessor: 'label',
      aggregate: 'count',
      Aggregated: ({ value }) => `${value} nomes`,
    },
    {
      Header: t('Description'),
      accessor: 'description',
    },
    {
      Header: t('Actions'),
      accessor: 'resource',
      Cell: ({ value }) => {
        return (
          <>
            <Link to={match.path === '/app/report/:id' ? `${value}` : `report/${value}`}>
              <Icon name="eye" title={t('PreviewButtonTitle')} />
            </Link>
            <Icon
              color="green"
              name="download"
              title={t('DownloadButtonTitle')}
              style={{ cursor: 'pointer' }}
              onClick={() => prepareForDate(value)}
            />
          </>
        )
      },
    },
  ] as TableColumn[]
}

export const ReportsColumnsCustom = (t: TFunction, cols: any = []) => {
  return cols.map((v: any, id: number) => {
    return {
      Header: v,
      accessor: 'col' + id,
      aggregate:
        v !== 'Duração'
          ? 'count'
          : (agg: any) => {
              return agg.reduce((prev: string, current: string) => {
                const durationSum = moment.duration(prev).add(moment.duration(current))
                return moment.utc(durationSum.asMilliseconds()).format('HH:mm:ss')
              }, '00:00:00')
            },
      Aggregated: ({ value }: any) => `${value}`,
    }
  }) as TableColumn[]
}
