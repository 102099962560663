/*  Author: Luís Mourão / Felipe Pegado
    Type: Smart
	Description: 
    TODO: 
    - Criar componente dumb grid com o conteúdo do return(render)
    - Colocar mesma lógica do Current para Dropdown de Rotas e Referências
*/

import React, { Component } from 'react'
import { Dropdown, Icon, Grid, Input, Popup, Label } from 'semantic-ui-react'
import getSafe from 'Utils/getSafe.js'
import fitBounds from 'Utils/fitBounds.js'
import { setCurrentIndoor } from 'Store/actions/indoor-action'
import { changeLoginPreferences } from 'Store/actions/login-action'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { setTrue, setFalse } from 'Store/actions/toggle-action'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import styled from 'styled-components'
import { setCurrentModule } from 'Store/actions/modules-action'
import MapMethods from 'Utils/components/MapMethods'
import { debounce } from 'throttle-debounce'
import { regExpEscape } from 'Utils/escape'

const DropdownStyled = styled(Dropdown)`
  color: #4b72b2;
  opacity: 1;
  z-index: 210 !important;
`
const DropdownMenu = styled(Dropdown.Menu)`
  border-top-width: 0px !important;
`
const DropdownGridInput = styled(Grid)`
  width: 217px;
  padding-left: 10px;
`
const DropdownInputGrid = styled(Grid.Column)``
const ItemEyeGrid = styled(Grid.Column)`
  padding: 10px !important;
`
const DropdownGridItem = styled(Grid)`
  width: 400px;
`
const DropdownGridName = styled(Grid.Column)`
  padding: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 14px;
  padding-right: 0;
`
const DropdownGridHeader = styled(Grid)`
  width: 217px;
  padding-left: 10px;
  margin: 0px !important;
`
const DropdownHeader = styled(Dropdown.Header)`
  font-size: 1.33em;
  font-weight: 800;
`

const DropdownDivider = styled(Dropdown.Divider)`
  margin-bottom: 10px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
`
const spaceType = { icon: 'shield', name: 'Plantas' }

class DropdownUnits extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Área: false,
      Equipamento: false,
      indoor: false,
      fitBounds: null,
      searchValue: '',
    }
    this.debounceCurrent = debounce(300, this.menuItemHover)
  }

  componentWillUnmount() {
    this.handleBlur()
  }

  itemClick(id, type) {
    let coords = getSafe(() => this.props[type].byId[id].info.geometry.coordinates, null)
    let radius = getSafe(() => this.props[type].byId[id].info.geometry.mRadius, null)
    this.props.setCurrentModule(id, type)
    if (coords) {
      if (this.props.inner) coords = innerPosition(coords, 'coordinates')
      if (!radius) fitBounds(type, coords, this.props.refMap)
      else this.setState({ fitBounds: { type, coords, radius } })
    }
  }

  iconClick(id, invisible) {
    this.props.changeLoginPreferences({ field: 'indoor', preferences: { [id]: { id, invisible } } })
  }

  handleBlur = () => {
    this.props.setCurrentIndoor(null)
  }

  handleSelectionInnerMap(e) {
    //TODO: Criar função para realizar a transformação abaixo uma vez
    //depois salvar no banco p/ deixar mais rápido
    this.props.setCurrentIndoor(e.id, 'inner')
    if (!this.props.inner) this.props.setTrue('showInnerMap')
  }

  headerIconClick(all) {
    let newState = {}
    let preferences = {}
    if (Array.isArray(all) && all.length > 0) {
      all.forEach((e) => {
        const invisible = this.props.indoorPreferences[e.id]?.invisible || false
        if (this.state.indoor === invisible) {
          preferences[e.id] = {
            id: e.id,
            invisible: !this.state.hideAll,
          }
        }
      })
    }

    newState.indoor = !this.state.indoor

    if (preferences) {
      this.props.changeLoginPreferences({ field: 'indoor', preferences })
    }

    this.setState({ ...newState, hideAll: !this.state.hideAll })
  }

  menuItemHover = (id, type) => {
    this.props.setCurrentModule(id, type)
  }

  handleSearch = (event, data) => {
    this.setState({ searchValue: data.value })
  }

  render() {
    const { perfil, indoor, t } = this.props
    const { searchValue } = this.state

    const sortMapIndoor = (par) => {
      if (Array.isArray(par) && par.length > 0) {
        let res = []
        par
          .sort((prev, next) => prev.name.localeCompare(next.name))
          .forEach((e, i, all) => {
            const invisible = this.props.indoorPreferences[e.id]?.invisible || false
            if (!i)
              res.push(
                <DropdownGridHeader className="header" key={spaceType.name}>
                  <DropdownGridName width={12}>
                    <DropdownHeader key={spaceType.name} icon={spaceType.icon} content={spaceType.name} />
                  </DropdownGridName>
                </DropdownGridHeader>
              )
            res.push(
              <Dropdown.Item value={e.id} key={'dpUnitsItem' + e.id}>
                {/* label={{ color: 'red', empty: true, circular: true }} */}
                <DropdownGridItem id="DropdownGridItem" key={'dpUnitsGridItem' + e.id}>
                  <DropdownGridName
                    onClick={() => this.handleSelectionInnerMap(e)}
                    width={14}
                    title={e.name}
                    key={'dpUnitsGridName' + e.id}
                  >
                    {e.name}
                  </DropdownGridName>
                  <ItemEyeGrid width={2} textAlign="right" style={{ left: '2px' }}>
                    <Icon
                      onClick={() => this.iconClick(e.id, !invisible)}
                      name={invisible ? 'eye slash' : 'eye'}
                      title={invisible ? t('EyesShow') : t('EyesHide')}
                      size="small"
                    />
                  </ItemEyeGrid>
                </DropdownGridItem>
              </Dropdown.Item>
            )
          })
        return res
      } else return null
    }

    let indoorList = indoor
      ? Object.values(indoor).filter((f) => getSafe(() => !f.info.properties.invisible, true) || perfil === 'DEV')
      : []
    let filtered = indoorList.filter((data) => data.name.search(new RegExp(regExpEscape(searchValue), 'i')) !== -1)
    return (
      <DropdownStyled
        trigger={
          <Popup
            trigger={<Icon name="building" size="large" onClick={() => this.setState({ open: !this.state.open })} />}
            content={t('Plants')}
            position="bottom center"
            size="tiny"
            inverted
          />
        }
        icon={null}
        //direction='left'
        style={{ pointerEvents: 'auto' }}
        pointing="top right"
        onBlur={() => this.setState({ open: false })}
        open={this.state.open}
        disabled={indoorList.length ? false : true}
      >
        <Dropdown.Menu>
          <DropdownGridInput className="header">
            <DropdownInputGrid width={14}>
              <Input
                onChange={(e, d) => this.handleSearch(e, d)}
                icon
                iconPosition="left"
                placeholder={t('Plants') + '...'}
                size="large"
                style={{ width: '150px' }}
              >
                <Icon name="building outline" />
                <input />
              </Input>
            </DropdownInputGrid>
            <ItemEyeGrid width={2} textAlign="right" floated="right" style={{ bottom: '1px' }}>
              <Label size="mini" style={{ marginLeft: '42px', marginTop: '-8px' }}>
                <Icon
                  onClick={() => this.headerIconClick(filtered)}
                  name={this.state.hideAll ? 'eye slash' : 'eye'}
                  size="small"
                  title={this.state.hideAll ? t('EyesAllShow') : t('EyesAllHide')}
                  style={{ fontSize: '1.50em', cursor: 'pointer' }}
                />
                <Label.Detail>
                  <span style={{ fontSize: '1.9em' }}> {this.state.hideAll ? t('EyesAllShow') : t('EyesAllHide')}</span>
                </Label.Detail>
              </Label>
            </ItemEyeGrid>
          </DropdownGridInput>
          <DropdownDivider />
          <DropdownMenu scrolling>
            {filtered.length > 0 ? sortMapIndoor(filtered) : <Dropdown.Item text={t('NoUnits')} disabled />}
          </DropdownMenu>
          {this.state.fitBounds && (
            <MapMethods type="circle" fit={this.state.fitBounds} cb={() => this.setState({ fitBounds: null })} />
          )}
        </Dropdown.Menu>
      </DropdownStyled>
    )
  }
}

const mapStateToProps = (state) => ({
  inner: state.toggle.showInnerMap,
  indoors: state.indoor.items,
  company: state.login.empresa,
  indoorPreferences: state.login.preferences.indoor,
})

const mapDispatchToProps = {
  setTrue: setTrue,
  setFalse: setFalse,
  setCurrentModule: setCurrentModule,
  setCurrentIndoor: setCurrentIndoor,
  changeLoginPreferences,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DropdownUnits))
