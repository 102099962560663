/*  
  Author: Bruno Melo
  Type: Component  
  Description: Dashboard com regras de negócio e kpi's
  To do: 
*/
import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import { useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'
import PieChartActive from 'Components/SidebarMenu/Contents/DashboardManageAndSecurity/PieChartActive'
import LineChartActive from 'Components/SidebarMenu/Contents/DashboardManageAndSecurity/LineChartActive'
import BarChartActive from 'Components/SidebarMenu/Contents/DashboardManageAndSecurity/BarChartActive'
import CardByRuleMAS from 'Components/SidebarMenu/Contents/DashboardManageAndSecurity/CardByRuleMAS'
import { semanticColors } from 'Utils/colorsFormat.js'

const friendOptions = [
  {
    key: 'now',
    text: 'Tempo Real',
    value: 'now',
    icon: 'calendar alternate outline',
  },
  {
    key: 'today',
    text: 'Hoje',
    value: 'today',
    icon: 'calendar alternate outline',
  },
  {
    key: '1d',
    text: 'Últimas 24h',
    value: '24h',
    icon: 'calendar alternate outline',
  },
  {
    key: '1d',
    text: 'Últimas 48h',
    value: '48h',
    icon: 'calendar alternate outline',
  },
  {
    key: '1d',
    text: 'Últimos 7d',
    value: '7d',
    icon: 'calendar alternate outline',
  },
  {
    key: '30d',
    text: 'Últimos 30d',
    value: '30d',
    icon: 'calendar alternate outline',
  },
  {
    key: '365d',
    text: 'Último ano',
    value: '365d',
    icon: 'calendar alternate outline',
  },
]

const SegmentPieActive = styled(Segment)`
  display: flex;
  border: 0px !important;
  height: 330px;
  box-shadow: 0px 0px !important;
  background-color: #f5f5f5 !important;
  margin: 0 !important;
  padding: 8px !important;
`

const titles = [
  'Pessoas no Site por Atribuição',
  'Acesso Indevido Por Perímetro',
  'Pessoas no Site por App',
  'Dispositivos por Tipo',
  'Pessoas no Site por Perímetro',
]

const data3 = [
  { name: 'Berço 1', value: 13, fill: semanticColors('orange') },
  { name: 'Berço 2', value: 8, fill: semanticColors('green') },
  { name: 'Berço 3', value: 3, fill: semanticColors('blue') },
  { name: 'Moega', value: 3, fill: semanticColors('yellow') },
  { name: 'Tancagem', value: 2, fill: semanticColors('gray') },
]

const data5 = [
  { name: 'Álcool ou Drogas', value: 7, fill: semanticColors('orange') },
  { name: 'Cinto de Segurança, Celular ou Velocidade', value: 5, fill: semanticColors('blue') },
  { name: 'Realizar Atividades sem Treinamento', value: 3, fill: semanticColors('purple') },
  { name: 'Não Realizar Bloqueio do Equipamento', value: 3, fill: semanticColors('yellow') },
]
const DashboardPanels = ({ dashboardMAS, fakeCardsVLI }) => {
  const assetsIds = useSelector((state) => state.assets?.allActivePeopleIds || [], shallowEqual)
  const assets = useSelector((state) => state.assets.byId || {}, shallowEqual)

  const typesIds = useSelector((state) => state.modules.types.allIds || [], shallowEqual)
  const types = useSelector((state) => state.modules.types.byId || [])

  const countedTypes = (typeId) => assetsIds.filter((a) => parseInt(assets[a]?.info?.type) === parseInt(typeId)).length

  const typesObj = typesIds
    .filter((tp) => types[tp].info.table === 'assets.people')
    .map((t) => ({ ...types[t], x: countedTypes(t) }))
    .sort((a, b) =>
      a.info.label && b.info.label ? a.info.label.toLowerCase().localeCompare(b.info.label.toLowerCase()) : 1
    )

  const data1 = typesObj
    .filter((to) => to.x > 0)
    .map((ai) => ({ name: ai.info.label, value: ai.x, fill: semanticColors(ai.info.color) }))

  const options = typesObj.map((to) => ({ text: to.info.label, value: to.id, key: to.id, x: to.x }))

  const appsTotal = useSelector((state) => state.assets?.allActiveAppIds?.length || 0)
  const sbTotal = useSelector((state) => state.assets?.allActiveSbIds?.length || 0)

  const data2 = [
    { name: 'Online', value: appsTotal, fill: semanticColors('green') },
    { name: 'Logout Manual', value: 0, fill: semanticColors('red') },
    { name: 'Sem Rede', value: 0, fill: semanticColors('yellow') },
  ]

  const data4 = [
    { name: 'APP', value: appsTotal, fill: semanticColors('blue') },
    { name: 'Smart Badge', value: sbTotal, fill: semanticColors('orange') }, //,
    // { name: 'Tracker', value: 0, fill: semanticColors('yellow') },
  ]

  return (
    <>
      <Grid centered columns={1}>
        <Grid
          columns="equal"
          style={{
            //paddingBottom: '40px',
            backgroundColor: '#f5f5f5',
          }}
        >
          <Grid columns={2}>
            <Grid.Column>
              <SegmentPieActive>
                <PieChartActive data={data1} title={titles[0]} dateOptions={friendOptions} />
              </SegmentPieActive>
              <SegmentPieActive>
                <PieChartActive data={data2} title={titles[2]} dateOptions={friendOptions} />
              </SegmentPieActive>
              <SegmentPieActive>
                <PieChartActive data={data5} title={'Violação Regras pela Vida'} dateOptions={friendOptions} />
              </SegmentPieActive>
            </Grid.Column>
            <Grid.Column>
              <SegmentPieActive>
                <PieChartActive data={data3} title={titles[1]} date={'today'} dateOptions={friendOptions} />
              </SegmentPieActive>
              <SegmentPieActive>
                <PieChartActive data={data4} title={titles[3]} date={'30d'} dateOptions={friendOptions} />
              </SegmentPieActive>
              <SegmentPieActive>
                <PieChartActive data={data1} title={titles[4]} dateOptions={friendOptions} />
              </SegmentPieActive>
            </Grid.Column>
          </Grid>

          <Grid columns={1}>
            <Grid.Column>
              <SegmentPieActive>
                <CardByRuleMAS date={'7d'} dateOptions={friendOptions} options={options} />
              </SegmentPieActive>
              <SegmentPieActive>
                <LineChartActive />
              </SegmentPieActive>
              <SegmentPieActive>
                <BarChartActive />
              </SegmentPieActive>
            </Grid.Column>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default DashboardPanels
