import request, { urlBase } from 'Apis/rest'
import axios from 'axios'
export const FILTER_HISTORY = 'filterHistory'

export const filterHistory = (filters) => ({
  type: FILTER_HISTORY,
  id: '',
  payload: filters,
})

export const sliderChange = (payload) => ({
  type: 'SLIDER_CHANGE',
  payload,
})

export const saveHistory = (data) => ({
  type: 'saveHistory',
  payload: data,
})

export const saveSelectedTime = (data) => ({
  type: 'saveSelectedTime',
  payload: data,
})

export const fetchHistory =
  ({ startTime, endTime, assetsIds = '', category = 'antpath' }) =>
  async (dispatch, getState) => {
    const jwt = localStorage.getItem('id_token')
    let res
    let start = startTime || getState().filters.timeStart
    let end = endTime || getState().filters.timeEnd
    try {
      dispatch({ type: 'FETCH_HISTORY_START', payload: { startTime: start, endTime: end, assetsIds, category } })
      dispatch({ type: 'SET_TRUE', payload: 'loadingScreen' })
      res = await axios
        .get(`${urlBase}/assets/measures?startTime=${start}&endTime=${end}&assetId=${assetsIds}&category=${category}`, {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        })
        .then((resp) => resp.data.result)
      dispatch({
        type: 'FETCH_HISTORY_SUCCESS',
        payload: res,
      })
    } catch (err) {
      dispatch({ type: 'FETCH_HISTORY_FAILURE' })
      console.error(err)
    }
    dispatch({ type: 'SET_FALSE', payload: ['loadingScreen', 'showHistoryCalendar'] })
  }

export const emptyHistory = () => ({
  type: 'EMPTY_HISTORY',
})

export const fetchPopupData = (id, params) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  try {
    dispatch({ type: 'FETCH_POPUP_START', payload: {} })
    const res = await request.get(`/core/v0/history/data/${id}`, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
      params: { date: params },
    })
    dispatch({ type: 'FETCH_POPUP_SUCCESS', payload: res.data })
  } catch (err) {
    console.log(err)
    dispatch({ type: 'FETCH_POPUP_FAILURE', payload: err })
  }
}

export const changeHistType = (payload) => ({
  type: 'CHANGE_HIST_TYPE',
  payload,
})

export const fetchHistCategories = (assetId) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    dispatch({ type: 'FETCH_HIST_CATEGORIES_START', payload: {}, params: { type: 'categories' }, assetId })
    res = await axios
      .get(`${urlBase}/assets/measures?type=categories&assetId=${assetId}`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_HIST_CATEGORIES_SUCCESS', assetId, payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_HIST_CATEGORIES_FAILURE', payload: err })
  }
}

export const fetchHistCategory = (assetId, category, time, load) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  time = new Date(time).toISOString().replace('T', ' ')
  let res
  try {
    dispatch({ type: 'FETCH_HIST_CATEGORY_START', assetId, category, time, payload: { load } })
    if (category !== 'coords' && load) {
      res = await axios
        .get(`${urlBase}/assets/measures?time=${time}&assetId=${assetId}&category=${category}`, {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        })
        .then((resp) => resp.data.result)
      dispatch({ type: 'FETCH_HIST_CATEGORY_SUCCESS', assetId, payload: res, category })
    }
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_HIST_CATEGORY_FAILURE', payload: err })
  }
}

export const changeHistFlag = (flag, payload) => {
  return {
    type: 'CHANGE_HIST_FLAG',
    flag,
    payload,
  }
}
