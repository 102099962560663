/*  
  Author: Luís Mourão
  Type: Reducer
  
  Description: 
    - 
    - 
  TODO: 
*/

import initialStore from 'Store/initialStore'
import set from 'immutable-set'
import { union } from 'lodash'
import getSafe from 'Utils/getSafe.js'

export default function tablesReducer(state = initialStore.tables, { type, id, payload, prop, value }) {
  switch (type) {
    case 'SET_TABLE_AUTO_UPDATE': {
      const auxState = set(state, `byId.${id}.autoUpdate`, payload)
      return set(auxState, `current.autoUpdate`, payload)
    }

    case 'DELETE_RULE':
      return set(state, 'byId.rules.autoUpdate', true)

    case 'SET_TABLE_SHOW_MODAL':
      const stepState = set(state, `current.showModal`, payload)
      return set(stepState, `byId.${id}.showModal`, payload)

    case 'FETCH_RULES_START':
      return set(state, `byId.rules.loading`, true)

    case 'FETCH_RULES':
      return set(state, `byId.rules.loading`, false)

    case 'FETCH_RULES_FAILURE':
      return set(state, `byId.rules.loading`, false)

    case 'SET_TABLE_SHOW_INPUT':
      return set(state, `byId.${id}.showInput`, payload)

    case 'FETCH_ASSETS_ASSOCIATIONS_START':
      return set(state, `byId.associations.loading`, true)

    case 'FETCH_ASSETS_ASSOCIATIONS_SUCCESS':
      return set(state, `byId.associations.loading`, false)

    case 'FETCH_LOCATION_HIST_START':
      return set(state, `byId.locationHist.loading`, true)

    case 'FETCH_LOCATION_HIST_SUCCESS':
      return set(state, `byId.locationHist.loading`, false)

    case 'FETCH_HISTORY_START':
      return set(state, `byId.measuresHist.loading`, true)

    case 'FETCH_HISTORY_SUCCESS':
      return set(state, `byId.measuresHist.loading`, false)

    case 'SET_TABLE_PROP':
      return set(state, prop, value)

    case 'FETCH_TABLE_CATEGORIES_START':
      return set(state, 'byId.measuresHist.filterStepLoading', 2)

    case 'FETCH_TABLE_CATEGORIES_SUCCESS':
      return {
        ...state,
        byId: set(
          state.byId,
          ['measuresHist', ['filterStepLoading', 'categoriesOptions']],
          [undefined, getSafe(() => Object.keys(payload), [])]
        ),
      }

    case 'FETCH_TABLE_CATEGORIES_FAILURE':
      return set(state, 'byId.measuresHist.filterStepLoading', undefined)

    case 'TOGGLE_TABLE_BOOLEAN':
      return set(state, `byId.${id}.${prop}`, !state.byId[id][prop])

    case 'FETCH_ASSETS_BY_MEASURES_TIME_START':
      return set(state, 'byId.measuresHist.filterStepLoading', 1)

    case 'FETCH_ASSETS_BY_MEASURES_TIME_SUCCESS':
      return {
        ...state,
        byId: set(state.byId, ['measuresHist', ['filterStepLoading', 'assetsOptions']], [undefined, payload]),
      }

    case 'FETCH_TABLE_MEASURES_START':
      return set(state, 'byId.measuresHist.filterStepLoading', 3)

    case 'FETCH_TABLE_MEASURES_SUCCESS':
      return {
        ...state,
        byId: set(state.byId, ['measuresHist', ['filterStepLoading', 'measuresOptions']], [undefined, payload]),
      }

    case 'FETCH_TABLE_MEASURES_HISTORY_START':
      return {
        ...state,
        byId: set(state.byId, [id, ['loading', 'showModal']], [true, false]),
      }

    case 'FETCH_TABLE_MEASURES_HISTORY_SUCCESS':
      return {
        ...state,
        error: null,
        byId: set(state.byId, [id, ['loading', 'data']], [false, payload || []]),
      }

    case 'FETCH_LOGIN_PREFERENCES_SUCCESS':
      if (!payload) return state
      else {
        const createdPanels = payload.routesPanels?.filter((e) => e.type === 'created') || []
        return {
          ...state,
          allIds: state.allIds.concat(createdPanels.map((e) => e.id)),
          byId: {
            ...state.byId,
            ...createdPanels.reduce(
              (a, c) => ({
                ...a,
                [c.id]: {
                  ...c,
                  selectedRelativeTime: c.filters?.find((e) => e.id === 'relativeTime')?.value,
                  selectedAsset: c.filters?.find((e) => e.id === 'asset')?.value,
                  selectedCategory: c.filters?.find((e) => e.id === 'category')?.value,
                  selectedMeasures: c.filters?.find((e) => e.id === 'measure')?.value,
                },
              }),
              {}
            ),
          },
        }
      }

    case 'SET_NEW_ROUTE_PANEL': {
      const auxState = set(state, 'byId', {
        ...state.byId,
        [value.id]: {
          ...value,
          selectedRelativeTime: value?.filters[0]?.value,
          selectedAsset: value?.filters[1]?.value,
          selectedCategory: value?.filters[2]?.value,
          selectedMeasures: value?.filters[3]?.value,
        },
      })
      if (state.allIds.includes(value.id)) return auxState
      else return set(auxState, 'allIds', [value.id, ...state.allIds])
    }

    case 'NEW_ASSET_LOCATION#6054': {
      if (state.byId.motionChart?.data && payload.motion) {
        return {
          ...state,
          byId: {
            ...state.byId,
            motionChart: {
              ...state.byId.motionChart,
              data: state.byId.motionChart.data.filter((e, i) => i !== 0).concat(JSON.parse(payload.motion)),
            },
          },
        }
      }
      return state
    }

    case 'FETCH_TABLE_MEASURES_HISTORY_FAILURE':
      return {
        ...state,
        error: payload,
        byId: set(state.byId, [id, ['loading', 'data']], [false, []]),
      }

    // please refactor this ; I just wanna set showModal to true
    case 'SELECT_DATE_REPORTS_OPEN':
      return {
        ...state,
        byId: {
          ...state.byId,
          reports: {
            ...state.byId.reports,
            showDateModal: true,
            reportType: payload,
            reportViewForm: id,
          },
        },
      }

    case 'SELECT_DATE_REPORTS_REOPEN':
      return {
        ...state,
        byId: {
          ...state.byId,
          reports: {
            ...state.byId.reports,
            showDateModal: true,
          },
        },
      }

    case 'SELECT_DATE_REPORTS_CLOSE':
      return {
        ...state,
        byId: {
          ...state.byId,
          reports: {
            ...state.byId.reports,
            fetchLoading: false,
            fetchSuccess: false,
            showDateModal: false,
            reportData: '',
          },
        },
      }

    case 'FETCH_REPORT_START':
      return {
        ...state,
        byId: {
          ...state.byId,
          reports: {
            ...state.byId.reports,
            fetchLoading: true,
            fetchSuccess: false,
          },
        },
      }

    case 'FETCH_REPORT_SUCCESS':
      return {
        ...state,
        byId: {
          ...state.byId,
          reports: {
            ...state.byId.reports,
            fetchLoading: false,
            fetchSuccess: true,
            reportData: payload,
            reportPayload: payload,
            reportColumns: payload[0],
          },
        },
      }

    case 'FETCH_REPORT_FAILURE':
      return {
        ...state,
        error: payload,
        byId: {
          ...state.byId,
          reports: {
            ...state.byId.reports,
            fetchLoading: false,
            fetchSuccess: false,
            reportData: '',
          },
        },
      }

    case 'FETCH_REPORT_CLEAR_STATE':
      return {
        ...state,
        error: payload,
        byId: {
          ...state.byId,
          reports: {
            ...state.byId.reports,
            fetchLoading: false,
            fetchSuccess: false,
            reportData: '',
            reportColumns: '',
            reportPayload: '',
            reportViewForm: '',
            reportType: '',
            showDateModal: false,
          },
        },
      }

    case 'EXPORT_FENCES_DASHBOARD_REPORT':
      return {
        ...state,
        byId: {
          ...state.byId,
          reports: {
            ...state.byId.reports,
            fetchLoading: false,
            fetchSuccess: true,
          },
        },
      }

    case 'FETCH_TABLE_NOTIFICATIONS_START':
      return {
        ...state,
        byId: set(state.byId, [id, ['loading', 'byId', 'allIds']], [true, { ...state.byId[id].allIds }, []]),
      }

    case 'FETCH_TABLE_NOTIFICATIONS_SUCCESS':
      const notificationsById = {
        ...state.byId[id].byId,
        ...payload.reduce((res, notification) => {
          return { ...res, [notification.id]: notification }
        }, {}),
      }
      const allNotificationsIds = union(
        state.byId[id].allIds,
        payload.map((notification) => notification.id)
      )
      return {
        ...state,
        byId: set(state.byId, [id, ['loading', 'byId', 'allIds']], [false, notificationsById, allNotificationsIds]),
      }

    case 'FETCH_TABLE_NOTIFICATIONS_FAILURE':
      return {
        ...state,
        error: payload,
        byId: set(state.byId, [id, ['loading', 'byId']], [false, { ...state.byId[id].allIds }]),
      }

    default:
      return state
  }
}
