import React, { useState } from 'react'
import { Icon, List, Grid, Form, Input, Label } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { deleteRoutePanel, setLoginProp } from 'Store/actions/login-action'

function PreferencesModalItem({ value, DragHandle, index, firstItem }) {
  const dispatch = useDispatch()

  const routesPanelsStr = useSelector((state) => JSON.stringify(state.login.preferences.routesPanels))
  const routesPanels = JSON.parse(routesPanelsStr)
  const routePanel = routesPanels.byId[value]

  const [showActions, setShowActions] = useState(value === firstItem)
  const [deleteState, setDeleteState] = useState({})
  const [editState, setEditState] = useState({})
  const [errorMsg, setErrorMsg] = useState()

  const changeTableLabel = (id, label) => {
    const nameExist = routesPanels.allIds.find((eachId) => routesPanels.byId[eachId].label === label)
    if (label && !nameExist) {
      dispatch(setLoginProp(`preferences.routesPanels.byId.${id}.label`, label))
      setEditState(false)
    } else {
      return nameExist ? 'nameExist' : 'emptyInput'
    }
  }
  return (
    <>
      <Grid
        columns={3}
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => {
          value !== firstItem && setShowActions(false)
        }}
      >
        <Grid.Column width="1">
          <Icon name={routePanel?.icon} />
        </Grid.Column>
        <Grid.Column width="13" style={{ right: '15px' }}>
          <List.Content>{routePanel?.label}</List.Content>
        </Grid.Column>
        <Grid.Column floated="right" width="2">
          {showActions && routePanel?.id !== 'preferences' && (
            <div style={{ float: 'right' }}>
              <Icon name="edit" onClick={() => setEditState({ id: routePanel.id, label: routePanel?.label })} />
              <Icon
                name={routePanel.invisible ? 'eye slash' : 'eye'}
                onClick={() => {
                  dispatch(
                    setLoginProp(`preferences.routesPanels.byId.${routePanel.id}.invisible`, !routePanel.invisible)
                  )
                }}
              />
              {routePanel.type !== 'permanent' && (
                <Icon
                  name="trash alternate"
                  onClick={() => setDeleteState({ id: routePanel.id, label: routePanel?.label })}
                />
              )}
              <DragHandle />
            </div>
          )}
        </Grid.Column>
      </Grid>
      {deleteState.id && (
        <ModalTemplate
          open
          header="Excluir tabela"
          onClose={() => setDeleteState(false)}
          onCancel={() => setDeleteState(false)}
          saveContent="Excluir"
          saveIcon="trash"
          saveColor="red"
          contentPXMargin={20}
          onSave={() => dispatch(deleteRoutePanel(deleteState))}
        >
          <p>
            Tem certeza que deseja excluir a tabela "<span style={{ fontWeight: 'bold' }}>{deleteState.label}</span>
            "?
          </p>
        </ModalTemplate>
      )}
      {editState.id && (
        <ModalTemplate
          open
          header="Alterar nome"
          onClose={() => setEditState(false)}
          onCancel={() => setEditState(false)}
          contentPXMargin={20}
          onSave={() => setErrorMsg(changeTableLabel(editState.id, editState.label))}
        >
          <Form style={{ marginTop: '25px', marginBottom: '25px' }}>
            <Form.Field inline required error={errorMsg}>
              <label>Informe o novo nome da tabela</label>
              <Input
                name="name"
                fluid
                placeholder={editState.label}
                value={editState.label}
                onChange={(e, { value }) => setEditState({ ...editState, label: value })}
              />
              {errorMsg === 'nameExist' && (
                <Label color="red" pointing>
                  Já existe uma tabela identificada com esse nome
                </Label>
              )}
            </Form.Field>
          </Form>
        </ModalTemplate>
      )}
    </>
  )
}

export default PreferencesModalItem
