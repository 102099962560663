/*  
  Author: Luís Mourão
  Type: Action
  
  Description: 
    - AçõesTables Store
  
  TODO: 
    
*/

import request, { urlBase } from 'Apis/rest'
import { API } from 'aws-amplify'
import { listNotifications } from 'graphql/queries'
import axios from 'axios'
import jwtDecode from 'Utils/jwtDecode'

export const setAutoUpdate = (id, payload) => ({
  type: 'SET_TABLE_AUTO_UPDATE',
  payload,
  id,
})

export const setShowInput = (id, payload) => ({
  type: 'SET_TABLE_SHOW_INPUT',
  payload,
  id,
})

export const setShowModal = (id, payload) => ({
  type: 'SET_TABLE_SHOW_MODAL',
  payload,
  id,
})

export const setTableProp = (prop, value) => ({
  type: 'SET_TABLE_PROP',
  prop,
  value,
})

export const toggleTableBoolean = (id, prop) => ({
  type: 'TOGGLE_TABLE_BOOLEAN',
  id,
  prop,
})

export const fetchTableCategories =
  ({ assetId, relativeTime }) =>
  async (dispatch) => {
    const jwt = localStorage.getItem('id_token')
    let res
    try {
      dispatch({
        type: 'FETCH_TABLE_CATEGORIES_START',
        payload: {},
        params: { assetId, type: 'categories', relativeTime },
      })
      res = await axios
        .get(`${urlBase}/assets/measures?type=categories&assetId=${assetId}&timeAgo=${relativeTime}`, {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        })
        .then((resp) => resp.data.result)
      dispatch({ type: 'FETCH_TABLE_CATEGORIES_SUCCESS', payload: res })
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_TABLE_CATEGORIES_FAILURE', payload: err })
    }
  }

export const fetchCategoriesByAbsoluteTime = (assetId, startTime, endTime) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    dispatch({
      type: 'FETCH_TABLE_CATEGORIES_START',
    })
    res = await axios
      .get(`${urlBase}/assets/measures?type=categories&assetId=${assetId}&startTime=${startTime}&endTime=${endTime}`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_TABLE_CATEGORIES_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_TABLE_CATEGORIES_FAILURE', payload: err })
  }
}

export const fetchCategoryMeasuresByTimeAgo = (assetId, category, timeAgo) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    dispatch({ type: 'FETCH_TABLE_MEASURES_START', assetId, category })
    res = await axios
      .get(`${urlBase}/assets/measures?timeAgo=${timeAgo}&assetId=${assetId}&category=${category}&type=measuresNames`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_TABLE_MEASURES_SUCCESS', assetId, payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_TABLE_MEASURES_FAILURE', payload: err })
  }
}

export const fetchCategoryMeasuresByAbsoluteTime = (assetId, category, startTime, endTime) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    dispatch({ type: 'FETCH_TABLE_MEASURES_START', assetId, category })
    res = await axios
      .get(
        `${urlBase}/assets/measures?startTime=${startTime}&endTime=${endTime}&assetId=${assetId}&category=${category}&type=measuresNames`,
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        }
      )
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_TABLE_MEASURES_SUCCESS', assetId, payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_TABLE_MEASURES_FAILURE', payload: err })
  }
}

export const fetchMeasuresHistory =
  (filters, table = 'measuresHist') =>
  async (dispatch, getState) => {
    const jwt = localStorage.getItem('id_token')
    let selectedRelativeTime,
      selectedAsset,
      selectedCategory,
      selectedMeasures,
      selectedAbsoluteStartTime,
      selectedAbsoluteEndTime
    if (filters) {
      selectedRelativeTime = filters.find((e) => e.id === 'relativeTime').value[0]
      selectedAsset = filters.find((e) => e.id === 'asset').value
      selectedCategory = filters.find((e) => e.id === 'category').value[0]
      selectedMeasures = filters.find((e) => e.id === 'measure').value
    } else {
      const states = getState().tables.byId[table]
      selectedRelativeTime = states.selectedRelativeTime
      selectedAsset = states.selectedAsset
      selectedCategory = states.selectedCategory
      selectedMeasures = states.selectedMeasures
      selectedAbsoluteStartTime = states.selectedAbsoluteStartTime
        ? new Date(states.selectedAbsoluteStartTime).toISOString()
        : undefined
      selectedAbsoluteEndTime = states.selectedAbsoluteEndTime
        ? new Date(states.selectedAbsoluteEndTime).toISOString()
        : undefined
    }

    if (selectedRelativeTime && selectedAsset.length > 0 && selectedCategory && selectedMeasures.length > 0) {
      let res
      try {
        const endpoint = `${urlBase}/assets/measures?type=historyByTime&${
          selectedRelativeTime[0] !== 'absoluteTime'
            ? 'timeAgo=' + selectedRelativeTime
            : 'startTime=' + selectedAbsoluteStartTime + '&endTime=' + selectedAbsoluteEndTime
        }&assetId=${selectedAsset}&category=${selectedCategory}&measure_name=${selectedMeasures}`
        dispatch({
          type: 'FETCH_TABLE_MEASURES_HISTORY_START',
          payload: { selectedRelativeTime, selectedAsset, selectedCategory, selectedMeasures },
          id: table,
        })
        res = await axios
          .get(endpoint, {
            headers: {
              Authorization: 'Bearer ' + jwt,
            },
          })
          .then((resp) => {
            return resp.data
          })
        if (res?.errorType?.includes('ResponseSizeTooLarge')) {
          const error = 'ResponseSizeTooLarge'
          throw error
        } else {
          dispatch({
            type: 'FETCH_TABLE_MEASURES_HISTORY_SUCCESS',
            payload: res?.result,
            id: table,
          })
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: 'FETCH_TABLE_MEASURES_HISTORY_FAILURE',
          payload: err,
          id: table,
        })
      }
    }
  }

export const fetchDevicesByTime = (args, relative) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${urlBase}/reports/devicesbytime?relativeTime=${args.relativeTime}&grouping=${args.grouping}`
  } else {
    requestURL = `${urlBase}/reports/devicesbytime?startTime=${args.startTime}&endTime=${args.endTime}&grouping=${args.grouping}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await request
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchUsersByFences = (args, relative) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${urlBase}/reports/usersByFences?relativeTime=${args.relativeTime}`
  } else {
    requestURL = `${urlBase}/reports/usersByFences?startTime=${args.startTime}&endTime=${args.endTime}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await request
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchLogoutsByTime = (args, relative) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${urlBase}/reports/logoutsbytime?relativeTime=${args.relativeTime}&grouping=${args.grouping}`
  } else {
    requestURL = `${urlBase}/reports/logoutsbytime?startTime=${args.startTime}&endTime=${args.endTime}&grouping=${args.grouping}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await request
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchFenceRulesByTime = (args, relative) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${urlBase}/reports/fenceRulesByTime?relativeTime=${args.relativeTime}&grouping=${args.grouping}`
  } else {
    requestURL = `${urlBase}/reports/fenceRulesByTime?startTime=${args.startTime}&endTime=${args.endTime}&grouping=${args.grouping}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await request
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchFirefightersByTime = (args, relative) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${urlBase}/reports/firefightersbytime?relativeTime=${args.relativeTime}`
  } else {
    requestURL = `${urlBase}/reports/firefightersbytime?startTime=${args.startTime}&endTime=${args.endTime}`
  }

  if (args.assetId) {
    requestURL += `&assetId=${args.assetId}`
  }
  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await request
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchPeopleTypeByTime = (args, relative, typeID) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${urlBase}/reports/peopleTypeByTime?relativeTime=${args.relativeTime}&typeID=${typeID}`
  } else {
    requestURL = `${urlBase}/reports/peopleTypeByTime?startTime=${args.startTime}&endTime=${args.endTime}&typeID=${typeID}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await request
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchRulesTypeByTime = (args, relative, typeID) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${urlBase}/reports/rulesTypeByTime?relativeTime=${args.relativeTime}&typeID=${typeID}`
  } else {
    requestURL = `${urlBase}/reports/rulesTypeByTime?startTime=${args.startTime}&endTime=${args.endTime}&typeID=${typeID}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await request
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchReportDataTypeByTime = (args, reportId, relative, typeID) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${urlBase}/reports/${reportId}?relativeTime=${args.relativeTime}&typeID=${typeID}`
  } else {
    requestURL = `${urlBase}/reports/${reportId}?startTime=${args.startTime}&endTime=${args.endTime}&typeID=${typeID}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await request
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchReportsData =
  (filters, reportId = 'firefightersbytime', args, relative) =>
  async (dispatch) => {
    let requestURL

    if (relative) {
      requestURL = `${urlBase}/reports/${reportId.toLowerCase}?relativeTime=${args.relativeTime}&grouping=${args.grouping}`
    } else {
      requestURL = `${urlBase}/reports/${reportId.toLowerCase}?startTime=${args.startTime}&endTime=${args.endTime}&grouping=${args.grouping}`
    }

    const jwt = localStorage.getItem('id_token')
    let res
    try {
      res = await request
        .get(requestURL, {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        })
        .then((resp) => resp.data.result)
      dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_REPORT_FAILURE' })
    }
  }

export const fetchPresenceByFences = (args, relative) => async (dispatch) => {
  let requestURL

  if (relative) {
    requestURL = `${urlBase}/reports/presenceByFences?relativeTime=${args.relativeTime}`
  } else {
    requestURL = `${urlBase}/reports/presenceByFences?startTime=${args.startTime}&endTime=${args.endTime}`
  }

  if (args.assetId) {
    requestURL += `&assetId=${args.assetId}`
  }

  if (args.fenceId) {
    requestURL += `&fenceId=${args.fenceId}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await request
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchNearMissesByPerimeter = (args, relative, severityLevel, perimeterID) => async (dispatch) => {
  // we're gonna build the request URL string
  let requestURL = `${urlBase}/reports/nearMissesByPerimeter`

  if (relative) {
    requestURL += `?relativeTime=${args.relativeTime}`
  } else {
    requestURL += `?startTime=${args.startTime}&endTime=${args.endTime}`
  }

  requestURL += `&perimeterID=${perimeterID}`

  if (severityLevel !== '0') {
    requestURL += `&severityLevel=${severityLevel}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await request
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

export const fetchReports = (args, relative, reportType, dataTypeID) => async (dispatch) => {
  // we're gonna build the request URL string
  let requestURL = `${urlBase}/reports/${reportType}`

  // check if relative time or dates
  if (relative) {
    requestURL += `?relativeTime=${args.relativeTime}`
  } else {
    requestURL += `?startTime=${args.startTime}&endTime=${args.endTime}`
  }

  if (args.assetId) {
    requestURL += `&assetId=${args.assetId}`
  }

  // used for the back-end filter
  if (dataTypeID) {
    requestURL += `&typeID=${dataTypeID}`
    if (reportType === 'peopleTypeByTime') {
      requestURL += `&grouping=${args.grouping}`
    }
  } else {
    requestURL += `&grouping=${args.grouping}`
  }

  const jwt = localStorage.getItem('id_token')
  let res
  try {
    res = await request
      .get(requestURL, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => resp.data.result)
    dispatch({ type: 'FETCH_REPORT_SUCCESS', payload: res })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_REPORT_FAILURE' })
  }
}

const _fetchNotificationByDateRange = async (tenant, startTime, endTime, nextToken) => {
  const response = await API.graphql({
    query: listNotifications,
    variables: { tenant, sortDirection: 'DESC', nextToken, time: { between: [startTime, endTime] } },
  })
  if (response.data.listNotifications.nextToken) {
    const nextResponse = await _fetchNotificationByDateRange(
      tenant,
      startTime,
      endTime,
      response.data.listNotifications.nextToken
    )
    return [...response.data.listNotifications.items, ...nextResponse]
  }
  return response.data.listNotifications.items
}

export const fetchNotificationByDateRange = (startTime, endTime) => async (dispatch, getState) => {
  const jwt = localStorage.getItem('id_token')
  const tenant = jwtDecode(jwt)['http://plataforma.phygitall.com.br/empresa']
  try {
    dispatch({ type: 'FETCH_TABLE_NOTIFICATIONS_START', id: 'notifications' })
    const payload = await _fetchNotificationByDateRange(tenant, startTime, endTime)
    dispatch({
      type: 'FETCH_TABLE_NOTIFICATIONS_SUCCESS',
      payload,
      id: 'notifications',
    })
  } catch (err) {
    console.error('error fetching notifications', err)
  }
}
