import React, { useEffect } from 'react'
import { Card, Label, Image, Icon, Placeholder } from 'semantic-ui-react'
import styled from 'styled-components'
import { fetchAssetImage } from 'Store/actions/assets-action'
import { useDispatch, useSelector } from 'react-redux'

const CardMeta = styled('div')`
  padding-bottom: 10px;
  text-align: center;
`

const CardImage = styled('div')`
  vertical-align: bottom;
  padding: 2px;
  display: table-cell;
`

export const CardHeader = styled('div')`
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  font-weight: bold;
`
const ImageStyle = { maxWidth: '400px', height: '200px' }

export const FirefightersModal = ({ assetId, name, cardId }) => {
  const dispatch = useDispatch()
  const loadingImage = useSelector((state) => state.assets.byId[assetId]?.loadingImage)

  const assetImageContent = useSelector((state) => state.assets.byId[assetId]?.info?.image?.content)
  const assetImageType = useSelector((state) => state.assets.byId[assetId]?.info?.image?.type)

  const assetImage = assetImageContent
    ? `data:image/${assetImageType};base64,${assetImageContent}`
    : '../images/noPhoto.png'

  useEffect(() => {
    dispatch(fetchAssetImage(cardId, assetId))
  }, [dispatch, cardId, assetId])

  return (
    <Card id={assetId} color={'green'}>
      <CardImage>
        {loadingImage ? (
          <Placeholder style={ImageStyle}>
            <Placeholder.Image />
          </Placeholder>
        ) : (
          <Image src={assetImage} style={ImageStyle} fluid />
        )}
      </CardImage>

      <CardHeader>
        <Card.Header> {name} </Card.Header>
      </CardHeader>

      <CardMeta>
        <Card.Meta>
          <Label color="green">
            presente <Icon name="check" />
          </Label>
        </Card.Meta>
      </CardMeta>
    </Card>
  )
}
