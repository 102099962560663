import React, { useState, useEffect, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Checkbox } from 'semantic-ui-react'
import m from 'moment'
import ReactTable from 'Utils/reactTable'
import {
  fetchRules,
  saveRule,
  deleteRules,
  clearRules,
  subscribeRuleChange,
  subscribeRuleDelete,
} from 'Store/actions/rules-action'
import { omit } from 'lodash'
import TableSpan from 'Components/SidebarMenu/TableSpan'
import { saveColumnsPreference } from 'Store/actions/login-action'

const AlarmsRulesContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const tenant = useSelector((state) => state.login.empresa)

  useEffect(() => {
    let subChange
    let subDelete
    if (tenant) {
      dispatch(fetchRules())
      subChange = dispatch(subscribeRuleChange())
      subDelete = dispatch(subscribeRuleDelete())
    }
    return () => {
      if (subChange && subDelete) {
        subChange.unsubscribe()
        subDelete.unsubscribe()
        dispatch(clearRules())
      }
    }
  }, [dispatch, tenant])

  const assetsStr = useSelector((state) => JSON.stringify(state.assets))

  const rulesByIdStr = useSelector((state) => JSON.stringify(state.rules.byId))
  const rulesAllIdsStr = useSelector((state) => JSON.stringify(state.rules.allIds))
  const loading = useSelector((state) => state.rules.loading)
  const rulesById = JSON.parse(rulesByIdStr)
  const rulesAllIds = JSON.parse(rulesAllIdsStr)

  const tableName = 'rules'
  const assets = JSON.parse(assetsStr)
  const fences = useSelector((state) => state.modules.fences.byId)
  const notificationProfile = useSelector((state) => state.notificationProfiles.byId, shallowEqual)
  const cudLoading = useSelector((state) => state.assets.cudLoading)
  const cudSuccess = useSelector((state) => state.assets.cudSuccess)

  const fetchAction = () => dispatch(fetchRules())
  const deleteAction = (ids) => dispatch(deleteRules(ids))
  // const hiddenButtons = ['rules', 'groups', 'import', 'refresh', 'fullscreen', 'toggle fullscreen', 'calendar']

  const [currentSort, setCurrentSort] = useState('createdAt')
  const hiddenColumns = useSelector((state) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns)

  const groupedValuesColumns = useSelector(
    (state) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const columns = React.useMemo(
    () => [
      {
        Header: t('Code'),
        accessor: 'id',
        aggregate: 'uniqueCount',
        Cell: ({ value }) => <span>{value}</span>,
        Aggregated: ({ value }) => `${value} ${'ids'}`,
      },
      {
        Header: t('Name'),
        accessor: 'name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('names')}`,
        Cell: ({ value }) => <TableSpan value={value} />,
      },
      {
        Header: t('Descrição'),
        accessor: 'description',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('description')}`,
        Cell: ({ value }) => <TableSpan value={value} />,
      },
      {
        Header: t('Type'),
        accessor: 'type',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('types')}`,
        Cell: ({ value }) => <TableSpan value={t(value)} />,
      },
      {
        Header: t('Property'),
        accessor: 'sourceName',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${value === 1 ? t('Property') : t('Properties')}`,
        Cell: ({ value }) => <TableSpan capitalize value={t(value)}></TableSpan>,
      },
      {
        Header: t('Value'),
        accessor: 'sourcePropValue',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${value === 1 ? t('Value') : t('Values')}`,
        Cell: ({ value }) => <TableSpan capitalize value={value?.map((e) => t(e)).join(', ')}></TableSpan>,
      },
      {
        Header: t('Scope'),
        accessor: 'scope',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('scopes')}`,
        Cell: ({ value }) => <span>{t(value)}</span>,
      },
      {
        Header: 'Perímetro/Grupo de Perímetros',
        accessor: 'targetName',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('Fences')}`,
        Cell: ({ value }) => <TableSpan value={value} />,
      },
      {
        Header: t('Actions'),
        accessor: 'action',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('actions')}`,
        Cell: ({ value }) => {
          return (
            <TableSpan value={value.map((e, i) => (i + 1 === value.length ? t(e.type) : t(e.type) + ', '))}></TableSpan>
          )
        },
      },
      {
        Header: 'Perfil de Notificação',
        accessor: 'notificationProfile',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('level(s)')}`,
        Cell: ({ value }) => <span>{t(value)}</span>,
      },
      {
        Header: 'Mensagem',
        accessor: 'message',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'mensagens'}`,
        Cell: ({ value }) => <TableSpan value={value} />,
      },
      {
        Header: t('sendTo'),
        accessor: 'sendTo',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('emails')}`,
      },
      {
        Header: t('Criado por'),
        accessor: 'createdBy',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('names')}`,
      },
      {
        Header: t('Criado em'),
        accessor: 'createdAt',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('hours')}`,
        Cell: ({ value }) => <span title={m(value).format('lll')}>{m(value).fromNow()}</span>,
      },
      {
        Header: t('Status'),
        accessor: 'status',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'status'}`,
        Cell: ({ row }) => {
          return (
            <Checkbox
              toggle
              checked={row.original.status === 'on'}
              onChange={(a, { checked }) =>
                dispatch(
                  saveRule({
                    mutation: 'update',
                    fields: {
                      ...omit(row.original, ['notificationProfile', 'sourceName', 'targetName', 'type']),
                      status: checked ? 'on' : 'off',
                    },
                  })
                )
              }
            />
          )
        },
      },
    ],
    [t, dispatch]
  )
  const data = React.useMemo(
    () =>
      rulesAllIds?.map((id) => {
        let row = rulesById?.[id]
        row.message =
          row?.action
            ?.map((eachMsg) => eachMsg.messageProfileId?.split('#')[1] || eachMsg.messageProfileId)
            .join(', ') || 'Sem Mensagem'
        row.notificationProfile = notificationProfile[row?.notificationProfileId]?.name || 'Nenhum'
        row.sourceName = assets.byId[row.sourceId[0]]?.name || row.sourceId[0] || row.sourceProp
        row.sourceId.forEach((sourceId, i) => {
          if (i > 0) row.sourceName += `, ${assets.byId[sourceId]?.name || sourceId}`
        })
        row.targetName =
          fences[row.targetId[0]]?.info?.properties?.label || fences[row.targetId[0]]?.name || row.targetId[0]
        row.targetId.forEach((targetId, i) => {
          if (i > 0)
            row.targetName += `, ${fences[targetId]?.info?.properties?.label || fences[targetId]?.name || targetId}`
        })
        row.type = `${t(row?.sourceType)}/${t(row?.targetType)}`
        return row
      }),
    [rulesById, rulesAllIds, assets, fences, notificationProfile, t]
  )

  return (
    <ReactTable
      table={tableName}
      hiddenColumns={hiddenColumns}
      // hiddenButtons={hiddenButtons}
      columns={columns}
      data={data}
      showPlaceholders={loading}
      fetchAction={fetchAction}
      deleteAction={deleteAction}
      currentSort={currentSort}
      setCurrentSort={setCurrentSort}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      cudLoading={cudLoading}
      cudSuccess={cudSuccess}
    />
  )
}

export default memo(AlarmsRulesContent, (props, nextProps) => {
  return !nextProps.autoUpdate
})
