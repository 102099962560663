import React, { useEffect, memo, useState } from 'react'
import styled from 'styled-components'
import { Grid, Placeholder } from 'semantic-ui-react'
import IconPopup from 'Utils/components/IconPopup'
import { fetchCategoryMeasures } from 'Store/actions/measures-action'
import { fetchHistCategory } from 'Store/actions/history-action'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { upsideButtons } from 'Components/Map/PopupMarker/jsonGroup.js'

/*  Author: Bruno Melo e Luís Mourão
    Type: Smart
	Description: Handler of all buttons in the popup's header;				 
  To do list: - Improve Design
              - Add button with asset values
              - Change the json buttons using props
              - (Design) The difference of the first icon can change the symmetry    
              - I18n Changes 
*/

const PopupTopButtons = ({ assetId, mode }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const currentTime = useSelector((state) => state[mode].byId[assetId]?.info.time)
  const currentCategory = useSelector((state) => state[mode].byId[assetId]?.currentCategory)
  const measuresCategoriesStr = useSelector((state) => JSON.stringify(state[mode].byId[assetId]?.info || {}))
  const measuresCategories = JSON.parse(measuresCategoriesStr)
  const loginProfile = useSelector((state) => state.login.perfil)
  const loadingCategories = useSelector(
    (state) => state[mode].currentAsset === assetId && state[mode].loadingCategories
  )

  const upButtons = upsideButtons(measuresCategories, loginProfile)

  const iconsLimit = 9 // Limite de ícones sem Paginação

  const [categoryCount, setCategoryCount] = useState(upButtons.length)
  const [begin, setBegin] = useState(0)
  const [end, setEnd] = useState()

  useEffect(() => {
    setEnd(categoryCount > iconsLimit ? iconsLimit - 2 : categoryCount)
  }, [categoryCount])

  useEffect(() => {
    if (upButtons.length !== categoryCount) setCategoryCount(upButtons.length)
  }, [upButtons, categoryCount])

  function arrowLeft() {
    setBegin(begin - 1)
    setEnd(end - 1)
  }

  function arrowRight() {
    if (end < upButtons.length) {
      setBegin(begin + 1)
      setEnd(end + 1)
    }
  }

  function selectCategory(category) {
    mode === 'measures'
      ? dispatch(fetchCategoryMeasures(assetId, category, !measuresCategories[category]?.time))
      : dispatch(fetchHistCategory(assetId, category, currentTime, !measuresCategories[category]?.time))
  }

  //ícones com tamanho diferente dependendo do desenho. Se alguém pensar numa solução melhor entre em contato.
  let mrgLft
  switch (upButtons?.[begin]?.grupo) {
    case 'lora':
      mrgLft = 7
      break
    case 'gps':
      mrgLft = 5
      break
    default:
      mrgLft = 4
  }

  const iconsQtd = upButtons.length

  return (
    <PopupIconsMenu id="PopupIconsMenu" textAlign="left" columns={13} mrglft={mrgLft}>
      {iconsQtd > iconsLimit && (
        <PopupTopIcon textAlign="right" id="PopupTopIcon" key={'arrow left'}>
          <IconPopup
            name={'angle left'}
            onClick={() => arrowLeft()}
            title={'arrow left'}
            onMouseOver={(e) => (e.target.className = e.target.className + ' large')}
            onMouseOut={(e) => (e.target.className = e.target.className.replace('large', ''))}
            disabled={begin === 0}
          />
        </PopupTopIcon>
      )}
      {upButtons.slice(begin, end).map((d, i) => {
        return (
          <PopupTopIcon textAlign="right" id="PopupTopIcon" key={'PopupTopButtonCol' + d.grupo}>
            {currentCategory !== d.grupo ? (
              <IconPopup
                name={d.icone}
                onClick={() => selectCategory(d.grupo)}
                title={t('ClickToSeeDetailsFrom') + t(d.grupo)}
                className="PopupUpButtons"
                onMouseOver={(e) => (e.target.className = e.target.className + ' large')}
                onMouseOut={(e) => (e.target.className = e.target.className.replace('large', ''))}
              />
            ) : (
              <IconPopup
                title={'Clique para desmarcar este grupo'}
                name={d.icone}
                onClick={() => selectCategory(d.grupo)}
                className="PopupUpButtonsSelected"
                size="large"
              />
            )}
          </PopupTopIcon>
        )
      })}
      {/* Só vai carregar as categorias visualmente na primeira vez que abrir o popup */}
      {iconsQtd < 2 && loadingCategories && (
        <PopupTopIcon width={13 - iconsQtd}>
          <Placeholder fluid style={{ marginTop: '5px', paddingTop: '5px' }}>
            <Placeholder.Paragraph>
              {/* <Placeholder.Line length="long" /> */}
              <Placeholder.Line length="full" />
            </Placeholder.Paragraph>
          </Placeholder>
        </PopupTopIcon>
      )}
      {iconsQtd > iconsLimit && (
        <PopupTopIcon textAlign="right" id="PopupTopIcon" key={'arrow right'}>
          <IconPopup
            name={'angle right'}
            onClick={() => arrowRight()}
            title={'angle double right'}
            onMouseOver={(e) => (e.target.className = e.target.className + ' large')}
            onMouseOut={(e) => (e.target.className = e.target.className.replace('large', ''))}
            disabled={end === iconsQtd}
          />
        </PopupTopIcon>
      )}
    </PopupIconsMenu>
  )
}

const PopupTopIcon = styled(Grid.Column)`
  min-width: 21px;
  margin: auto;
  display: inline-block;
`
const PopupIconsMenu = styled(Grid)`
  height: 25px;
  margin-left: ${(props) => props.mrglft}px !important;
`

export default memo(PopupTopButtons)
