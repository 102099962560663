//import React from 'react'
//import {store} from 'Store/configureStore'
//const state = store.getState()

import { defaultMessage } from './NewItemRT'

export const dataGroup = {
  fences: [
    {
      name: 'generalInfo',
      icon: 'columns',
      levelAccess: 'ALL',
      parentField: 'info.properties',
      fields: ['label', 'icon', 'description', 'color'],
    },
    {
      name: 'generalInfo',
      icon: 'columns',
      levelAccess: 'ALL',
      parentField: '',
      fields: ['code', 'groupsText', 'rulesText', 'status', 'createdBy', 'createdAt'],
    },
  ],
  refs: [
    {
      name: 'generalInfo',
      icon: 'columns',
      levelAccess: 'ALL',
      parentField: 'info.properties',
      fields: ['label', 'icon', 'description', 'color'],
    },
    {
      name: 'generalInfo',
      icon: 'columns',
      levelAccess: 'ALL',
      parentField: '',
      fields: ['id', 'groupsText', 'info.properties.color', 'createdBy', 'createdAt', 'status'],
    },
  ],
  types: [
    {
      name: 'generalInfo',
      icon: 'columns',
      levelAccess: 'ALL',
      parentField: 'info',
      fields: ['label', 'icon', 'description', 'color'],
    },
    {
      name: 'generalInfo',
      icon: 'columns',
      levelAccess: 'ALL',
      parentField: '',
      fields: ['id', 'groupsText', 'status', 'createdBy', 'createdAt', 'updatedAt'],
    },
  ],
  units: [
    {
      name: 'generalInfo',
      icon: 'columns',
      levelAccess: 'ALL',
      parentField: '',
      fields: ['info.label', 'groupsText', 'info.description', 'info.url', 'status', 'createdBy', 'createdAt'],
    },
  ],
  associations: [
    {
      name: 'generalInfo',
      icon: 'columns',
      levelAccess: 'ALL',
      parentField: '',
      fields: [
        'assetId',
        'assetName',
        'deviceId',
        'deviceName',
        'start',
        'startExtense',
        'end',
        'endExtense',
        'status',
      ],
    },
  ],
  people: [
    {
      name: 'generalInfo',
      icon: 'columns',
      levelAccess: 'ALL',
      parentField: '',
      initState: {
        name: '',
        sensor: '',
        color: '',
        icon: '',
        type: '',
        time: '',
        createdBy: '',
      },
      options: {
        icon: [
          { key: 'iconBadge', value: 'id badge', text: 'Crachá' },
          { key: 'iconPeople', value: 'pessoa', text: 'Pessoa' },
        ],
        color: [
          { key: 'peopleBlue', value: 'blue', text: 'Azul' },
          { key: 'peopleGreen', value: 'green', text: 'Verde' },
          { key: 'peopleRed', value: 'red', text: 'Vermelho' },
        ],
      },
      fields: ['name', 'groupsText', 'sensor', 'color'],
    },
    {
      name: 'parameters',
      icon: 'cog',
      levelAccess: 'ALL',
      parentField: 'info',
      fields: [
        'cardId',
        'consentAcceptedAt',
        'consentStatus',
        'type',
        'sector',
        'position',
        'registrationId',
        'email',
        'supervisor',
        'company',
        'onBoarding',
        'certifications',
        'description',
        'createdAt',
        'createdBy',
        'createdFrom',
      ],
    },
  ],
  assets: [
    {
      name: 'generalInfo',
      icon: 'columns',
      levelAccess: 'ALL',
      parentField: '',
      initState: {
        name: '',
        sensor: '',
        color: '',
        icon: '',
        type: '',
        time: '',
        createdBy: '',
      },
      options: {
        icon: [
          { key: 'iconBadge', value: 'id badge', text: 'Crachá' },
          { key: 'iconPeople', value: 'pessoa', text: 'Pessoa' },
        ],
        color: [
          { key: 'peopleBlue', value: 'blue', text: 'Azul' },
          { key: 'peopleGreen', value: 'green', text: 'Verde' },
          { key: 'peopleRed', value: 'red', text: 'Vermelho' },
        ],
      },
      fields: ['name', 'groupsText', 'sensor', 'color', 'icon', 'type', 'time', 'createdBy'],
    },
    {
      name: 'parameters',
      icon: 'cog',
      levelAccess: 'ALL',
      parentField: 'info',
      fields: ['matricula', 'nfc', 'consentStatus', 'setor', 'cargo', 'email', 'empresa'],
    },
  ],

  sensors: [
    {
      name: 'sensorInfo',
      icon: 'microchip',
      levelAccess: 'basic',
      parentField: '',
      initState: {
        name: '',
        model: '',
        assets: '',
        comments: '',
        mackey: '',
        time: '',
        createdBy: '',
      },
      fields: ['name', 'model', 'assets', 'comments', 'mackey', ''],
    },
    {
      name: 'logistics',
      icon: 'truck',
      levelAccess: 'basic',
      parentField: 'info',
      fields: ['destino', 'responsavel', 'saida'],
    },
    {
      name: 'parameters',
      icon: 'cogs',
      levelAccess: 'DEV',
      parentField: 'info',
      fields: [
        'appeui',
        'appkey',
        'appskey',
        'connectivity',
        'devaddr',
        'deveui',
        'mac',
        'modo',
        'netwskey',
        'recorrencia',
      ],
    },
    {
      name: 'firmware',
      icon: 'idea',
      levelAccess: 'DEV',
      parentField: 'info.model_info',
      fields: [
        'battery',
        'deepsleep',
        'fw_version',
        'rfid_enable',
        'timeout_gps',
        'wifi_enable',
        'timestamp_en',
        'enable_fw_ota',
        'wifi_connected',
        'lora_version_msg',
        'deepsleep_charging',
        'mpu_odometer_enable',
        'enable_mercury_switch',
      ],
    },
  ],
  interactions: [
    {
      name: 'infoInteractions',
      icon: 'cog',
      levelAccess: 'basic',
      parentField: '',
      fields: [
        'icon',
        'user',
        'interact',
        'category',
        'time',
        'lastTime',
        'when',
        'duration',
        'location',
        'type',
        'scope',
        'distance',
      ],
    },
  ],
  rules: [
    {
      name: 'rules',
      icon: 'idea',
      levelAccess: 'basic',
      parentField: '',
      initState: {
        name: '',
        description: '',
        type: '',
        sourceAssetId: null,
        moduleId: null,
        scope: null,
        checkForm: false,
        criticality: null,
        message: defaultMessage,
        status: 'on',
        createdBy: '',
        createdAt: '',
      },
      fields: [
        'name',
        'description',
        'type',
        'sourceName',
        'sourcePropValue',
        'scope',
        'targetName',
        'action',
        'notificationProfile',
        'message',
        'sendTo',
        'createdBy',
        'createdAt',
        'status',
      ],
    },
  ],
  notificationProfiles: [
    {
      name: 'notificationProfiles',
      icon: 'idea',
      levelAccess: 'basic',
      parentField: '',
      initState: {
        name: '',
        description: '',
        bgColor: '',
        txtColor: '',
        level: '',
      },
      fields: [
        'info.code',
        'notification',
        'description',
        'level',
        'bgColor',
        'txtColor',
        'screenTime',
        'icon',
        'sound',
        'createdBy',
        'createdAt',
        'status',
      ],
      options: {
        bgColor: [
          { key: 'yellow', value: 'YELLOW', text: 'Amarelo' }, //#B3A402
          { key: 'blue', value: 'BLUE', text: 'Azul' }, // #058DB3
          { key: 'orange', value: 'ORANGE', text: 'Laranja' }, //#B36002
          { key: 'green', value: 'GREEN', text: 'Verde' }, //#5CB300
          { key: 'red', value: 'RED', text: 'Vermelho' }, //#B31800
        ],
        txtColor: [{ key: 'black', value: 'BLACK', text: 'Preto' }], //#000000
      },
    },
  ],
  notifications: [
    {
      name: 'Notificações',
      icon: 'columns',
      levelAccess: 'basic',
      parentField: '',
      fields: ['id', 'title', 'message', 'type', 'title', 'updatedAt', 'createdAt'],
    },
  ],
  fases: [
    {
      name: 'fases',
      icon: 'idea',
      levelAccess: 'basic',
      parentField: '',
      fields: [
        'activity',
        'shiftLabel',
        'cycleLabel',
        'phase',
        'start',
        'duration',
        'userName',
        'saturation',
        'description',
        'tgMin',
        'vanva',
      ],
    },
  ],
  messages: [
    {
      name: 'colunas',
      icon: 'columns',
      levelAccess: 'basic',
      parentField: '',
      fields: [
        'id',
        'label',
        'status',
        'type',
        'title',
        'body',
        'updatedAt',
        'updatedBy',
        'updatedFrom',
        'createdAt',
        'createdBy',
        'createdFrom',
      ],
    },
  ],
}
export default dataGroup
