import React from 'react'
import {
  CriticalityContent,
  DashboardContent,
  DevicesContent,
  FencesContent,
  FirefightersContent,
  InteractionsContent,
  MessagesContent,
  PeopleTypeContent,
  PhasesContent,
  RefsContent,
  UnitsContent,
} from 'Components/SidebarMenu/Contents'
import PeopleContent from 'Components/SidebarMenu/Contents/People'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchActiveAssets, fetchAssets } from 'Store/actions/assets-action'
import { fetchRules } from 'Store/actions/rules-action'
import PreferencesModal from 'Components/SidebarMenu/PreferencesModal'
import AlarmsRulesContent from 'Components/SidebarMenu/Contents/AlarmRules'
import MeasuresHistContent from 'Components/SidebarMenu/Contents/MeasuresHist'
import AssociationsContent from 'Components/SidebarMenu/Contents/Associations'
import LogoutsContent from 'Components/SidebarMenu/Contents/Logouts'
import AssetsContent from 'Components/SidebarMenu/Contents/Assets'
import { setFalse } from 'Store/actions/toggle-action'
import { setTableProp } from 'Store/actions/tables-action'
import { MotionContent } from 'Components/SidebarMenu/Contents/Motion'
import MeasuresHistTemplateContent from 'Components/SidebarMenu/Contents/MeasuresHistTemplate'
import ReportsContent from 'Components/SidebarMenu/Contents/Reports'
import CycleTimeMeasurementContent from 'Components/SidebarMenu/Contents/CycleTimeMeasurement'
import { DashboardManageAndSecurity } from 'Components/SidebarMenu/Contents/DashboardManageAndSecurity/ManageAndSecurity'
import { NotificationsContent } from 'Components/SidebarMenu/Contents/Notifications'
import { RootStore } from '../Store/initialStore'

export const TabWrapper = (args: any) => {
  const path = args.path
  const tabType = useSelector((state: RootStore) => state.tables.byId[path]?.type)
  const DictOfFetchAndComponent = {
    people: {
      fetches: [fetchActiveAssets, fetchAssets, fetchRules],
      component: <PeopleContent />,
    },
    types: {
      fetches: [],
      component: <PeopleTypeContent />,
    },
    interactions: {
      fetches: [],
      component: <InteractionsContent />,
    },
    assets: {
      fetches: [],
      component: <AssetsContent />,
    },
    associations: {
      fetches: [],
      component: <AssociationsContent />,
    },
    logouts: {
      fetches: [],
      component: <LogoutsContent />,
    },
    devices: {
      fetches: [],
      component: <DevicesContent />,
    },
    firefighter: {
      fetches: [],
      component: <FirefightersContent />,
    },
    measuresHist: {
      fetches: [fetchActiveAssets, fetchAssets, fetchRules],
      component: <MeasuresHistContent />,
    },
    fences: {
      fetches: [],
      component: <FencesContent />,
    },
    locationHist: {
      fetches: [],
      component: '',
    },
    units: {
      fetches: [],
      component: <UnitsContent />,
    },
    refs: {
      fetches: [],
      component: <RefsContent />,
    },
    messages: {
      fetches: [],
      component: <MessagesContent />,
    },
    notificationProfiles: {
      fetches: [],
      component: <CriticalityContent />,
    },
    notifications: {
      fetches: [],
      component: <NotificationsContent />,
    },
    rules: {
      fetches: [],
      component: <AlarmsRulesContent />,
    },
    phases: {
      fetches: [],
      component: <PhasesContent />,
    },
    labelingTool: {
      fetches: [],
      component: '',
    },
    insights: {
      fetches: [],
      component: <DashboardContent currentSelected="ciclo" />,
    },
    preferences: {
      fetches: [],
      component: <PreferencesModal />,
    },
    motionChart: {
      fetches: [],
      component: <MotionContent />,
    },
    manualYamazumi: {
      fetches: [],
      component: <DashboardContent currentSelected="saturação" />,
    },
    automaticYamazumi: {
      fetches: [],
      component: <DashboardContent currentSelected="shift" />,
    },
    cycleTimeMeasurement: {
      fetches: [],
      component: <CycleTimeMeasurementContent />,
    },
    reports: {
      fetches: [],
      component: <ReportsContent />,
    },
    manageandsecurity: {
      fetches: [],
      component: <DashboardManageAndSecurity />,
    },
  }

  const dispatch = useDispatch()

  // TODO: properly type this
  let necessaryFetches: any[]
  let tabComponent: any

  console.log(path)
  // disgusting; should check for 'created' type
  try {
    necessaryFetches = DictOfFetchAndComponent[path].fetches
    tabComponent = DictOfFetchAndComponent[path].component
  } catch {
    // find "/app/" 's index in the string of the current url
    // and use that as the fallback name
    const currentURL: string = window.location.href
    // if regexIndex is 0, something has really gone wrong
    const regexIndex: number = currentURL.match(/\/app\//)?.index || 0
    const postIndex: number = regexIndex + '/app/'.length
    const tabPath: string = currentURL.substring(postIndex)

    const finalTab = path || tabPath

    necessaryFetches = []
    tabComponent = tabType === 'created' && <MeasuresHistTemplateContent tableName={finalTab} />
  }

  // main data
  useEffect(() => {
    //hide statsbar
    dispatch(setFalse('showStatsBar'))
    // hide most navbar icons
    dispatch(setFalse('displayingMap'))
    // highlight selected tab
    dispatch(setTableProp('current.tab', path))

    for (const fetch of necessaryFetches) {
      dispatch(fetch)
    }
  }, [necessaryFetches, dispatch, path])

  return tabComponent
}
