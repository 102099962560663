import React, { useState } from 'react'
import m from 'moment'

export const DateTime = ({ value, style = { cursor: 'pointer' } }) => {
  const [timeMode, setTimeMode] = useState(m(value).isAfter(m().subtract(1, 'day')))
  return (
    <span
      title={!timeMode ? m(value).fromNow() : m(value).format('lll')}
      onClick={() => setTimeMode(!timeMode)}
      style={style}
    >
      {timeMode ? m(value).fromNow() : m(value).format('DD/MMM HH:mm')}
    </span>
  )
}
