const initialState = {
  confirm: {},
  modal: {},
}

export default function system(state = initialState, { type, payload }) {
  switch (type) {
    case 'SHOW_CONFIRM':
      return {
        ...state,
        confirm: payload,
      }
    case 'HIDE_CONFIRM':
      return { ...state, confirm: payload }
    case 'SET_CURRENT_STATS_BAR':
      return { ...state, statsbar: payload }
    default:
      return state
  }
}
