import React from 'react'
import { Segment, Icon, Select } from 'semantic-ui-react'

export const PaginationReactTable = ({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageSize,
  setPageSize,
  pageCount,
  pageOptionsLength,
  rowsLength,
}) => {
  return (
    <Segment
      floated="left"
      style={{ marginTop: '0px', maxWidth: '300px', border: 'none', right: '15px', textAlign: 'right' }}
    >
      <Icon
        name="angle double left"
        title="ir para primeiro"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        style={{ cursor: 'pointer' }}
      />{' '}
      <Icon
        name="angle left"
        title="ir para anterior"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        style={{ cursor: 'pointer' }}
      />{' '}
      <Icon
        name="angle right"
        title="ir para próximo"
        onClick={() => nextPage()}
        disabled={!canNextPage}
        style={{ cursor: 'pointer' }}
      />{' '}
      <Icon
        name="angle double right"
        title="ir para último"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        style={{ cursor: 'pointer' }}
      />{' '}
      <span>
        <strong>
          <span title="página atual">{pageIndex + 1}</span> /<span title="total de páginas">{pageOptionsLength} </span>
          <span title="total de itens">({rowsLength})</span>
        </strong>{' '}
      </span>
      {/*<span>
        | =>{' '}
        <Input
          type="number"
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            gotoPage(page)
          }}
          value={pageIndex + 1}
          min={1}
          max={pageCount}
        />
        </span>{' '}*/}
      <Select
        value={pageSize}
        onChange={(event, data) => {
          //console.log(event,data)
          setPageSize(Number(data.value))
        }}
        options={[4, 8, 10, 20, 50, 100, 200, 1000].map((nPage) => ({ key: 'sl' + nPage, value: nPage, text: nPage }))}
        compact
        style={{}}
      />
    </Segment>
  )
}

export default PaginationReactTable
