import React, { Component, Fragment } from 'react'
import { Modal, List, Header } from 'semantic-ui-react'

// Now the main version comes from releaseNotes[0].version
// We need to improve this as a service in future

const releaseNotes = [
  { version: '2.9.2', date: '06JAN22', itemsDescriptions: ['Correção no filtro de histórico de medidas'] },
  {
    version: '2.10.0',
    date: '05JAN23',
    itemsDescriptions: ['Dashboard disponível! Minimamente customizável'],
  },
  {
    version: '2.9.1',
    date: '15DEZ22',
    itemsDescriptions: [
      'Minor fixes! Desing: Alinhamento da logo da Phygitall',
      'Congelamento do cabeçalho das tabelas',
      'Removido espaço à esquerda no mapa',
    ],
  },
  {
    version: '2.9.0',
    date: '12DEZ22',
    itemsDescriptions: ['Novo recurso! Modo de visualização por gráfico de linha no histórico de medidas'],
  },
  {
    version: '2.8.14',
    date: '14DEZ22',
    itemsDescriptions: ['Correção no tempo final do filtro dos históricos em mapa'],
  },
  {
    version: '2.8.13',
    date: '12DEZ22',
    itemsDescriptions: [
      'Hotfix espaço ao abrir tabela quando sidebar de módulo está aberta no mapa',
      'Hotfix coluna Caixas levantadas com números diferente ao ser exportada para CSV',
    ],
  },
  {
    version: '2.8.12',
    date: '05DEZ22',
    itemsDescriptions: ['Melhorias estruturais'],
  },
  {
    version: '2.8.11',
    date: '29NOV22',
    itemsDescriptions: ['Correção na tabela de histórico de medidas'],
  },
  {
    version: '2.8.10',
    date: '24NOV22',
    itemsDescriptions: ['Correção nos formulários de histórico de medidas'],
  },
  {
    version: '2.8.9',
    date: '24NOV22',
    itemsDescriptions: ['Correções e aprimoramentos nos históricos de trajetos e pontos'],
  },
  {
    version: '2.8.8',
    date: 'Nov 24, 2022',
    itemsDescriptions: ['Correção no modal de pessoas das notificações', 'Pequenas correções no carregamento inicial'],
  },
  {
    version: '2.8.7',
    date: 'Nov 16, 2022',
    itemsDescriptions: ['Pequenas correções no carregamento inicial'],
  },
  {
    version: '2.8.6',
    date: 'Out 19, 2022',
    itemsDescriptions: ['Correção da perda de foco nos formulários'],
  },
  {
    version: '2.8.5',
    date: 'Out 13, 2022',
    itemsDescriptions: ['Relatórios traduzidos para Inglês, Português, Espanhol ', '3 Relatórios disponíveis'],
  },
  {
    version: '2.8.4',
    date: 'Out 6, 2022',
    itemsDescriptions: ['Traduzido Insights (parcialmente)'],
  },
  {
    version: '2.8.3',
    date: 'Ago 17, 2022',
    itemsDescriptions: ['Removido a StatsBar'],
  },
  {
    version: '2.7.3',
    date: 'Jul 07, 2022',
    itemsDescriptions: [
      'Nova barra de edição para referências',
      'União de marcadores de referências próximos',
      'Nova cor para o menu da esquerda',
      'Vários bug fixes',
    ],
  },
  {
    version: '2.6.3',
    date: 'Jul 01, 2022',
    itemsDescriptions: ['Integração com o suricato usando API de produção.'],
  },
]

export default class ReleaseNotes extends Component {
  render() {
    return (
      <Modal
        trigger={
          <span style={{ cursor: 'pointer', marginLeft: '3px' }}>
            {'- Release Notes v' + releaseNotes?.[0]?.version}
          </span>
        }
        closeIcon
        centered={false}
      >
        <Modal.Header>Novidades</Modal.Header>
        {releaseNotes.map((data) => (
          <Fragment key={data?.version}>
            <Header style={{ borderBottom: 'none' }}>
              <span> {'Release ' + data?.version} </span>
              <span style={{ float: 'right', marginRight: '20px' }}> {data?.date} </span>
            </Header>
            <Modal.Content>
              <List bulleted key={data?.version}>
                {data?.itemsDescriptions?.map((note, i) => (
                  <List.Item content={note} key={data?.itemsDescriptions[0]} />
                ))}
              </List>
            </Modal.Content>
          </Fragment>
        ))}
      </Modal>
    )
  }
}
