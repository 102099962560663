import React from 'react'
import { Menu, Sidebar, Icon } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import TableSpan from 'Components/SidebarMenu/TableSpan'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useObjSelector } from 'Utils/hooks/useObjSelector'

//Se alguem conseguir transformar isso em styled-component seria otimo.
const sideBarStyle = {
  zIndex: 5000,
  background: 'rgba(255,255,255,.15)',
  textColor: 'white',
  height: '86vh',
  width: '8vw',
  display: 'flex',
  alignItems: 'center',
}

const sideBarStyleDown = {
  zIndex: 5000,
  background: 'rgba(255,255,255,.15)',
  textColor: 'white',
  height: '86vh',
  width: '8vw',
  display: 'flex',
  alignItems: 'center',
  paddingTop: '46px',
}

const sideBarMenuItemStyle = {
  color: '#4b72b2',
  paddingTop: '5px',
  paddingRight: '0px',
  paddingLeft: '0px',
  paddingBottom: '0px',
  minHeight: '55px',
  width: '8vw',
  display: 'flex',
  alignItems: 'center',
}

//Se alguem conseguir transformar isso em styled-component seria otimo by TH
const sideBarStyleMobile = {
  zIndex: 5000,
  background: 'rgba(255,255,255,.15)',
  textColor: 'white',
  height: '86vh',
  width: '25vw',
  display: 'flex',
  alignItems: 'center',
}
const sideBarMenuItemStyleMobile = {
  color: '#4b72b2',
  paddingTop: '5px',
  paddingRight: '0px',
  paddingLeft: '0px',
  paddingBottom: '0px',
  minHeight: '55px',
  width: '20vw',
  display: 'flex',
  alignItems: 'center',
}

const SideBarLeft = ({ isMobile, showHistoryCalendar }) => {
  // this should probably be refactored
  const currentTab = useSelector((state) => state.tables.current.tab)

  const visible = useSelector((state) => state.toggle.sideBarLeft)
  let history = useHistory()
  const match = useRouteMatch()
  const { t } = useTranslation()
  const loginRoutesPanels = useObjSelector((state) => state.login.preferences.routesPanels)

  return (
    <Sidebar
      as={Menu}
      animation="push"
      direction="left"
      icon="labeled"
      compact
      vertical
      size={'mini'}
      style={showHistoryCalendar ? sideBarStyleDown : isMobile ? sideBarStyleMobile : sideBarStyle}
      visible={visible}
      width="thin"
    >
      {visible && (
        <React.Fragment>
          <Menu.Item
            active={currentTab === 'map'}
            color="green"
            key="map"
            as="a"
            style={sideBarMenuItemStyle}
            onClick={() => {
              window.location.replace('/')
            }}
            title={t('Map')}
          >
            <Icon name="map outline" />
            <TableSpan value={t('Map')} width="100px" padding="5px" minWidth="100px" noTitle />
          </Menu.Item>
          {loginRoutesPanels.allIds
            .filter((eachId) => loginRoutesPanels.byId[eachId] && !loginRoutesPanels.byId[eachId]?.invisible)
            .map((id) => {
              // to change the color
              const selectedTab = id === currentTab
              return (
                <Menu.Item
                  active={selectedTab}
                  color="green"
                  key={id}
                  as="a"
                  style={isMobile ? sideBarMenuItemStyleMobile : sideBarMenuItemStyle}
                  onClick={() => {
                    history.push(`${match.url}${loginRoutesPanels.byId[id].route}`)
                  }}
                  title={loginRoutesPanels.byId[id].label?.length > 16 ? loginRoutesPanels.byId[id].label : ''}
                >
                  <Icon name={loginRoutesPanels.byId[id].icon} />
                  <TableSpan value={loginRoutesPanels.byId[id].label} padding="5px" noTitle width="95%" />
                </Menu.Item>
              )
            })}
        </React.Fragment>
      )}
    </Sidebar>
  )
}

export default SideBarLeft
