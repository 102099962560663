import React, { memo, useEffect } from 'react'
import { Checkbox } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { InputTemplate } from 'Utils/components/InputTemplate.js'
import { updatePerson, insertPeople, deletePeople } from 'Store/actions/people-action'
import {
  fetchAssets,
  // deleteAssets,
  // insertAssets,
  fetchAssetsType,
  saveGroup,
  deleteGroup,
} from 'Store/actions/assets-action'
import m from 'moment'
import ReactTable from 'Utils/reactTable'
import { fetchRules, clearRules, subscribeRuleChange, subscribeRuleDelete } from 'Store/actions/rules-action'
import { twoColumns } from 'Utils/twoColumns'
import { SvgIcon } from 'Utils/components/SvgIcon.jsx'
import { useCanAccess } from 'Utils/rbac'
//import ConsentButton from 'Utils/components/ConsentButton'
import { showConfirm, hideConfirm } from 'Store/actions/system-action'
import { saveColumnsPreference } from 'Store/actions/login-action'

//import { RootStore } from 'Store/initialStore'

/* interface PeopleContentProps {
  assetsAllIdsStr: string
  assetsByIdStr: string
  assetsMeasuresStr: string
} */

const columnsDefault = ['id', 'name', 'type', 'info.cardId', 'status']

// const hiddenButtons = ['rules', 'import', 'fullscreen', 'toggle fullscreen', 'calendar']

const PeopleContent = memo(
  () => {
    const table = 'people'
    const assetsAllIdsStr = useSelector((state) => JSON.stringify(state.assets.allIds))
    const assetsByIdStr = useSelector((state) => JSON.stringify(state.assets.byId))
    const assetsMeasuresStr = useSelector((state) => JSON.stringify(state.measures.byId))
    const tenantColumns = useSelector((state) => state.tenants.current.info?.routes?.people?.columns)
    const assetsAllIds = JSON.parse(assetsAllIdsStr)
    const assetsById = JSON.parse(assetsByIdStr)
    const assetsMeasures = JSON.parse(assetsMeasuresStr)
    const cudLoading = useSelector((state) => state.people.cudLoading)
    const cudSuccess = useSelector((state) => state.people.cudSuccess)
    const { t } = useTranslation()
    const canAccess = useCanAccess('WRITE')
    const assetTypes = useSelector((state /* : RootStore */) => state.modules.types, shallowEqual)
    const [currentSort, setCurrentSort] = React.useState('name')

    const types = React.useMemo(
      () =>
        assetTypes.allIds
          .filter((tp) => assetTypes.byId[tp].info.table === 'assets.people')
          .map((t) => ({
            key: t,
            value: t,
            text: assetTypes.byId[t].info.label,
            color: assetTypes.byId[t].info.color,
            iconsvg: assetTypes.byId[t].info.icon,
          })),
      [assetTypes]
    )

    const rules = useSelector((state) => state.rules, shallowEqual)

    const typeName = React.useCallback(
      (id) => {
        return assetTypes?.byId?.[id]?.info?.label || ' '
      },
      [assetTypes]
    )

    const dispatch = useDispatch()
    useEffect(() => {
      dispatch(fetchRules())
      const subChange = dispatch(subscribeRuleChange())
      const subDelete = dispatch(subscribeRuleDelete())
      return () => {
        subChange.unsubscribe()
        subDelete.unsubscribe()
        dispatch(clearRules())
      }
    }, [dispatch])

    const fetchAction = React.useCallback(() => {
      dispatch(fetchAssets())
      dispatch(fetchAssetsType())
    }, [dispatch])
    const insertAction = React.useCallback(
      (body) => {
        dispatch(insertPeople(body))
      },
      [dispatch]
    )
    const updateAction = React.useCallback(
      (id, changes) => {
        dispatch(updatePerson(id, changes))
      },
      [dispatch]
    )
    const deleteAction = React.useCallback(
      (ids /* : string */) => {
        dispatch(deletePeople(ids.ids))
      },
      [dispatch]
    )
    const groupAction = React.useCallback((g, s, a) => dispatch(saveGroup(g, s, a)), [dispatch])
    const deleteGroupAction = React.useCallback((ids) => deleteGroup(ids), [])
    // let hiddenColumns = ['id', 'location', 'info.matricula', 'info.createdBy', 'info.createdAt']
    const hiddenColumns = useSelector(
      (state) => state.login.preferences.routesPanels.byId[table].hiddenColumns,
      shallowEqual
    )

    const groupedValuesColumns = useSelector(
      (state) => state.login.preferences.routesPanels.byId[table].groupedValuesColumns
    )

    const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
    useEffect(() => {
      dispatch(saveColumnsPreference(table, false, currentGroup))
    }, [currentGroup, dispatch])

    const noType = '<<' + t('noAssignment') + '>>'

    const columns = React.useMemo(() => {
      const typeColor = (id) => types.filter((f) => f.value === id)[0]?.color
      const typeIcon = (id) => types.filter((f) => f.value === id)[0]?.iconsvg
      const allColumnsAvailable = [
        {
          Header: t('uniqueID'),
          accessor: 'id',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} id`,
          disableGlobalFilter: true,
        },
        {
          Header: t('Code'),
          accessor: 'info.uniqueID',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} code`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.code"
              table={table}
            />
          ),
        },
        {
          Header: 'NFC',
          accessor: 'info.cardId',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} nfc`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.cardId"
              table={table}
            />
          ),
        },
        {
          Header: t('Name'),
          accessor: 'name',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} nomes`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="name"
              table="people"
            />
          ),
        },
        {
          Header: t('Assignment'),
          accessor: 'type',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} ${value === 1 ? t('Assignment') : t('Assignments')}`,
          Cell: (props) => {
            return (
              <>
                <SvgIcon
                  title={'Clique no nome para editá-lo'}
                  color={typeColor(props.value)}
                  name={typeIcon(props.value)}
                  compact
                />
                <br />
                <InputTemplate
                  options={types}
                  value={props.value || noType}
                  id={props?.cell?.row?.original?.id}
                  action={(id, field, value) => {
                    dispatch(updatePerson(id, { [field]: value }))
                  }}
                  field="type"
                  table={table}
                />
              </>
            )
          },
        },
        {
          Header: t('LastSeen'),
          accessor: 'time',
          Cell: ({ value }) => (value ? m(value).fromNow() : t('Never')),
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} horários`,
          disableGlobalFilter: true,
        },
        {
          Header: t('Groups'),
          accessor: 'groupsText',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} grupos`,
        },
        {
          Header: t('Rules'),
          accessor: 'rulesText',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} ${'regras'}`,
        },
        {
          Header: t('Master'),
          accessor: 'info.master',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value}`,
          Cell: ({ value }) => <Checkbox checked={value} disabled />,
        },
        {
          Header: t('Sector'),
          accessor: 'info.sector',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} setores`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.sector"
              table={table}
            />
          ),
        },
        {
          Header: t('info.position'),
          accessor: 'info.position',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} cargos`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.position"
              table={table}
            />
          ),
        },
        {
          Header: t('RegistrationId'),
          accessor: 'info.registrationId',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} matrículas`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.resgistrationId"
              table={table}
              type={'number'}
            />
          ),
        },
        {
          Header: t('info.email'),
          accessor: 'info.email',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} e-mails`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.email"
              table={table}
            />
          ),
        },
        {
          Header: t('info.supervisor'),
          accessor: 'info.supervisor',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} e-mails`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.supervisor"
              table={table}
            />
          ),
        },
        {
          Header: t('info.company'),
          accessor: 'info.company',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} empresas`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.company"
              table={table}
            />
          ),
        },
        {
          Header: t('info.onBoarding'),
          accessor: 'info.onBoarding',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} Ambientações`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.onBoarding"
              table={table}
            />
          ),
        },
        {
          Header: t('info.certifications'),
          accessor: 'info.certifications',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} empresas`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.certifications"
              table={table}
            />
          ),
        },
        {
          Header: t('Description'),
          accessor: 'info.description',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} descrições`,
          Cell: (props) => (
            <InputTemplate
              value={props.value}
              id={props?.cell?.row?.original?.id}
              action={(id, field, value) => {
                dispatch(updatePerson(id, { [field]: value }))
              }}
              field="info.description"
              table={table}
            />
          ),
        },
        {
          Header: t('info.consentAcceptedAt'),
          accessor: 'info.consentAcceptedAt',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} consentimentos`,
          Cell: ({ value }) => (
            <span title={value ? m(value).format('DD/MM/YY HH:mm:ss') : 'Aguardando aprovação'}>
              {value ? m(value).format('DD/MM/YY') : 'Aguardando'}
            </span>
          ),
        },
        {
          Header: t('info.consentStatus'),
          accessor: 'info.consentStatus',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} formas de consentimento`,
          Cell: ({ value }) => value || 'Pendente',
        },
        {
          Header: t ? t('Status') : 'Status',
          accessor: 'status',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} ${t('status')}`,
          Cell: ({ value, cell }) => {
            const handleClick = () => {
              let now = new Date()
              let data = !value
                ? {
                    content: (
                      <div style={{ width: '40vw', height: 'auto', fontSize: '1.5em', padding: '10px' }}>
                        {' '}
                        <span>
                          Ao clicar neste botão você confirma que a pessoa leu o{' '}
                          <a href="https://phygitall.com.br/privacidade/" rel="noopener noreferrer" target="_blank">
                            termo de aceite
                          </a>{' '}
                          e concorda com todos os termos. (Essa informação poderá ser revogada a qualquer momento.)
                        </span>
                      </div>
                    ),
                    onConfirm: () => {
                      dispatch(
                        updatePerson(cell?.row?.original?.id, {
                          consentAcceptedAt: now.toISOString(),
                          consentStatus: 'Manual',
                        })
                      )
                      dispatch(hideConfirm())
                    },
                    onCancel: () => dispatch(hideConfirm()),
                    confirmHeader: 'Aceitar Manualmente o Termo de Aceite?',
                  }
                : {
                    content: (
                      <div style={{ width: '40vw', height: 'auto', fontSize: '1.5em', padding: '10px' }}>
                        {' '}
                        <span>
                          Ao clicar neste botão a pessoa revogará seu aceite ao{' '}
                          <a href="https://phygitall.com.br/privacidade/" rel="noopener noreferrer" target="_blank">
                            termo de aceite
                          </a>{' '}
                          e deixará de enviar informações imediatamente. Tem certeza que deseja continuar?
                        </span>
                      </div>
                    ),
                    onConfirm: () => {
                      dispatch(
                        updatePerson(cell?.row?.original?.id, {
                          consentAcceptedAt: now.toISOString(),
                          consentStatus: 'Revogado Manual',
                        })
                      )
                      dispatch(hideConfirm())
                    },
                    onCancel: () => dispatch(hideConfirm()),
                    confirmHeader: 'Revogar Manualmente o Termo de Aceite?',
                  }
              dispatch(showConfirm(data))
            }
            return (
              <Checkbox
                toggle
                onClick={handleClick}
                checked={value ? true : false}
                title={
                  !canAccess
                    ? 'Status atual da pessoa quanto ao envio de dados.'
                    : value
                    ? 'Clique para revogar manualmente o envio dos dados.'
                    : 'Clique aceitar manualmente o envio dos dados.'
                }
                disabled={!canAccess}
              />
            )
          },
        },
        {
          Header: t('info.sexo'),
          accessor: 'info.sexo',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} sexos`,
        },
        {
          Header: t('info.raça'),
          accessor: 'info.raça',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} raças`,
        },
        {
          Header: t('info.idade'),
          accessor: 'info.idade',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} idades`,
        },
        {
          Header: t('info.rendaFamiliar'),
          accessor: 'info.rendaFamiliar',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} rendas`,
        },
        {
          Header: t('info.escolaridade'),
          accessor: 'info.escolaridade',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} escolaridades`,
        },
        {
          Header: t('info.automovel'),
          accessor: 'info.automovel',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} automóveis`,
        },
        {
          Header: t('info.residencia'),
          accessor: 'info.residencia',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} residencias`,
        },
        ...twoColumns(t, true, canAccess),
      ]
      return allColumnsAvailable.filter((c) =>
        ['id', 'name', ...(tenantColumns || columnsDefault)].includes(c.accessor)
      )
    }, [t, dispatch, types, noType, canAccess, tenantColumns])

    const rows = assetsAllIds.filter((id) => assetsById[id]?.idType === 2 && assetsById[id]?.auto === false)
    const checkRule = (targetType, targetId, groups = [], id) => {
      if (targetId === '<any>') return true
      if (targetType === 'fenceGroup') return groups.includes(targetId)
      return targetId === id.toString()
    }
    const data = React.useMemo(
      () =>
        rows.map((id) => {
          let asset = assetsById[id]
          let assetMeasure = assetsMeasures[id]
          let rulesText = rules.allIds
            ?.filter((f) => checkRule(rules?.byId?.[f]?.sourceType, rules?.byId?.[f]?.sourceId, asset?.groups, id))
            ?.map((m) => (rules.byId[m].id.includes('#') ? rules.byId[m].id.split('#')[1] : rules.byId[m].id))
            ?.sort()
            ?.join(', ')
          let typeColor = types?.filter((f) => f.key === asset?.info?.type)?.[0]?.color || 'green'
          let typeIcon = types?.filter((f) => f.key === asset?.info?.type)?.[0]?.iconsvg || 'worker'
          return {
            //icon: asset?.info?.url || assetTypes.find((each) => each.id === asset?.idType)?.info?.suiIcon || 'user',
            id: asset?.id,
            name: asset?.name,
            groups: asset?.groups,
            groupsText: asset?.groups?.sort()?.toString(),
            info: asset?.info,
            time: assetMeasure?.time || assetMeasure?.hora || assetMeasure?.info?.time,
            //color: asset?.info?.color, //<Icon name='circle' color={assets.byId[id]?.info?.color}/>,
            //type: assetTypes.filter((type) => type.id === asset?.idType)[0]?.nome,
            type: asset?.info?.type,
            typeIcon: typeIcon,
            typeColor: typeColor,
            status: ![null, undefined, 'Pendente', 'Revogado Manual'].includes(asset?.info?.consentStatus),
            createdAt: asset?.createdAt,
            createdBy: asset?.createdBy,
            rulesText,
          }
        }),
      [rows, assetsById, assetsMeasures, rules, types]
    )

    return (
      <ReactTable
        table={table}
        setCurrentSort={setCurrentSort}
        currentSort={currentSort}
        columns={columns}
        hiddenColumns={hiddenColumns}
        // hiddenButtons={hiddenButtons}
        data={data}
        insertAction={insertAction}
        fetchAction={fetchAction}
        updateAction={updateAction}
        deleteAction={deleteAction}
        groupAction={groupAction}
        deleteGroupAction={deleteGroupAction}
        currentGroup={currentGroup}
        setCurrentGroup={setCurrentGroup}
        typeName={typeName}
        cudLoading={cudLoading}
        cudSuccess={cudSuccess}
      />
    )
  },
  (props, nextProps) => {
    return !nextProps.autoUpdate
  }
)

export default PeopleContent
