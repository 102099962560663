import React, { Fragment, useEffect } from 'react'
import {
  useTable,
  useSortBy,
  /*useFilters, */ useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useRowSelect,
  useGroupBy,
  useExpanded,
} from 'react-table'
import { Table, Icon, Segment, Modal, Header, Dropdown /* Placeholder*/ } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { ColumnsReactTable } from './modals/ColumnsReactTable'
import { GroupsReactTable } from './modals/GroupsReactTable'
import { ConfirmReactTable } from './modals/ConfirmReactTable'
import PaginationReactTable from './components/pagination'
import MenuTableIcons from './components/MenuTableIcons'
import Loading from 'Utils/components/Loading'
import NewItemRT from './modals/NewItemRT'
import { isEmpty, size, get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { setAutoUpdate } from 'Store/actions/tables-action'
import IMEInput from 'Utils/imeInput'
import styled from 'styled-components'

const TableHeaderCell = styled(Table.HeaderCell)`
  position: sticky;
  top: -1px;
  z-index: 3;
`
const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  )
})

export const ReactTableTemplate = ({
  typeName,
  showPlaceholders = false,
  showTable = true,
  table,
  tableLabel,
  columns,
  data,
  hiddenColumns = [],
  stateDataGroupFields,
  setStateDataGroupFields,
  setTheseColumns,
  theseColumns,
  fetchAction,
  insertAction,
  updateAction,
  deleteAction,
  groupAction,
  deleteGroupAction,
  // hiddenButtons,
  insightActions,
  insightActionsValues,
  dashboard,
  dashboardId,
  dashboardOptions,
  currentSort,
  descSortOrder = true,
  setCurrentSort,
  FormModal,
  currentGroup,
  setCurrentGroup,
  menuButtons,
  cudLoading,
  cudSuccess,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [columnsOpen, setColumnsOpen] = React.useState(false)
  const [value, setValue] = React.useState('')
  /*Grupos*/
  const [groupsOpen, setGroupsOpen] = React.useState(false)
  const [groupsAction, setGroupsAction] = React.useState(false)

  const profile = useSelector((state) => state.login.perfil)
  const specialProfiles = ['dev', 'adm', 'sup']

  const empresa = useSelector((state) => state.login.empresa)
  const autoUpdate = useSelector((state) => state.tables.byId[table]?.autoUpdate)
  const showModal = useSelector((state) => state.tables.byId[table]?.showModal)
  const showInput = useSelector((state) => state.tables.byId[table]?.showInput)
  //const [currentData, setCurrentData] = React.useState(data)
  const getResolvedState = () => {
    let cols = []
    columns.forEach((c, i) => (cols[i] = c.Header))
    let body = []
    let stringVal = ''
    data.forEach(
      (d, i) =>
        (body[i] = columns.map(
          (c) => {
            stringVal = get(d, c.accessor)
            stringVal = stringVal?.toString()?.replace(';', '||')?.replace(',', '|')
            return stringVal?.replace(/[^\w\s]/gi, '')
          } /* get(d, c.accessor)*/
        ))
    )
    return [cols, ...body]
  }
  const [addOpen, setAddOpen] = React.useState(false)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [confirmText, setConfirmText] = React.useState('Texto explicando o que acontece ao executar essa ação.')
  const [confirmAction, setConfirmAction] = React.useState('')
  const [newClick, setNewClick] = React.useState(true)
  const allTableProps = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: hiddenColumns,
        sortBy: [{ desc: descSortOrder, id: currentSort }],
        groupBy: currentGroup || [],
        globalFilter: value,
      },
    },
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,

    (hooks) => {
      // only show true id column to certain profiles
      if (!specialProfiles.includes(profile.toLocaleLowerCase())) {
        columns.forEach((c, index) => {
          if (c.Header === 'id') {
            columns.splice(index, 1)
          }
        })
      }

      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: (useRowSelect) => (
            <div>
              <IndeterminateCheckbox {...useRowSelect.getToggleAllRowsSelectedProps({ title: t('SelectAllRows') })} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps({ title: t('SelectRow') })} />
            </div>
          ),
          // aggregate:'count',Aggregated:({value})=>`${value} selecionados`, canGroupBy: true
        },
        ...columns,
      ])
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter, sortBy, groupBy },
    //preGlobalFilteredRows,
    setGlobalFilter,
  } = allTableProps
  //const { pageIndex, pageSize} = state;

  useEffect(() => {
    const selectedRowsLen = Object.keys(selectedRowIds).length
    if (selectedRowsLen > 0 && autoUpdate) dispatch(setAutoUpdate(table, false))
    if (!autoUpdate && selectedRowsLen === 0 && !showInput && !showModal) dispatch(setAutoUpdate(table, true))
  }, [autoUpdate, selectedRowIds, dispatch, table, showInput, showModal])

  useEffect(() => {
    const current = sortBy[0]?.id
    if (setCurrentSort && current) setCurrentSort(current)
  }, [sortBy, setCurrentSort])

  useEffect(() => {
    const currentGroup = groupBy
    if (setCurrentGroup && currentGroup) setCurrentGroup(groupBy)
  }, [groupBy, setCurrentGroup])

  // Define a default UI for filtering
  function GlobalFilter({ setGlobalFilter, value }) {
    //const count = preGlobalFilteredRows.length

    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined)
    }, 400)

    return (
      <Segment style={{ minWidth: '175px', border: 'none' }}>
        <IMEInput
          externalVal={value}
          externalOnChange={(e) => {
            setValue(e)
            onChange(e)
          }}
          externalIcon="search"
        />
      </Segment>
    )
  }
  return (
    <Fragment>
      <Modal.Header style={{ padding: '0', height: '10vh' }}>
        <Segment.Group horizontal style={{ margin: '0', border: 'none', boxShadow: 'none', padding: '0' }}>
          <Segment>
            <Header style={{ textTransform: 'capitalize', position: 'relative', top: '25%' }}>
              {dashboard ? (
                <Dropdown
                  disabled={empresa === 'Stellantis'}
                  selection
                  options={dashboardOptions || []}
                  value={dashboardId}
                />
              ) : tableLabel ? (
                tableLabel
              ) : table === 'types' && ['Tiplam', 'TiplamTest'].includes(empresa) ? (
                'Atribuições'
              ) : (
                t(table)
              )}
            </Header>
            {/* : ${insightActionsValues.time.length} ${insightActionsValues.time.length !== 1 ? 'turnos' : 'turno'} */}
          </Segment>
          <GlobalFilter
            //preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            value={value}
          />

          <MenuTableIcons
            typeName={typeName}
            dashboard={dashboard}
            t={t}
            exportAction={getResolvedState}
            insightActions={insightActions}
            insightActionsValues={insightActionsValues}
            setAddOpen={setAddOpen}
            setConfirmOpen={setConfirmOpen}
            setConfirmAction={setConfirmAction}
            noSelectedRows={isEmpty(selectedRowIds) /*||table!=='rules'*/}
            deleteAction={deleteAction}
            setNewClick={setNewClick}
            setConfirmText={setConfirmText}
            setColumnsOpen={setColumnsOpen}
            fetchAction={fetchAction}
            refresh={typeof fetchAction === 'function' || ['Dashboard', 'Perímetros'].includes(dashboard)}
            setGroupsOpen={setGroupsOpen}
            setGroupsAction={setGroupsAction}
            deleteBtn={typeof deleteAction === 'function'}
            edit={size(selectedRowIds) !== 1}
            // hiddenButtons={hiddenButtons}
            menuButtons={menuButtons}
            table={table}
          />

          <PaginationReactTable
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageIndex={pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageOptionsLength={pageOptions.length}
            rowsLength={rows.length}
            autoResetPage={false}
          />
        </Segment.Group>
      </Modal.Header>
      <Modal.Content>
        <Segment style={{ border: 'none', boxShadow: 'none' }}>
          {
            <NewItemRT
              addOpen={addOpen}
              setAddOpen={setAddOpen}
              t={t}
              insertAction={insertAction}
              updateAction={updateAction}
              newClick={newClick}
              setNewClick={setNewClick}
              table={table}
              tableLabel={tableLabel}
              ids={selectedFlatRows.map((d) => d?.original?.id || d?.original?.sk)}
              confirmAction={confirmAction}
              context={table}
              FormModal={FormModal}
            />
          }
          <ConfirmReactTable
            table={table}
            confirmOpen={confirmOpen}
            setConfirmOpen={setConfirmOpen}
            t={t}
            confirmAction={confirmAction}
            deleteAction={deleteAction}
            message={confirmText}
            title={'Confirma ação'}
            ids={selectedFlatRows.map((d) => d?.original?.id || d?.original?.sk)}
            labels={selectedFlatRows.map(
              (d) =>
                d?.original?.info?.label ||
                d?.original?.info?.properties?.label ||
                d?.original?.name ||
                d?.original?.label
            )}
            setConfirmText={setConfirmText}
            setConfirmAction={setConfirmAction}
            deleteGroupAction={deleteGroupAction}
            cudLoading={cudLoading}
            cudSuccess={cudSuccess}
          />
          <ColumnsReactTable
            setColumnsOpen={setColumnsOpen}
            columnsOpen={columnsOpen}
            t={t}
            columnsStr={JSON.stringify(allTableProps.columns)}
            allTableProps={allTableProps}
            setTheseColumns={setTheseColumns}
            theseColumns={theseColumns}
            table={table}
            stateDataGroupFields={stateDataGroupFields}
            setStateDataGroupFields={setStateDataGroupFields}
          />
          <GroupsReactTable
            setGroupsOpen={setGroupsOpen}
            groupsOpen={groupsOpen}
            t={t}
            columns={allTableProps.columns}
            groupsAction={groupsAction}
            setGroupsAction={setGroupsAction}
            setTheseColumns={setTheseColumns}
            theseColumns={theseColumns}
            table={table}
            selected={selectedFlatRows}
            stateDataGroupFields={stateDataGroupFields}
            setStateDataGroupFields={setStateDataGroupFields}
            groupAction={groupAction}
            fetchAction={fetchAction}
          />

          {showTable && (
            <Fragment>
              {showPlaceholders ? (
                <Segment
                  style={{
                    overflowY: 'auto',
                    height: '76vh',
                    border: 'none',
                    boxShadow: 'none',
                    padding: '0',
                    margin: '0',
                  }}
                >
                  <Table striped color={'blue'} singleLine structured {...getTableProps()}>
                    <Table.Header>
                      {
                        // Loop over the header rows
                        headerGroups.map((headerGroup) => (
                          <Table.Row {...headerGroup.getHeaderGroupProps()}>
                            {
                              // Loop over the headers in each row
                              headerGroup.headers.map((column) => (
                                <TableHeaderCell
                                  textAlign="center"
                                  {...column.getHeaderProps(column.getSortByToggleProps())}
                                >
                                  {column.canGroupBy ? (
                                    // If the column can be grouped, let's add a toggle
                                    <Icon
                                      {...column.getGroupByToggleProps()}
                                      name={column.isGrouped ? 'level down' : 'level up'}
                                      rotated="clockwise"
                                    />
                                  ) : null}
                                  {
                                    // Render the heade
                                    column.render('Header')
                                  }
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <Icon name="sort down" />
                                    ) : (
                                      <Icon name="sort up" />
                                    )
                                  ) : (
                                    ''
                                  )}
                                </TableHeaderCell>
                              ))
                            }
                          </Table.Row>
                        ))
                      }
                    </Table.Header>
                    {/* Apply the table body props */}
                  </Table>
                  {
                    <div style={{ left: '50vw', marginTop: '25vh' }}>
                      <Loading />
                    </div>
                  }
                </Segment>
              ) : (
                //When it's not loading
                <Segment
                  style={{
                    overflowY: 'auto',
                    height: '76vh',
                    border: 'none',
                    boxShadow: 'none',
                    padding: '0',
                    margin: '0',
                  }}
                >
                  <Table striped color={'blue'} singleLine structured {...getTableProps()}>
                    <Table.Header>
                      {
                        // Loop over the header rows
                        headerGroups.map((headerGroup) => (
                          <Table.Row {...headerGroup.getHeaderGroupProps()}>
                            {
                              // Loop over the headers in each row
                              headerGroup.headers.map((column) => (
                                <TableHeaderCell
                                  textAlign="center"
                                  {...column.getHeaderProps(column.getSortByToggleProps())}
                                  //Gambiarra aqui, usar o style do column no futuro
                                  // style={{
                                  //   borderTop: `${column.id.startsWith('MediaTgTot') ? '3px green' : '1px rgba(34,36,38,.1)'
                                  //     } none`,
                                  //   borderLeft: `${column.id.startsWith('MediaTgTot') ? '3px green' : '1px rgba(34,36,38,.1)'
                                  //     } solid`,
                                  //   borderRight: `${column.id.startsWith('MediaTgTot') ? '3px green' : '1px rgba(34,36,38,.1)'
                                  //     } solid`,
                                  //   cursor: 'pointer',
                                  // }}
                                >
                                  {column.canGroupBy ? (
                                    // If the column can be grouped, let's add a toggle
                                    <Icon
                                      {...column.getGroupByToggleProps()}
                                      name={column.isGrouped ? 'level down' : 'level up'}
                                      rotated="clockwise"
                                    />
                                  ) : null}
                                  {
                                    // Render the headecolumn.id.startsWith('MediaTgTot')
                                    column.render('Header')
                                  }
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <Icon name="sort down" />
                                    ) : (
                                      <Icon name="sort up" />
                                    )
                                  ) : (
                                    ''
                                  )}
                                </TableHeaderCell>
                              ))
                            }
                          </Table.Row>
                        ))
                      }
                    </Table.Header>
                    {/* Apply the table body props */}
                    <Table.Body {...getTableBodyProps()}>
                      {
                        // Loop over the table rows
                        page.map((row) => {
                          // Prepare the row for display
                          prepareRow(row)
                          return (
                            // Apply the row props
                            <Table.Row {...row.getRowProps()}>
                              {
                                // Loop over the rows cells
                                row.cells.map((cell) => {
                                  // Apply the cell props
                                  return (
                                    <Table.Cell
                                      textAlign={'center'}
                                      verticalAlign="middle"
                                      {...cell.getCellProps()}
                                      // style={{
                                      //   borderLeft: `${cell.column.id.startsWith('MediaTgTot')
                                      //     ? '3px green'
                                      //     : '1px rgba(34,36,38,.1)'
                                      //     } solid`,
                                      //   borderRight: `${cell.column.id.startsWith('MediaTgTot')
                                      //     ? '3px green'
                                      //     : '1px rgba(34,36,38,.1)'
                                      //     } solid`,
                                      // }}
                                    >
                                      {
                                        // Render the cell contents
                                        cell.isGrouped ? (
                                          <>
                                            <Icon
                                              name={row.isExpanded ? 'minus square outline' : 'plus square outline'}
                                              {...row.getToggleRowExpandedProps()}
                                            />
                                            {cell.render('Cell')} ({row.subRows.length})
                                          </>
                                        ) : cell.isAggregated ? (
                                          cell.render('Aggregated')
                                        ) : cell.isPlaceholder ? null : (
                                          cell.render('Cell')
                                        )
                                      }
                                    </Table.Cell>
                                  )
                                })
                              }
                            </Table.Row>
                          )
                        })
                      }
                    </Table.Body>
                  </Table>
                </Segment>
              )}
            </Fragment>
          )}
        </Segment>
      </Modal.Content>
    </Fragment>
  )
}

ReactTableTemplate.whyDidYouRender = true

export default React.memo(ReactTableTemplate)
