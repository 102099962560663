import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from 'Utils/reactTable'
import { fetchModules, saveGroup } from 'Store/actions/modules-action'
import { deleteDynamicData, updateDynamicData } from 'Store/actions/modules-action'
import { InputTemplate } from 'Utils/components/InputTemplate.js'
import { threeColumns } from 'Utils/threeColumns'
import { saveColumnsPreference } from 'Store/actions/login-action'

export const UnitsContent = () => {
  const { t } = useTranslation()
  const tableName = 'units'
  const [currentSort, setCurrentSort] = React.useState('createdAt')
  const tableLabel = t('Plants')
  const unitsStr = useSelector((state) => JSON.stringify(state.modules.units))
  const dispatch = useDispatch()
  const fetchAction = () => dispatch(fetchModules())
  const groupAction = (groupName, selected, action) => dispatch(saveGroup('units', groupName, selected, action))
  const deleteAction = (ids) => dispatch(deleteDynamicData(ids, 'units'))

  // let hiddenColumns = ['id']
  const hiddenColumns = useSelector((state) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns)
  // let hiddenButtons = ['import', 'rules', 'fullscreen', 'toggle fullscreen', 'calendar']

  const groupedValuesColumns = useSelector(
    (state) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const defaultImage = '/images/noImage.png'

  const columns = React.useMemo(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} ids`,
      },
      {
        Header: t('code'),
        accessor: 'info.code',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} code`,
        Cell: (props) => (
          <InputTemplate
            value={props.value}
            id={props?.cell?.row?.original?.id}
            action={(id, field, value) => {
              dispatch(updateDynamicData({ id: id, field: field, value: value }, 'units'))
            }}
            field="info.code"
            table={tableName}
          />
        ),
      },
      {
        Header: t('Blueprint Name'),
        accessor: 'info.label',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} ${t('plants')}`,
        Cell: (props) => (
          <InputTemplate
            value={props.value || '<<sem nome>>'}
            id={props?.cell?.row?.values?.id}
            action={(id, field, value) => dispatch(updateDynamicData({ id: id, field: field, value: value }, 'units'))}
            field={'info.label'}
            table={tableName}
          />
        ),
      },
      {
        Header: t('Groups'),
        accessor: 'groupsText',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${'grupos'}`,
      },
      {
        Header: t('File'),
        accessor: 'info.url',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('urls')}`,
        Cell: (props) => (
          <img
            src={props.value}
            id={props?.cell?.row?.values?.id}
            alt={props.value}
            style={{ maxHeight: '50px', maxWidth: '50px', backgroundColor: 'lightgray' }}
            onError={(event) => {
              event.target.src = defaultImage
              event.onerror = null
            }}
          />
        ),
      },
      {
        Header: t ? t('order') : 'Ordem',
        accessor: 'info.order',
        aggregate: 'order',
        Aggregated: ({ value }) => `${value}`,
      },
      ...threeColumns(t),
    ],
    [t, dispatch]
  )

  const data = React.useMemo(() => {
    const units = JSON.parse(unitsStr)
    return units?.allIds?.map((id) => {
      const unit = units?.byId[id]
      return {
        ...unit,
        info: {
          ...unit?.info,
          label: unit?.info?.label || unit.name,
        },
        groupsText: unit?.groups
          ?.filter((g) => !g.startsWith('R0'))
          ?.sort()
          ?.join(', '),
        rulesText: unit?.groups
          ?.filter((g) => g.startsWith('R0'))
          ?.sort()
          ?.join(', '),
      }
    })
  }, [unitsStr])

  return (
    <ReactTable
      table={tableName}
      tableLabel={tableLabel}
      hiddenColumns={hiddenColumns}
      columns={columns}
      data={data}
      // hiddenButtons={hiddenButtons}
      fetchAction={fetchAction}
      groupAction={groupAction}
      deleteAction={deleteAction}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      setCurrentSort={setCurrentSort}
      currentSort={currentSort}
    />
  )
}
