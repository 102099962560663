import { Tooltip as LeafletTooltip } from 'leaflet'
import { DivOverlay, withLeaflet } from 'react-leaflet'

export class CustomTooltip extends DivOverlay {
  createLeafletElement(props) {
    return new LeafletTooltip(this.getOptions(props), props.leaflet.popupContainer)
  }

  updateLeafletElement(fromProps, toProps) {
    const { popupContainer } = this.props.leaflet
    if (toProps.permanent !== fromProps.permanent) {
      if (popupContainer == null) return
      if (toProps.permanent) popupContainer.openTooltip()
      else popupContainer.closeTooltip()
    }
  }

  componentDidMount() {
    const { popupContainer } = this.props.leaflet
    if (popupContainer == null) return

    popupContainer.on({
      tooltipopen: this.onTooltipOpen,
      tooltipclose: this.onTooltipClose,
    })
    popupContainer.bindTooltip(this.leafletElement)
  }

  componentWillUnmount() {
    const { popupContainer } = this.props.leaflet
    if (popupContainer == null) return

    popupContainer.off({
      tooltipopen: this.onTooltipOpen,
      tooltipclose: this.onTooltipClose,
    })
    if (popupContainer._map != null) {
      popupContainer.unbindTooltip(this.leafletElement)
    }
  }

  onTooltipOpen = ({ tooltip }) => {
    if (tooltip === this.leafletElement) {
      this.onOpen()
    }
  }

  onTooltipClose = ({ tooltip }) => {
    if (tooltip === this.leafletElement) {
      this.onClose()
    }
  }
}

export default withLeaflet(CustomTooltip)
