import React, { Fragment } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useLoadData } from 'Utils/hooks/useLoadData'
import { useTranslation } from 'react-i18next'

const Organizations = ({ currentOrg, orgs }) => {
  const { t } = useTranslation()
  const loadData = useLoadData()
  const handleClick = (a, obj) => {
    loadData(obj.children)
  }
  return (
    <Fragment>
      <Dropdown.Header icon="fort awesome" content={t('Organizations')} />
      {orgs
        .sort((prev, next) => prev.name.localeCompare(next.name))
        .map((org) => (
          <Dropdown.Item selected={org.name === currentOrg} key={org.id} onClick={handleClick}>
            {org.name}
          </Dropdown.Item>
        ))}
    </Fragment>
  )
}

export default Organizations
