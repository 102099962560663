/*  Author: Luís Mourão
    Type: reducer
    Description: reducer from modules store
    To do list: Incluir Current
*/

import { merge, indexOf } from 'lodash'
import produce from 'immer'
import initialStore from 'Store/initialStore'

export default function modulesReducer(state = initialStore.modules, { type, id, payload }) {
  const checkTypes = ['INSERT_MODULE', 'UPDATE_MODULE_SUCCESSFULL', 'DELETE_MODULE']
  let checkedType = type

  if (checkTypes.includes(type)) {
    if (!payload) {
      console.error('Empty payload in', type)
      checkedType = 'error'
    }
    if (!(typeof payload === 'object')) {
      console.error('Payload is not Object in', type)
      checkedType = 'error'
    }
    if (!(payload.moduleType && state[payload.moduleType])) {
      console.error('Payload with moduleType error in', type, payload, state)
      checkedType = 'error'
    }
    if (!id || !payload.id) {
      console.error('Payload without id in', type)
      checkedType = 'error'
    }
  }

  switch (checkedType) {
    case 'FETCH_MODULES':
      if (!payload) {
        console.error('Empty payload in', type)
        return state
      }
      if (!Array.isArray(payload)) {
        console.error('Module initial store payload is not Array')
        return state
      }
      return produce(state, (newState) => {
        payload.forEach((each) => {
          if (!newState[each.moduleType]) {
            newState[each.moduleType] = {}
            newState[each.moduleType].byId = {}
            newState[each.moduleType].allIds = []
            newState[each.moduleType].groups = []
            newState[each.moduleType].current = {}
          }
          newState[each.moduleType].byId[each.id] = each
          newState[each.moduleType].byId[each.id].groups.forEach((group) => {
            if (!newState[each.moduleType].groups.includes(group)) newState[each.moduleType].groups.push(group)
          })
          if (!newState[each.moduleType].allIds.includes(each.id.toString()))
            newState[each.moduleType].allIds.push(each.id.toString())
        })
      })

    //Serve p/ receber cercas de outros logins
    //Porém, está causando erro de ids duplicados, comentando por enquanto.
    case 'INSERT_MODULE':
      if (payload.createdBy === state.wip.createdBy) return state
      return produce(state, (newState) => {
        newState[payload.moduleType].byId[id] = payload
        newState[payload.moduleType].allIds.push(id)
        newState.wip = payload
      })

    case 'CREATE_MODULE_REQUEST':
      if (!payload.moduleType) return state
      return { ...state, wip: { ...payload } }

    case 'CREATE_MODULE_SUCCESSFULL':
    case 'DYNAMIC_INSERT_SUCCESS':
      if (!payload.moduleType) {
        return { ...state, wip: { ...payload } }
      }
      if (!id) id = payload?.id
      if (!id) return { ...state, wip: { ...payload } }
      return produce(state, (newState) => {
        newState[payload.moduleType].byId[id] = payload
        if (indexOf(newState[payload.moduleType].allIds, id) === -1) newState[payload.moduleType].allIds.push(id)
        newState.wip = { ...payload }
      })

    case 'CREATE_MODULE_FAILURE':
      if (!payload.moduleType) return state
      return { ...state, wip: { ...payload } }

    case 'UPDATE_MODULE_SUCCESSFULL':
    case 'UPDATE_MODULE':
      /*if(payload.createdBy === state.wip.createdBy)
            return state;*/
      return produce(state, (newState) => {
        if (!newState[payload.moduleType]) {
          newState[payload.moduleType] = {}
          newState[payload.moduleType].byId = {}
          newState[payload.moduleType].allIds = []
          newState[payload.moduleType].current = {}
        }
        if (!newState[payload.moduleType].byId[id]) {
          newState[payload.moduleType].byId[id] = {}
          newState[payload.moduleType].allIds.push(id)
        }
        newState[payload.moduleType].byId[id].info = merge({}, newState[payload.moduleType].byId[id].info, payload.info)
        //newState[payload.moduleType].byId[id].info.label = payload
        //newState.wip = payload
      })

    case 'UPDATE_MODULE_GROUPS_SUCCESSFULL':
    case 'UPDATE_MODULE_GROUPS':
      /*if(payload.createdBy === state.wip.createdBy)
                    return state;*/
      return produce(state, (newState) => {
        payload.selected.forEach((p) => {
          if (!newState[payload.moduleType]) {
            newState[payload.moduleType] = {}
            newState[payload.moduleType].byId = {}
            newState[payload.moduleType].allIds = []
            newState[payload.moduleType].groups = []
            newState[payload.moduleType].current = {}
          }
          if (!newState[payload.moduleType].groups.includes(payload.groupName))
            newState[payload.moduleType].groups.push(payload.groupName)

          newState[payload.moduleType].allIds.forEach((modId) => {
            if (payload.selected.includes(newState[payload.moduleType].byId[modId].name))
              if (!newState[payload.moduleType].byId[modId].groups.includes(payload.groupName))
                newState[payload.moduleType].byId[modId].groups.push(payload.groupName)
          })
        })
      })

    case 'REMOVE_MODULE_GROUPS_SUCCESSFULL':
    case 'REMOVE_MODULE_GROUPS':
      /*if(payload.createdBy === state.wip.createdBy)
                    return state;*/
      return produce(state, (newState) => {
        payload.selected.forEach((p) => {
          if (!newState[payload.moduleType]) {
            newState[payload.moduleType] = {}
            newState[payload.moduleType].byId = {}
            newState[payload.moduleType].allIds = []
            newState[payload.moduleType].groups = []
            newState[payload.moduleType].current = {}
          }

          newState[payload.moduleType].allIds.forEach((modId) => {
            if (payload.selected.includes(newState[payload.moduleType].byId[modId].name))
              newState[payload.moduleType].byId[modId].groups = newState[payload.moduleType].byId[modId].groups.filter(
                (f) => f !== payload.groupName
              )
          })
        })
      })

    case 'DELETE_MODULE':
    case 'DYNAMIC_DELETE_SUCCESS':
      return produce(state, (newState) => {
        if (!newState[payload.moduleType]) {
          newState[payload.moduleType] = {}
          newState[payload.moduleType].byId = {}
          newState[payload.moduleType].allIds = []
          newState[payload.moduleType].current = {}
        }
        if (!id) id = payload?.ids?.split(',') || []

        //if(!id) return  { ...state, wip: { ...payload } }
        //newState.wip = {}
        newState[payload.moduleType].allIds = newState[payload.moduleType].allIds.filter((a) => !id.includes(a))
      })

    case 'MODULE_INSERT_STARTED':
      return produce(state, (newState) => {
        if (payload) newState[payload.moduleType].wip = payload
      })

    case 'MODULE_INSERT_STOPPED':
      return produce(state, (newState) => {
        if (payload) newState[payload.moduleType].wip = {}
      })

    case 'SET_CURRENT_MODULE':
      return produce(state, (newState) => {
        if (payload) newState[payload].current = id ? state[payload].byId[id] : {}
      })

    case 'MODULE_UPDATE_WIP':
      return produce(state, (newState) => {
        if (payload) {
          /*Check if name or uniqueID*/
          if (payload?.changes?.['form-name'])
            payload.changes.sameNameError = newState[payload.moduleType].allIds.filter(
              (a) => newState[payload.moduleType].byId[a].info.properties.label === payload.changes['form-name']
            ).length
          if (payload?.changes?.['form-uniqueID'])
            payload.changes.sameIdError = newState[payload.moduleType].allIds.filter(
              (a) => newState[payload.moduleType].byId[a].info.properties.uniqueId === payload.changes['form-uniqueID']
            ).length
          if (payload?.geometry) {
            if (!payload.changes) payload.changes = {}
            payload.changes.geometry = payload?.geometry
          }
          if (payload?.mradius) {
            if (!payload.changes) payload.changes = {}
            payload.changes.mradius = payload?.mradius
          }
          newState[payload.moduleType].wip = merge({}, newState[payload.moduleType].wip, payload.changes)
        }
      })

    case 'MODULE_CLEAR_WIP':
      return produce(state, (newState) => {
        newState[payload].wip = {}
      })

    case 'MODULE_FETCH_CHANGES_SUCCESS':
      return produce(state, (newState) => {
        newState.wip = { id: id, changes: payload }
      })

    case 'MODULE_FETCH_CHANGES_CLEAR':
      return produce(state, (newState) => {
        newState.wip = { id: id, changes: [] }
      })

    default:
      return state
  }
}
