import React from 'react'
import { ReactComponent as Worker } from '../images/worker.svg'
import { ReactComponent as Fireman } from '../images/fireman.svg'
import { ReactComponent as Car } from '../images/car.svg'
import { ReactComponent as Driver } from '../images/car.svg'
import { ReactComponent as DriverLocomotive } from '../images/driverLocomotive.svg'
import { ReactComponent as Authority } from '../images/authority.svg'
import { ReactComponent as Authority2 } from '../images/authority2.svg'
import { ReactComponent as Authority3 } from '../images/authority3.svg'
import { ReactComponent as Fireman2 } from '../images/fireman-2.svg'
import { ReactComponent as Mariner } from '../images/mariner.svg'
import { ReactComponent as Mariner2 } from '../images/mariner-2.svg'
import { ReactComponent as Trucker } from '../images/trucker.svg'
import { ReactComponent as Visitor } from '../images/visitor.svg'
import { ReactComponent as Visitor2 } from '../images/visitor-2.svg'
import { ReactComponent as Workervli } from '../images/workervli.svg'
import { ReactComponent as Device } from '../images/device.svg'
import { ReactComponent as DeviceFull } from '../images/subtitles_on_use.svg'
import { ReactComponent as DeviceUnknown } from '../images/subtitles_unkonw.svg'
import { ReactComponent as DeviceCharging } from '../images/subtitles_charging.svg'
import { ReactComponent as DeviceDischarge } from '../images/subtitles_charged.svg'
import { ReactComponent as Totem } from '../images/totem.svg'

import { semanticColors } from 'Utils/colorsFormat'

export const SvgIcon = ({ name, color, paddingBottom, height, width }) => {
  const result = () => {
    const suiColor = semanticColors(color)
    const styleDefault = {
      paddingBottom: paddingBottom || '10px',
      maxHeight: height || '37.5px',
      maxWidth: width || '27.5px',
      minHeight: 17,
    }
    switch (name) {
      case 'worker':
        return <Worker fill={suiColor} style={styleDefault} />
      case 'fireman':
        return <Fireman fill={suiColor} style={styleDefault} />
      case 'car':
        return <Car fill={suiColor} style={styleDefault} />
      case 'authority':
        return <Authority fill={suiColor} style={styleDefault} />
      case 'authority2':
        return <Authority2 fill={suiColor} style={styleDefault} />
      case 'authority3':
        return <Authority3 fill={suiColor} style={styleDefault} />
      case 'driver':
        return <Driver fill={suiColor} style={styleDefault} />
      case 'driverLocomotive':
        return <DriverLocomotive fill={suiColor} style={styleDefault} />
      case 'fireman2':
        return <Fireman2 fill={suiColor} style={styleDefault} />
      case 'mariner':
        return <Mariner fill={suiColor} style={styleDefault} />
      case 'mariner2':
        return <Mariner2 fill={suiColor} style={styleDefault} />
      case 'trucker':
        return <Trucker fill={suiColor} style={styleDefault} />
      case 'visitor':
        return <Visitor fill={suiColor} style={styleDefault} />
      case 'visitor2':
        return <Visitor2 fill={suiColor} style={styleDefault} />
      case 'workervli':
        return <Workervli fill={suiColor} style={styleDefault} />
      case 'device':
        return <Device fill={suiColor} style={styleDefault} />
      case 'device-full':
        return <DeviceFull fill={suiColor} style={styleDefault} />
      case 'device-unknown':
        return <DeviceUnknown fill={suiColor} style={styleDefault} />
      case 'device-charging':
        return <DeviceCharging fill={suiColor} style={styleDefault} />
      case 'device-discharge':
        return <DeviceDischarge fill={suiColor} style={styleDefault} />
      case 'totem':
        return <Totem fill={suiColor} style={styleDefault} />
      default:
        return <Worker fill={suiColor} style={styleDefault} />
    }
  }

  return result()
}

export default SvgIcon
