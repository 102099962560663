const fitBounds = (type, coords, refMap) => {
  let auxCoords
  if (!coords) {
    console.error(`Fitbounds do ${type} without coords: ${coords}`)
    return
  }
  switch (type) {
    case 'fences':
      let typeCoords = coords.length === 2 ? [coords] : coords[0]
      auxCoords = typeCoords.map((e) => [e[1], e[0]])
      break
    case 'routes':
      auxCoords = coords.map((e) => [e[1], e[0]])
      break
    case 'refs':
      auxCoords = [[coords[1], coords[0]]]
      break
    default:
      auxCoords = coords
      break
  }
  if (auxCoords && auxCoords[0] && refMap) {
    refMap.flyToBounds(auxCoords)
  }
}
export default fitBounds
