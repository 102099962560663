import React from 'react'
import { Table, Header, Image, Icon } from 'semantic-ui-react'
import m from 'moment'

const LogRow = ({ id, name, timestamp, status, rank }) => (
  <Table.Row>
    <Table.Cell verticalAlign="middle">{id}</Table.Cell>
    <Table.Cell>
      <Header as="h4" image>
        <Image
          rounded
          src={`https://i0.wp.com/cdn.auth0.com/avatars/${name.toLowerCase().slice(0, 1)}.png?ssl=1`}
          size="mini"
        />
        <Header.Content>
          {name}
          <Header.Subheader>{rank ? 'Funcionário' : 'Visitante'}</Header.Subheader>
        </Header.Content>
      </Header>
    </Table.Cell>
    <Table.Cell verticalAlign="middle">299.277.937-74</Table.Cell>
    <Table.Cell verticalAlign="middle">
      <time>{m(timestamp).format('lll')}</time>
    </Table.Cell>
    <Table.Cell verticalAlign="middle">
      <Icon
        title={status}
        name="external square alternate"
        flipped={status === 'Entrada' && 'vertically'}
        color={status === 'Entrada' ? 'green' : 'red'}
      />
    </Table.Cell>
  </Table.Row>
)
export default LogRow
