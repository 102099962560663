import React from 'react'
import { Segment, Modal, Header, Dropdown } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ModalFilter from 'Utils/modals/ModalFilter.js'
import MenuTableIcons from 'Utils/reactTable/components/MenuTableIcons.jsx'

const ModalHeader = ({
  dashboard,
  dashboardOptions,
  exportAction,
  insightActions,
  insightActionsValues,
  // hiddenButtons,
  name,
  noFilter,
  fetchAction,
  menuButtons,
}) => {
  const empresa = useSelector((state) => state.login.empresa)
  const dashboardId = useSelector((state) => state.dashboards.currentId)
  const { t } = useTranslation()

  return (
    <Modal.Header style={{ padding: '0' }}>
      <Segment.Group horizontal style={{ border: 'none', boxShadow: 'none', padding: '0' }}>
        <Segment>
          <Header style={{ textTransform: 'capitalize' }}>
            {dashboard ? (
              <Dropdown disabled={empresa === 'Stellantis'} selection options={dashboardOptions} value={dashboardId} />
            ) : (
              <span style={{ marginTop: '13px', display: 'block' }}>{t(name)}</span>
            )}
          </Header>
        </Segment>

        {!noFilter && <ModalFilter />}

        {
          <MenuTableIcons
            dashboard={dashboard}
            t={t}
            exportAction={exportAction}
            insightActions={insightActions}
            insightActionsValues={insightActionsValues}
            refresh={true}
            // hiddenButtons={hiddenButtons}
            fetchAction={fetchAction}
            menuButtons={menuButtons}
            table={name}
          />
        }
      </Segment.Group>
    </Modal.Header>
  )
}

export default ModalHeader
