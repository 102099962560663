import './App.css'
import './Resources/Icons/iconLibrary'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, /* Confirm,*/ Modal, Sidebar } from 'semantic-ui-react'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import Header from 'Components/Header'
import { SideBarLeft } from 'Components/SidebarMenu'
import { RenderMap } from 'Components/Map/RenderMap'
import Footer from 'Components/Footer'
import FileUpload from 'Utils/FileUpload'
import { LoadingScreen } from 'Utils/LoadingScreen'
import { Toasts, NotificationStatus, notificationState } from 'Utils/notifications'
import { setFalse } from 'Store/actions/toggle-action'
import getSafe from 'Utils/getSafe.js'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { StatsBar } from 'Components/StatsBar'
import { Route, Switch, Redirect } from 'react-router-dom'
import { TabWrapper } from 'RoutesTables/TabWrapper'
import withWindowDimensions from 'Utils/hocs/withWindowDimensions'
import OfflineBanner from 'Components/Header/OfflineBanner'

// const defaultTablePaths = [
//   'people',
//   'types',
//   'interactions',
//   'assets',
//   'associations',
//   'logouts',
//   'devices',
//   'firefighter',
//   'measuresHist',
//   'fences',
//   'locationHist',
//   'units',
//   'refs',
//   'messages',
//   'notificationProfiles',
//   'rules',
//   'phases',
//   'labelingTool',
//   'insights',
//   'preferences',
//   'motionChart',
// ]

const Container = styled.div`
  position: relative;
  bottom: auto;
  width: 100%;
  top: 0 !important;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
`
const SidebarContainer = styled(Sidebar.Pushable)`
  overflow: hidden;
`

class App extends PureComponent {
  static defaultProps = {
    text: 'loading',
  }

  onKeyDown(e) {
    const key = getSafe(() => e.keyCode, null)
    if (key === 27) {
      if (this.props.inner) this.props.setFalse('showInnerMap')
    }
  }

  render() {
    const {
      login,
      empresa,
      center,
      carregando,
      dispatch,
      openConfirm,
      text,
      modal,
      confirmContent,
      onConfirm,
      onCancelConfirm,
      confirmHeader,
      t,
      sideBarLeft,
      tabIds,
      offline,
      isMobileSized,
      showStatsBar,
      currentTab,
    } = this.props

    const PusherStyle = {
      height: '86vh',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      placeContent: 'center',
      backgroundColor: 'white', //default do semanticui é #cce2ff
      paddingLeft: currentTab !== 'map' ? '1.2vw' : '0',
    }

    let mapSize = 100
    if (sideBarLeft) {
      isMobileSized ? (mapSize = 80) : (mapSize = 92)
    }
    if (showStatsBar) isMobileSized ? (mapSize = 20) : (mapSize -= 20)
    let transformSize = 100 - mapSize
    return (
      <FileUpload
        login={login}
        empresa={empresa}
        center={[center.lat, center.lng]}
        dispatch={dispatch}
        refMap={this.props.refMap}
      >
        {offline && <OfflineBanner />}
        <div id="outer-container" style={{ zIndex: '2998' }}>
          <main id="page-wrap">
            <div className="container-fluid" onKeyDown={(e) => this.onKeyDown(e)}>
              <LoadingScreen
                carregando={carregando}
                text={t(text)}
                text1={t('Takingtoolong')}
                text2={t('Checkyourconnection')}
              />
              <ModalTemplate
                header={confirmHeader}
                open={openConfirm}
                onClose={onCancelConfirm}
                onSave={onConfirm}
                onCancel={onCancelConfirm}
              >
                {confirmContent}
              </ModalTemplate>
              <Modal open={modal} content={'teste'} />
              <Header refMap={this.props.refMap} profile={this.props.profile} />
              <Container id="ContainerMainContent">
                <SidebarContainer>
                  <SideBarLeft
                    isMobile={this.props.isMobileSized}
                    showHistoryCalendar={this.props.showHistoryCalendar}
                  />
                  <StatsBar
                    isMobile={this.props.isMobileSized}
                    sideBarLeft={sideBarLeft}
                    showHistoryCalendar={this.props.showHistoryCalendar}
                  />
                  <Sidebar.Pusher
                    style={{
                      width: mapSize + 'vw',
                      transform: `translate(${transformSize}vw, 0vh)`,
                      ...PusherStyle,
                    }}
                  >
                    <Switch>
                      <Route
                        exact
                        path={'/app/report/:id'}
                        key={'report'}
                        render={(props) => {
                          return <TabWrapper style={{ height: '100%' }} path={'reports'} />
                        }}
                      />
                      {tabIds.map((tabPath) => (
                        <Route
                          exact
                          path={'/app/' + tabPath}
                          key={tabPath}
                          render={(props) => {
                            return <TabWrapper style={{ height: '100%' }} path={tabPath} />
                          }}
                        />
                      ))}
                      <Route exact path="/app" component={RenderMap} />
                      <Route path="*">
                        <Redirect to="/app" />
                      </Route>
                    </Switch>
                  </Sidebar.Pusher>
                </SidebarContainer>
              </Container>
              <Toasts
                colors={{
                  [NotificationStatus.ERROR]: '#DC3545',
                  [NotificationStatus.SUCCESS]: '#5da700',
                  [NotificationStatus.NOTICE]: '#40A9F3',
                  [NotificationStatus.WARNING]: '#FFD399',
                }}
                state={notificationState}
                Button={Button}
              />
              <ToastContainer
                style={{ right: '3vw', top: '6vh', width: '33vw' }}
                transition={Slide}
                limit={5}
                newestOnTop
                pauseOnFocusLoss={!['Phygitall', 'PhygitallDevs'].includes(empresa)}
              />
              <Footer />
            </div>
          </main>
        </div>
      </FileUpload>
    )
  }
}

const mapStateToProps = (state) => ({
  openConfirm: state.system.confirm.open,
  confirmContent: state.system.confirm.content,
  onCancelConfirm: state.system.confirm.onCancel,
  onConfirm: state.system.confirm.onConfirm,
  confirmHeader: state.system.confirm.confirmHeader,
  carregando: state.toggle.loadingScreen,
  login: state.login.email,
  center: state.login.preferences.center,
  empresa: state.login.empresa,
  refMap: state.login.refMap,
  inner: state.toggle.showInnerMap,
  sideBarLeft: state.toggle.sideBarLeft,
  tabIds: state.login.preferences.routesPanels.allIds,
  offline: state.toggle.offline,
  showStatsBar: state.toggle.showStatsBar,
  showHistoryCalendar: state.toggle.showHistoryCalendar,
  currentTab: state.tables.current.tab,
})

const mapDispatchToProps = {
  setFalse: setFalse,
}

App.propTypes = {
  carregando: PropTypes.bool,
  center: PropTypes.object,
  confirmacao: PropTypes.bool,
  empresa: PropTypes.string,
  login: PropTypes.string,
  refMap: PropTypes.object,
  text: PropTypes.string,
}
export default withWindowDimensions(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App)))
