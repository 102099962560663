import React /*, { useState }*/ from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  // ReferenceLine,
  ResponsiveContainer,
} from 'recharts'
import { semanticColors } from 'Utils/colorsFormat.js'

const data = [
  {
    name: 'Ago',
    APP: 4000,
    'Smart Badge': 2400,
    amt: 2400,
  },
  {
    name: 'Set',
    APP: 3000,
    SB: 1398,
    amt: 2210,
  },
  {
    name: 'Out',
    APP: 2000,
    SB: 9800,
    amt: 2290,
  },
  {
    name: 'Nov',
    APP: 2780,
    SB: 3908,
    amt: 2000,
  },
  {
    name: 'Dez',
    APP: 1890,
    SB: 4800,
    amt: 2181,
  },
  {
    name: 'Jan',
    APP: 2390,
    SB: 3800,
    amt: 2500,
  },
]

export const LineChartActive = (props) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        textAlign: 'left',
        fontSize: '14px',
        backgroundColor: 'white',
        borderRadius: '8px',
        paddingTop: '30px',
      }}
    >
      <span style={{ top: '20px', left: '25px', position: 'absolute', fontWeight: '700' }}>
        {props.title || 'Uso do Dispositivo'}
      </span>
      <ResponsiveContainer>
        <LineChart width={700} height={800} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="SB" stroke={semanticColors('orange')} activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="APP" stroke={semanticColors('blue')} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default LineChartActive
