import React, { memo, useEffect, useState } from 'react'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import { useSelector } from 'react-redux'
import { FeatureGroup, Polyline } from 'react-leaflet'
import { useCanAccess } from 'Utils/rbac'
import CustomTooltip from 'Utils/components/CustomTooltip'

export const RenderRoute = ({ id, click }) => {
  //const routeData = useSelector((state) => state.modules.routes?.byId[id])
  const moduleType = 'routes'

  const typeId = useSelector((state) => state.modules[moduleType]?.byId[id]?.info?.properties?.type)
  const typeData = useSelector((state) => state.modules.types?.byId[parseInt(typeId)]?.info) || {}
  const coordinates = useSelector((state) => state.modules[moduleType]?.byId[id]?.info?.geometry?.coordinates) || []
  const tooltipText = useSelector((state) => state.modules[moduleType]?.byId[id]?.info?.properties?.label) || ''
  const color = useSelector((state) => state.modules[moduleType]?.byId[id]?.info?.properties?.color)
  //const currentId = useSelector((state) => state.modules[moduleType]?.current?.id)
  const inner = useSelector((state) => state.toggle.showInnerMap)
  const currentInner = useSelector((state) => state.indoor.current?.inner)
  const canAccess = useCanAccess('WRITE')

  const statsbarId = useSelector((state) => state?.system?.statsbar?.id)
  const editionMode = useSelector((state) => state.modules[moduleType]?.wip?.editMode)
  const idTooltipText = (canAccess ? `${id} - ` : '') + tooltipText
  const [tooltipState, setTooltipState] = useState(idTooltipText)
  const [permanentState, setPermanentState] = useState(true)

  useEffect(() => {
    if (statsbarId === id) {
      setPermanentState(true)
      if (editionMode) setTooltipState(`(Editando) ` + idTooltipText)
      else setTooltipState(idTooltipText)
    } else setPermanentState(false)
  }, [id, statsbarId, editionMode, idTooltipText])

  return (
    <FeatureGroup
      key={id}
      onClick={(data) => click(data, id)}
      onmouseover={() => setPermanentState(true)}
      onmouseout={() => id !== statsbarId && setPermanentState(false)}
    >
      <Polyline
        positions={coordinates.map((e) =>
          inner ? innerPosition([e[1], e[0]], 'array', currentInner?.center, currentInner?.bearing) : [e[1], e[0]]
        )}
        weight="2"
        dashArray="3, 6"
        dashOffset="0"
        color="gray"
      >
        {
          <CustomTooltip permanent={permanentState} direction="bottom">
            {tooltipState}
          </CustomTooltip>
        }
      </Polyline>
      <Polyline
        positions={coordinates.map((e) =>
          inner ? innerPosition([e[1], e[0]], 'array', currentInner?.center, currentInner?.bearing) : [e[1], e[0]]
        )}
        weight="2"
        dashArray="3, 6"
        dashOffset="6"
        color={typeData?.color ? typeData.color : color ? color : 'blue'}
      />
      <CustomTooltip permanent={permanentState} direction="bottom">
        {tooltipState}
      </CustomTooltip>
    </FeatureGroup>
  )
}

export default memo(RenderRoute)
