import React, { memo, useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLogouts } from 'Store/actions/assets-action'
import m from 'moment'
import ReactTable from 'Utils/reactTable'
import { saveColumnsPreference } from 'Store/actions/login-action'

// const menuButtons = ['columns', 'export', 'refresh']

const LogoutsContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchLogouts())
  }, [dispatch])

  const tableName = 'logouts'
  const allIdsStr = useSelector((state) => JSON.stringify(state.assets.logouts || []))
  const hiddenColumns = useSelector((state) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns)
  const allIds = JSON.parse(allIdsStr)

  const [currentSort, setCurrentSort] = useState('start')

  const groupedValuesColumns = useSelector(
    (state) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const fetchAction = () => {
    dispatch(fetchLogouts())
  }

  const columns = useMemo(
    () => [
      {
        Header: t('assetId'),
        accessor: 'assetId',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ids`,
      },
      {
        Header: 'NFC',
        accessor: 'nfc',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} nfc`,
      },
      {
        Header: t('assetName'),
        accessor: 'assetName',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} nomes`,
      },
      {
        Header: t('Type'),
        accessor: 'auto',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value}`,
        // Cell: ({ value, row: { original } }) => <Checkbox /*disabled*/ checked={value === 'true'} />,
      },
      {
        Header: t('Start'),
        accessor: 'start',
        Cell: ({ value }) => (value ? m(value).format('lll') : t('Now')),
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} horários`,
      },
      /* {
        Header: t('Status'),
        accessor: 'status',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('status')}`,
        Cell: ({ value, row: { original } }) => (
          <Checkbox toggle checked={!!value} /*onChange={(c, { checked }) => setStatus(original?.assetId)} />
        ),
      },*/
    ],
    [t /*, dispatch*/]
  )

  const rows = allIds

  const data = useMemo(
    () =>
      rows.map((id) => {
        /* if (status?.toString() === id.assetId?.toString())
          if (id['status'] === true) {
            id.end = Date.now()
            id.status = false
          }
        if (id.start === id.end) id.end = ''
        id.startExtense = id.start
        id.endExtense = id.end*/
        //console.log(id.auto)
        return id
      }),
    [rows]
  )

  return (
    <ReactTable
      table={'logouts'}
      tableName={tableName}
      setCurrentSort={setCurrentSort}
      currentSort={currentSort}
      columns={columns}
      // menuButtons={menuButtons}
      hiddenColumns={hiddenColumns}
      data={data}
      fetchAction={fetchAction}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
    />
  )
}

export default memo(LogoutsContent, (props, nextProps) => {
  return !nextProps.autoUpdate
})
