import React, { Component } from 'react'
import { Button, Dimmer, Image } from 'semantic-ui-react'
import request from 'Apis/rest'
import styled from 'styled-components'

export default class TenantLogo extends Component {
  state = {
    show: true,
  }
  fileRef = React.createRef()

  uploadImage = async (data) => {
    const jwt = localStorage.getItem('id_token')
    const fd = new FormData()
    fd.append('File', data)
    try {
      await request.put('/core/v0/logo', fd, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
    } catch (err) {
      console.error(err)
      alert('Erro ao fazer o upload da imagem')
    }
  }

  removeUrl = async () => {
    const jwt = localStorage.getItem('id_token')
    try {
      await request.delete('/core/v0/logo', {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      this.revokeUrl()
      this.hideImage()
    } catch (err) {
      console.error(err)
      alert('Erro ao deletar a imagem')
    }
  }

  revokeUrl = () => this.state.objectUrl && window.URL.revokeObjectURL(this.state.objectUrl)

  handleSelect = (ev) => {
    const file = ev.target.files && ev.target.files[0]
    if (!file) return
    if (!/^image\//.test(file.type)) {
      alert('Imagem inválida')
      return
    }
    this.revokeUrl()
    const oURL = window.URL.createObjectURL(file)
    this.setState({ objectUrl: oURL })
    this.uploadImage(file)
  }

  handleShow = () => this.setState({ active: true })
  handleHide = () => this.setState({ active: false })
  handleEdit = () => this.fileRef.current && this.fileRef.current.click()
  hideImage = () => this.setState({ show: false })
  render() {
    const allowedProfiles = ['dev', 'adm', 'sup']
    const isAllowed = allowedProfiles.includes(this.props.perfil.toLocaleLowerCase())
    const { active, objectUrl, show } = this.state
    const content = isAllowed ? (
      <div>
        <input
          type="file"
          onChange={this.handleSelect}
          accept="image/*"
          style={{ display: 'none' }}
          ref={this.fileRef}
        />
        <Button icon="photo" primary size="mini" onClick={this.handleEdit} />
        <Button icon="trash" size="mini" negative onClick={this.removeUrl} />
      </div>
    ) : (
      <div>
        <input type="file" accept="image/*" style={{ display: 'none' }} ref={this.fileRef} />
      </div>
    )
    const url =
      objectUrl ||
      'https://plataforma-phygitall.s3.us-east-2.amazonaws.com/images/navBarLogo/' + this.props.tenant + '.png'
    return (
      <Dimmer.Dimmable
        as={Logo}
        dimmed={active}
        dimmer={isAllowed ? { active, content } : { content }}
        onMouseEnter={this.handleShow}
        onMouseLeave={this.handleHide}
        onError={this.hideImage}
        src={url}
        hidden={!show}
      />
    )
  }
}

const Logo = styled(Image)`
  > img {
    max-height: 52px;
  }
`
