import React from 'react'
import { List, Label, Placeholder } from 'semantic-ui-react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import m from 'moment'

const ItemLI = styled(List.Item)`
  padding-right: 20px;
`
export const ItemDetailsField = ({ li, value, tenant }) => {
  const [show, setShow] = React.useState(false)
  const { t } = useTranslation()
  const liValue = (li) => {
    if (li?.type?.toLowerCase()?.startsWith('timestamp'))
      return value[li.field] ? m(value[li.field]).format('lll') : 'Não encontrado'
    if (li?.type?.toLowerCase()?.startsWith('array')) return value[li.field].toString()
    return value[li.field] || 'Não encontrado'
  }

  return tenant === 'Tiplam' ? (
    <ItemLI onClick={() => setShow(!show)}>
      {show && <Label pointing="below">{li.desc}</Label>}
      <List.Header color="green">{t(li.label)}</List.Header>
      {value ? (
        liValue(li)
      ) : (
        <Placeholder fluid>
          <Placeholder.Line length="short" />
        </Placeholder>
      )}
    </ItemLI>
  ) : (
    <ItemLI onClick={() => setShow(!show)}>
      {show && li.desc && <Label pointing="below">{li.desc}</Label>}
      <List.Header color="green">{t(li.label)}</List.Header>
      {value ? (li.type === 'time' ? m(value).format('lll') : value) : 'Não encontrado'}
    </ItemLI>
  )
}
export default ItemDetailsField
