/*  Author: Luís Mourão
    Type: Dumb
	Description: Formatação e posicionamente da msg de histórico
	TODO: 
*/

import React from 'react'
import m from 'moment'
import { useTranslation } from 'react-i18next'
import HistInfoDisplay from './HistInfoDisplay'
import { getMinTime } from 'Utils/getSliderTime.js'
import { useSelector, shallowEqual } from 'react-redux'

export const HistoryInfo = ({ loadingScreen, heatMap, start, end, heatMapTotal, onClick }) => {
  const { t } = useTranslation()

  const min = useSelector((state) => state.history.slider.filteredMin)
  const max = useSelector((state) => state.history.slider.filteredMax)
  const startTime = useSelector((state) => state.history.startTime)
  const endTime = useSelector((state) => state.history.endTime)

  const histAllIds = useSelector((state) => state.history.allIds, shallowEqual)
  const histById = useSelector((state) => state.history.byId, shallowEqual)
  const showHistoryCalendar = useSelector((state) => state.toggle.showHistoryCalendar)

  const minTime = getMinTime(histAllIds, histById, min)
  const maxTime = getMinTime(histAllIds, histById, max)

  let firstElement = t('HistorySliderSelect')
  let secondElement
  if ((heatMap && !start && !end) || (!heatMap && !minTime && !maxTime && showHistoryCalendar))
    firstElement = t('HistorySliderSelect')
  else if (loadingScreen) firstElement = t('HistoryCharging')
  else if (histAllIds.length === 0 && !heatMap) {
    firstElement = m(startTime).local().format('lll') + ' / ' + m(endTime).local().format('lll')
  } else if (histAllIds.length && !heatMap) {
    firstElement = m(minTime).local().format('lll')
    secondElement = m(maxTime).local().format('lll')
  } else if (minTime === maxTime && !heatMap) firstElement = m(minTime).local().format('lll')
  else if (heatMap && heatMapTotal) {
    firstElement = m(start).local().format('lll')
    secondElement = m(end).local().format('lll')
  } else if (heatMap && heatMapTotal === 0)
    firstElement = t('HistorySliderNoData') + m(start).local().format('lll') + ' / ' + m(end).local().format('lll')
  else if (start === end) firstElement = m(start).local().format('lll')
  else {
    firstElement = m(start).local().format('lll')
    secondElement = m(end).local().format('lll')
  }
  return <HistInfoDisplay firstElement={firstElement} secondElement={secondElement} onClick={onClick} />
}
