import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'Utils/reactTable/components/DateTime'
import { List, Divider, Image, Modal, Button, Grid } from 'semantic-ui-react'
import styled from 'styled-components'
import ItemDetailsField from 'Components/Sidebar/Contents/Notification/ItemDetailsField'
import StatsBarFormHistoryPagination from 'Components/StatsBar/StatsBarFormHistoryPagination'
import TypeName from 'Utils/components/TypeName'

import { fetchModuleChanges, clearModuleChanges } from 'Store/actions/modules-action'

const ListStyled = styled(List)`
  max-height: 300px;
  overflow-y: auto;
`

const ListContentStyled = styled(List.Content)`
  text-align: left;
`

const ListHeaderStyled = styled(List.Header)`
  font-weight: 400px;
  color: black;
  &:hover {
    text-decoration: underline;
    color: blue;
    background-color: white;
  }
`
const ListDescriptionStyled = styled(List.Description)`
  color: black;
  &:hover {
    background-color: white;
  }
`

const DivInfo = styled.div`
  margin: 30px;
  width: 800px;
`
const FormStatsBarHistory = ({ data, id, moduleType }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const [changes, setChanges] = useState([])
  const [changeId, setChangeId] = useState(0)

  const rawChanges = useSelector((state) => state.modules.wip.changes)

  useEffect(() => {
    if (currentId !== id) {
      dispatch(clearModuleChanges(id))
      dispatch(fetchModuleChanges(id, moduleType))
      setCurrentId(id)
    }
  }, [id, dispatch, moduleType, currentId])

  const firstKey = (values) => {
    if (!values) return
    let keys = Object.keys(values)
    let res = t(keys[0])
    if (keys.length > 1) res = res + ', ...'
    return ' - ' + res
  }

  const formatChanges = (keyChanges) => {
    if (!keyChanges) return ''
    let keys = Object.keys(keyChanges)
    return (
      <ul>
        {keys.map((k, l) => {
          return !['name'].includes(k) ? (
            <li>
              {t(k)}: {k === 'type' ? <TypeName id={keyChanges[k]} /> : JSON.stringify(keyChanges[k])}
            </li>
          ) : null
        })}
      </ul>
    )
  }

  const handleClick = (i) => {
    setChangeId(i)
    setChanges([
      {
        field: 'closedAt',
        label: 'Realizada em',
        type: 'time',
        value: rawChanges?.[i]?.register,
      },
      {
        field: 'closedBy',
        label: 'Realizada por',
        type: 'text',
        value: rawChanges?.[i]?.login,
      },
      {
        field: 'valor original',
        label: 'Valor(es) antigo(s)',
        type: 'text',
        value: formatChanges(rawChanges?.[i]?.oldvalues),
      },
      {
        field: 'valor novo',
        label: 'Valor(es) novo(s)',
        type: 'text',
        value: formatChanges(rawChanges?.[i]?.newvalues),
      },
    ])
    setOpen(true)
  }

  return (
    <>
      <ListStyled divided relaxed>
        {rawChanges?.map((r, i) => (
          <>
            <List.Item key={`modHist${i}`}>
              <ListContentStyled>
                <Image
                  title={r?.login || 'sem informação'}
                  circular
                  src={
                    'https://i1.wp.com/cdn.auth0.com/avatars/' + (r?.login?.[0]?.toLowerCase() || 'si') + '.png?ssl=1'
                  }
                  size="mini"
                  verticalAlign="middle"
                  style={{ float: 'left', marginRight: '5px' }}
                />
                <ListHeaderStyled as="a" title={t('clickToSeeMoreDetails')} value={i} onClick={() => handleClick(i)}>
                  {t('past' + r.operation)}
                  {r.operation === 'UPDATE' && firstKey(r.newvalues)}
                </ListHeaderStyled>
                <ListDescriptionStyled as="a">
                  <br />
                  <DateTime value={r.register} />
                </ListDescriptionStyled>
              </ListContentStyled>
            </List.Item>
            <Divider />
          </>
        ))}
      </ListStyled>
      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => handleClick()}
          /*trigger={<Icon name="external" size="small" style={{ cursor: 'pointer' }} />}*/
        >
          <Modal.Header
            style={{
              paddingBottom: '10px',
              paddingTop: '10px',
              paddingRight: '0px',
              paddingLeft: '30px',
            }}
          >
            <Grid celled style={{ margin: '0', boxShadow: 'none' }}>
              <Grid.Row style={{ boxShadow: 'none' }}>
                <Grid.Column width={12} style={{ boxShadow: 'none' }}>
                  <h3 style={{ margin: '0' }}>Detalhes da Atualização</h3>
                  <h4 style={{ marginTop: '2px', fontColor: 'green' }}>
                    Por {changes[1].value} <DateTime value={changes[0].value} />
                  </h4>
                </Grid.Column>
                <Grid.Column width={4} style={{ boxShadow: 'none' }}>
                  <StatsBarFormHistoryPagination
                    gotoPage={handleClick}
                    previousPage={() => handleClick(changeId - 1)}
                    nextPage={() => handleClick(changeId + 1)}
                    canPreviousPage={changeId > 0}
                    canNextPage={changeId < rawChanges?.length - 1}
                    pageIndex={changeId}
                    pageOptionsLength={rawChanges?.length}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Header>
          <Modal.Content>
            <DivInfo>
              <Grid columns={'equal'}>
                <Grid.Column width={6} style={{ overflow: 'auto' }}>
                  <List>
                    <ItemDetailsField key={2} li={changes[2]} value={changes[2].value} />
                  </List>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={7} style={{ overflow: 'auto' }}>
                  <List>
                    <ItemDetailsField key={3} li={changes[3]} value={changes[3].value} />
                  </List>
                </Grid.Column>
              </Grid>
            </DivInfo>
          </Modal.Content>
          <Modal.Actions>
            <Button content="OK" labelPosition="right" icon="checkmark" onClick={() => setOpen(false)} positive />
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}
export default FormStatsBarHistory
