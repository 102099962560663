import React, { useState, useEffect } from 'react'
import { ReactComponent as LoadingLogo } from 'Resources/Icons/loading-logo.svg'

export const LoadingScreen = ({ carregando, text = 'Loading', text1, text2 }) => {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (!carregando) {
      setVisible(false)
      return
    }
    const timer = setTimeout(() => {
      setVisible(true)
    }, 20000)
    return () => clearTimeout(timer)
  }, [visible, carregando])
  return (
    carregando && (
      <div id="telaCarregamento" className="telaCarregamento">
        <div className="logoTelaCarregamento">
          <LoadingLogo />
          <span>{text}</span>
          <br />
          {visible && (
            <div className="timeout">
              <p style={{ margin: '0 0 0 12px' }}>{text1}?</p>
              <p>{text2}</p>
            </div>
          )}
        </div>
      </div>
    )
  )
}
