import React from 'react'
import m from 'moment'
//import { Checkbox } from 'semantic-ui-react'

export const threeColumns = (t, inInfo = false, canEdit) => {
  return [
    {
      Header: t ? t('createdBy') : 'Criado por',
      accessor: inInfo ? 'info.createdBy' : 'createdBy',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${t('emails')}`,
    },
    {
      Header: t ? t('createdAt') : 'Criado em',
      accessor: inInfo ? 'info.createdAt' : 'createdAt',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${'horários'}`,
      Cell: ({ value }) => <span title={m(value).format('lll')}>{m(value).fromNow()}</span>,
    } /* ,
    {
      Header: t ? t('Status') : 'Status',
      accessor: 'status',
      aggregate: 'uniqueCount',
      Aggregated: ({ value }) => `${value} ${t('status')}`,
      Cell: ({ value }) => <Checkbox toggle checked={value ? true : false} disabled={!canEdit} />
    }, */,
  ]
}
