import React from 'react'
import { Table, Menu, Icon } from 'semantic-ui-react'
import { LogRow } from 'Components/Settings'

/* const entries = [
    { id: 1, name: "Luis", timestamp: "08/18/2019 18:50", rank: "Funcionário", status: "Pending" },
    { id: 2, name: "Felipe", rank: "Visitante", timestamp: "08/18/2019 18:50" },
    { id: 3, name: "Bruno", rank: "Visitante", timestamp: "08/17/2019 17:50", status: "Pending" },
    { id: 4, name: "Leonardo", rank: "Visitante", timestamp: "08/17/2019 17:50" },
    { id: 5, name: "Luis", timestamp: "08/18/2019 21:10", rank: "Funcionário", status: "Inactive" }
] */

const LogTable = ({ entries }) => {
  const hasEntries = entries && entries.length > 0
  console.log(entries)
  return (
    <Table singleLine striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Crachá</Table.HeaderCell>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <Table.HeaderCell>CPF</Table.HeaderCell>
          <Table.HeaderCell>Registro</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {hasEntries ? (
          entries.map((row) => (
            <LogRow
              key={row.timestamp}
              id={row.badge}
              name={row.staff_name}
              timestamp={row.last_time}
              rank={row.staff}
              status={row.status}
            />
          ))
        ) : (
          <Table.Row>
            <Table.Cell textAlign="center" colSpan="5">
              Não há dados disponíveis.
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="5">
            <Menu floated="right" pagination>
              <Menu.Item as="a" icon>
                <Icon name="chevron left" />
              </Menu.Item>
              <Menu.Item as="a">1</Menu.Item>
              <Menu.Item as="a">2</Menu.Item>
              <Menu.Item as="a" icon>
                <Icon name="chevron right" />
              </Menu.Item>
            </Menu>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  )
}

export default LogTable
