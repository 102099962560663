export const showConfirm = (data) => ({
  type: 'SHOW_CONFIRM',
  payload: { ...data, open: true },
})

export const hideConfirm = () => ({
  type: 'HIDE_CONFIRM',
  payload: { open: false },
})

export const setCurrentStatsBar = (id, table, type) => ({
  type: 'SET_CURRENT_STATS_BAR',
  id: id,
  payload: {
    id: id,
    table: table,
    type: type,
  },
})
