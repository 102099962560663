import React, { useState, useMemo, memo, useEffect } from 'react'
import { Icon, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { InputTemplate } from 'Utils/components/InputTemplate.js'
import { updateDynamicData } from 'Store/actions/modules-action'
import { updateAsset } from 'Store/actions/assets-action'
import {
  fetchAssets,
  deleteAssets,
  insertAssets,
  fetchAssetsType,
  saveGroup,
  deleteGroup,
} from 'Store/actions/assets-action'
import m from 'moment'
import ReactTable from 'Utils/reactTable'
import { saveColumnsPreference } from 'Store/actions/login-action'

const AssetsContent = () => {
  const table = 'assets'
  const { t } = useTranslation()

  const assetsAllIdsStr = useSelector((state) => JSON.stringify(state.assets.allIds))
  const assetsByIdStr = useSelector((state) => JSON.stringify(state.assets.byId))
  const assetsMeasuresStr = useSelector((state) => JSON.stringify(state.measures.byId))
  const cudLoading = useSelector((state) => state.assets.cudLoading)
  const cudSuccess = useSelector((state) => state.assets.cudSuccess)

  const assetsAllIds = JSON.parse(assetsAllIdsStr)
  const assetsById = JSON.parse(assetsByIdStr)
  const assetsMeasures = JSON.parse(assetsMeasuresStr)

  const assetTypes = useSelector((state) => state.modules.types)
  const types = assetTypes.allIds
    .filter((tp) => assetTypes.byId[tp].info.table === 'assets.assets')
    .map((t) => ({ key: t, value: t, text: assetTypes.byId[t].info.label }))

  const [currentSort, setCurrentSort] = useState('time')

  // const hiddenButtons = ['rules', 'refresh', 'import', 'fullscreen', 'toggle fullscreen']

  const dispatch = useDispatch()
  const fetchAction = () => {
    dispatch(fetchAssets())
    dispatch(fetchAssetsType())
  }
  const insertAction = (body) => {
    dispatch(insertAssets(body))
  }
  const deleteAction = (ids) => {
    dispatch(deleteAssets(ids.ids))
  }

  const updateAction = (id, body) => {
    dispatch(updateAsset(id, body))
  }

  const groupAction = (g, s, a) => dispatch(saveGroup(g, s, a))
  const deleteGroupAction = (ids) => deleteGroup(ids)
  //let hiddenColumns = ['location', 'info.matricula', 'info.createdBy', 'info.createdAt']
  const hiddenColumns = useSelector((state) => state.login.preferences.routesPanels.byId[table].hiddenColumns)

  const groupedValuesColumns = useSelector(
    (state) => state.login.preferences.routesPanels.byId[table].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(table, false, currentGroup))
  }, [currentGroup, dispatch])

  const columns = useMemo(
    () => [
      {
        Header: t('id'),
        accessor: 'id',
        aggregate: 'uniqueCount',
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return value
        },
      },
      {
        Header: t('code'),
        accessor: 'info.code',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} code`,
        Cell: (props) => (
          <InputTemplate
            value={props.value}
            id={props?.cell?.row?.original?.id}
            action={(id, field, value) => {
              dispatch(updateDynamicData({ id: id, field: field, value: value }, 'assets'))
            }}
            field="info.code"
            table={table}
          />
        ),
      },
      {
        Header: t('Icon'),
        accessor: 'icon',
        disableGlobalFilter: true,
        aggregate: 'count',
        Cell: ({ value }) =>
          value?.toString()?.startsWith('http') ? (
            <Image bordered src={value} circular centered size="mini" />
          ) : (
            <Icon bordered circular name={'user'} />
          ),
      },
      {
        Header: t('Name'),
        accessor: 'name',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} nomes`,
        Cell: (props) => (
          <InputTemplate
            value={props.value}
            id={props?.cell?.row?.original?.id}
            action={(id, field, value) => {
              dispatch(updateAsset(id, { [field]: value }))
            }}
            field="name"
            table={table}
          />
        ),
      },
      {
        Header: t('info.type'), // atribuiçao
        accessor: 'info.type',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} atribuições`,
        Cell: (props) => (
          <InputTemplate
            options={types}
            value={props.value || 'Sem Atribuição'}
            id={props?.cell?.row?.original?.id}
            action={(id, field, value) => {
              dispatch(updateAsset(id, { [field]: value }))
            }}
            field="type"
            table={table}
          />
        ),
      },
      {
        Header: t('Groups'),
        accessor: 'groupsText',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} grupos`,
      },
      {
        Header: t('Sector'),
        accessor: 'info.sector',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} setores`,
      },
      {
        Header: t('Last device used'), // 'Último Dispositivo Utilizado'
        accessor: 'sensor',
        icon: 'cog',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} dispositivos`,
      },
      {
        Header: t('LastSeen'),
        accessor: 'time',
        Cell: ({ value }) => (value ? m(value).fromNow() : t('Never')),
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} horários`,
        disableGlobalFilter: true,
      },
      {
        Header: t('createdBy'),
        accessor: 'info.createdBy',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} logins`,
        disableGlobalFilter: true,
      },
      {
        Header: t('createdAt'),
        accessor: 'info.createdAt',
        Cell: ({ value }) => (value ? m(value).fromNow() : t('Never')),
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} horários`,
        disableGlobalFilter: true,
      },
    ],
    [t, dispatch, types]
  )

  const rows = assetsAllIds.filter((id) => ![2, 6, 8, 12, 14, 16, 17].includes(assetsById[id]?.idType))

  const data = useMemo(
    () =>
      rows.map((id) => {
        let asset = assetsById[id]
        let assetMeasure = assetsMeasures[id]
        return {
          //icon: asset?.info?.url || assetTypes.find((each) => each.id === asset?.idType)?.info?.suiIcon || 'user',
          id: asset?.id,
          name: asset?.name,
          groups: asset?.groups,
          groupsText: asset?.groups?.sort()?.toString(),
          info: asset?.info,
          time: assetMeasure?.time || assetMeasure?.hora || assetMeasure?.info?.time,
          //color: asset?.info?.color, //<Icon name='circle' color={assets.byId[id]?.info?.color}/>,
          //type: assetTypes.filter((type) => type.id === asset?.idType)[0]?.nome,
          type: asset?.info?.type,
        }
      }),
    [rows, assetsById, assetsMeasures]
  )

  return (
    <ReactTable
      table={table}
      setCurrentSort={setCurrentSort}
      currentSort={currentSort}
      columns={columns}
      hiddenColumns={hiddenColumns}
      // hiddenButtons={hiddenButtons}
      data={data}
      insertAction={insertAction}
      updateAction={updateAction}
      fetchAction={fetchAction}
      deleteAction={deleteAction}
      groupAction={groupAction}
      deleteGroupAction={deleteGroupAction}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      cudLoading={cudLoading}
      cudSuccess={cudSuccess}
    />
  )
}

export default memo(AssetsContent, (props, nextProps) => {
  return !nextProps.autoUpdate
})
