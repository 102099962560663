import axios, { AxiosError } from 'axios'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { Content, IData } from './components/VerificationContent'

type TParams = { hash: string }
const URL = 'https://jstm1estza.execute-api.us-east-2.amazonaws.com/v0'

export const VerificationCode = ({ match }: RouteComponentProps<TParams>) => {
  const [success, setSuccess] = useState<boolean>()
  const [approved, setApproved] = useState<boolean>()
  const [isLoadingApprove, setLoadingApprove] = useState(false)
  const [isLoadingReject, setLoadingReject] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [data, setData] = useState<IData>()
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios(`${URL}/${match.params.hash}`)
        setData(result.data)
      } catch (err) {
        setError(true)
      }
    }
    fetchData()
  }, [match.params.hash])
  const handleResponse = async (hasApproved: boolean) => {
    try {
      hasApproved ? setLoadingApprove(true) : setLoadingReject(true)
      setApproved(hasApproved)
      await axios.post(URL, {
        hash: match.params.hash,
        name: data?.name,
        hasApproved,
      })
      setSuccess(true)
    } catch (err) {
      const errors = err as Error | AxiosError<string>
      setSuccess(false)
      hasApproved ? setLoadingApprove(false) : setLoadingReject(false)
      if (!axios.isAxiosError(errors)) {
        setErrorMsg(errors.message)
        return
      }
      // @ts-ignore
      setErrorMsg(errors?.response?.data || 'Erro desconhecido')
    }
  }
  if (!match.params.hash) return null
  return (
    <Grid textAlign="center" style={{ height: '100vh', background: '#f7f7f7' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 500 }}>
        <Content
          data={data}
          handleClick={handleResponse}
          success={success}
          error={error}
          isLoadingApprove={isLoadingApprove}
          isLoadingReject={isLoadingReject}
          approved={approved}
          errorMsg={errorMsg}
        />
      </Grid.Column>
    </Grid>
  )
}
