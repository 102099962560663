import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import 'Store/actions/dashboards-action'
import { FirefightersModal, CardHeader } from './FirefightersModal'
import ModalHeaderFirefighter from 'Utils/modals/ModalHeaderFirefighter'
import { Card, Segment, Modal } from 'semantic-ui-react'
import Loading from 'Utils/components/Loading'

export const FirefightersContent = () => {
  const dashboardLabel = 'Quadro de Brigadistas'
  const dashboard = 'firefighter'
  const hiddenButtons = ['new', 'edit', 'groups', 'rules', 'columns', 'delete', 'import', 'fullscreen', 'calendar']

  // get the 'brigadista' id
  const firefighterTypeId = useSelector(
    (state) => state.modules.types.allIds.filter((id) => state.modules.types.byId[id].name === 'Brigadista')[0]
  )

  // get everybody's id's
  const allPeopleIds = useSelector((state) => state.assets.allIds, shallowEqual)

  // get all info
  const allPeople = useSelector((state) => state.assets.byId, shallowEqual)

  // get every id which is 'online'
  const presentPeopleIds = useSelector((state) => state.assets.allActivePeopleIds, shallowEqual)

  const loading = useSelector((state) => state.tables.byId.firefighter.loading)

  // filter the firefighters and only get those who are online
  const firefighters = allPeopleIds
    .filter((idf) => allPeople[idf]?.info?.type === firefighterTypeId && presentPeopleIds?.find((pid) => pid === idf))
    .map((id) => {
      return {
        id,
        name: allPeople[id]?.name,
        cardId: allPeople[id]?.info?.cardId,
      }
    })

  return (
    <>
      <ModalHeaderFirefighter
        dashboard={dashboard}
        dashboardLabel={dashboardLabel}
        hiddenButtons={hiddenButtons}
        firefighters={firefighters}
      />
      <Modal.Content style={{ padding: '0' }}>
        {loading ? (
          <div style={{ marginTop: '35vh' }}>
            <Loading />
          </div>
        ) : (
          <Segment style={{ border: 'none', boxShadow: 'none', padding: '0', height: '76vh', overflowY: 'auto' }}>
            <Card.Group centered textAlign="center">
              {firefighters.length > 0 ? (
                firefighters.map((eachFireFighter) => (
                  <FirefightersModal
                    key={eachFireFighter.id}
                    assetId={eachFireFighter.id}
                    name={eachFireFighter.name}
                    cardId={eachFireFighter.cardId}
                  />
                ))
              ) : (
                <div>
                  <CardHeader>
                    <Segment style={{ marginTop: '20vh' }}> {'Nenhum brigadista presente.'} </Segment>
                  </CardHeader>
                </div>
              )}
            </Card.Group>
          </Segment>
        )}
      </Modal.Content>
    </>
  )
}
