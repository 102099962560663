import React, { Fragment, useState } from 'react'
import { Dropdown, Image, Icon } from 'semantic-ui-react'
import { selectLanguage } from 'Resources/Locale/selectLanguage'
import { useTranslation } from 'react-i18next'
import Organizations from './Organizations'
import { useSelector, shallowEqual } from 'react-redux'
import request from 'Apis/rest'
import { SignOut } from 'Views/Authentication/logout'

/* const orgs = [{ name: "Phygitall", id: "12" }, { name: "Ambev", id: "7" }, 
{ name: "Gerdau", id: "4" }, { name: "Reanalt", id: "d4" }, { name: "Yssy", id: "32" },
{ name: "Sabesp", id: "3231232" }, { name: "Logicalis", id: "32e" }, { name: "TJ", id: "e3eq" },
{ name: "Teste", id: "w12w12" }
] */

const LanguageSelect = ({ selectedLanguage, lang }) => {
  const languages = [
    { lan: 'en', value: 'English' },
    { lan: 'es', value: 'Español' },
    { lan: 'fr', value: 'Français' },
    { lan: 'pt', value: 'Português' },
    { lan: 'zh', value: '中文' },
  ]
  return languages.map((l) => (
    <Dropdown.Item selected={l.lan === lang} key={l.lan} lan={l.lan} onClick={selectedLanguage}>
      {l.value}
    </Dropdown.Item>
  ))
}

const AvatarMenu = ({ picture, rights, lang, save, email, savenow }) => {
  const { i18n, t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [statusSimulation, setStatusSimulation] = useState('off')
  const [chosenMenu, chooseMenu] = useState(0)
  const orgs = useSelector((state) => state.tenants.available, shallowEqual)
  const currentOrg = useSelector((state) => state.login.empresa)
  const selectedLanguage = (e, data) => {
    save(data.lan, email)
    savenow(data.lan)
    selectLanguage(data.lan, i18n)
  }
  const handleClick = () => setOpen(!open)
  const handleMenuChange = (e, { id }) => chooseMenu(id)
  const handleBlur = () => {
    setOpen(false)
    chooseMenu(0)
  }
  const handleSimulation = async () => {
    const jwt = localStorage.getItem('id_token')
    if (statusSimulation === 'off') {
      try {
        await request.post(
          '/core/v0/simulation/on',
          {},
          {
            headers: {
              Authorization: 'Bearer ' + jwt,
            },
          }
        )
        setStatusSimulation('on')
      } catch (err) {
        alert('Tente novamente mais tarde')
      }
      return
    }
    try {
      await request.post(
        '/core/v0/simulation/off',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        }
      )
      setStatusSimulation('off')
    } catch (err) {
      alert('Tente novamente mais tarde')
    }
  }
  const firstMenu = (
    <Fragment>
      {orgs.length > 1 && (
        <Dropdown.Item
          key="orgs"
          icon="fort awesome"
          id="1"
          text={currentOrg.includes('Mob') || currentOrg.includes('MOB') ? 'Projetos' : t('Organizations')}
          onClick={handleMenuChange}
        />
      )}

      {(email.includes('phygitall') || email.includes('2s')) && (
        <Dropdown.Item key="simulation" onClick={handleSimulation}>
          {' '}
          <Icon name={'toggle ' + statusSimulation} size="large" /> Demo{' '}
        </Dropdown.Item>
      )}

      <Dropdown.Item disabled key="licenses">
        {' '}
        <Icon name={'drivers license'} />
        Licenças
      </Dropdown.Item>

      <Dropdown.Item
        key="language"
        icon="language"
        id="2"
        title={t('ListLanguages')}
        text={t('LanguageSelection')}
        onClick={handleMenuChange}
      />
      <SignOut />
    </Fragment>
  )
  const currentMenu = [
    firstMenu,
    <Organizations orgs={orgs} currentOrg={currentOrg} />,
    <LanguageSelect lang={lang} selectedLanguage={selectedLanguage} />,
  ]

  return (
    <Dropdown
      trigger={
        <span title={t('NavButtonsConfig')}>
          <Image avatar src={picture} size="mini" onClick={handleClick} />
        </span>
      }
      pointing="top right"
      onBlur={handleBlur}
      icon={null}
      open={open}
      scrolling={chosenMenu === '1'}
    >
      <Dropdown.Menu>{currentMenu[chosenMenu]}</Dropdown.Menu>
    </Dropdown>
  )
}

export default AvatarMenu
