import request from 'Apis/rest'

export const INITIAL_STORE = 'routesInitialStore'
export const GEODADOS = 'geoData'
export const GEOFENCE = 'saveGeoRoute'

export const saveGeoRoute = (geojson, empresa, name, email) => ({
  type: 'server/insere/modulo',
  fields: {
    nome: `'${name}'`,
    id_tipo_modulo: 7,
    empresa: `'${empresa}'`,
    info: `'${geojson}'`,
    login: `'${email}'`,
  },
})

export const deleteManyRoute = (ids_rotas) => {
  return {
    type: 'deletaRotas',
    payload: ids_rotas,
  }
}

export const deleteManyRouteServer = (rotas) => {
  return {
    type: 'server/deleta/modulo',
    where: ['id_modulo IN (' + rotas + ')'],
  }
}

export const updateRouteServer = (id, valor, campo) => {
  let campovalor = ''
  switch (campo) {
    case 'Nome':
      campovalor = { nome: valor }
      break
    case 'Cor':
      campovalor = { info: `'{"properties":{"color": "${valor}"}}'` }
      break
    default:
      console.log('Campo não preparado para atualização, mudar na actions da Store')
  }
  return {
    type: 'server/atualiza/modulo',
    fields: campovalor,
    where: { id_modulo: id },
  }
}

export const updateRoute = (id, valor, campo) => {
  return {
    type: 'atualizaRota',
    id: id,
    payload: { valor: valor, campo: campo },
  }
}

export const updateColorServer = (id, cor) => {
  return {
    type: 'server/atualiza/modulo',
    fields: { info: `'{"properties": {"color": "${cor}" }}'` },
    where: { id_modulo: id },
  }
}

export const fetchRoutes = () => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  const res = await request.get('/routes', {
    headers: {
      Authorization: 'Bearer ' + jwt,
    },
  })
  dispatch({ type: 'routesInitialStore', payload: res.data })
}
