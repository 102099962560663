import Page from './settingsPage'
import React, { useState } from 'react'
import { Route, Link } from 'react-router-dom'
//import errorHandler from 'views/routeError'
//import MainHeader from "components/Header/header";
import AccessControlView from './accessControlView'
import { Menu, Header, Breadcrumb, Container, Grid, Segment } from 'semantic-ui-react'

const MenuSegment = () => {
  const [activeItem, setActiveItem] = useState('general')
  const handleClick = (e, { name }) => setActiveItem(name)
  return (
    <Grid>
      <Grid.Column width={4}>
        <Menu pointing vertical>
          <Menu.Item name="general" active={activeItem === 'general'} onClick={handleClick} />
          <Menu.Item name="users" active={activeItem === 'users'} onClick={handleClick} />
          <Menu.Item name="friends" active={activeItem === 'friends'} onClick={handleClick} />
          <Menu.Item name="subscription" active={activeItem === 'subscription'} onClick={handleClick} />
        </Menu>
      </Grid.Column>
      <Grid.Column stretched width={12}>
        <Segment>ok</Segment>
      </Grid.Column>
    </Grid>
  )
}

const paths = ({ pathname }) =>
  pathname.split('/').map((p, i, arr) => {
    if (i === 0)
      return {
        key: i,
        content: <Link to={'/'}>map</Link>,
        active: i === arr.length - 1,
      }

    if (i === arr.length - 1)
      return {
        key: i,
        content: p,
        active: i === arr.length - 1,
      }
    return {
      key: i,
      content: <Link to={`${arr.slice(0, i + 1).join('/')}/`}>{p}</Link>,
      active: i === arr.length - 1,
    }
  })

const SettingOptions = ({ match, location }) => (
  <Container>
    <Header size="huge" as="h3">
      <Breadcrumb icon="right angle" sections={paths(location)} />
    </Header>
    <br />

    <Route exact path="/settings" name="Settings" component={Page} />
    <Route name="Organization" path={`${match.path}/organization`} component={MenuSegment} />
    <Route name="Access Control" path={`${match.path}/access-control`} component={AccessControlView} />
  </Container>
)

export default SettingOptions
