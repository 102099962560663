import React, { Component } from 'react'
import { CircleMarker } from 'react-leaflet'
import getSafe from 'Utils/getSafe.js'
import { connect } from 'react-redux'
import { sliderChange } from 'Store/actions/history-action'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'

class HistDiscrete extends Component {
  discreteClick(assetId, index) {
    const { min, allAssetsFilteredIndexes, sliderChange } = this.props
    let max = allAssetsFilteredIndexes.findIndex((eachIndex) => {
      return eachIndex === index
    })
    if (max) sliderChange({ min, max })
  }

  render() {
    let { allIds, byId, assets, min, max, inner, assetsTypes } = this.props

    return allIds.map((assetId) => {
      const assetColor = getSafe(() => assetsTypes[assets[assetId].info.type].info.color, 'black')

      return byId[assetId].allFilteredIndexes
        .filter((eachIndex) => {
          return eachIndex <= max && eachIndex >= min
        })
        .map((filteredIndex, k) => {
          const dataInfo = byId[assetId].byIndex[filteredIndex]?.info
          let { lat, lng } = dataInfo?.coords || {}
          if (inner) {
            const innerCoords = innerPosition({ lat, lng }, 'marker')
            lat = innerCoords.lat
            lng = innerCoords.lng
          }
          if (lat && lng) {
            return (
              <CircleMarker
                onClick={() => this.discreteClick(assetId, filteredIndex)}
                center={{ lat, lng }}
                radius={2}
                color={assetColor}
                key={filteredIndex}
              />
            )
          } else return null
        })
    })
  }
}

const mapStateToProps = (state) => ({
  allIds: state.history.allIds,
  allAssetsFilteredIndexes: state.history.allAssetsFilteredIndexes,
  byId: state.history.byId,
  max: state.history.slider.filteredMax,
  min: state.history.slider.filteredMin,
  inner: state.toggle.showInnerMap,
  assets: state.assets.byId,
  assetsTypes: state.modules.types.byId,
})

const mapDispatchToProps = {
  sliderChange: sliderChange,
}

export default connect(mapStateToProps, mapDispatchToProps)(HistDiscrete)
