import React, { useEffect, useState, useMemo /*, useEffect*/ } from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import StatsBarHeader from './StatsBarHeader'
import StatsBarButtons from './StatsBarButtons'
import StatsBarFooter from './StatsBarFooter'
import StatsBarFormGeo from './StatsBarFormGeo'
import StatsBarFormHistory from './StatsBarFormHistory'
import StatsBarFormMain from './StatsBarFormMain'
//import StatsBarActions from './StatsBarActions'
import { useSelector, useDispatch } from 'react-redux'
import {
  setCurrentModule,
  createModule,
  insertStopped,
  updateWiP /*insertDynamicData*/,
} from 'Store/actions/modules-action'
import { setFalse } from 'Store/actions/toggle-action'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import { debounce } from 'lodash'

const FormStatsBarInsert = ({ id, table, moduleType = 'refs' }) => {
  const [changes, setChanges] = useState([])
  const [name, setName] = useState()
  const [type, setType] = useState()
  const [uniqueId, setUniqueId] = useState()
  const [description, setDescription] = useState()

  const [missingId, setMissingId] = useState(false)
  const [missingName, setMissingName] = useState(false)
  const [missingType, setMissingType] = useState(false)

  const dispatch = useDispatch()

  const data = useSelector((state) => state[table][moduleType].wip)
  const email = useSelector((state) => state.login.email)
  const innerMode = useSelector((state) => state.toggle.showInnerMap)
  const currentInner = useSelector((state) => state.indoor.current?.inner)

  let info = data?.geoJson
  const url = null

  const [currentForm, setCurrentForm] = useState('main')

  const handleChange = (e, { name }) => {
    setCurrentForm(name)
  }

  const buttons = [
    { name: 'main', icon: 'home' },
    { name: 'geo', icon: 'compass outline', disabled: true },
    { name: 'history', icon: 'history', disabled: true },
  ]

  const handleSave = () => {
    if (data.sameNameError) {
      alert('Já existe um módulo com este nome!')
      setMissingName(true)
    } else if (data.sameIdError) {
      alert('Já existe um módulo com este id!')
      setMissingId(true)
    } else if (!(missingId || missingName || missingType)) {
      let payload = {}
      info.properties.type = type
      info.properties.uniqueId = uniqueId
      info.properties.label = name
      info.properties.description = description

      payload.name = name
      payload.type = type
      payload.uniqueId = uniqueId
      payload.description = description
      payload.info = info
      payload.info.geometry.coordinates = innerMode
        ? innerPosition(info.geometry.coordinates, moduleType, currentInner?.center, currentInner?.bearing)
        : info.geometry.coordinates
      dispatch(createModule({ info: info, name: name, moduleType, login: email }))
      setCurrentModule(null, moduleType)
      dispatch(setFalse('showStatsBar'))
      dispatch(insertStopped({ moduleType: moduleType }))
    } else alert('Preencha todos os campos obrigatórios!')
  }

  const handleCancel = () => {
    setCurrentModule(null, moduleType)
    dispatch(setFalse('showStatsBar'))
    dispatch(insertStopped({ moduleType: moduleType }))
  }

  const debouncedChangeHandler = useMemo(
    () => debounce((change) => dispatch(updateWiP({ moduleType: moduleType, changes: change })), 300),
    [dispatch, moduleType]
  )

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  return (
    <>
      <Grid container>
        <Grid.Row centered>
          <StatsBarHeader name={'Novo módulo ' + moduleType} url={url} cancelAction={handleCancel} />
        </Grid.Row>
        <Grid.Row centered>
          <StatsBarButtons handleChange={handleChange} currentForm={currentForm} buttons={buttons} />
        </Grid.Row>
        <Grid.Row stretched>
          <Segment style={{ border: 'none', boxShadow: 'none', width: '100%' }}>
            {currentForm === 'main' && (
              <StatsBarFormMain
                moduleType={moduleType}
                table={table}
                state={{ changes, name, type, uniqueId, description, missingId, missingName, missingType }}
                setWip={debouncedChangeHandler}
                setState={{
                  setChanges,
                  setName,
                  setType,
                  setUniqueId,
                  setDescription,
                  setMissingId,
                  setMissingName,
                  setMissingType,
                }}
              />
            )}
            {currentForm === 'geo' && <StatsBarFormGeo moduleType={moduleType} />}
            {currentForm === 'history' && <StatsBarFormHistory />}
          </Segment>
        </Grid.Row>
      </Grid>
      {
        //<StatsBarActions id={id} moduleType={moduleType} />
      }
      <StatsBarFooter saveAction={handleSave} cancelAction={handleCancel} />
    </>
  )
}
export default FormStatsBarInsert
