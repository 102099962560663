import React from 'react'
import Auth from 'Libs/Auth'
import { Dropdown } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useMsal } from '@azure/msal-react'
import { useTranslation } from 'react-i18next'
import { saveLoginPreferences } from 'Store/actions/login-action'

export const SignOut = () => {
  const authMethod = useSelector((state) => state.login.authMethod)
  const { instance, homeAccountId } = useMsal()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(saveLoginPreferences())
    if (authMethod === 'auth0') {
      const auth = new Auth()
      const { logout } = auth
      logout()
      return
    }
    if (authMethod === 'msal') {
      handleLogoutMsal(instance, homeAccountId)
      return
    }
    console.error('Auth method not implemented')
  }

  return <Dropdown.Item icon="sign out" text={t('sign-out')} key="sign-out" onClick={handleLogout} />
}

function handleLogoutMsal(instance, homeAccountId) {
  instance
    .logoutRedirect({
      account: instance.getAccountByHomeId(homeAccountId),
      postLogoutRedirectUri: 'https://vli.phygitall.com.br/',
    })
    .catch((e) => {
      console.error(e)
    })
}
