import React from 'react'
import { PieChart, Pie, Sector, ResponsiveContainer, Legend } from 'recharts'
import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'

const GraphLegend = styled(Legend)`
  width: 20vw;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  max-height: 42px;
  overflow-y: scroll;
  & .recharts-legend-wrapper {
    max-height: 42px;
    overflow-y: scroll;
  }
`
const RADIAN = Math.PI / 180

const PieChartActive = (props) => {
  const total = props.data.reduce((total, { value }) => value + total, 0)

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontSize: '18px' }}
      >
        {`${value}`}
      </text>
    )
  }

  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props

    return (
      <g>
        <text x={cx} y={cy} dy={15} textAnchor="middle" style={{ fontSize: '40px' }}>
          {total}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    )
  }

  const activeIndex = 0
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        textAlign: 'left',
        fontSize: '14px',
        backgroundColor: 'white',
        borderRadius: '8px',
      }}
    >
      <div id="PanelHeader" style={{ height: '10%', padding: '6px' }}>
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '65%',
            display: 'inline-block',
            padding: '5px',
          }}
        >
          {' '}
          <span title={props.title || 'Título do Gráfico'} style={{ fontWeight: '700', maxWidth: '50%' }}>
            {props.title || 'Título do Gráfico'}
          </span>
        </div>
        <Dropdown
          inline
          style={{ marginTop: '4px', float: 'right', zIndex: 4, maxWidth: '42%' }}
          options={props.dateOptions}
          defaultValue={props.date || props.dateOptions[0].value}
        />
      </div>
      <div style={{ height: '90%' }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              labelLine={false}
              label={renderCustomizedLabel}
              data={props.data}
              cx="50%"
              cy="50%"
              innerRadius={50}
              outerRadius={100}
              dataKey="value"
              startAngle={90}
              endAngle={450}
            />
            <GraphLegend />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
export default PieChartActive
