import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Card, Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'

const CardVLI = styled(Card)`
  display: flex;
  flex-direction: column;
  align-content: left;
  justify-content: left;
  align-items: left;
  text-align: left;
  height: 14vh;
`

const CardContent = styled(Card.Content)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CardKPI = () => {
  const assetsIds = useSelector((state) => state.assets?.allActivePeopleIds || [], shallowEqual)
  const assets = useSelector((state) => state.assets.byId || {}, shallowEqual)

  const typesIds = useSelector((state) => state.modules.types.allIds || [], shallowEqual)
  const types = useSelector((state) => state.modules.types.byId || [], shallowEqual)

  const countedTypes = (typeId) => assetsIds.filter((a) => parseInt(assets[a]?.info?.type) === parseInt(typeId)).length

  const typesObj = typesIds
    .filter((tp) => types[tp].info.table === 'assets.people')
    .map((t) => ({ ...types[t], x: countedTypes(t) }))
    .sort((a, b) =>
      a.info.label && b.info.label ? a.info.label.toLowerCase().localeCompare(b.info.label.toLowerCase()) : 1
    )

  const options = typesObj.map((to) => ({ text: to.info.label, value: to.id, key: to.id, x: to.x }))

  const [meta, setMeta] = useState(1)
  const [result, setResult] = useState(0)
  const [assignment, setAssignment] = useState(options?.[0]?.value)
  const [color, setColor] = useState(result >= meta ? 'green' : 'red')

  useEffect(() => {
    //alert(assignment)
    //lert(JSON.stringify(options))
    setResult(options?.filter((op) => op.value === assignment)?.[0]?.x)
    setColor(result >= meta ? 'green' : 'red')
    if (!assignment) setAssignment(options?.[0]?.value)
  }, [assignment, options, result, meta])

  const handleMeta = (e, t) => {
    setMeta(e.target.value)
  }

  return options ? (
    <CardVLI
      style={{
        borderLeft: `0.6vw solid ${color}`,
        height: '100px',
      }}
    >
      <CardContent>
        <div style={{ height: '36px' }}>
          <Dropdown
            search
            inline
            style={{ zIndex: 4, marginTop: '4px' }}
            options={options}
            defaultValue={assignment}
            onChange={(e, { value }) => setAssignment(value)}
          />{' '}
        </div>
        <Card.Description /*style={{ display: 'flex', flexDirection: 'column' }}*/ textAlign="left">
          {' '}
          <span>Meta: </span>
          <input type={'number'} value={meta} style={{ width: '20px', border: 'none' }} onChange={handleMeta} />
          <br />
          <span
            style={{
              color: `${color}`,
            }}
          >
            Resultado: {result}
          </span>
        </Card.Description>
      </CardContent>
    </CardVLI>
  ) : null
}

export default CardKPI
