import React from 'react'
import styled from 'styled-components'
import PopupTitle from './PopupTitle'
import PopupTopButtons from './PopupTopButtons'
import { Grid } from 'semantic-ui-react'
import MissingSignal from 'Utils/components/MissingSignals'
import Avatar from 'Utils/components/Avatar'
import { useSelector, useDispatch } from 'react-redux'
import { updateAssetAvatar } from 'Store/actions/assets-action'

/*  Author: Bruno Melo / Luís Mourão
    Type: Smart
	Description: The popup header with buttons and title;				 
	To do list: -				
*/

const PopupHeaderContainer = styled(Grid.Row)`
  border-bottom: 1px solid #cecece;
  width: 255px;
  padding: 2px !important;
`
const PopupRightHeader = styled(Grid.Column)``
const PopupLeftHeader = styled(Grid.Column)``

const PopupHeader = ({ assetId, signals, mode }) => {
  const dispatch = useDispatch()

  const assetType = useSelector((state) => state.assets.byId[assetId]?.info?.type)
  const assetImageUrl = useSelector((state) => state.assets.byId[assetId]?.info?.url)
  const assetTypeSUIIcon = useSelector((state) => state.modules.types.byId[assetType]?.info?.suiIcon || 'microchip')
  const loginProfile = useSelector((state) => state.login.perfil)
  const isUploading = useSelector((state) => state.assets?.current?.isUploading)
  const assetName = useSelector((state) => state.assets.byId[assetId]?.name)

  return (
    <PopupHeaderContainer verticalAlign="middle" id="PopupHeaderContainer" columns={16}>
      <PopupLeftHeader width={3}>
        <Avatar
          url={assetImageUrl}
          name={assetTypeSUIIcon}
          editable={loginProfile === 'DEV'}
          isLoading={isUploading}
          onSelectedFile={(file) => dispatch(updateAssetAvatar(assetId, file))}
        />
      </PopupLeftHeader>
      <PopupRightHeader width={13}>
        {signals.length > 0 && (
          <div style={{ marginLeft: '12px' }}>
            <MissingSignal signals={signals} description={true} />
          </div>
        )}
        <PopupTitle assetName={assetName} assetId={assetId} />
        <PopupTopButtons assetId={assetId} mode={mode} />
      </PopupRightHeader>
    </PopupHeaderContainer>
  )
}

export default PopupHeader
