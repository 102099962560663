import React from 'react'
import { Icon, Image, Card, Statistic /*, Segment, Grid, GridColumn */ } from 'semantic-ui-react'
import { useSelector, shallowEqual } from 'react-redux'
import { PieGraphSmall } from 'Components/SidebarMenu/Contents/Dashboard/PieGraphSmall.jsx'
import FencesDashboard from 'Utils/reactTable/components/FencesDashboard'
import InteractionsDashboard from 'Utils/reactTable/components/InteractionsDashboard'
import styled from 'styled-components'
import LineChart from 'Components/SidebarMenu/Contents/DashboardManageAndSecurity/LineChart.js'
import RulesLineChart from 'Components/Charts/RulesLineChart'
import RulesCards from 'Components/Cards/RulesCards'
import { useTranslation } from 'react-i18next'

const CardFiat = styled(Card)`
  width: 290px !important;
`

const Cards = ({ fakeCards, fakeCardsVLI, setCurrentCard, dashboard, dashboardMAS }) => {
  const { t } = useTranslation()

  const charts = useSelector((state) => state.dashboardStellantis.charts, shallowEqual)

  const empresa = useSelector((state) => state.login.empresa)
  const search = useSelector((state) => state.dashboards.filter)

  let pieChart = {}
  if (charts?.length > 0) {
    charts.forEach((e) => {
      Object.values(e.label)
        .filter((element) => element.name === 'Dessaturação' || element.name === 'Saturação')
        .forEach((eachLabel) => {
          if (!pieChart[eachLabel.name]) {
            pieChart[eachLabel.name] = {
              name: eachLabel.name,
              value: eachLabel.duration,
              color: eachLabel.name === 'Dessaturação' ? '#c40233' : '#66afe9',
            }
          } else {
            pieChart[eachLabel.name].value = pieChart[eachLabel.name].value + eachLabel.duration
          }
        })
    })
  }
  pieChart = Object.values(pieChart)
  let filteredCards =
    search && empresa === 'Stellantis'
      ? fakeCards.filter((card) => card.time.toLowerCase().includes(search.toLowerCase()) || card.meta.includes(search))
      : empresa === 'PhygitallDevs'
      ? fakeCardsVLI
      : fakeCards

  if (dashboard === 'Perímetros' && empresa === 'JTI') {
    return (
      <div>
        <LineChart />
        <br />
        <br />
        <InteractionsDashboard key="FencesDashboard" />
      </div>
    )
  } else if (dashboard === 'Perímetros' && empresa !== 'JTI') {
    return (
      <div>
        <LineChart />
        <br />
        <br />
        <FencesDashboard key="FencesDashboard" />
      </div>
    )
  } else {
    return empresa === 'Stellantis' ? (
      <Card.Group centered>
        {filteredCards.map((card, i) => {
          return (
            <CardFiat
              onClick={() =>
                setCurrentCard({ color: card.color, filters: card.filters, tgMin: card.tgMin, name: card.header })
              }
              color={card.color}
              key={'cardFake' + card.color}
            >
              <Card.Content>
                <Statistic size="mini" floated="right" color={card.color}>
                  <Statistic.Value>
                    <Icon name={card.image} /> {card.duration}
                  </Statistic.Value>
                  <Statistic.Label>{t(card.labelValue)}</Statistic.Label>
                </Statistic>
                <Card.Header>{t(card.header)}</Card.Header>
                <Card.Meta>{t(card.meta)}</Card.Meta>
                {card.description.map((eachDesc, descIndex) => {
                  const descSplit = eachDesc?.split(':')
                  const descLabel = descSplit?.[0]
                  const descValue = descSplit?.[1]
                  return (
                    <Card.Description key={descIndex}>{t(descLabel) + ':' + descValue || '\u00A0'}</Card.Description>
                  )
                })}
                {/* <Card.Description key={'resp1'}>{card.description[0] || '\u00A0'}</Card.Description>
              <Card.Description key={'resp2'}>{card.description[1] || '\u00A0'}</Card.Description>
              <Card.Description key={'resp3'}>{card.description[2] || '\u00A0'}</Card.Description>
              {card.description[3] && <Card.Description key={'resp4'}>{card.description[3] || '\u00A0'}</Card.Description>} */}
                <div style={{ width: '80px', height: '80px', float: 'right', marginTop: '-65px' }}>
                  {dashboard === 'Dashboard' ? (
                    card.source === 'ciclo' && (
                      <PieGraphSmall data={pieChart} color={card.color} colors={['#c40233', '#66afe9']} />
                    )
                  ) : (
                    <PieGraphSmall data={card.data} color={card.color} colors={card.colors} />
                  )}
                </div>
              </Card.Content>
              {/*<Card.Content extra textAlign='center'>
              {<Icon name='time' />}
              {card.time}
        </Card.Content>*/}
            </CardFiat>
          )
        })}
      </Card.Group>
    ) : empresa === 'Demo' ? (
      <Card.Group>
        {filteredCards.map((card, i) => (
          <Card color={card.color} key={'cardFake' + i}>
            <Card.Content>
              {card.image ? (
                card.image.startsWith('http') ? (
                  <Image circular floated="right" size="tiny" src={card.image} />
                ) : (
                  <Icon
                    circular
                    inverted
                    color={card.color}
                    size="large"
                    style={{ float: 'right' }}
                    name={card.image}
                  />
                )
              ) : null}
              <Card.Header>{card.header}</Card.Header>
              <Card.Meta>{card.meta}</Card.Meta>
              <Card.Description>{card.description}</Card.Description>
            </Card.Content>
            <Card.Content extra>{card.time}</Card.Content>
          </Card>
        ))}
      </Card.Group>
    ) : (
      <div>
        {!dashboardMAS && (
          <>
            {' '}
            <RulesLineChart /> <br /> <br />{' '}
          </>
        )}
        <RulesCards dashboardMAS={dashboardMAS}></RulesCards>

        {/* <Grid columns="equal">
          <Grid columns={2}>
            <Grid.Column>
              <SegmentPieActive>
                <PieChartActive />
              </SegmentPieActive>
              <SegmentPieActive>
                <PieChartActive />
              </SegmentPieActive>
            </Grid.Column>
            <Grid.Column>
              <SegmentPieActive>
                <PieChartActive />
              </SegmentPieActive>
              <SegmentPieActive>
                <PieChartActive />
              </SegmentPieActive>
            </Grid.Column>
          </Grid>

          <Grid columns={1}>
            <Grid.Column>
              <SegmentPieActive style={{ height: '50vh' }}>
                <CardByRule></CardByRule>
              </SegmentPieActive>
            </Grid.Column>

            <Grid.Column>
              <SegmentPieActive style={{ height: '50vh' }}>
                <LineChart></LineChart>
              </SegmentPieActive>
            </Grid.Column>
          </Grid>
        </Grid>

        <Grid columns={1}>
          <Grid.Column>
            <SegmentPieActive style={{ height: '50vh' }}>
              <LineChart></LineChart>
            </SegmentPieActive>
          </Grid.Column>
        </Grid> */}
      </div>
    )
  }
}

export default Cards
