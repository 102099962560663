import React, { useState, useEffect, useMemo } from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import StatsBarHeader from './StatsBarHeader'
import StatsBarButtons from './StatsBarButtons'
import StatsBarFooter from './StatsBarFooter'
import StatsBarFormGeo from './StatsBarFormGeo'
import StatsBarFormHistory from './StatsBarFormHistory'
import StatsBarFormMain from './StatsBarFormMain'
import StatsBarActions from './StatsBarActions'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentModule, updateDynamicData, updateWiP, clearWiP, insertStopped } from 'Store/actions/modules-action'
import { setFalse } from 'Store/actions/toggle-action'
import { debounce } from 'lodash'

const FormStatsBar = ({ id, table, moduleType }) => {
  const dispatch = useDispatch()

  const data = useSelector((state) => state[table][moduleType].byId[id])
  const wip = useSelector((state) => state[table][moduleType].wip)
  const title = useSelector(
    (state) =>
      state[table][moduleType].byId[id]?.info?.label || state[table][moduleType].byId[id]?.info?.properties?.label
  )
  const url = useSelector(
    (state) => state[table][moduleType].byId[id]?.info?.url || state[table][moduleType].byId[id]?.info?.properties?.url
  )

  const [currentForm, setCurrentForm] = useState('main')

  const handleChange = (e, { name }) => {
    setCurrentForm(name)
  }

  const buttons = [
    { name: 'main', icon: 'home' },
    { name: 'geo', icon: 'compass outline' },
    { name: 'history', icon: 'history' },
  ]
  const [changes, setChanges] = useState([])
  const [currentId, setCurrentId] = useState(data?.id)
  const [name, setName] = useState(data?.info?.label || data?.info?.properties?.label)
  const [type, setType] = useState(data?.info?.properties?.type || data?.info?.properties?.risk)
  const [uniqueId, setUniqueId] = useState(data?.info?.uniqueId || data?.info?.properties?.uniqueId)
  const [description, setDescription] = useState(data?.info?.description || data?.info?.properties?.description)

  const [missingId, setMissingId] = useState(false)
  const [missingName, setMissingName] = useState(false)
  const [missingType, setMissingType] = useState(false)

  const handleSave = () => {
    if (wip?.sameNameError) {
      alert('Já existe um módulo com este nome!')
      setMissingName(true)
    } else if (wip?.sameIdError) {
      alert('Já existe um módulo com este código!')
      setMissingId(true)
    } else if (!(missingId || missingName || missingType)) {
      let payload = {}
      payload.id = id
      payload.name = name
      payload.type = type
      payload.uniqueId = uniqueId
      payload.description = description
      if (wip?.geometry) payload.geometry = wip.geometry
      if (wip?.mradius) payload.mradius = wip.mradius

      dispatch(updateDynamicData(payload, moduleType))
      dispatch(setFalse('showStatsBar'))
      dispatch(setFalse('fencesEditionMode'))
      dispatch(clearWiP(moduleType))
    } else alert('Preencha todos os campos obrigatórios!')
  }

  useEffect(() => {
    if (data.id !== currentId) {
      if (changes.length === 0) {
        setChanges([])
        setCurrentId(data.id)
        setName(title || '')
        setType(data?.info?.properties?.type || data?.info?.properties?.risk || '')
        setUniqueId(data?.info?.uniqueId || data?.info?.properties?.uniqueId || '')
        setDescription(data?.info?.description || data?.info?.properties?.description || '')
      } else {
        if (window.confirm('Tem certeza que deseja perder as modificações feitas?')) {
          setChanges([])
          setCurrentId(data.id)
          setName(title || '')
          setType(data?.info?.properties?.type || data?.info?.properties?.risk || '')
          setUniqueId(data?.info?.uniqueId || data?.info?.properties?.uniqueId || '')
          setDescription(data?.info?.description || data?.info?.properties?.description || '')
        }
      }
    }
  }, [changes, data, title, currentId, setChanges, setCurrentId, setName, setType, setUniqueId, setDescription])

  const handleCancel = () => {
    if (changes.length === 0) {
      setCurrentModule(null, moduleType)
      dispatch(setFalse('showStatsBar'))
      dispatch(clearWiP(moduleType))
      dispatch(insertStopped({ moduleType: moduleType }))
    } else {
      if (window.confirm('Tem certeza que deseja perder as modificações feitas?')) {
        setCurrentModule(null, moduleType)
        dispatch(setFalse('showStatsBar'))
        dispatch(clearWiP(moduleType))
        dispatch(insertStopped({ moduleType: moduleType }))
      }
    }
  }

  const debouncedChangeHandler = useMemo(
    () => debounce((change) => dispatch(updateWiP({ moduleType: moduleType, changes: change })), 300),
    [dispatch, moduleType]
  )

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  return (
    <>
      <Grid container>
        <Grid.Row centered>
          <StatsBarHeader name={title} url={url} cancelAction={handleCancel} />
        </Grid.Row>
        <Grid.Row centered>
          <StatsBarButtons handleChange={handleChange} currentForm={currentForm} buttons={buttons} />
        </Grid.Row>
        <Grid.Row stretched>
          <Segment style={{ border: 'none', boxShadow: 'none', width: '100%' }}>
            {currentForm === 'main' && (
              <StatsBarFormMain
                {...data}
                moduleType={moduleType}
                table={table}
                state={{ changes, name, type, uniqueId, description, missingId, missingName, missingType }}
                setWip={debouncedChangeHandler}
                setState={{
                  setChanges,
                  setName,
                  setType,
                  setUniqueId,
                  setDescription,
                  setMissingId,
                  setMissingName,
                  setMissingType,
                }}
              />
            )}
            {currentForm === 'geo' && <StatsBarFormGeo {...data} moduleType={moduleType} />}
            {currentForm === 'history' && <StatsBarFormHistory {...data} moduleType={moduleType} id={id} />}
          </Segment>
        </Grid.Row>
      </Grid>
      <StatsBarActions id={id} moduleType={moduleType} table={table} />
      <StatsBarFooter saveAction={handleSave} cancelAction={handleCancel} />
    </>
  )
}
export default FormStatsBar
