/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNotificationProfile = /* GraphQL */ `
  query GetNotificationProfile($id: ID!) {
    getNotificationProfile(id: $id) {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
  }
`;
export const getRule = /* GraphQL */ `
  query GetRule($id: ID!) {
    getRule(id: $id) {
      id
      notificationProfileId
      notificationProfile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      name
      tenant
      status
      createdBy
      description
      action {
        type
        messageProfileId
      }
      message
      messageId
      scope
      sourceId
      sourceProp
      sourcePropValue
      sourceType
      targetProp
      targetPropValue
      targetId
      targetType
      threshold
      sendTo
      editable
      createdAt
      updatedAt
    }
  }
`;
export const getMessageProfile = /* GraphQL */ `
  query GetMessageProfile($id: ID!) {
    getMessageProfile(id: $id) {
      id
      tenant
      label
      type
      title
      body
      extra
      tags
      createdBy
      status
      createdAt
      updatedAt
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      tenant
      assetId
      message
      title
      time
      type
      deviceId
      profileId
      profile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      ruleId
      confirmedBy
      confirmedAt
      closedBy
      closedAt
      id
      createdAt
      updatedAt
    }
  }
`;
export const listAssetsLocation = /* GraphQL */ `
  query ListAssetsLocation(
    $tenant: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAssetLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetsLocation(
      tenant: $tenant
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetId
        deviceId
        lat
        lng
        location
        fencesLocation
        groupsLocation
        analytics
        tenant
        time
        motion
        coords
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssetIdLocation = /* GraphQL */ `
  query ListAssetIdLocation(
    $tenant: String!
    $assetId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetIdLocation(
      tenant: $tenant
      assetId: $assetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetId
        deviceId
        lat
        lng
        location
        fencesLocation
        groupsLocation
        analytics
        tenant
        time
        motion
        coords
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listNotificationProfiles = /* GraphQL */ `
  query ListNotificationProfiles(
    $tenant: String!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationProfiles(
      tenant: $tenant
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRules = /* GraphQL */ `
  query ListRules(
    $tenant: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRules(
      tenant: $tenant
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notificationProfileId
        notificationProfile {
          id
          name
          tenant
          bgColor
          status
          screenTime
          icon
          description
          level
          titleColor
          bodyColor
          actionColor
          dateColor
          txtColor
          createdBy
          theme
          iconColor
          sound
          createdAt
          updatedAt
        }
        name
        tenant
        status
        createdBy
        description
        action {
          type
          messageProfileId
        }
        message
        messageId
        scope
        sourceId
        sourceProp
        sourcePropValue
        sourceType
        targetProp
        targetPropValue
        targetId
        targetType
        threshold
        sendTo
        editable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMessageProfiles = /* GraphQL */ `
  query ListMessageProfiles(
    $tenant: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageProfiles(
      tenant: $tenant
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        label
        type
        title
        body
        extra
        tags
        createdBy
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLoginPreferences = /* GraphQL */ `
  query GetLoginPreferences(
    $tenant: String!
    $login: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoginPreferencesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLoginPreferences(
      tenant: $tenant
      login: $login
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenant
        login
        center {
          lat
          lng
        }
        zoom
        mapLayers
        refs
        fences
        routes
        indoor
        tables
        routesPanels
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $tenant: String!
    $time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(
      tenant: $tenant
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenant
        assetId
        message
        title
        time
        type
        deviceId
        profileId
        profile {
          id
          name
          tenant
          bgColor
          status
          screenTime
          icon
          description
          level
          titleColor
          bodyColor
          actionColor
          dateColor
          txtColor
          createdBy
          theme
          iconColor
          sound
          createdAt
          updatedAt
        }
        ruleId
        confirmedBy
        confirmedAt
        closedBy
        closedAt
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
