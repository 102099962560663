import { createLoginPreferences, updateLoginPreferences } from 'graphql/mutations'
import { API } from 'aws-amplify'
import { getLoginPreferences } from 'graphql/queries'
export const LOAD_STORE = 'loadStore'
export const LOAD_STORE_LOGIN = 'loadStoreLogin'
export const SAVE_CENTER_ZOOM = 'saveCenterZoom'

export const loadStore = (empresa, recurso) => ({
  type: `server/consulta/${recurso}_view`,
  fields: [`${recurso}_store as ${recurso}`],
  where: { empresa: empresa },
  callback: `${recurso}InitialStore`,
})

export const loadStoreLogin = (login, recurso) => ({
  type: `server/consulta/${recurso}_view`,
  fields: [`${recurso}_store as ${recurso}`],
  where: { email: login },
  callback: `${recurso}InitialStore`,
})

export const loadLogin = (payload) => ({
  type: 'LOAD_LOGIN',
  payload,
})

export const saveCenterZoom = (center, zoom) => ({
  type: 'saveCenterZoom',
  payload: { center: center, zoom: zoom },
})

export const saveRefMap = (refMap) => ({
  type: 'saveCenterZoom',
  payload: { refMap: refMap },
})

export const saveServerCenterZoom = (center, zoom, email) => ({
  type: 'server/atualiza/login',
  fields: { info: `'{"center": [${center}], "zoom": ${zoom} }'` },
  where: { email: email },
})

export const saveLanguage = (language, email) => ({
  type: 'server/atualiza/login',
  fields: { info: `'{"language": "${language}"}'` },
  where: { email: email },
})

export const saveLanguageNow = (language) => ({
  type: 'saveLanguages',
  payload: { language: language },
})

export const saveMarkerFields = (markerFields, email) => ({
  type: 'server/atualiza/login',
  fields: { info: `'{"markerFields": [${markerFields}]}'` },
  where: { email: email },
})

export const saveFavFields = (favFields, email) => ({
  type: 'server/atualiza/login',
  fields: { info: `'{"favFields": [${favFields}]}'` },
  where: { email: email },
})

export const saveFavFieldsNow = (favFields) => ({
  type: 'saveFavorites',
  payload: { favFields: favFields },
})

export const removeFavorite = (favorites, email) => ({
  type: 'server/atualiza/login',
  fields: { info: `'{"favorites": [${favorites.toString()}]}'` },
  where: { email: email },
})
export const removeFavoriteNow = (favorites) => ({
  type: 'saveFavorites',
  payload: { favorites: favorites },
})

export const saveFavorite = (favorites, email) => ({
  type: 'server/atualiza/login',
  fields: { info: `'{"favorites": [${favorites.toString()}]}'` },
  where: { email: email },
})

export const saveFavoriteNow = (favorites) => ({
  type: 'saveFavorites',
  payload: { favorites: favorites },
})

export const saveFollowMeStore = (seguidos) => ({
  type: 'saveLoginInfo',
  payload: { followMe: seguidos },
})

export const saveFollowMeNow = (seguidos, email) => ({
  type: 'server/atualiza/login',
  fields: { info: `'{"followMe": [${seguidos.toString()}]}'` },
  where: { email: email },
})
export const saveServerFollowMe = (followMe, email) => ({
  type: 'server/atualiza/login',
  fields: { info: `'{"followMe": [${followMe}]}'` },
  where: { email: email },
})

export const saveFollowMe = (id) => {
  return {
    type: 'saveFollowMe',
    payload: { id },
  }
}

export const removeFollowMe = () => {
  return {
    type: 'removeFollowMe',
  }
}

export const saveCurrentRelease = (release, email) => ({
  type: 'server/atualiza/login',
  fields: { info: `'{"currentRelease": "${release}"}'` },
  where: { email: email },
})

export const saveServerLoginHideObj = (obj, email) => ({
  type: 'server/atualiza/login',
  fields: { info: `'{"hide": ${obj}}'` },
  where: { email: email },
})

export const setLoginConnection = (payload) => {
  return {
    type: 'SET_LOGIN_CONNECTION',
    payload,
  }
}

export const fetchLoginPreferences = () => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  const login = getState().login.email
  const info = getState().tenants.current?.info
  const features = getState().tenants.current?.features

  let formatFeatures = features.reduce((acc, current) => {
    if (current === 'location_hist') return [...acc, 'locationHist']
    if (current === 'firefighters_view') return [...acc, 'firefighter']
    if (current === 'data_collection')
      // return [...acc, 'labelingTool', 'manualYamazumi', 'automaticYamazumi', 'cycleTimeMeasurement']
      //labelingTool em branco e sem uso, retirado até que necessite
      return [...acc, 'manualYamazumi', 'automaticYamazumi', 'cycleTimeMeasurement']
    if (current === 'app') return [...acc, 'logouts']
    return [...acc, current]
  }, [])

  try {
    dispatch({ type: 'FETCH_LOGIN_START', tenant, login })
    const response = await API.graphql({
      query: getLoginPreferences,
      variables: { tenant, login: { eq: login } },
    })
    const { items } = response.data.getLoginPreferences

    let payload = JSON.parse(JSON.stringify(items[0] || {}))

    if (!items[0] && info.lat) {
      payload = {
        center: { lat: info.lat, lng: info.lng },
        zoom: 18,
      }
    }

    const filteredPayload = {
      ...payload,
      fences: payload.fences?.map((fence) => JSON.parse(fence)),
      indoor: payload.indoor?.map((eachIndoor) => JSON.parse(eachIndoor)),
      refs: payload.refs?.map((ref) => JSON.parse(ref)),
      routes: payload.routes?.map((route) => JSON.parse(route)),
      routesPanels: payload?.routesPanels?.filter((e) => e.id !== 'motion').map((routePanel) => JSON.parse(routePanel)),
    }

    dispatch({
      type: 'FETCH_LOGIN_PREFERENCES_SUCCESS',
      payload: filteredPayload,
      features: formatFeatures,
    })
  } catch (err) {
    dispatch({
      type: 'FETCH_LOGIN_PREFERENCES_FAILURE',
      features: formatFeatures,
    })
    console.error('error fetching login preferences', err)
  }
}

export const saveLoginPreferences = () => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  const login = getState().login.email
  const loginState = getState().login
  let center
  let zoom
  if (loginState.refMap) {
    center = loginState.refMap.getCenter()
    zoom = loginState.refMap.getZoom()
  } else {
    center = loginState.preferences.center
    zoom = loginState.preferences.zoom
  }

  const loginMapLayers = getState().login.preferences.mapLayers
  const indoor = Object.values(getState().login.preferences.indoor).map((eachIndoor) => JSON.stringify(eachIndoor))
  const fences = Object.values(getState().login.preferences.fences).map((fence) => JSON.stringify(fence))
  const refs = Object.values(getState().login.preferences.refs).map((ref) => JSON.stringify(ref))
  const routes = Object.values(getState().login.preferences.routes).map((route) => JSON.stringify(route))
  const allRoutesPanels = getState().login.preferences.routesPanels
  const routesPanels = allRoutesPanels.allIds?.map((each) => JSON.stringify(allRoutesPanels.byId[each]))

  let input = {
    id: `${tenant}#${login}`,
    tenant,
    login,
    center,
    zoom,
    mapLayers: loginMapLayers || [],
    indoor,
    fences,
    refs,
    routes,
    routesPanels,
  }
  dispatch({ type: 'SAVE_LOGIN_START', input })
  try {
    await API.graphql({
      query: updateLoginPreferences,
      variables: {
        input,
      },
    })
    dispatch({ type: 'UPDATE_TAB_ACTION_SUCCESS' })
  } catch (err) {
    try {
      await API.graphql({
        query: createLoginPreferences,
        variables: {
          input,
        },
      })
      dispatch({ type: 'UPDATE_TAB_ACTION_SUCCESS' })
    } catch (err) {
      dispatch({ type: 'UPDATE_TAB_ACTION_FAILURE', payload: err })
      console.error('error create:', err)
    }
  }
}

export const saveLoginMapLayer = (mapLayer, add) => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  const login = getState().login.email
  const loginMapLayers = getState().login.preferences.mapLayers

  let input = {
    id: `${tenant}#${login}`,
    tenant,
    login,
    mapLayers: add
      ? loginMapLayers.includes(mapLayer)
        ? loginMapLayers
        : loginMapLayers.concat(mapLayer)
      : loginMapLayers.includes(mapLayer)
      ? loginMapLayers.filter((e) => e !== mapLayer)
      : loginMapLayers,
  }
  dispatch({ type: 'SAVE_LOGIN_MAP_LAYERS', payload: input.mapLayers })
  try {
    await API.graphql({
      query: updateLoginPreferences,
      variables: {
        input,
      },
    })
  } catch (err) {
    try {
      await API.graphql({
        query: createLoginPreferences,
        variables: {
          input,
        },
      })
    } catch (err) {
      console.error('error create:', err)
    }
  }
}

export const saveColumnsPreference = (tableName, columnsPreference, groupedValuesColumns) => async (dispatch) => {
  // get columns toggled by user
  // save that to their login preferences
  if (columnsPreference) {
    dispatch({
      type: 'UPDATE_COLUMNS_DISPLAY',
      payload: { tableName, columnsPreference },
    })
  }
  if (groupedValuesColumns) {
    dispatch({
      type: 'UPDATE_GROUPED_ROWS',
      payload: { tableName, groupedValuesColumns },
    })
  }
}

export const changeLoginPreferences = (payload) => ({
  type: 'CHANGE_LOGIN_PREFERENCES',
  payload,
})

export const setLoginProp = (prop, value) => ({
  type: 'SET_LOGIN_PROP',
  prop,
  value,
})

export const setNewRoutePanel = (value) => ({
  type: 'SET_NEW_ROUTE_PANEL',
  value,
})

export const deleteRoutePanel = (payload) => ({
  type: 'DELETE_ROUTE_PANEL',
  payload,
})
