import React, { Fragment, useCallback, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Grid, Dropdown, List, Input, Segment, Form, Checkbox, Divider } from 'semantic-ui-react'
import { ModalTemplate } from 'Utils/reactTable/components/ModalTemplate'
import { difference, intersection, union } from 'lodash'
import { UseGroupByRowProps, UseTableRowProps } from 'Components/SidebarMenu/Contents/react-table-config'
import { RootStore } from 'Store/initialStore'
import { saveRule } from 'Store/actions/rules-action'
import styled from 'styled-components'
/*  Author: Bruno Melo
    Type: Smart
	Description: Content of Groups Actions;			 
	To do list: -
				
*/
const SelectedsSpan = styled.span`    
    display: block;
    margin: 0 0 .28571429rem 0;
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
}`

interface FenceBaseRow {
  rulesText: string
  groups: string[]
  name: string
  id: string
  info: {
    label?: string
    properties: {
      label: string
    }
  }
}

interface SelectedItem extends UseGroupByRowProps<FenceBaseRow>, UseTableRowProps<FenceBaseRow> {}
type GroupActions = 'updateRules' | 'insert' | 'update' | 'delete'
interface UpdateChanges {
  groupName: string
  selected: string[]
  type: GroupActions
}
interface GroupsReactTableProps {
  table: string
  selected: SelectedItem[]
  groupsOpen: boolean
  groupAction: (groupName: string, selected: string | string[], groupActions: GroupActions) => void
  setGroupsOpen: (b: boolean) => void
  groupsAction: GroupActions
  setGroupsAction: (b: boolean) => void
}

export const GroupsReactTable = ({
  groupsAction,
  setGroupsAction,
  groupsOpen,
  setGroupsOpen,
  table,
  selected,
  groupAction,
}: GroupsReactTableProps) => {
  const dispatch = useDispatch()
  if (table === 'people') table = 'assets'
  const [updateChanges, setUpdateChanges] = React.useState<UpdateChanges[]>([])
  const [selectedState, setSelectedState] = React.useState<FenceBaseRow[]>([])
  const groups = useSelector(
    (state: RootStore) => state[table]?.groups || state['modules']?.[table]?.groups,
    shallowEqual
  )
  const rules = useSelector((state: RootStore) => state.rules.byId, shallowEqual)
  const rulesIds = useSelector((state: RootStore) => state.rules.allIds, shallowEqual)
  const [groupName, setGroupName] = React.useState('')
  const [groupsValue, setGroupsValue] = React.useState<string[]>([])
  const [errorState, setErrorState] = React.useState('')
  const getOptions = useCallback(
    () =>
      groupsAction !== 'updateRules'
        ? (groups || [])
            .filter((f: string) => !f?.startsWith('R0'))
            .sort()
            .map((g: string) => ({ key: 'saveGroups' + g, value: g, text: g }))
        : rulesIds
            .filter((r) => rules[r]?.id && rules[r]?.targetType === 'fence' && rules[r]?.editable === true)
            .map((r) => ({ text: (rules[r]?.id || '') + ' - ' + (rules[r]?.name || ''), value: rules[r]?.id || '' }))
            .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0))
            .map((g) => ({ key: 'saveRules' + g.value, value: g.value, text: g.text })),
    [groupsAction, groups, rulesIds, rules]
  )

  useEffect(() => {
    if (!selected[0]) return
    if (groupsAction === 'updateRules') {
      setGroupsValue(selected[0].original.rulesText.split(',').map((i) => i.trim()))
      return
    }
    if (groupsAction) {
      setSelectedState(selected.filter((s) => !s.isGrouped).map((s) => s.original))
      let a = selected.filter((s) => !s.isGrouped).map((s) => s.original.groups)
      setGroupsValue(intersection(...a))
    }
  }, [selected, groupsAction])

  const handleHeader = () => {
    return groupsAction === 'updateRules'
      ? 'Editar Regras'
      : groupsAction === 'insert'
      ? 'Criar Grupos'
      : groupsAction === 'update'
      ? 'Editar Grupos'
      : 'Excluir Grupos'
  }

  const handleChange = (e: any, { value }: any) => {
    if (groupsAction === 'insert') {
      //console.log('Estou aqui, modo insert')
      setGroupName(value)
    } else {
      if (value.length > groupsValue.length && groupsAction !== 'updateRules') {
        //console.log('adding groups', difference(value, groupsValue))
        let addThisGroups = difference(value, groupsValue)
        let selectedNames = selected.filter((s) => !s.isGrouped).map((s) => s.original.name)
        let changes = union<UpdateChanges>(updateChanges, [
          { groupName: addThisGroups[0], selected: selectedNames, type: 'update' },
        ])
        //console.log('Changes', changes)
        setUpdateChanges(changes)
      } else {
        //console.log('removing groups', difference(groupsValue, value))
        let removeThisGroups = difference(groupsValue, value)
        let selectedNames = selected.filter((s) => !s.isGrouped).map((s) => s.original.name)
        let changes = union<UpdateChanges>(updateChanges, [
          { groupName: removeThisGroups[0], selected: selectedNames, type: 'delete' },
        ])
        setUpdateChanges(changes)
      }

      setGroupsValue(value)
    }
    groupsAction === 'insert' && groups?.includes(value.trim())
      ? setErrorState('Já existe um grupo com esse nome.')
      : setErrorState('')
  }

  /*const handleCheck = (e, { name, checked }) => {
    //let newSelected = selecteds;
    if (!groupName) return
    if (groupsAction.startsWith('update'))
      if (checked) {
        updateChanges.push({ groupName: groupName, selected: [name], type: 'update' })
        setUpdateChanges(updateChanges)
        setSelectedState(
          selectedState.map((s) => {
            if (s.name === name) s.groups.push(groupName)
            return s
          })
        )

        //groupAction(groupName, [label],'update')
      } else {
        updateChanges.push({ groupName: groupName, selected: [name], type: 'delete' })
        setUpdateChanges(updateChanges)
        setSelectedState(
          selectedState.map((s) => {
            if (s.name === name) s.groups = s.groups.filter((g) => g !== groupName)
            return s
          })
        )
        //groupAction(groupName, [label],'delete')
      }
  }*/

  const clearStates = () => {
    setGroupName('')
    setErrorState('')
    setGroupsAction(false)
    setGroupsOpen(false)
    setUpdateChanges([])
  }

  const handleSave = () => {
    var reg = /^\d+$/
    if (errorState) {
      return
    }
    if (groupsAction === 'updateRules') {
      const original = selected[0].original.rulesText.split(',').map((i) => i.trim())
      const add = difference(groupsValue, original)
      const sub = difference(original, groupsValue)
      add.forEach((v) => {
        if (rules[v]?.targetId?.includes(selected[0].original.id)) return
        dispatch(
          saveRule({
            mutation: 'update',
            fields: {
              ...rules[v],
              //@ts-ignore
              targetId: [...rules[v]?.targetId, selected[0].original.id],
            },
          })
        )
      })
      sub.forEach((v) => {
        dispatch(
          saveRule({
            mutation: 'update',
            fields: {
              ...rules[v],
              targetId: rules[v]?.targetId?.filter((f) => f !== selected[0].original.id),
            },
          })
        )
      })
      clearStates()
      return
    }
    if (groupsAction === 'update') {
      updateChanges.forEach((action) => action.groupName && groupAction(action.groupName, action.selected, action.type))
      clearStates()
      return
    }
    if (!groupName.trim()) {
      setErrorState('Digite o nome do grupo')
      return
    }
    if (reg.test(groupName.trim())) {
      setErrorState('Digite ao menos uma letra')
      return
    }
    //console.log('Group action',groupName,selected,groupsAction)
    let selectedFormat = selected.map((s) => s.original.name)
    groupAction(groupName.trim(), selectedFormat, groupsAction)
    clearStates()
  }

  return (
    <ModalTemplate
      size="small"
      onClose={() => clearStates()}
      open={groupsOpen}
      header={<>{handleHeader()}</>}
      saveContent={groupsAction === 'delete' ? 'Excluir' : 'Salvar'}
      saveColor={groupsAction === 'delete' ? 'red' : 'green'}
      onSave={handleSave}
      onCancel={() => clearStates()}
    >
      <Grid>
        <Grid.Column key={'col1e'}>
          <Segment key={'segGroups' + table} style={{ border: 'none', boxShadow: 'none' }}>
            <List key={'lis1'}>
              <List.Header>
                <Segment style={{ border: 'none', boxShadow: 'none', padding: '0 1em' }}>
                  <Form>
                    <Form.Field>
                      <label>{groupsAction === 'updateRules' ? 'Regras' : 'Grupos'}</label>
                      {groupsAction === 'insert' ? (
                        <Input
                          fluid
                          size="mini"
                          placeholder={`Digite o nome do grupo...`}
                          required
                          value={groupName}
                          onChange={handleChange}
                          label={{ active: false, color: errorState ? 'red' : undefined, content: errorState }}
                          labelPosition="right"
                        />
                      ) : (
                        <Dropdown
                          fluid
                          search
                          selection
                          multiple={true}
                          key={'dropdownSaveGroups'}
                          onChange={handleChange}
                          placeholder={`Escolha ${groupsAction === 'updateRules' ? 'uma regra' : 'um grupo'}`}
                          value={groupsValue}
                          noResultsMessage={'Sem resultados'}
                          options={getOptions()}
                        />
                      )}
                    </Form.Field>
                  </Form>
                </Segment>
              </List.Header>
              <Divider />
              <Segment
                key={'segGroupslist' + table}
                style={{ border: 'none', overflowY: 'auto', maxHeight: '27em', boxShadow: 'none' }}
              >
                <SelectedsSpan>Itens Selecionados:</SelectedsSpan>
                {selectedState?.map((s) => (
                  <List.Item key={'listItem' + s?.name}>
                    {s?.name && (
                      <Fragment key={'frag' + s?.name}>
                        <Checkbox
                          key={'chkbox' + s?.name}
                          label={s?.info?.label || s?.info?.properties?.label || s?.name}
                          name={s?.name}
                          checked={true}
                          disabled
                        />
                        <br />
                      </Fragment>
                    )}
                  </List.Item>
                ))}
              </Segment>
            </List>
          </Segment>
        </Grid.Column>
      </Grid>
    </ModalTemplate>
  )
}

export default GroupsReactTable
