import React from 'react'
import { Button } from 'semantic-ui-react'

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInWithAuth0 = ({ auth }) => {
  return (
    <Button className="primary" onClick={() => auth.login()}>
      Conectar com email
    </Button>
  )
}
