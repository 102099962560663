export const oldIconOptions = [
  { key: 'icon1', text: 'Pessoa', value: 'user', icon: { name: 'user', circular: true } },
  { key: 'icon2', text: 'Homem', value: 'male', icon: { name: 'male', circular: true } },
  { key: 'icon3', text: 'Mulher', value: 'female', icon: { name: 'female', circular: true } },
  { key: 'icon4', text: 'Criança', value: 'child', icon: { name: 'child', circular: true } },
  { key: 'icon5', text: 'Deficiente', value: 'blind', icon: { name: 'blind', circular: true } },
  { key: 'icon6', text: 'Pessoas', value: 'users', icon: { name: 'users', circular: true } },
  { key: 'icon7', text: 'Médico', value: 'doctor', icon: { name: 'doctor', circular: true } },
  { key: 'icon8', text: 'Detetive', value: 'detective', icon: { name: 'detective', circular: true } },
  { key: 'icon9', text: 'Ambulância', value: 'emergency', icon: { name: 'emergency', circular: true } },
  { key: 'icon10', text: 'Legal', value: 'legal', icon: { name: 'legal', circular: true } },
  { key: 'icon11', text: 'Lei', value: 'law', icon: { name: 'law', circular: true } },
  { key: 'icon12', text: 'Ciência', value: 'lab', icon: { name: 'lab', circular: true } },
  { key: 'icon13', text: 'Coração', value: 'like', icon: { name: 'like', circular: true } },
  { key: 'icon14', text: 'Bola', value: 'soccer', icon: { name: 'soccer', circular: true } },
  { key: 'icon15', text: 'Estudante', value: 'student', icon: { name: 'student', circular: true } },
  { key: 'icon16', text: 'Hotel', value: 'hotel', icon: { name: 'hotel', circular: true } },
  { key: 'icon17', text: 'Favorito', value: 'favorite', icon: { name: 'favorite', circular: true } },
  { key: 'icon18', text: 'Fogo', value: 'fire', icon: { name: 'fire', circular: true } },
  { key: 'icon19', text: 'Extintor', value: 'fire extinguisher', icon: { name: 'fire extinguisher', circular: true } },
  { key: 'icon20', text: 'Pet', value: 'paw', icon: { name: 'paw', circular: true } },
  { key: 'icon21', text: 'Carro', value: 'car', icon: { name: 'car', circular: true } },
  { key: 'icon22', text: 'Moto', value: 'motorcycle', icon: { name: 'motorcycle', circular: true } },
  { key: 'icon23', text: 'Barco', value: 'ship', icon: { name: 'ship', circular: true } },
  { key: 'icon24', text: 'Metrô', value: 'subway', icon: { name: 'subway', circular: true } },
  { key: 'icon25', text: 'Caminhão', value: 'truck', icon: { name: 'truck', circular: true } },
  { key: 'icon26', text: 'Trem', value: 'train', icon: { name: 'train', circular: true } },
]

export const iconOptions = [
  {
    key: 'icon1',
    text: 'Funcionário',
    value: 'worker',
    image: { avatar: true, src: '../images/worker.svg' },
  },
  { key: 'icon2', text: 'Bombeiro', value: 'fireman', image: { avatar: true, src: '../images/fireman.svg' } },
  { key: 'icon3', text: 'Bombeiro 2', value: 'fireman2', image: { avatar: true, src: '../images/fireman-2.svg' } },
  { key: 'icon4', text: 'Autoridade', value: 'authority', image: { avatar: true, src: '../images/authority.svg' } },
  { key: 'icon5', text: 'Tripulante', value: 'mariner', image: { avatar: true, src: '../images/mariner.svg' } },
  { key: 'icon6', text: 'Tripulante 2', value: 'mariner2', image: { avatar: true, src: '../images/mariner-2.svg' } },
  { key: 'icon7', text: 'Caminhoneiro', value: 'trucker', image: { avatar: true, src: '../images/trucker.svg' } },
  { key: 'icon8', text: 'Visitante', value: 'visitor', image: { avatar: true, src: '../images/visitor.svg' } },
  { key: 'icon9', text: 'Visitante 2', value: 'visitor2', image: { avatar: true, src: '../images/visitor-2.svg' } },
  //{ key: 'icon10', text: 'Carro', value: 'car' },
  { key: 'icon11', text: 'Autoridade2', value: 'authority2', image: { avatar: true, src: '../images/authority2.svg' } },
  { key: 'icon12', text: 'Autoridade3', value: 'authority3', image: { avatar: true, src: '../images/authority3.svg' } },
  { key: 'icon13', text: 'Motorista', value: 'driver', image: { avatar: true, src: '../images/driver.svg' } },
  {
    key: 'icon14',
    text: 'Maquinista',
    value: 'driverLocomotive',
    image: { avatar: true, src: '../images/driverLocomotive.svg' },
  },
  { key: 'icon15', text: 'Totem', value: 'totem', image: { avatar: true, src: '../images/totem.svg' } },
]

export default iconOptions
