import React from 'react'
//import styled from 'styled-components'
import { Button } from 'semantic-ui-react'
/*  
  Author: Bruno Melo
  Type: Dumb
	Description:Define the Grid format for the buttons on navbar;
				      Separate the props of each part of the grid;				 
	To do list:-Use a array of objects to mount the buttons				
*/

import AvatarMenu from 'Components/Header/NavBar/Dropdowns/AvatarMenu/AvatarMenu'
import MobileMenu from 'Components/Header/NavBar/Dropdowns/MobileMenu'
import DropdownDevs from 'Components/Header/NavBar/Dropdowns/DropdownDevs'
import DropdownFences from 'Components/Header/NavBar/Dropdowns/DropdownFences'
import DropdownRefs from 'Components/Header/NavBar/Dropdowns/DropdownRefs'
import DropdownRoutes from 'Components/Header/NavBar/Dropdowns/DropdownRoutes'
import DropdownUnits from 'Components/Header/NavBar/Dropdowns/DropdownUnits'
import DropdownAssets from 'Components/Header/NavBar/Dropdowns/Assets/DropdownAssets'
import DropdownDevices from 'Components/Header/NavBar/Dropdowns/Devices/DropdownDevices'
import DropdownHistory from 'Components/Header/NavBar/Dropdowns/DropdownHistory'
import DropdownNotification from 'Components/Header/NavBar/Dropdowns/DropdownNotification'
import DropdownEmergency from 'Components/Header/NavBar/Dropdowns/DropdownEmergency'
import { useSelector } from 'react-redux'
import UploadModal from './Dropdowns/UploadModal'

export const NavButtonsGrid = (Props) => {
  const displayingMap = useSelector((state) => state.toggle.displayingMap)
  const empresa = useSelector((state) => state.tenants.current)
  let displayingNavButtons
  if (displayingMap) {
    displayingNavButtons = [
      <Button basic key="dropdev">
        {Props.perfil === 'DEV' && <DropdownDevs />}
      </Button>,
      <Button basic key="dropdownDevices">
        <DropdownDevices />
      </Button>,
      <Button basic key="dropdownEmergency">
        <DropdownEmergency perfil={Props.perfil} refMap={Props.refMap} />
      </Button>,
      <Button basic key="dropdownHistory">
        <DropdownHistory />
      </Button>,
      <Button basic key="dropdownAsset">
        <DropdownAssets />
      </Button>,
      <Button basic key="dropdownRefs">
        <DropdownRefs perfil={Props.perfil} refMap={Props.refMap} />
      </Button>,
      <Button basic key="dropdownRoutes">
        <DropdownRoutes perfil={Props.perfil} refMap={Props.refMap} />
      </Button>,
      <Button basic key="dropdownFences">
        <DropdownFences perfil={Props.perfil} refMap={Props.refMap} />
      </Button>,
      <Button basic key="dropdownUnits">
        <DropdownUnits indoor={Props.indoor} perfil={Props.perfil} refMap={Props.refMap} />
      </Button>,
      <Button basic key="dropdownNoification">
        <DropdownNotification />
      </Button>,
    ]
  } else {
    displayingNavButtons = [
      <Button basic key="dropdownNotification">
        <DropdownNotification />
      </Button>,
    ]
  }

  return (
    <>
      {!displayingMap && ['Tiplam', 'PhygitallDevs'].includes(empresa) && <span>Gestão & Segurança</span>}
      <Button.Group icon compact>
        {Props.isMobileSized ? (
          <Button>
            <MobileMenu {...Props} />
          </Button>
        ) : (
          <Button.Group>{displayingNavButtons.map((button) => button)}</Button.Group>
        )}
        <Button basic className="boxButtonsNavbarItemImg">
          <AvatarMenu
            picture={Props.profile.picture}
            rights={Props.rights}
            lang={Props.lang}
            save={Props.saveLanguage}
            savenow={Props.saveLanguageNow}
            email={Props.email}
          />
        </Button>
      </Button.Group>
      <UploadModal />
    </>
  )
}
export default NavButtonsGrid
