export const msalConfig = {
  auth: {
    clientId: 'f1b3b45f-fbe1-41b4-ae62-0a31d09ec186',
    authority: 'https://login.microsoftonline.com/eef8f41a-1fa3-486c-9dc3-2fa5f63c9231',
    redirectUri: 'https://beta.phygitall.com.br/callback',
    postLogoutRedirectUri: 'https://beta.phygitall.com.br/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read', 'Group.Read.All'],
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com',
}

const securityGroups = {
  GroupOperator: 'f20a1e08-521a-4b00-864b-7fc8e2708aba',
  GroupSupervisor: '2d804dbe-cb5f-48a0-93c3-852c711985cb',
  GroupAdmin: 'e127de02-a006-4943-988f-8c19df4645b5',
  GroupUser: '349f1948-1bb5-45c6-b381-5431c7165547',
}

export const permissions = {
  [securityGroups.GroupOperator]: 'OPE',
  [securityGroups.GroupSupervisor]: 'SUP',
  [securityGroups.GroupAdmin]: 'ADM',
  [securityGroups.GroupUser]: 'USER',
}

const userRolesHierarchy = {
  [permissions[securityGroups.GroupAdmin]]: 1,
  [permissions[securityGroups.GroupSupervisor]]: 2,
  [permissions[securityGroups.GroupOperator]]: 3,
  [permissions[securityGroups.GroupUser]]: 4,
}

export const userSecurityGroup = (roles: string[]): string => {
  const securityGroupsHashes = Object.values(securityGroups)
  const userPermissionsHashes = securityGroupsHashes.filter((el) => roles.includes(el))
  if (!userPermissionsHashes.length) return 'NA'
  const uPermissions = userPermissionsHashes.map((p) => permissions[p])
  if (!uPermissions.length) return 'NA'
  uPermissions.sort((a, b) => userRolesHierarchy[a] - userRolesHierarchy[b])
  return uPermissions[0]
}
