/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onChangeAssetLocation = /* GraphQL */ `
  subscription OnChangeAssetLocation($tenant: String!) {
    onChangeAssetLocation(tenant: $tenant) {
      id
      assetId
      deviceId
      lat
      lng
      location
      fencesLocation
      groupsLocation
      analytics
      tenant
      time
      motion
      coords
      createdAt
      updatedAt
    }
  }
`;
export const onChangeNotificationProfile = /* GraphQL */ `
  subscription OnChangeNotificationProfile($tenant: String!) {
    onChangeNotificationProfile(tenant: $tenant) {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotificationProfile = /* GraphQL */ `
  subscription OnDeleteNotificationProfile($tenant: String!) {
    onDeleteNotificationProfile(tenant: $tenant) {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
  }
`;
export const onChangeRule = /* GraphQL */ `
  subscription OnChangeRule($tenant: String!) {
    onChangeRule(tenant: $tenant) {
      id
      notificationProfileId
      notificationProfile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      name
      tenant
      status
      createdBy
      description
      action {
        type
        messageProfileId
      }
      message
      messageId
      scope
      sourceId
      sourceProp
      sourcePropValue
      sourceType
      targetProp
      targetPropValue
      targetId
      targetType
      threshold
      sendTo
      editable
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRule = /* GraphQL */ `
  subscription OnDeleteRule($tenant: String!) {
    onDeleteRule(tenant: $tenant) {
      id
      notificationProfileId
      notificationProfile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      name
      tenant
      status
      createdBy
      description
      action {
        type
        messageProfileId
      }
      message
      messageId
      scope
      sourceId
      sourceProp
      sourcePropValue
      sourceType
      targetProp
      targetPropValue
      targetId
      targetType
      threshold
      sendTo
      editable
      createdAt
      updatedAt
    }
  }
`;
export const onChangeMessageProfile = /* GraphQL */ `
  subscription OnChangeMessageProfile($tenant: String!) {
    onChangeMessageProfile(tenant: $tenant) {
      id
      tenant
      label
      type
      title
      body
      extra
      tags
      createdBy
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessageProfile = /* GraphQL */ `
  subscription OnDeleteMessageProfile($tenant: String!) {
    onDeleteMessageProfile(tenant: $tenant) {
      id
      tenant
      label
      type
      title
      body
      extra
      tags
      createdBy
      status
      createdAt
      updatedAt
    }
  }
`;
export const onChangeNotification = /* GraphQL */ `
  subscription OnChangeNotification($tenant: String!) {
    onChangeNotification(tenant: $tenant) {
      tenant
      assetId
      message
      title
      time
      type
      deviceId
      profileId
      profile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      ruleId
      confirmedBy
      confirmedAt
      closedBy
      closedAt
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification($tenant: String!) {
    onDeleteNotification(tenant: $tenant) {
      tenant
      assetId
      message
      title
      time
      type
      deviceId
      profileId
      profile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      ruleId
      confirmedBy
      confirmedAt
      closedBy
      closedAt
      id
      createdAt
      updatedAt
    }
  }
`;
