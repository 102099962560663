import Dropdown from 'rc-dropdown'
import Menu, { Item as MenuItem, ItemGroup as MenuItemGroup } from 'rc-menu'
import 'rc-dropdown/assets/index.css'
import 'rc-menu/assets/index.css'
import React, { Component } from 'react'
import { setTrue } from 'Store/actions/toggle-action'
import { filterMarkers, setSearch, setFilter } from 'Store/actions/filters-action'
import { withTranslation } from 'react-i18next'
import { regExpEscape } from 'Utils/escape'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Input, Icon } from 'semantic-ui-react'
import { debounce } from 'lodash'

const MenuItemGroupStyled = styled(MenuItemGroup)`
  text-transform: capitalize;
`

class DropdownMenu extends Component {
  constructor(props) {
    super(props)
    this.debounceInput = debounce(this.debounceInput.bind(this), 300)
    this.state = {
      visible: false,
      value: '',
      icon: 'search',
      input: this.props.cercasSelecionadas ? this.props.cercasSelecionadas.toString() : '',
      searchInputTitle: 'DropdownMenuTypeToFilter',
      csvFields: false,
      assetFilter: [],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({ input: this.props.value })
      if (this.state.icon !== 'x' && this.props.value !== '') this.setState({ icon: 'x' })
    }
    if (prevProps.cancelButton && !this.props.cancelButton) {
      if (this.props.value !== '') this.props.filterMarkers('')
      this.setState({ input: '', icon: 'search' })
    }
    if (!prevProps.showHist && this.props.showHist) {
      this.setState({ searchInputTitle: 'DropdownMenuInput' })
    }
    if (prevProps.showHist && !this.props.showHist) {
      this.setState({ searchInputTitle: 'DropdownMenuTypeToFilter' })
    }
    if (prevState.csvFields === true) this.setState({ csvFields: false })
    if (prevState.input !== this.state.input) {
      let assetFilter
      if (this.state.input) {
        assetFilter = Object.values(this.props.assets).filter(
          (i) =>
            //this.props.measures?.[i.id]?.info.coords.lat &&
            i.name.search(new RegExp(regExpEscape(this.state.input), 'i')) !== -1
        )
      } else {
        assetFilter = Object.values(this.props.assets)
      }
      this.setState({ assetFilter: assetFilter })
      this.debounceInput(
        this.state.input,
        assetFilter.map((eachAsset) => ({ id: eachAsset.id?.toString(), type: 'assets' }))
      )
    }
  }

  onVisibleChange = (visible) => {
    this.setState({
      visible,
    })
  }

  selected = []

  saveSelected = ({ selectedKeys, item, key }) => {
    this.setState({ value: item.props.children })
    this.selected = selectedKeys
  }

  inputChange = (i) => {
    this.setState({ input: i.target.value, visible: true })
    if (i.target.value === '') {
      this.props.changeSearch(false)
      this.props.filterMarkers('')
    } else {
      if (this.state.icon !== 'x') this.setState({ icon: 'x' })
      this.props.changeSearch(true)
    }
  }

  searchInputKeydown = (e) => {
    if (e.keyCode === 13) {
      this.props.filterMarkers(this.state.input)
      this.props.setTrue('showFitBounds')
      this.setState({ visible: false })
    }
  }

  menuItemClick = (e) => {
    this.props.setFilter(this.props.value, [{ id: e.key?.toString(), type: 'assets' }])
    this.props.filterMarkers(e.item.props.children, e.item.props.grupo)
    this.props.setTrue('showFitBounds')
    this.setState({ input: this.props.value, visible: false })
  }

  clearInput() {
    this.props.cancelClick()
  }

  debounceInput(input, assetFilter) {
    this.props.setFilter(input, assetFilter)
  }

  render() {
    let { t, assetsTitle, deviceTitle } = this.props
    const assetFilter = this.state.assetFilter
    let menu
    if (this.state.input !== '') {
      menu = (
        <Menu
          //borderless
          style={{ width: this.props.width, border: 'none', boxShadow: 'none' }}
          multiple={this.props.multiple}
          onSelect={this.saveSelected}
          onDeselect={this.saveSelected}
          onClick={this.menuItemClick}
        >
          {assetFilter.length > 0 && (
            <MenuItemGroupStyled title={t(assetsTitle || 'Assets')} key={t('User')}>
              {assetFilter
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((value) => (
                  <MenuItem group={t('Asset')} key={value.id} data={value}>
                    {value.name}
                  </MenuItem>
                ))}
            </MenuItemGroupStyled>
          )}
        </Menu>
      )
    } else
      menu = (
        <Menu
          style={{ width: this.props.width, border: 'none', boxShadow: 'none' }}
          multiple={this.props.multiple}
          onSelect={this.saveSelected}
          onDeselect={this.saveSelected}
        />
      )

    let placeholderDynamic = t('FilterBy')
    assetsTitle === 'People'
      ? (placeholderDynamic = placeholderDynamic + ' ' + t(assetsTitle || 'Assets'))
      : (placeholderDynamic =
          placeholderDynamic + ' ' + t(assetsTitle || 'Assets') + t('Or') + t(deviceTitle || 'Sensors'))

    return (
      <Dropdown
        trigger={['click']}
        onVisibleChange={this.onVisibleChange}
        visible={this.state.visible}
        closeOnSelect={false}
        overlay={menu}
        animation="slide-up"
      >
        <Input
          icon
          fluid
          size="small"
          onKeyDown={this.searchInputKeydown}
          autoComplete="off"
          name="search"
          type="text"
          title={t(this.state.searchInputTitle)}
          length="20"
          maxLength="1000"
          alt={placeholderDynamic}
          placeholder={placeholderDynamic}
          value={this.state.input}
          onChange={this.inputChange}
          disabled={this.props.showHist}
        >
          <Icon name={this.state.icon} link={this.state.icon === 'x'} onClick={() => this.clearInput()} />
          <input />
        </Input>
      </Dropdown>
    )
  }
}
const mapStateToProps = (state) => ({
  assetsTitle: state.projects[0]?.info?.customized?.assetsTitle,
})

const mapDispatchToProps = {
  setTrue,
  filterMarkers,
  setSearch,
  setFilter,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DropdownMenu))
