import React, { useCallback } from 'react'
import NavButtons from './NavButtons'
//import { ReactComponent as Logo } from 'Resources/Icons/mini-logo.svg'
import { ReactComponent as LogoEmpresa } from 'Resources/Icons/logo-empresa.svg'
import { useDispatch } from 'react-redux'
import { toggle } from 'Store/actions/toggle-action'
import styled from 'styled-components'
import { Transition, Icon, Menu } from 'semantic-ui-react'
import TenantLogo from './TenantLogo'
import { connect } from 'react-redux'
import LogoPhygitall from '../LogoPhygitall'

const LogoAmbevSvg = styled(LogoEmpresa)`
  fill: #0b4589;
  max-height: 30px;
  max-width: 185px;
`
const LogoOurs = styled(Menu.Item)`
  position: absolute !important;
  left: 40px;
`

const NavBarTab = (Props) => {
  const dispatch = useDispatch()
  const handleClick = useCallback(() => dispatch(toggle('sideBarLeft')), [dispatch])

  return (
    <Menu secondary>
      <Menu.Item position="left">
        <Icon name="bars" size="big" onClick={handleClick} style={{ color: '#4b72b2' }} />
      </Menu.Item>

      <LogoOurs>
        <LogoPhygitall />
      </LogoOurs>

      {Props.windowWidth > 900 && (
        <Menu.Item style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
          <Transition animation={'scale'} duration={5000} transitionOnMount={true}>
            {Props.company !== 'Ambev' ? <TenantLogo tenant={Props.company} perfil={Props.perfil} /> : <LogoAmbevSvg />}
          </Transition>
        </Menu.Item>
      )}

      <Menu.Item position="right">
        <NavButtons {...Props} />
      </Menu.Item>
    </Menu>
  )
}

const mapStateToProps = (state) => ({
  perfil: state.login.perfil,
})

export default connect(mapStateToProps)(NavBarTab)
