import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ModalHeader from 'Utils/modals/ModalHeader'
import Loading from 'Utils/components/Loading'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Header, Modal, Segment } from 'semantic-ui-react'
import { fetchMeasuresHistory } from 'Store/actions/tables-action'
import m from 'moment'
import { useTranslation } from 'react-i18next'

export const MotionContent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const loading = useSelector((state) => state.tables.byId.motion?.loading)
  const label = useSelector((state) => state.login.preferences.routesPanels.byId.motionChart?.label)
  const motionDataStr = useSelector((state) => JSON.stringify(state.tables.byId.motion?.data || []))
  let motionData = JSON.parse(motionDataStr)
  motionData = motionData.map((e, i) => ({
    ...e,
    formatTime: m(e.time).format('DD/MM HH:mm:ss'),
  }))

  const fetchData = useCallback(() => {
    dispatch(
      fetchMeasuresHistory(
        [
          {
            id: 'relativeTime',
            value: ['2m'],
          },
          { id: 'asset', value: '6054' },
          { id: 'category', value: ['motion'] },
          {
            id: 'measure',
            value: ['ax_mean_right', 'ax_mean_left', 'ay_mean_right', 'ay_mean_left', 'az_mean_right', 'az_mean_left'],
          },
        ],
        'motion'
      )
    )
  }, [dispatch])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return !loading ? (
    <>
      <ModalHeader
        name={label || t('motion')}
        menuButtons={['toggle fullscreen', 'refresh', 'aiModel']}
        noFilter
        fetchAction={() => fetchData()}
        style={{ padding: '0', height: '10vh' }}
      />
      <Modal.Content
        style={{
          overflowY: 'auto',
          height: '70vh',
          border: 'none',
          boxShadow: 'none',
          marginLeft: '2vw',
        }}
      >
        <Header>{t('x_axis') + ' - ' + t('accelerometer')}</Header>
        <Segment style={{ height: '40vh' }}>
          <ResponsiveContainer>
            <LineChart
              data={motionData}
              syncId="anyId"
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="formatTime" minTickGap={20} />
              <YAxis domain={[-6000, 6000]} />
              <Tooltip />
              <Legend verticalAlign="top" height={36} formatter={(value) => t(value)} />
              <Line
                type="monotone"
                dataKey="ax_mean_left"
                stroke="#4bb258"
                fill="#4bb258"
                activeDot={{ r: 8 }}
                connectNulls={true}
                isAnimationActive={false}
              />
              <Line
                type="monotone"
                dataKey="ax_mean_right"
                stroke="#4b72b2"
                fill="#4b72b2"
                activeDot={{ r: 8 }}
                connectNulls={true}
                isAnimationActive={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </Segment>

        <Header>{t('y_axis') + ' - ' + t('accelerometer')}</Header>
        <Segment style={{ height: '32vh' }}>
          <ResponsiveContainer>
            <LineChart
              data={motionData}
              syncId="anyId"
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="formatTime" minTickGap={20} />
              <YAxis domain={[-6000, 6000]} />
              <Tooltip />
              <Legend verticalAlign="top" height={36} formatter={(value) => t(value)} />
              <Line
                type="monotone"
                dataKey="ay_mean_left"
                stroke="#4bb258"
                fill="#4bb258"
                connectNulls={true}
                isAnimationActive={false}
              />
              <Line
                type="monotone"
                dataKey="ay_mean_right"
                stroke="#4b72b2"
                fill="#4b72b2"
                connectNulls={true}
                isAnimationActive={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </Segment>

        <Header>{t('z_axis') + ' - ' + t('accelerometer')}</Header>
        <Segment style={{ height: '32vh' }}>
          <ResponsiveContainer>
            <LineChart
              data={motionData}
              syncId="anyId"
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="formatTime" minTickGap={20} />
              <YAxis domain={[-6000, 6000]} />
              <Tooltip />
              <Legend verticalAlign="top" height={36} formatter={(value) => t(value)} />
              <Line
                type="monotone"
                dataKey="az_mean_left"
                stroke="#4bb258"
                fill="#4bb258"
                connectNulls={true}
                isAnimationActive={false}
              />
              <Line
                type="monotone"
                dataKey="az_mean_right"
                stroke="#4b72b2"
                fill="#4b72b2"
                connectNulls={true}
                isAnimationActive={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </Segment>
      </Modal.Content>
    </>
  ) : (
    <div style={{ marginTop: '35vh' }}>
      <Loading />
      <h4 style={{ textAlign: 'center' }}>{t('loading_icon_message')}</h4>
    </div>
  )
}
