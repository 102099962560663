import React from 'react'
import { Card } from 'semantic-ui-react'
import { SettingCard } from 'Components/Settings'

const staticCards = [
  {
    title: 'Minha conta',
    links: ['Projects', 'Members'],
    goTo: 'account',
    image: 'https://www.shareicon.net/data/128x128/2015/10/08/652737_man_512x512.png',
  },
  {
    title: 'Minha empresa',
    links: ['Projects', 'Members'],
    goTo: 'access-control',
    image: 'https://www.shareicon.net/data/256x256/2017/06/01/886628_building_512x512.png',
  },
  {
    title: 'Controle de acesso',
    links: ['Crachás', 'Logs'],
    goTo: 'organization',
    image:
      'https://alpscontrols.com/prod_data/CatalogIcons/grey/access_controls_and_security_devices-proximity_reader.svg',
  },
]

const SettingsIndex = ({ match }) => (
  <Card.Group>
    {staticCards.map(({ goTo, ...card }) => (
      <SettingCard key={card.title} {...card} goTo={`${match.path}/${goTo}`} />
    ))}
  </Card.Group>
)

export default SettingsIndex
