import React from 'react'
import ReactTable from 'Utils/reactTable'
import { useTranslation } from 'react-i18next'
import { CycleTimeMeasurementColumns } from './columns'
import { useSelector } from 'react-redux'
import { RootStore } from 'Store/initialStore'

const tableName = 'reports'

const data = [
  {
    phase: '1',
    trigger: 'Hand on part',
    operation: 'MP: Pick up part and screw',
    startTime: '0s',
    endTime: '9s',
    duration: '9s',
    cycle: 1,
  },
  {
    phase: '2',
    trigger: 'Hand on the vehicle',
    operation: 'MP: Pick up part and screw',
    startTime: '9s',
    endTime: '25s',
    duration: '16s',
    cycle: 1,
  },
  {
    phase: '3',
    trigger: 'Hand on screwdriver',
    operation: 'MP: Pick up screwdriver',
    startTime: '25s',
    endTime: '29s',
    duration: '4s',
    cycle: 1,
  },
  {
    phase: '4',
    trigger: 'Screwdriver on screw',
    operation: 'MP: Screw',
    startTime: '29s',
    endTime: '37s',
    duration: '8s',
    cycle: 1,
  },
  {
    phase: '5',
    trigger: 'Screwdriver on screw',
    operation: 'Remove screwdriver',
    startTime: '37s',
    endTime: '43s',
    duration: '5s',
    cycle: 1,
  },
  {
    phase: '1',
    trigger: 'Hand on part',
    operation: 'MP: Pick up part and screw',
    startTime: '43s',
    endTime: '51s',
    duration: '8s',
    cycle: 2,
  },
  {
    phase: '2',
    trigger: 'Hand on the vehicle',
    operation: 'MP: Pick up part and screw',
    startTime: '51s',
    endTime: '65s', //01:05min
    duration: '14s',
    cycle: 2,
  },
  {
    phase: '3',
    trigger: 'Hand on screwdriver',
    operation: 'MP: Pick up screwdriver',
    startTime: '65s', //01:05min
    endTime: '70s', //01:10min
    duration: '5s',
    cycle: 2,
  },
  {
    phase: '4',
    trigger: 'Screwdriver on screw',
    operation: 'MP: Screw',
    startTime: '70s', //01:10min
    endTime: '81s', //01:21min
    duration: '11s',
    cycle: 2,
  },
  {
    phase: '5',
    trigger: 'Screwdriver on screw',
    operation: 'Remove screwdriver',
    startTime: '81s', //01:21min
    endTime: '85s', //01:25min
    duration: '4s',
    cycle: 2,
  },
  {
    phase: '1',
    trigger: 'Hand on part',
    operation: 'MP: Pick up part and screw',
    startTime: '85s', //01:25min
    endTime: '95s', //01:35min
    duration: '10s',
    cycle: 3,
  },
  {
    phase: '2',
    trigger: 'Hand on the vehicle',
    operation: 'MP: Pick up part and screw',
    startTime: '95s', //01:35min
    endTime: '108s', //01:48min
    duration: '13s',
    cycle: 3,
  },
  {
    phase: '3',
    trigger: 'Hand on screwdriver',
    operation: 'MP: Pick up screwdriver',
    startTime: '108s', //01:48min
    endTime: '112s', //01:52min
    duration: '4s',
    cycle: 3,
  },
  {
    phase: '4',
    trigger: 'Screwdriver on screw',
    operation: 'MP: Screw',
    startTime: '112s', //01:52min
    endTime: '122s', //02:02min
    duration: '10s',
    cycle: 3,
  },
  {
    phase: '5',
    trigger: 'Screwdriver on screw',
    operation: 'Remove screwdriver',
    startTime: '122s', //02:02min
    endTime: '127s', //02:07min
    duration: '5s',
    cycle: 3,
  },
  {
    phase: '1',
    trigger: 'Hand on part',
    operation: 'MP: Pick up part and screw',
    startTime: '173s', //02:53min
    endTime: '182s', //03:02min
    duration: '9s',
    cycle: 4,
  },
  {
    phase: '2',
    trigger: 'Hand on the vehicle',
    operation: 'MP: Pick up part and screw',
    startTime: '182s', //03:02min
    endTime: '197s', //03:17min
    duration: '15s',
    cycle: 4,
  },
  {
    phase: '3',
    trigger: 'Hand on screwdriver',
    operation: 'MP: Pick up screwdriver',
    startTime: '197s', //03:17min
    endTime: '201s', //03:21min
    duration: '4s',
    cycle: 4,
  },
  {
    phase: '4',
    trigger: 'Screwdriver on screw',
    operation: 'MP: Screw',
    startTime: '201s', //03:21min
    endTime: '216s', //03:36min
    duration: '15s',
    cycle: 4,
  },
  {
    phase: '5',
    trigger: 'Screwdriver on screw',
    operation: 'Remove screwdriver',
    startTime: '216s', //03:36min
    endTime: '221s', //03:41min
    duration: '5s',
    cycle: 4,
  },
  {
    phase: '1',
    trigger: 'Hand on part',
    operation: 'MP: Pick up part and screw',
    startTime: '221s', //03:41min
    endTime: '232s', //03:52min
    duration: '11s',
    cycle: 5,
  },
  {
    phase: '2',
    trigger: 'Hand on the vehicle',
    operation: 'MP: Pick up part and screw',
    startTime: '232s', //03:52min
    endTime: '246s', //04:16min
    duration: '24s',
    cycle: 5,
  },
  {
    phase: '3',
    trigger: 'Hand on screwdriver',
    operation: 'MP: Pick up screwdriver',
    startTime: '246s', //04:16min
    endTime: '252s', //04:22min
    duration: '6s',
    cycle: 5,
  },
  {
    phase: '4',
    trigger: 'Screwdriver on screw',
    operation: 'MP: Screw',
    startTime: '252s', //04:22min
    endTime: '262s', //04:32min
    duration: '10s',
    cycle: 5,
  },
  {
    phase: '5',
    trigger: 'Screwdriver on screw',
    operation: 'Remove screwdriver',
    startTime: '262s', //04:32min
    endTime: '266s', //04:36min
    duration: '4s',
    cycle: 5,
  },
  {
    phase: '1',
    trigger: 'Hand on part',
    operation: 'MP: Pick up part and screw',
    startTime: '266s', //04:36min
    endTime: '275s', //04:45min
    duration: '9s',
    cycle: 6,
  },
  {
    phase: '2',
    trigger: 'Hand on the vehicle',
    operation: 'MP: Pick up part and screw',
    startTime: '275s', //04:45min
    endTime: '290s', //05:00min
    duration: '15s',
    cycle: 6,
  },
  {
    phase: '3',
    trigger: 'Hand on screwdriver',
    operation: 'MP: Pick up screwdriver',
    startTime: '290s', //05:00min
    endTime: '295s', //05:05min
    duration: '5s',
    cycle: 6,
  },
  {
    phase: '4',
    trigger: 'Screwdriver on screw',
    operation: 'MP: Screw',
    startTime: '295s', //05:05min
    endTime: '307s', //05:17min
    duration: '12s',
    cycle: 6,
  },
  {
    phase: '5',
    trigger: 'Screwdriver on screw',
    operation: 'Remove screwdriver',
    startTime: '307s', //05:17min
    endTime: '312s', //05:22min
    duration: '5s',
    cycle: 6,
  },
  {
    phase: '1',
    trigger: 'Hand on part',
    operation: 'MP: Pick up part and screw',
    startTime: '312s', //05:22min
    endTime: '321s', //05:31min
    duration: '9s',
    cycle: 7,
  },
  {
    phase: '2',
    trigger: 'Hand on the vehicle',
    operation: 'MP: Pick up part and screw',
    startTime: '321s', //05:31min
    endTime: '336s', //05:46min
    duration: '15s',
    cycle: 7,
  },
  {
    phase: '3',
    trigger: 'Hand on screwdriver',
    operation: 'MP: Pick up screwdriver',
    startTime: '336s', //05:46min
    endTime: '341s', //05:51min
    duration: '5s',
    cycle: 7,
  },
  {
    phase: '4',
    trigger: 'Screwdriver on screw',
    operation: 'MP: Screw',
    startTime: '341s', //05:51min
    endTime: '351s', //06:01min
    duration: '10s',
    cycle: 7,
  },
  {
    phase: '5',
    trigger: 'Screwdriver on screw',
    operation: 'Remove screwdriver',
    startTime: '351s', //06:01min
    endTime: '351s', //06:05min
    duration: '4s',
    cycle: 7,
  },
  {
    phase: '1',
    trigger: 'Hand on part',
    operation: 'MP: Pick up part and screw',
    startTime: '351s', //06:05min
    endTime: '361s', //06:15min
    duration: '10s',
    cycle: 8,
  },
  {
    phase: '2',
    trigger: 'Hand on the vehicle',
    operation: 'MP: Pick up part and screw',
    startTime: '361s', //06:15min
    endTime: '375s', //06:29min
    duration: '14s',
    cycle: 8,
  },
  {
    phase: '3',
    trigger: 'Hand on screwdriver',
    operation: 'MP: Pick up screwdriver',
    startTime: '375s', //06:29min
    endTime: '378s', //06:32min
    duration: '3s',
    cycle: 8,
  },
  {
    phase: '4',
    trigger: 'Screwdriver on screw',
    operation: 'MP: Screw',
    startTime: '378s', //06:32min
    endTime: '388s', //06:42min
    duration: '10s',
    cycle: 8,
  },
  {
    phase: '5',
    trigger: 'Screwdriver on screw',
    operation: 'Remove screwdriver',
    startTime: '388s', //06:42min
    endTime: '393s', //06:47min
    duration: '5s',
    cycle: 8,
  },
  {
    phase: '1',
    trigger: 'Hand on part',
    operation: 'MP: Pick up part and screw',
    startTime: '393s', //06:47min
    endTime: '402s', //06:56min
    duration: '9s',
    cycle: 9,
  },
  {
    phase: '2',
    trigger: 'Hand on the vehicle',
    operation: 'MP: Pick up part and screw',
    startTime: '402s', //06:56min
    endTime: '416s', //07:10min
    duration: '14s',
    cycle: 9,
  },
  {
    phase: '3',
    trigger: 'Hand on screwdriver',
    operation: 'MP: Pick up screwdriver',
    startTime: '416s', //07:10min
    endTime: '420s', //07:14min
    duration: '4s',
    cycle: 9,
  },
  {
    phase: '4',
    trigger: 'Screwdriver on screw',
    operation: 'MP: Screw',
    startTime: '420s', //07:14min
    endTime: '431s', //07:25min
    duration: '11s',
    cycle: 9,
  },
  {
    phase: '5',
    trigger: 'Screwdriver on screw',
    operation: 'Remove screwdriver',
    startTime: '431s', //07:25min
    endTime: '434s', //07:28min
    duration: '3s',
    cycle: 9,
  },
]

const CycleTimeMeasurementContent = () => {
  const { t } = useTranslation()
  const [currentSort, setCurrentSort] = React.useState('startTime')
  const label = useSelector((state: RootStore) => state.login.preferences.routesPanels.byId.cycleTimeMeasurement?.label)
  const columns = React.useMemo(() => CycleTimeMeasurementColumns(t), [t])

  return (
    <>
      <ReactTable
        table={tableName}
        tableLabel={label || t('cycleTimeMeasurement')}
        columns={columns}
        data={data}
        setCurrentSort={setCurrentSort}
        currentSort={currentSort}
        descSortOrder={false}
      />
    </>
  )
}

export default CycleTimeMeasurementContent
