/*  
  Author: Luís Mourão
  Type: Action
  
  Description: 
    - Ações Dashboard Store
  
  TODO: 
    
*/

import request, { urlBase } from 'Apis/rest'

export const setChart = (payload) => ({
  type: 'SET_CHART',
  payload,
})

export const fetchDashboard =
  (id, token, report = false) =>
  async (dispatch, getState) => {
    const jwt = localStorage.getItem('id_token')
    const dashboards = getState().dashboards
    let fences
    let rules
    const dashInfo = dashboards.byId[dashboards.currentId]

    let params = Object.assign({}, dashInfo.params)
    params.timeAgo = dashboards.timeAgo
    params.token = token
    let company = getState().login.empresa
    //console.log('Parametros: ', params)
    if (['JTI'].includes(company)) params.category = 'proximity'
    try {
      if (id === 'rules') {
        rules = getState().rules.allIds
      } else {
        fences = getState().modules.fences.allIds
      }

      dispatch({ type: 'FETCH_DASHBOARD_START', payload: params, id, fences, rules })
      await request
        .get(`${urlBase}/reports`, {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
          params,
        })
        .then(({ data }) => {
          dispatch({ type: 'FETCH_DASHBOARD_SUCCESS', payload: data, id, fences, rules, token })
          if (data.NextToken) {
            dispatch(fetchDashboard(id, data.NextToken))
          }
        })
      if (report) {
        dispatch(exportFencesDashboardReport())
      }
    } catch (err) {
      console.error(err)
    }
  }

export const exportFencesDashboardReport = () => (dispatch, getState) => {
  const fences = getState().modules.fences
  dispatch({ type: 'EXPORT_FENCES_DASHBOARD_REPORT', fences })
}

export const exportInteractionsDashboardReport = () => (dispatch, getState) => {
  const fences = getState().modules.fences
  dispatch({ type: 'EXPORT_INTERACTIONS_DASHBOARD_REPORT', fences })
}

export const exportRulesDashboardReport = () => (dispatch, getState) => {
  const rules = getState().rules
  dispatch({ type: 'EXPORT_RULES_DASHBOARD_REPORT', rules })
}

export const changeCurrentDashboard = (id) => ({
  type: 'CHANGE_CURRENT_DASHBOARD',
  id,
})

export const changeCurrentDashboardTime = (payload) => ({
  type: 'CHANGE_CURRENT_DASHBOARD_TIME',
  payload,
})

export const setDashboardFilter = (payload) => ({
  type: 'SET_DASHBOARD_FILTER',
  payload,
})

export const setSelectedCard = (id, payload) => ({
  type: 'SET_SELECTED_CARD',
  payload,
  id,
})
