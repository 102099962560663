import React from 'react'
import { useSelector } from 'react-redux'

const TypeName = ({ id }) => {
  const name = useSelector((state) => state.modules?.types?.byId?.[id]?.info?.label)

  return <span>{name || id}</span>
}

export default TypeName
