import React, { memo /*, useState*/ } from 'react'
import { useSelector } from 'react-redux'
import { Menu, Sidebar } from 'semantic-ui-react'
import FormStatsBar from 'Components/StatsBar/FormStatsBar.js'
import FormStatsBarInsert from 'Components/StatsBar/FormStatsBarInsert.js'
import { useRouteMatch } from 'react-router-dom'

const StatsBar = ({ isMobile, sideBarLeft, showHistoryCalendar }) => {
  const show = useSelector((state) => state.toggle.showStatsBar)
  const current = useSelector((state) => state.system.statsbar)
  //*for now with the routes, it hides the Sidebar when other routers other than /app (map) is selected.
  let match = useRouteMatch()
  const go = match?.isExact && match.path === '/app'
  //*Later it can be used for other tables other than the map.
  if (current) current.moduleType = current?.type

  const sideBarStyle = {
    zIndex: 4999,
    background: 'rgba(255,255,255,1)',
    //top: '4px',
    //left: '4px',
    left: sideBarLeft ? (isMobile ? '20vw' : '8vw') : '0vw',
    textColor: 'white',
    maxHeight: '100%',
    width: isMobile ? '80vw' : '20vw',
    minWidth: '282px',
    borderTop: '1px solid rgb(206, 206, 206)',
    paddingTop: '2px',
    height: '100vh !important',
  }

  const sideBarStyleDown = {
    zIndex: 4999,
    background: 'rgba(255,255,255,1)',
    left: sideBarLeft ? (isMobile ? '20vw' : '8vw') : '0vw',
    textColor: 'white',
    maxHeight: '100%',
    width: isMobile ? '80vw' : '20vw',
    minWidth: '300px',
    paddingTop: '46px',
  }
  return (
    show &&
    go && (
      <Sidebar
        as={Menu}
        animation="push"
        icon="labeled"
        inverted
        direction="left"
        vertical
        //onHide={()=>setVisible(false)}
        visible={show}
        style={showHistoryCalendar ? sideBarStyleDown : sideBarStyle}
        width="wide"
      >
        {current?.id ? <FormStatsBar {...current} /> : current?.table && <FormStatsBarInsert {...current} />}
      </Sidebar>
    )
  )
}
export default memo(StatsBar)
