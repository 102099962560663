import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Segment, Icon } from 'semantic-ui-react'
//import { setFalse } from 'Store/actions/toggle-action'
import { useTranslation } from 'react-i18next'
import { useCanAccess } from 'Utils/rbac'
import StatsBarActionsSearch from './StatsBarActionsSearch'
import StatsBarActionsDelete from './StatsBarActionsDelete'
import { StatsBarActionsEdit } from './StatsBarActionsEdit'
import { changeLoginPreferences } from 'Store/actions/login-action'
const StatsBarActions = ({ id, moduleType, table }) => {
  const canAccess = useCanAccess('WRITE')
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const invisible = useSelector((state) => state.login.preferences[moduleType][id]?.invisible)
  return (
    <Segment style={{ width: '100%', bottom: '64px', position: 'absolute', border: 'none', alignItems: 'left' }}>
      <StatsBarActionsSearch moduleType={moduleType} id={id} />
      {canAccess && <StatsBarActionsEdit id={id} moduleType={moduleType} table={table} />}
      {canAccess && <StatsBarActionsDelete id={id} moduleType={moduleType} />}
      <Icon
        id="icon-eye"
        name={!invisible ? 'eye' : 'eye slash'}
        title={t(!invisible ? 'EyesHide' : 'EyesShow')}
        size="large"
        circular
        style={{ backgroundColor: '#4b72b2', color: 'white', cursor: 'pointer' }}
        onClick={() =>
          dispatch(changeLoginPreferences({ field: moduleType, preferences: { [id]: { id, invisible: !invisible } } }))
        }
      />
    </Segment>
  )
}
export default StatsBarActions
