/*  Author: Luís Mourão
    Type: Smart
	Description: 
	TODO: - Tentar Usar Componente Genérico com as partes iguais dos Módulos
*/

import React, { Component } from 'react'
import { Dropdown, Icon, Grid, Input, Popup, Label } from 'semantic-ui-react'
import getSafe from 'Utils/getSafe.js'
import fitBounds from 'Utils/fitBounds.js'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { changeLoginPreferences } from 'Store/actions/login-action'
import styled from 'styled-components'
import { setCurrentModule } from 'Store/actions/modules-action'
import { regExpEscape } from 'Utils/escape'

const DropdownMenu = styled(Dropdown.Menu)`
  border-top-width: 0px !important;
`
const DropdownGridInput = styled(Grid)`
  width: 217px;
  padding-left: 10px;
`

const DropdownStyled = styled(Dropdown)`
  color: #4b72b2;
  opacity: 1;
  z-index: 210 !important;
`
const DropdownGridHeader = styled(Grid)`
  width: 217px;
  padding-left: 10px;
  margin: 0px !important;
`
const DropdownHeader = styled(Dropdown.Header)`
  font-size: 1.33em;
  font-weight: 800;
`

const DropdownGridItem = styled(Grid)`
  width: 400px;
`
const DropdownGridName = styled(Grid.Column)`
  padding: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 14px;
  padding-right: 0;
`

const ItemEyeGrid = styled(Grid.Column)`
  padding: 10px !important;
  left: 5px;
`

const DropdownInputGrid = styled(Grid.Column)``

const DropdownDivider = styled(Dropdown.Divider)`
  margin-bottom: 10px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
`
const spaceType = { icon: 'shield', name: 'Referências' }

class DropdownRefs extends Component {
  state = {
    hideAll: false,
    searchValue: '',
  }

  componentWillUnmount() {
    this.handleBlur()
  }

  itemClick(id) {
    this.props.setCurrentModule(id, 'refs')
    const coords = getSafe(() => this.props.refs.byId[id].info.geometry.coordinates, null)
    if (coords) fitBounds('refs', coords, this.props.refMap)
  }

  iconClick(id, invisible) {
    this.props.changeLoginPreferences({ field: 'refs', preferences: { [id]: { id, invisible } } })
  }

  headerIconClick(all) {
    let newState = {}
    let preferences = {}
    if (Array.isArray(all) && all.length > 0) {
      all.forEach((e) => {
        const invisible = this.props.refsPreferences[e.id]?.invisible || false
        if (this.state.hideAll === invisible) {
          preferences[e.id] = {
            id: e.id,
            invisible: !this.state.hideAll,
          }
        }
      })
    }

    newState.hideAll = !this.state.hideAll

    this.props.changeLoginPreferences({ field: 'refs', preferences })
    this.setState({ ...newState })
  }
  handleBlur = () => {
    this.props.setCurrentModule(null, 'refs')
    this.setState({ open: false })
  }

  handleSearch = (event, data) => {
    this.setState({ searchValue: data.value })
  }

  render() {
    const sortMapRoutesRefs = (par) => {
      if (Array.isArray(par) && par.length > 0) {
        let res = []
        par
          .sort((prev, next) => {
            if (prev.info?.properties?.label)
              return prev.info.properties.label.localeCompare(next.info?.properties?.label)
            else return 0
          })
          .forEach((e, i) => {
            const invisible = this.props.refsPreferences[e.id]?.invisible ? true : false
            if (!i)
              res.push(
                <DropdownGridHeader className="header" key={spaceType.name}>
                  <DropdownGridName width={12}>
                    <DropdownHeader key={spaceType.name} icon={spaceType.icon} content={spaceType.name} />
                  </DropdownGridName>
                </DropdownGridHeader>
              )
            if (e.info?.properties?.label) {
              res.push(
                <Dropdown.Item value={e.id} key={e.id}>
                  <DropdownGridItem>
                    <DropdownGridName onClick={() => this.itemClick(e.id)} width={13} title={e.info.properties.label}>
                      {e.info.properties.label}
                    </DropdownGridName>
                    <ItemEyeGrid width={3} textAlign="right" style={{ left: '7px' }}>
                      <Icon
                        onClick={() => this.iconClick(e.id, !invisible)}
                        name={invisible ? 'eye slash' : 'eye'}
                        title={invisible ? t('EyesShow') : t('EyesHide')}
                        size="small"
                      />
                    </ItemEyeGrid>
                  </DropdownGridItem>
                </Dropdown.Item>
              )
            }
          })
        return res
      } else return null
    }

    const { refs, perfil, t } = this.props
    const { searchValue } = this.state
    let refsList = refs
      ? refs.allIds
          .filter((id) => getSafe(() => !refs.byId[id].info.properties.invisible, true) || perfil === 'DEV')
          .map((each) => refs.byId[each])
      : []
    let filtered = refsList.filter(
      (data) =>
        data.info.properties.label &&
        data.info.properties.label.search(new RegExp(regExpEscape(searchValue), 'i')) !== -1
    )
    return (
      <DropdownStyled
        trigger={
          <Popup
            trigger={
              <Icon
                name="map marker alternate"
                size="large"
                onClick={() => this.setState({ open: !this.state.open })}
              />
            }
            content={t('DropdownMenuRefs')}
            position="bottom center"
            size="tiny"
            inverted
          />
        }
        icon={null}
        style={{ pointerEvents: 'auto' }}
        pointing="top right"
        onBlur={() => this.setState({ open: false })}
        open={this.state.open}
        disabled={refsList.length ? false : true}
      >
        <Dropdown.Menu>
          <DropdownGridInput className="header">
            <DropdownInputGrid width={13}>
              <Input
                onChange={(e, d) => this.handleSearch(e, d)}
                icon
                iconPosition="left"
                placeholder={t('DropdownMenuRefs') + '...'}
                size="large"
                style={{ width: '150px' }}
              >
                <Icon name="map marker alternate" />
                <input />
              </Input>
            </DropdownInputGrid>
            <ItemEyeGrid width={3} textAlign="right" floated="right" style={{ bottom: '1px' }}>
              <Label size="mini" style={{ marginLeft: '42px', marginTop: '-8px' }}>
                <Icon
                  onClick={() => this.headerIconClick(filtered)}
                  name={this.state.hideAll ? 'eye slash' : 'eye'}
                  size="small"
                  title={this.state.hideAll ? t('EyesAllShow') : t('EyesAllHide')}
                  style={{ fontSize: '1.50em', cursor: 'pointer' }}
                />
                <Label.Detail>
                  <span style={{ fontSize: '1.9em' }}> {this.state.hideAll ? t('EyesAllShow') : t('EyesAllHide')}</span>
                </Label.Detail>
              </Label>
            </ItemEyeGrid>
          </DropdownGridInput>
          <DropdownDivider />
          <DropdownMenu scrolling>
            {filtered.length > 0 ? sortMapRoutesRefs(filtered, 'refs') : <Dropdown.Item text={t('Norefs')} disabled />}
          </DropdownMenu>
        </Dropdown.Menu>
      </DropdownStyled>
    )
  }
}

const mapDispatchToProps = {
  setCurrentModule: setCurrentModule,
  changeLoginPreferences,
}

const mapStateToProps = (state) => ({
  refs: state.modules.refs,
  refsPreferences: state.login.preferences.refs,
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DropdownRefs))
