import React from 'react'
import { useIsAuthenticated } from '@azure/msal-react'
import { Redirect } from 'react-router-dom'
//import Callback from 'Callback/Callback'

function AuthWrapper({ children, auth }) {
  const isAuthenticatedMsal = useIsAuthenticated()
  const isAuthenticatedAuth0 = auth.isAuthenticated()
  //console.log(isAuthenticatedAuth0)
  if (isAuthenticatedAuth0 === isAuthenticatedMsal) {
    return <Redirect to="/login" />
  }

  return <>{children}</>
}
export default AuthWrapper
