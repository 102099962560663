/*  Author: Bruno Melo
    Type: Smart
	Description: Edit values from the data. Calibration data 
	TODO: -
*/

import React from 'react'
import styled from 'styled-components'
import { Grid, Icon, Checkbox /*, Input */ } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { PopupInfoEditInput } from './PopupInfoEditInput.jsx'
import { toggleChannel } from 'Store/actions/sensors-action'
//import { SET_CALIBRATION_TRANSFORMATION } from 'Store/actions/sensors-action'
import { useSelector, useDispatch } from 'react-redux'

const InfoField = styled(Grid.Row)`
  min-height: 19px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`
const IconStyled = styled(Icon)`
  cursor: pointer;
  margin-bottom: 5px;
`
export const PopupInfoEdit = (props) => {
  const { data } = props
  const { t } = useTranslation()
  const [editMode, setEditMode] = React.useState(false)
  const expectedChannels = useSelector((state) => state.sensors.byId?.[props.sensor]?.info?.expectedChannels || {})
  const [currentChecked, setCurrentCheck] = React.useState(expectedChannels[data.campo] ?? !!data.valor.props.children)
  const dispatch = useDispatch()

  //// const [changes, setChanges] = React.useState({})

  //console.log(calibration)
  const handleCancel = () => {
    setEditMode(false)
  }

  const handleToggleChange = (campo, sensor, value) => {
    setCurrentCheck(value)
    let params = { campo, value, sensor }
    dispatch(toggleChannel(params))
  }

  const handleConfirm = async () => {
    alert('Essa feature não está mais disponível')
    setEditMode(false)
  }

  //const handleChange = (e, f) => {
  //e.target.value.replace("'","")
  //this.setState({[e.target.name]:e.target.value})
  //}

  const label = data.campo
  //let inputSize = 'small'
  return !editMode ? (
    <InfoField title={data.title} key={'infoeditfieldstitle' + data.campo}>
      <Grid.Column width={12}>
        <span>{'   '}</span>
        {data.icon ? <Icon title={label} name={data.icon} /> : <span>{label}</span>}
        <span>{' : '}</span>
        <span>{data.valor}</span>
      </Grid.Column>
      <Grid.Column width={3} floated="right">
        {data.buttons?.name === 'toggle' ? (
          <Checkbox
            toggle
            checked={currentChecked}
            title={'Clique para Ativar/Desativar'}
            onChange={(c, { checked }) => {
              handleToggleChange(data.campo, props.sensor, checked)
            }}
          />
        ) : (
          <IconStyled
            title={data.buttons?.title}
            name={data.buttons?.name}
            size="large"
            onClick={() => setEditMode(!editMode)}
          />
        )}
      </Grid.Column>
    </InfoField>
  ) : (
    <InfoField title={data.title} key={'infoeditfieldstitle' + data.campo}>
      <Grid.Column width={12}>
        <PopupInfoEditInput value={label} icon="tags" />
        <PopupInfoEditInput label="Unidade" example="Ex:m, cm, °C, etc" />
        <PopupInfoEditInput label="Ya" left />
        <PopupInfoEditInput label="Xa" />
        <PopupInfoEditInput label="Yb" left />
        <PopupInfoEditInput label="Xb" />

        {/*<span>{'       '}</span>
            <InputStyledAlone name='label' size = {inputSize} icon='tags' iconPosition='left' placeholder={defaultLabel} value={label} 
                              onChange={this.handleChange}/>
            <br/>*/
        /*
            <InputStyledRight name='outputMin' type='number' label='Xa' size={inputSize}  placeholder={defaultOutputMin} 
                              value={outputMin} min={0} onChange={this.handleChange}/>
            <br/>
            <InputStyledLeft name='inputMax' type='number' label='Yb' size={inputSize} placeholder={defaultInputMax}             
                              value={inputMax} min={0} onChange={this.handleChange}/>
            <InputStyledRight name='outputMax'  type='number' label='Xb' size={inputSize} placeholder={defaultOutputMax}                              
                              value={outputMax} min={0} onChange={this.handleChange}/>*/}
      </Grid.Column>
      <Grid.Column width={4} floated="right">
        {/*<InputStyledRight name='unit'  type='number' label='Grandeza' size={inputSize} placeholder={defaultOutputMax}                              
                              value={outputMax} min={0} onChange={this.handleChange}/>*/}
        <IconStyled size={'large'} title={t('Confirm')} name={'check'} color={'green'} onClick={handleConfirm} />
        <IconStyled size={'large'} title={t('Cancel')} name={'cancel'} color={'red'} onClick={handleCancel} />
      </Grid.Column>
    </InfoField>
  )
}

export default PopupInfoEdit
