import { combineReducers } from 'redux'
import assets from './assets-reducer'
import cards from './cards-reducer'
import filters from './filters-reducer'
import history from './history-reducer'
import indoor from './indoor-reducer'
import login from './login-reducer'
import notification from './notification-reducer'
import people from './people-reducer'
import toggle from './toggle-reducer'
import sensors from './sensors-reducer'
import assetTypes from './assetTypes-reducer'
import signals from './signals-reducer'
import models from './models-reducer'
import reftypes from './reftypes-reducer'
import projects from './projects-reducer'
import heatmap from './heatmap-reducer'
import modules from './modules-reducer'
import messages from './messages-reducer'
import system from './system-reducer'
import accessControl from './access-control-reducer'
import tenants from './tenant-reducer'
import rules from './rules-reducer'
import interactions from './interactions-reducer'
import dashboards from './dashboards-reducer'
import dashboardStellantis from './dashboardStellantis-reducer'
import notificationProfiles from './notificationProfiles-reducer'
import measures from './measures-reducer'
import tables from './tables-reducer'
import locationHist from './locationHist-reducer'

const reducers = combineReducers({
  accessControl,
  assets,
  assetTypes,
  cards,
  dashboards,
  dashboardStellantis,
  filters,
  heatmap,
  history,
  indoor,
  interactions,
  locationHist,
  login,
  measures,
  messages,
  models,
  modules,
  notification,
  notificationProfiles,
  people,
  projects,
  reftypes,
  rules,
  sensors,
  signals,
  system,
  tables,
  tenants,
  toggle,
})

export default reducers
