/*  
  Author: Luís Mourão
  Type: Reducer
  
  Description: 
    - 
    - 
	
  TODO: 
*/

export default function dashboardReducer(state = {}, { type, id, payload }) {
  switch (type) {
    case 'FETCH_DASHBOARD_STELLANTIS':
      //console.log('FETCH_DASHBOARD', payload)
      return {
        cards: payload.cardData,
        charts: {
          data: payload.cycleData,
          selected: 'ciclo',
        },
      }

    case 'SET_CHART_STELLANTIS':
      return { ...state, charts: { ...state.charts, selected: payload } }

    default:
      return state
  }
}
