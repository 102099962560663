import React from 'react'
import { Segment, Icon, Header, Grid } from 'semantic-ui-react'
import StatsBarImage from 'Components/StatsBar/StatsBarImage.js'
import { useTranslation } from 'react-i18next'

const StatsBarHeader = ({ name, url = './images/noImage.png', cancelAction }) => {
  const { t } = useTranslation()

  return (
    <Segment>
      <Grid columns={16} style={{ width: '25vw' }}>
        <Grid.Column style={{ width: '19vw' }}>
          <Header as="h3">
            <StatsBarImage name={name} url={url} />
            <span> {name?.slice(0, 30)}</span>
          </Header>
        </Grid.Column>
        <Grid.Column style={{ width: '3vw' }}>
          <Icon
            title={t('Close')}
            name="close"
            position="right"
            onClick={() => cancelAction()}
            style={{ right: 0, cursor: 'pointer' }}
          />
        </Grid.Column>
      </Grid>
    </Segment>
  )
}
export default StatsBarHeader
