import React from 'react'
import { useSelector } from 'react-redux'
import { Icon } from 'semantic-ui-react'

export const FencesAssets = ({ fenceName, fenceId }) => {
  const allActivePeopleIds = useSelector((state) => state.assets.allActivePeopleIds)
  const measuresStr = useSelector((state) => JSON.stringify(state.measures.byId))

  const measures = JSON.parse(measuresStr)

  let fenceInfo = {}

  fenceInfo[fenceId] = {
    name: fenceName,
    count: 0,
    id: fenceId,
  }

  Object.entries(measures)
    .filter((e) => {
      return allActivePeopleIds.includes(e[0])
    })
    .forEach((each) => {
      const locationAnalytics = each[1]?.info?.fencesLocation
      if (locationAnalytics) {
        Object.keys(locationAnalytics).forEach((eachLoc) => {
          if (fenceInfo[eachLoc]) {
            fenceInfo[eachLoc].count += 1
          }
        })
      }
    })

  return (
    <span>
      (<Icon name="user" color={fenceInfo[fenceId].count > 0 ? 'red' : 'green'} />
      {fenceInfo[fenceId].count}) -{' '}
    </span>
  )
}
export default FencesAssets
