import request, { urlBase } from 'Apis/rest'
import axios from 'axios'
// import userInfoIntegration from 'Apis/integrations'
// import { getAssetIdDetails } from 'Apis/getAssetIdDetails'

export const deletePeople = (ids) => async (dispatch) => {
  dispatch({ type: 'DELETE_PEOPLE_ACTION_START' })
  try {
    const jwt = localStorage.getItem('id_token')
    const headers = {
      headers: { Authorization: 'Bearer ' + jwt },
    }
    axios.delete(urlBase + `/assets?ids=${ids}`, headers).then((res) => {
      axios.post(`${urlBase}/devices/totemfiles`, {}, headers).then((res) => {
        //console.log(res)
        dispatch({ type: 'UPDATE_TOTEM_FILES', payload: res })
      })
      dispatch({ type: 'DELETE_ASSETS', payload: ids })
      dispatch({ type: 'DELETE_PEOPLE_ACTION_SUCCESS' })
    })
  } catch (err) {
    dispatch({ type: 'DELETE_PEOPLE_ACTION_FAILURE' })
  }
}

export const updatePerson = (id, changes) => async (dispatch) => {
  dispatch({ type: 'UPDATE_PEOPLE_ACTION_START' })
  dispatch({ type: 'UPDATE_ASSET_REQUEST', id })
  try {
    const jwt = localStorage.getItem('id_token')
    const body = { id: id, changes: changes }
    const headers = { headers: { Authorization: 'Bearer ' + jwt } }
    //console.log('Ação salvar grupo, parametros:',body)
    request.put(`${urlBase}/assets`, body, headers).then((res) => {
      request.post(`${urlBase}/devices/totemfiles`, {}, headers).then((res) => {
        //console.log(res)
        dispatch({ type: 'UPDATE_TOTEM_FILES', payload: res })
      })
      if (res.data.statusCode === 200 && res.data.body) {
        request.get(urlBase + '/assets/', headers).then((res) => dispatch({ type: 'FETCH_ASSETS', payload: res.data }))
        dispatch({ type: 'UPDATE_ASSET_NAME_SUCCESSFULL', id: id, payload: res.data.body })
      }
      dispatch({ type: 'UPDATE_PEOPLE_ACTION_SUCCESS' })
    })
  } catch (err) {
    dispatch({ type: 'UPDATE_ASSET_FAILURE', payload: err })
    dispatch({ type: 'UPDATE_PEOPLE_ACTION_FAILURE', payload: err })
  }
}

export const insertPeople = (body) => async (dispatch) => {
  dispatch({ type: 'INSERT_PEOPLE_ACTION_START' })

  try {
    const jwt = localStorage.getItem('id_token')
    const headers = { headers: { Authorization: 'Bearer ' + jwt } }
    //console.log('Ação salvar grupo',actiontype,' parametros:',body)

    axios.post(`${urlBase}/assets/`, body, headers).then((res) => {
      //console.log(res)
      dispatch({ type: 'INSERT_ASSETS', payload: res })
      dispatch({ type: 'INSERT_PEOPLE_ACTION_SUCCESS' })
      axios.post(`${urlBase}/devices/totemfiles`, {}, headers).then((res) => {
        //console.log(res)
        dispatch({ type: 'UPDATE_TOTEM_FILES', payload: res })
      })
    })
  } catch (err) {
    console.error('Error in saveGroup', err)
    dispatch({ type: 'INSERT_PEOPLE_ACTION_FAILURE', payload: err })
  }
}
