import React from 'react'
//import TimeAbsolute from 'Utils/components/TimeAbsolute'

/*  Author: Felipe
	Description: Funções para montar o popup dinamicamente				 
	To do list: -	
*/

export const jsonGroup = {
  gps: {
    getIcon: () => 'map signs',
    levelAccess: 'DEV',
  },
  health: {
    getIcon: () => 'heartbeat',
    levelAccess: 'basic',
  },
  battery: {
    getIcon: (o, g) => handleStatusBattery(o, g),
    levelAccess: 'basic',
  },
  debug: {
    getIcon: () => 'bug',
    levelAccess: 'DEV',
  },
  interaction: {
    getIcon: () => 'users',
    levelAccess: 'basic',
  },
  proximity: {
    getIcon: () => 'arrows alternate horizontal',
    levelAccess: 'DEV',
  },
  beacons: {
    getIcon: () => 'h',
    levelAccess: 'DEV',
  },
  rfid: {
    getIcon: () => 'tags',
    levelAccess: 'DEV',
  },
  analytics: {
    getIcon: () => 'chart line',
    levelAccess: 'DEV',
  },
  dnaspaces: {
    getIcon: () => 'bug',
    levelAccess: 'basic',
  },
  model_info: {
    getIcon: () => 'microchip',
    levelAccess: 'basic',
  },
  motion: {
    getIcon: () => 'street view',
    levelAccess: 'basic',
  },
  telemetry: {
    getIcon: () => 'file audio outline',
    levelAccess: 'basic',
  },
  i2v: {
    getIcon: () => 'exchange',
    levelAccess: 'basic',
  },
  i2vinputs: {
    getIcon: () => 'arrow up',
    levelAccess: 'DEV',
  },
  calibration: {
    getIcon: () => 'cogs',
    levelAccess: 'DEV',
  },
  dnaSpaces: {
    getIcon: () => 'lightbulb outline',
    levelAccess: 'basic',
  },
  gauge: {
    getIcon: () => 'cogs',
    levelAccess: 'basic',
  },
  coords: {
    getIcon: () => 'map marker alternate',
    levelAccess: 'basic',
    fields: ['lat', 'lng'],
  },
  lora: {
    getIcon: () => 'sellsy',
    levelAccess: 'basic',
  },
  wifi: {
    getIcon: () => 'wifi',
    levelAccess: 'basic',
  },
  connectivity: {
    getIcon: () => 'wifi',
    levelAccess: 'basic',
  },
  default: {
    getIcon: () => 'help circle',
    levelAccess: 'basic',
  },
}

const handleStatusBattery = (obj, grupo) => {
  try {
    if (obj[grupo]?.charging === 'Unknown') return 'battery full'
    if (obj[grupo]?.charging === 'True') return 'bolt'
    let number = obj[grupo]?.lastBatLevel
    if (number >= 85) return 'battery full'
    if (number >= 65) return 'battery high'
    if (number >= 35) return 'battery half'
    if (number >= 15) return 'battery low'
    return 'battery empty'
  } catch (e) {
    return 'battery empty'
  }
}

export const upsideButtons = (info, perfil) =>
  info
    ? Object.keys(info)
        .sort()
        .filter(
          (grupo) =>
            jsonGroup[grupo] && (jsonGroup[grupo].levelAccess === perfil || jsonGroup[grupo].levelAccess === 'basic')
        )
        .map((grupo) => ({
          grupo,
          icone: jsonGroup[grupo].getIcon(info, grupo),
        }))
    : null

export const infoField = (info, t, group) => {
  return info
    ? Object.entries(info).map((pair) => {
        let [field, value] = pair
        if (suppressibleFields.includes(field)) return null
        if (translatableValues.includes(value)) value = t(value)
        let valor = typeof value === 'object' ? JSON.stringify(value) : value
        //TODO: generalizar linha abaixo
        if (field === 'charging') {
          field = 'Status'
          valor = valor === 'Unknown' ? t('Unknown') : valor === 'True' ? t('charging') : t('batteryUsing')
        }
        return {
          campo: noTranlationKeys.includes(field) ? field : t(field),
          //getIcon: jsonValues[campo] ? jsonValues[campo].icon : undefined,
          title: valor,
          valor: <span>{valor}</span>,
          buttons:
            group === 'i2v' &&
            (['DO4', 'DO5', 'DO6', 'DO7'].includes(field)
              ? { name: 'toggle', title: 'Clique para Ativar/Desativar essa saída' }
              : { name: 'cog', title: t('NavButtonsConfig') }),
        }
      })
    : null
}

const translatableValues = ['Not connected']

const suppressibleFields = [
  'hdop',
  'action',
  'latA',
  'lngA',
  'latB',
  'lngB',
  'latC',
  'lngC',
  'latD',
  'lngD',
  'latP',
  'lngP',
  'latG',
  'lngG',
  'antpath',
]

const noTranlationKeys = [
  'wifi_enable',
  'debug',
  'raw',
  'payload',
  'fport',
  'devEUI',
  'velocidade',
  'precisao',
  'deepsleep_charging',
  'deepsleep',
  'reset',
  'lora_version_msg',
  'mpu_odometer_enable',
  'timestamp_en',
  'enable_fw_ota',
  'id',
  'id_dec',
  'wifi_connected',
  'timeout_gps',
  'devEui',
]
