import { API } from 'aws-amplify'
import { listNotificationProfiles } from 'graphql/queries'
import { createNotificationProfile, updateNotificationProfile, deleteNotificationProfile } from 'graphql/mutations'
import { onChangeNotificationProfile, onDeleteNotificationProfile } from 'graphql/subscriptions'
import { omit } from 'lodash'

export const cleanNotificationsProfiles = () => ({
  type: 'CLEAN_NOTIFICATION_PROFILES',
})

export const fetchNotificationProfiles = () => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  try {
    dispatch({ type: 'FETCH_NOTIFICATION_PROFILES_START', tenant })
    const response = await API.graphql({
      query: listNotificationProfiles,
      variables: { tenant },
    })
    const { items } = response.data.listNotificationProfiles
    dispatch({
      type: 'FETCH_NOTIFICATION_PROFILES',
      payload: items,
    })
  } catch (err) {
    console.error('error fetching notification Profiles', err)
  }
}

export const saveNotificationProfile =
  ({ type, fields }) =>
  async (dispatch, getState) => {
    const tenant = getState().login.empresa
    let input = {
      ...fields,
      txtColor: fields.txtColor || 'black',
      id: fields.name ? `${tenant}#${fields.name}` : fields.id,
      tenant,
      createdBy: getState().login.email,
      icon: fields.icon ? fields.icon : null,
    }
    input = omit(input, ['createdAt', 'updatedAt'])
    dispatch({ type: 'SAVE_NOTIFICATION_PROFILE_START', input, subscription: type })
    try {
      await API.graphql({
        query: type === 'create' ? createNotificationProfile : updateNotificationProfile,
        variables: {
          input,
        },
      })
    } catch (err) {
      console.error('error:', err)
    }
  }

export const subscribeNotificationProfileChange = () => (dispatch, getState) => {
  const tenant = getState().login.empresa
  return API.graphql({
    query: onChangeNotificationProfile,
    variables: {
      tenant,
    },
  }).subscribe({
    next: ({ provider, value }) => {
      const payload = value.data.onChangeNotificationProfile
      dispatch({ type: 'SAVE_NOTIFICATION_PROFILE', payload, id: payload.id })
    },
    error: (error) => console.warn('error', error),
  })
}

export const subscribeNotificationProfileDelete = () => (dispatch, getState) => {
  const tenant = getState().login.empresa
  return API.graphql({
    query: onDeleteNotificationProfile,
    variables: {
      tenant,
    },
  }).subscribe({
    next: ({ provider, value }) => {
      const data = value.data.onDeleteNotificationProfile
      dispatch({ type: 'DELETE_NOTIFICATION_PROFILE', id: data.id })
    },
    error: (error) => console.warn('error', error),
  })
}

export const deleteNotificationProfiles =
  ({ ids }) =>
  async (dispatch, getState) => {
    for (const id of ids) {
      try {
        dispatch({ type: 'DELETE_NOTIFICATION_PROFILE_START', id })
        await API.graphql({
          query: deleteNotificationProfile,
          variables: {
            input: {
              id,
            },
          },
        })
        dispatch({ type: 'DELETE_NOTIFICATION_PROFILE', id })
      } catch (err) {
        console.error('error:', err)
      }
    }
  }
