/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAssetLocation = /* GraphQL */ `
  mutation CreateAssetLocation(
    $input: CreateAssetLocationInput!
    $condition: ModelAssetLocationConditionInput
  ) {
    createAssetLocation(input: $input, condition: $condition) {
      id
      assetId
      deviceId
      lat
      lng
      location
      fencesLocation
      groupsLocation
      analytics
      tenant
      time
      motion
      coords
      createdAt
      updatedAt
    }
  }
`;
export const updateAssetLocation = /* GraphQL */ `
  mutation UpdateAssetLocation(
    $input: UpdateAssetLocationInput!
    $condition: ModelAssetLocationConditionInput
  ) {
    updateAssetLocation(input: $input, condition: $condition) {
      id
      assetId
      deviceId
      lat
      lng
      location
      fencesLocation
      groupsLocation
      analytics
      tenant
      time
      motion
      coords
      createdAt
      updatedAt
    }
  }
`;
export const deleteAssetLocation = /* GraphQL */ `
  mutation DeleteAssetLocation(
    $input: DeleteAssetLocationInput!
    $condition: ModelAssetLocationConditionInput
  ) {
    deleteAssetLocation(input: $input, condition: $condition) {
      id
      assetId
      deviceId
      lat
      lng
      location
      fencesLocation
      groupsLocation
      analytics
      tenant
      time
      motion
      coords
      createdAt
      updatedAt
    }
  }
`;
export const createNotificationProfile = /* GraphQL */ `
  mutation CreateNotificationProfile(
    $input: CreateNotificationProfileInput!
    $condition: ModelNotificationProfileConditionInput
  ) {
    createNotificationProfile(input: $input, condition: $condition) {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationProfile = /* GraphQL */ `
  mutation UpdateNotificationProfile(
    $input: UpdateNotificationProfileInput!
    $condition: ModelNotificationProfileConditionInput
  ) {
    updateNotificationProfile(input: $input, condition: $condition) {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotificationProfile = /* GraphQL */ `
  mutation DeleteNotificationProfile(
    $input: DeleteNotificationProfileInput!
    $condition: ModelNotificationProfileConditionInput
  ) {
    deleteNotificationProfile(input: $input, condition: $condition) {
      id
      name
      tenant
      bgColor
      status
      screenTime
      icon
      description
      level
      titleColor
      bodyColor
      actionColor
      dateColor
      txtColor
      createdBy
      theme
      iconColor
      sound
      createdAt
      updatedAt
    }
  }
`;
export const createRule = /* GraphQL */ `
  mutation CreateRule(
    $input: CreateRuleInput!
    $condition: ModelRuleConditionInput
  ) {
    createRule(input: $input, condition: $condition) {
      id
      notificationProfileId
      notificationProfile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      name
      tenant
      status
      createdBy
      description
      action {
        type
        messageProfileId
      }
      message
      messageId
      scope
      sourceId
      sourceProp
      sourcePropValue
      sourceType
      targetProp
      targetPropValue
      targetId
      targetType
      threshold
      sendTo
      editable
      createdAt
      updatedAt
    }
  }
`;
export const updateRule = /* GraphQL */ `
  mutation UpdateRule(
    $input: UpdateRuleInput!
    $condition: ModelRuleConditionInput
  ) {
    updateRule(input: $input, condition: $condition) {
      id
      notificationProfileId
      notificationProfile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      name
      tenant
      status
      createdBy
      description
      action {
        type
        messageProfileId
      }
      message
      messageId
      scope
      sourceId
      sourceProp
      sourcePropValue
      sourceType
      targetProp
      targetPropValue
      targetId
      targetType
      threshold
      sendTo
      editable
      createdAt
      updatedAt
    }
  }
`;
export const deleteRule = /* GraphQL */ `
  mutation DeleteRule(
    $input: DeleteRuleInput!
    $condition: ModelRuleConditionInput
  ) {
    deleteRule(input: $input, condition: $condition) {
      id
      notificationProfileId
      notificationProfile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      name
      tenant
      status
      createdBy
      description
      action {
        type
        messageProfileId
      }
      message
      messageId
      scope
      sourceId
      sourceProp
      sourcePropValue
      sourceType
      targetProp
      targetPropValue
      targetId
      targetType
      threshold
      sendTo
      editable
      createdAt
      updatedAt
    }
  }
`;
export const createMessageProfile = /* GraphQL */ `
  mutation CreateMessageProfile(
    $input: CreateMessageProfileInput!
    $condition: ModelMessageProfileConditionInput
  ) {
    createMessageProfile(input: $input, condition: $condition) {
      id
      tenant
      label
      type
      title
      body
      extra
      tags
      createdBy
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageProfile = /* GraphQL */ `
  mutation UpdateMessageProfile(
    $input: UpdateMessageProfileInput!
    $condition: ModelMessageProfileConditionInput
  ) {
    updateMessageProfile(input: $input, condition: $condition) {
      id
      tenant
      label
      type
      title
      body
      extra
      tags
      createdBy
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageProfile = /* GraphQL */ `
  mutation DeleteMessageProfile(
    $input: DeleteMessageProfileInput!
    $condition: ModelMessageProfileConditionInput
  ) {
    deleteMessageProfile(input: $input, condition: $condition) {
      id
      tenant
      label
      type
      title
      body
      extra
      tags
      createdBy
      status
      createdAt
      updatedAt
    }
  }
`;
export const createLoginPreferences = /* GraphQL */ `
  mutation CreateLoginPreferences(
    $input: CreateLoginPreferencesInput!
    $condition: ModelLoginPreferencesConditionInput
  ) {
    createLoginPreferences(input: $input, condition: $condition) {
      tenant
      login
      center {
        lat
        lng
      }
      zoom
      mapLayers
      refs
      fences
      routes
      indoor
      tables
      routesPanels
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateLoginPreferences = /* GraphQL */ `
  mutation UpdateLoginPreferences(
    $input: UpdateLoginPreferencesInput!
    $condition: ModelLoginPreferencesConditionInput
  ) {
    updateLoginPreferences(input: $input, condition: $condition) {
      tenant
      login
      center {
        lat
        lng
      }
      zoom
      mapLayers
      refs
      fences
      routes
      indoor
      tables
      routesPanels
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteLoginPreferences = /* GraphQL */ `
  mutation DeleteLoginPreferences(
    $input: DeleteLoginPreferencesInput!
    $condition: ModelLoginPreferencesConditionInput
  ) {
    deleteLoginPreferences(input: $input, condition: $condition) {
      tenant
      login
      center {
        lat
        lng
      }
      zoom
      mapLayers
      refs
      fences
      routes
      indoor
      tables
      routesPanels
      id
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      tenant
      assetId
      message
      title
      time
      type
      deviceId
      profileId
      profile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      ruleId
      confirmedBy
      confirmedAt
      closedBy
      closedAt
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      tenant
      assetId
      message
      title
      time
      type
      deviceId
      profileId
      profile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      ruleId
      confirmedBy
      confirmedAt
      closedBy
      closedAt
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      tenant
      assetId
      message
      title
      time
      type
      deviceId
      profileId
      profile {
        id
        name
        tenant
        bgColor
        status
        screenTime
        icon
        description
        level
        titleColor
        bodyColor
        actionColor
        dateColor
        txtColor
        createdBy
        theme
        iconColor
        sound
        createdAt
        updatedAt
      }
      ruleId
      confirmedBy
      confirmedAt
      closedBy
      closedAt
      id
      createdAt
      updatedAt
    }
  }
`;
