import React, { memo } from 'react'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import { useSelector } from 'react-redux'
import { FeatureGroup, Polyline } from 'react-leaflet'
//import { useCanAccess } from 'Utils/rbac'
import CustomTooltip from 'Utils/components/CustomTooltip'

export const RenderCreationRoute = ({ wip, click }) => {
  let geoJson = wip?.geoJson
  const tooltipText = wip['form-name']
  const typeId = wip['form-type']
  const typeData = useSelector((state) => state.modules.types?.byId[parseInt(typeId)]?.info) || {}
  const coordinates = geoJson.geometry.coordinates || []

  const color = typeData?.color ? typeData.color : 'blue'
  const inner = useSelector((state) => state.toggle.showInnerMap)
  const currentInner = useSelector((state) => state.indoor.current?.inner)
  //const canAccess = useCanAccess('WRITE')
  return (
    <FeatureGroup key={'routeCreation'}>
      <Polyline
        positions={coordinates.map((e) =>
          inner ? innerPosition([e[1], e[0]], 'array', currentInner?.center, currentInner?.bearing) : [e[1], e[0]]
        )}
        weight="2"
        dashArray="3, 6"
        dashOffset="0"
        color="gray"
      >
        {
          <CustomTooltip permanent direction="bottom">
            {tooltipText}
          </CustomTooltip>
        }
      </Polyline>
      <Polyline
        positions={coordinates.map((e) =>
          inner ? innerPosition([e[1], e[0]], 'array', currentInner?.center, currentInner?.bearing) : [e[1], e[0]]
        )}
        weight="2"
        dashArray="3, 6"
        dashOffset="6"
        color={typeData?.color ? typeData.color : color ? color : 'blue'}
      />
      <CustomTooltip permanent direction="bottom">
        {tooltipText}
      </CustomTooltip>
    </FeatureGroup>
  )
}

export default memo(RenderCreationRoute)
