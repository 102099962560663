import { deleteFile } from 'react-s3'
import { configs3 } from 'Utils/constantes'
import { handleDeleteFile } from 'Utils/database'
import innerMeasures from 'Utils/innerMeasures'
import produce from 'immer'

export default function indoorReducer(state = {}, { type, id, payload, company }) {
  let items = state.items ? [...state.items] : []
  switch (type) {
    case 'indoorInitialStore':
      if (payload === null) {
        console.error('Error in indoor initial store')
        return state
      }
      if (!payload[0]) {
        console.info('No indoor map in store!')
        return state
      }
      if (!payload[0].indoor) {
        console.info("Indoor's store empty!")
        return state
      }
      if (typeof payload[0] !== 'object' || Object.keys(payload[0]).length === 0) return state
      items = [...payload[0].indoor]
      if (Array.isArray(items) && items.length > 0) {
        items = items.map((e) => {
          return {
            ...e,
            info: {
              ...e.info,
              properties: {
                ...e.info?.properties,
                spaceType: 'unidade',
              },
            },
          }
        })
      }
      return { ...state, items }

    case 'indoorNew':
      let x = state.items.length
      state.items[x] = payload
      return { ...state }

    case 'updateindoorNew':
      return produce(state, (newState) => {
        Object.entries(state).forEach((data) => {
          if (payload.id === data[1].id) {
            newState[data[0]] = payload
          }
        })
      })

    case 'deleta imagem': {
      const items = state.items.filter(({ id }) => id !== payload.id)
      const filename = payload.url.split('/')[5]
      deleteFile(filename, configs3)
        .then((response) => handleDeleteFile(payload.id))
        .catch((err) => console.error(err))
      return { ...state, items }
    }

    case 'seleciona imagem': {
      const selectedItem = state.items.find(({ id }) => id === payload.id)
      return { ...state, selectedItem }
    }

    case 'atualizaIndoor':
      return produce(state, (newState) => {
        Object.entries(state).forEach((data) => {
          if (id === data[1].id) {
            switch (payload.campo) {
              case 'Nome':
                newState[data[0]].name = payload.valor
                break
              case 'Campos':
                newState[data[0]].info.corners = payload.valor
                break
              default:
            }
          }
        })
      })
    case 'SET_CURRENT_INDOOR':
      if (!id) return { ...state, current: { inner: state.current.inner } }
      return produce(state, (newState) => {
        let index = state.items.findIndex((el) => el.id === id)
        let item = state.items[index]
        newState.current = item
        if (!item.inner) {
          let measures = innerMeasures(item.info.corners, company, item)
          newState.items[index].inner = measures
          newState.current.inner = measures
        }
      })

    default:
      return state
  }
}
