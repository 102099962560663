// DO NOT TOUCH
// """documentation""" : https://jsfiddle.net/m792qtys/
// Main Issue Thread: https://github.com/facebook/react/issues/3926

// The externalVal is slow to update, which causes the IME composition to
// be interrupted. To compensate, when composing, we use a local
// localValue to update the controlled value quickly. This doesn't
// interrupt the IME composition.
// However, we still rely on the externalVal, so we switch back to it when the
// composition has ended.

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { RootStore } from '../Store/initialStore'

interface IMEInputInterface {
  externalVal: string
  externalOnChange: (val: string) => void
  toggleFluid?: boolean
  externalPlaceholder?: string
  toggleDisabled?: boolean
  externalName?: string
  externalIcon?: string
  externalWidth?: string
}

export default function IMEInput({
  externalVal,
  externalOnChange,
  toggleFluid,
  externalPlaceholder,
  toggleDisabled,
  externalName,
  externalIcon,
  externalWidth,
}: IMEInputInterface) {
  const { t } = useTranslation()

  const formIsOpen = useSelector((state: RootStore) => state.tables.current.showModal)

  const [localVal, setLocalVal] = useState('')
  const [isComposing, setIsComposing] = useState(false)

  const onCompositionStart = (e: any) => {
    // update local on start
    setLocalVal(externalVal)
    setIsComposing(true)
  }

  const onCompositionEnd = (e: any) => {
    setIsComposing(false)
    // update external and filter on end
    externalOnChange(localVal)
  }

  const handleChange = (e: any) => {
    const temp = e.target.value
    setLocalVal(temp)
    if (!isComposing) {
      externalOnChange(temp)
    }
  }

  return (
    <Input
      fluid={toggleFluid || false}
      name={externalName || ''}
      autoFocus={formIsOpen ? false : true}
      value={isComposing ? localVal : externalVal}
      icon={externalIcon ? externalIcon : ''}
      iconPosition={externalIcon ? 'left' : undefined}
      size="large"
      onChange={handleChange}
      onCompositionStart={onCompositionStart}
      onCompositionEnd={onCompositionEnd}
      placeholder={externalPlaceholder || t('Search')}
      style={{ width: externalWidth ? externalWidth : '17vw', minWidth: '130px' }}
      disabled={toggleDisabled || false}
    />
  )
}
