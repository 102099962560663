import { API } from 'aws-amplify'
import { createMessageProfile, deleteMessageProfile, updateMessageProfile } from 'graphql/mutations'
import { listMessageProfiles } from 'graphql/queries'

export const fetchMessages = () => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  try {
    dispatch({ type: 'FETCH_MESSAGES_START' })
    const response = await API.graphql({
      query: listMessageProfiles,
      variables: { tenant },
    })
    const { items } = response.data.listMessageProfiles
    dispatch({
      type: 'FETCH_MESSAGES_SUCCESS',
      payload: items,
    })
  } catch (err) {
    console.error('error fetching rules', err)
  }
}

export const createMessage = (fields) => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  let input = {
    ...fields,
    id: `${tenant}#${fields.id}`,
    tenant,
  }
  dispatch({ type: 'CREATE_MESSAGE_START' })
  try {
    const { data, errors } = await API.graphql({
      query: createMessageProfile,
      variables: {
        input,
      },
    })
    if (errors?.length > 0) {
      dispatch({ type: 'CREATE_MESSAGE_FAILURE' })
      return
    }
    dispatch({ type: 'CREATE_MESSAGE_SUCCESS', payload: data.createMessageProfile })
  } catch (err) {
    console.error('error:', err)
    dispatch({ type: 'CREATE_MESSAGE_FAILURE', error: err?.message || 'Ocorreu um erro inesperado' })
  }
}

export const updateMessage = (id, fields) => async (dispatch, getState) => {
  const tenant = getState().login.empresa
  let input = {
    ...fields,
    tenant,
    id,
  }
  dispatch({ type: 'UPDATE_MESSAGE_START' })
  try {
    const { data, errors } = await API.graphql({
      query: updateMessageProfile,
      variables: {
        input,
      },
    })
    if (errors?.length > 0) {
      dispatch({ type: 'UPDATE_MESSAGE_FAILURE' })
      return
    }
    dispatch({ type: 'UPDATE_MESSAGE_SUCCESS', payload: data.updateMessageProfile, id /* subscription: type */ })
  } catch (err) {
    console.error('error:', err)
    dispatch({ type: 'UPDATE_MESSAGE_FAILURE', error: err?.message || 'Ocorreu um erro inesperado' })
  }
}

export const deleteMessage =
  ({ ids }) =>
  async (dispatch) => {
    for (const id of ids) {
      try {
        dispatch({ type: 'DELETE_MESSAGE_START', id })
        await API.graphql({
          query: deleteMessageProfile,
          variables: {
            input: {
              id,
            },
          },
        })
        dispatch({ type: 'DELETE_MESSAGE_SUCCESS', id })
      } catch (err) {
        console.error('error:', err)
      }
    }
  }
