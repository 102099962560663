import produce from 'immer'

export default function sensorsReducer(state = [], { type, id, payload }) {
  let errorTypes = ['sensorNew', 'updatesensorNew', 'updatedeletesensorNew']

  let byId = { ...state.byId }
  let allIds = state.allIds ? [...state.allIds] : []
  let current = { ...state.current }

  if (errorTypes.includes(type)) {
    if (payload === null) {
      console.error(type + ' null: ', id, payload)
      return state
    }
    if (!id && !payload.id) {
      console.error(type + ' without ID: ', id, payload)
      return state
    }
    if (!payload.info) {
      console.error(type + ' without info', id, payload)
      return state
    }
  }
  switch (type) {
    case 'FETCH_DEVICES':
      if (payload === null || payload.success === false) {
        console.error('Error in store initial store')
        return state
      }
      if (!payload[0]) {
        console.warn('No sensors in store!')
        return state
      }
      if (!payload[0].sensors) {
        console.warn("Sensor's store empty!")
        return state
      }
      return produce(state, (newState) => {
        newState.allIds = []
        newState.byId = {}
        newState.groups = []
        newState.current = {}
        newState.loading = false
        newState.success = true
        let sensors = payload[0].sensors
        if (Array.isArray(sensors) && sensors.length > 0) {
          sensors.forEach((e) => {
            newState.byId[e.id] = e
            newState.allIds.push(e.id)
            e.groups &&
              e.groups.forEach((group) => {
                if (!newState.groups.includes(group)) newState.groups.push(group)
              })
          })
        }
      })

    case 'sensorNew':
      byId[id] = payload
      allIds.push(id)
      return { ...state, byId, allIds }

    case 'updatesensorNew':
      byId[id] = {
        ...state.byId[id],
        ...payload,
      }
      return { ...state, byId }

    case 'updatedeletesensorNew':
      allIds = allIds.filter((e) => e !== id)
      return { ...state, allIds }

    case 'SET_CURRENT_ASSET':
      current = byId[id]
      return { ...state, current }
    case 'UPDATE_SENSOR_START':
      return { ...state, cudLoading: true }
    case 'UPDATE_SENSOR_SUCCESS':
      byId[id] = {
        ...state.byId[id],
        ...payload,
      }
      return { ...state, byId, cudLoading: false }
    case 'PROVISION_DEVICE_START':
      return { ...state, cudLoading: true, errors: undefined, success: undefined }
    case 'PROVISION_DEVICE_SUCCESS':
      //allIds = [...state.allIds, payload.sensorId]
      console.log(`state.byId`, state.byId)
      return { ...state, /*allIds, */ cudLoading: false, errors: undefined, success: true }
    case 'PROVISION_DEVICE_ERROR':
      return { ...state, cudLoading: false, errors: payload.message, success: undefined }
    case 'SENSOR_CLEAR_STATES':
      return { ...state, errors: undefined, success: undefined, loading: false }
    case 'FETCH_DEVICES_START':
      return { ...state, loading: true }
    case 'CLEAR_DEVICES':
      return {
        ...state,
        byId: {},
        allIds: [],
        current: {},
        loading: false,
        success: undefined,
        errors: undefined,
      }
    default:
      return state
  }
}
