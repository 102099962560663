import React from 'react'
import { SignInWithAzure } from './SignInWithAzure'
import { SignInWithAuth0 } from './SignInWithAuth0'
import { Button, Segment, Header } from 'semantic-ui-react'
import history from 'Utils/history'
//@ts-ignore
import styled from 'styled-components'
import { useEffect } from 'react'
import { useIsAuthenticated } from '@azure/msal-react'

export const Login = ({ auth }) => {
  const isAuthenticatedMsal = useIsAuthenticated()
  useEffect(() => {
    if (isAuthenticatedMsal) {
      history.replace('/')
    }
  }, [isAuthenticatedMsal])
  return (
    <LoginWrapper>
      <LoginMain>
        <LoginSection>
          <Segment placeholder raised>
            <Header as="h2" textAlign="center">
              Entrar na plataforma PHYGITALL
            </Header>
            <Button.Group>
              {window.location.hostname === 'beta.phygitall.com.br' && <SignInWithAzure />}
              {window.location.hostname === 'beta.phygitall.com.br' && <Button.Or />}
              <SignInWithAuth0 auth={auth} />
            </Button.Group>
          </Segment>
        </LoginSection>
      </LoginMain>
    </LoginWrapper>
  )
}

const LoginSection = styled.section`
  display: flex;
  flex-flow: column;
  align-items: stretch;
`
const LoginMain = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

const LoginWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
  padding: 0 16px;
  height: 100%;
  backgroundcolor: #fafafa;
`
