/*  
  Author: Luís Mourão
  Type: Function
  
  Description: 
    - 
    - 
  TODO: 
*/

export const exportReport = (exportData, fileName = 'report', t, typeName, m) => {
  let csvContent = ''
  let needFormat = []
  let row
  if (!exportData) return null
  exportData[0].forEach((col, i) => {
    if (['Criado em', 'Última vez Visto', 'Consentimento Em', 'Início', 'Fim'].includes(col))
      needFormat.push({ index: i, type: 'date' })
    if (['Cor'].includes(col)) needFormat.push({ index: i, type: 'translate' })
    if (['Escopo'].includes(col)) needFormat.push({ index: i, type: 'scope' })
    if (['Status'].includes(col)) needFormat.push({ index: i, type: 'onOff' })
    if (['Atribuição'].includes(col)) needFormat.push({ index: i, type: 'typeName' })
    if (['Ações'].includes(col)) needFormat.push({ index: i, type: 'actionType' })
    if (['Mensagem'].includes(col)) needFormat.push({ index: i, type: 'messageAction' })
    if (['Latitude', 'Longitude'].includes(col)) needFormat.push({ index: i, type: 'latitude' })
    if (['Hora'].includes(col)) needFormat.push({ index: i, type: 'dateIsoToBrDate' })
    if (['Caixas_levantadas'].includes(col)) needFormat.push({ index: i, type: 'floatToInt' })
  })

  exportData.forEach((rowArray, i) => {
    rowArray.forEach((v) => {
      if (typeof v === 'string') v = v.replace(/\n/g, ' ').replace(';', '.').replace(/\r/g, ' ')
    })
    if (needFormat.length > 0 && i !== 0) {
      needFormat.forEach((format) => {
        if (format.type === 'date') {
          let newDate = new Date(rowArray[format.index]).toLocaleString()
          rowArray[format.index] = newDate === 'Invalid Date' ? '' : newDate
        }
        if (format.type === 'dateIsoToBrDate') {
          let formatDate =
            rowArray[format.index].substring(0, 4) +
            '-' +
            rowArray[format.index].substring(4, 6) +
            '-' +
            rowArray[format.index].substring(6, 8) +
            ' ' +
            rowArray[format.index].substring(9, 11) +
            ':' +
            rowArray[format.index].substring(11, 13) +
            ':' +
            rowArray[format.index].substring(13, 15) +
            '+00'
          let newDate = new Date(formatDate).toLocaleString()
          rowArray[format.index] = newDate
        }
        if (format.type === 'floatToInt') {
          rowArray[format.index] = parseInt(rowArray[format.index])
        }
        if (format.type === 'latitude') {
          rowArray[format.index] =
            '-' + rowArray[format.index].substring(0, 2) + '.' + rowArray[format.index].substring(2)
        }
        if (format.type === 'onOff') {
          rowArray[format.index] = ['on', 'true'].includes(rowArray[format.index])
            ? 'Habilitado'
            : ['off', 'false'].includes(rowArray[format.index])
            ? 'Desabilitado'
            : rowArray[format.index]
        }
        if (format.type === 'scope') {
          switch (rowArray[format.index]) {
            case 'in':
              rowArray[format.index] = 'Entrada'
              break
            case 'out':
              rowArray[format.index] = 'Saída'
              break
            case 'inout':
              rowArray[format.index] = 'Entrada/Saída'
              break
            default:
              rowArray[format.index] = ''
          }
        }
        if (format.type === 'typeName' && typeof typeName === 'function') {
          rowArray[format.index] = typeName(rowArray[format.index])
        }
        if (format.type === 'actionType') {
          rowArray[format.index] = rowArray[format.index]?.map((a) => t(a.type))
        }
        if (format.type === 'messageAction') {
          rowArray[format.index] = JSON.stringify(rowArray[format.index]) //rowArray[format.index]?.map((a) => t(a.type))
        }
      })
    }

    row = rowArray.join(';')
    csvContent += row + '\r\n'
  })

  var encodedUri = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent?.replaceAll('#', ' '))
  var link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', fileName + '.csv')
  document.body.appendChild(link)

  link.click()
}

export default exportReport
