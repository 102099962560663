import React from 'react'
import ErrorBoundary from 'Utils/ErrorBoundary/ErrorBoundary.js'
import ReactTableTemplate from './ReactTableTemplate.js'

export const ReactTable = (props) => {
  return (
    <ErrorBoundary>
      <ReactTableTemplate {...props} data={props.data} />
    </ErrorBoundary>
  )
}

export default ReactTable
