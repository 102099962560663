/*  Author: Luís Mourão / Felipe Pegado
    Type: Smart
	Description: 
    TODO: 
    - Criar componente dumb grid com o conteúdo do return(render)
    - Colocar mesma lógica do Current para Dropdown de Rotas e Referências
*/

import React, { Component } from 'react'
import { Dropdown, Icon, Grid, Input, Popup /*, Label */ } from 'semantic-ui-react'
import getSafe from 'Utils/getSafe.js'
import fitBounds from 'Utils/fitBounds.js'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { setTrue, setFalse } from 'Store/actions/toggle-action'
import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
import styled from 'styled-components'
import { setCurrentModule } from 'Store/actions/modules-action'
import MapMethods from 'Utils/components/MapMethods'
import { debounce } from 'throttle-debounce'
import { regExpEscape } from 'Utils/escape'
import { broadcastMessage } from 'Store/actions/notification-action'
import { FencesAssets } from 'Utils/components/FencesAssets'
import { FencesEmergencyButton } from 'Utils/components/FencesEmergencyButton'
import { playAudio } from 'Utils/playAudio'

//import m from 'moment'

const DropdownStyled = styled(Dropdown)`
  color: #4b72b2;
`
const DropdownMenu = styled(Dropdown.Menu)`
  border-top-width: 0px !important;
`
const DropdownGridInput = styled(Grid)`
  width: 367px;
  padding-left: 10px;
`
const DropdownGridHeader = styled(Grid)`
  width: 367px;
  padding-left: 10px;
  margin: 0px !important;
`
const DropdownHeader = styled(Dropdown.Header)`
  font-size: 1.33em;
  font-weight: 800;
`
const DropdownGridItem = styled(Grid)`
  width: 400px;
`
const DropdownGridName = styled(Grid.Column)`
  padding: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 14px;
  padding-right: 0;
`

const ItemEyeGrid = styled(Grid.Column)`
  padding: 5px !important;
`
const DropdownInputGrid = styled(Grid.Column)``
const DropdownDivider = styled(Dropdown.Divider)`
  margin-bottom: 10px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
`

class DropdownEmergency extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Área: false,
      Equipamento: false,
      fences: false,
      fitBounds: null,
      searchValue: '',
      open: false,
      iconMouseHover: false,
      selectedItemId: null,
    }
    this.debounceCurrent = debounce(300, this.menuItemHover)
  }

  componentWillUnmount() {
    this.handleBlur()
  }

  itemClick(id, type) {
    let coords = getSafe(() => this.props[type].byId[id].info.geometry.coordinates, null)
    let radius = getSafe(() => this.props[type].byId[id].info.geometry.mRadius, null)
    this.props.setCurrentModule(id, type)
    if (coords) {
      if (this.props.inner) coords = innerPosition(coords, 'coordinates')
      if (!radius) fitBounds(type, coords, this.props.refMap)
      else this.setState({ fitBounds: { type, coords, radius } })
    }
  }

  audio = new Audio('./evacuation.mp3')

  iconClick = async (id, type, assets, emergencyMode, emergencyType) => {
    let fenceName = id ? this.props[type].byId[id].name : 'Planta Geral'
    let fence = id
      ? { name: fenceName, ...this.props[type].byId[id].info.properties }
      : { name: fenceName, label: fenceName }
    let params = {
      type: emergencyType || 'evacuation',
      context: (emergencyMode ? 'Stop ' : 'Start ') + (id ? 'Local' : 'General'),
      assetIds: assets, //this.props.allActiveIds,
      fence: fence,
    }
    this.setState({ clicked: true })

    emergencyMode ? this.audio.pause() : playAudio(this.audio, true)
    await this.props.broadcastMessage(params)
  }

  handleBlur = () => {
    this.props.setCurrentModule(null, 'fences')
    this.setState({ open: false })
  }

  menuItemHover = (id, type) => {
    this.props.setCurrentModule(id, type)
  }

  handleSearch = (event, data) => {
    this.setState({ searchValue: data.value })
  }

  handleMouseLeave = () => {
    this.setState({ iconMouseHover: false, selectedItemId: null })
  }

  render() {
    const { fences, perfil, t, empresa, allActiveIds } = this.props
    const { searchValue } = this.state
    const spaceTypes = [
      { icon: 'shield', name: 'Área' },
      { icon: 'configure', name: 'Equipamento' },
    ]

    const sortMapFences = (par) => {
      if (Array.isArray(par) && par.length > 0) {
        let res = []
        spaceTypes.forEach((spaceType, j, allST) => {
          par
            .filter((fence) => getSafe(() => fence.info.properties.spaceType === spaceType.name, false))
            .sort((prev, next) => prev.info.properties.label.localeCompare(next.info.properties.label))
            .forEach((e, i, all) => {
              if (!i)
                res.push(
                  <DropdownGridHeader className="header" key={spaceType.name}>
                    <DropdownGridName width={14}>
                      <DropdownHeader
                        key={spaceType.name}
                        icon={spaceType.icon}
                        content={
                          ['TiplamTest', 'Tiplam', 'Phygitall'].includes(empresa)
                            ? 'Emergência por Perímetro'
                            : t(spaceType.name)
                        }
                      />
                    </DropdownGridName>
                  </DropdownGridHeader>
                )
              res.push(
                <Dropdown.Item value={e.id} key={spaceType.name + e.id}>
                  <DropdownGridItem
                    id="DropdownGridItem"
                    onMouseEnter={() => {
                      this.debounceCurrent(e.id, 'fences')
                      this.setState({ iconMouseHover: true, selectedItemId: e.id })
                    }}
                    onMouseLeave={() => this.handleMouseLeave()}
                  >
                    <DropdownGridName
                      onClick={() => this.itemClick(e.id, 'fences')}
                      width={10}
                      title={e.info.properties.label}
                    >
                      <FencesAssets fenceName={e.name} fenceId={e.id} />
                      {e.info.properties.label}
                    </DropdownGridName>
                    <ItemEyeGrid width={6} textAlign="right" style={{ left: '2px' }}>
                      <FencesEmergencyButton
                        id={e.id}
                        emergencyType="seek_shelter"
                        fenceName={e.name}
                        fenceLabel={e.info.properties.label}
                        iconClick={this.iconClick}
                        icon={
                          this.state.selectedItemId === e.id && this.state.iconMouseHover
                            ? 'selected lightning'
                            : 'default lightning'
                        }
                        title={t('seek_shelter')}
                      />
                      <FencesEmergencyButton
                        id={e.id}
                        fenceName={e.name}
                        emergencyType="emergency"
                        fenceLabel={e.info.properties.label}
                        iconClick={this.iconClick}
                        icon={
                          this.state.selectedItemId === e.id && this.state.iconMouseHover
                            ? 'selected emergency'
                            : 'default emergency'
                        }
                        title={t('emergency')}
                      />
                      <FencesEmergencyButton
                        id={e.id}
                        emergencyType="evacuation"
                        fenceName={e.name}
                        fenceLabel={e.info.properties.label}
                        iconClick={this.iconClick}
                        icon={
                          this.state.selectedItemId === e.id && this.state.iconMouseHover
                            ? 'selected arrow right'
                            : 'default arrow right'
                        }
                        title={t('evacuation')}
                      />
                    </ItemEyeGrid>
                  </DropdownGridItem>
                </Dropdown.Item>
              )
            })
          if (j + 1 < allST.length) res.push(<DropdownDivider key={'dpFencesDivEnd' + j} />)
        })

        return res
      } else return null
    }

    let fencesList = fences
      ? fences.allIds
          .filter((id) => getSafe(() => !fences.byId[id].info.properties.invisible, true) || perfil === 'DEV')
          .map((each) => fences.byId[each])
      : []
    let filtered = fencesList.filter(
      (data) =>
        data?.info?.properties?.label &&
        data.info.properties.label.search(new RegExp(regExpEscape(searchValue), 'i')) !== -1
    )
    return (
      <DropdownStyled
        trigger={
          <Popup
            trigger={<Icon name="bullhorn" size="large" onClick={() => this.setState({ open: !this.state.open })} />}
            content="Emergência"
            position="bottom center"
            size="tiny"
            inverted
          />
        }
        icon={null}
        pointing="top right"
        onBlur={this.handleBlur}
        style={{ pointerEvents: 'auto' }}
        open={this.state.open}
        disabled={fencesList.length ? false : true}
      >
        <Dropdown.Menu>
          <DropdownGridInput className="header">
            <DropdownInputGrid width={10} /*style={{ border: '1px red solid' }}*/>
              <Input
                onChange={(e, d) => this.handleSearch(e, d)}
                icon
                iconPosition="left"
                placeholder={
                  ['TiplamTest', 'Tiplam', 'Phygitall'].includes(empresa)
                    ? 'Perímetros'
                    : t('DropdownMenuFences') + '...'
                }
                size="large"
                style={{ width: '500px' }}
              >
                <Icon name="bullhorn" />
                <input />
              </Input>
            </DropdownInputGrid>
            <Grid.Column
              width={6}
              textAlign="right"
              floated="right"
              //compact
              style={{ bottom: '1px' /*, marginTop: '-10px'*/ }}
            >
              {/*<Label size="mini" style={{ marginLeft: '-10px', marginTop: '-10px' }}>
                <Label.Detail>*/}
              <span style={{ fontSize: '1.9em', marginTop: '20px' }}> Geral: </span>
              {/* </Label.Detail>
              </Label>*/}
              {/* <FencesEmergencyButton
                allActiveIds={allActiveIds}
                iconClick={this.iconClick}
                fenceLabel={'Total'}
                style={{ fontSize: '1.10em', cursor: 'pointer' }}
              />*/}
              <FencesEmergencyButton
                allActiveIds={allActiveIds}
                emergencyType="seek_shelter"
                fenceLabel={'Total'}
                iconClick={this.iconClick}
                style={{ fontSize: '1.50em', cursor: 'pointer' }}
                icon={'default lightning'}
                title={'Incidência de Raios: Alarme Geral'}
              />
              <FencesEmergencyButton
                allActiveIds={allActiveIds}
                emergencyType="emergency"
                fenceLabel={'Total'}
                iconClick={this.iconClick}
                style={{ fontSize: '1.50em', cursor: 'pointer' }}
                icon={'default emergency'}
                title={'Chuva e ventos fortes: Alarme Geral'}
              />
              <FencesEmergencyButton
                allActiveIds={allActiveIds}
                emergencyType="evacuation"
                fenceLabel={'Total'}
                iconClick={this.iconClick}
                style={{ fontSize: '1.50em', cursor: 'pointer' }}
                icon={'default arrow right'}
                title={'Abandono de Área Geral'}
              />
            </Grid.Column>
          </DropdownGridInput>
          <DropdownDivider />
          <DropdownMenu scrolling>
            {filtered.length > 0 ? sortMapFences(filtered) : <Dropdown.Item text={t('NoFences')} disabled />}
          </DropdownMenu>
          {this.state.fitBounds && (
            <MapMethods type="circle" fit={this.state.fitBounds} cb={() => this.setState({ fitBounds: null })} />
          )}
        </Dropdown.Menu>
      </DropdownStyled>
    )
  }
}

const mapStateToProps = (state) => ({
  inner: state.toggle.showInnerMap,
  fences: state.modules.fences,
  empresa: state.login.empresa,
  allActiveIds: state.assets.allActivePeopleIds,
})

const mapDispatchToProps = {
  setTrue: setTrue,
  setFalse: setFalse,
  setCurrentModule: setCurrentModule,
  broadcastMessage: broadcastMessage,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DropdownEmergency))
