import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Icon, Card, Statistic, Menu } from 'semantic-ui-react'
import { SvgIcon } from './SvgIcon.jsx'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

const IconLabel = styled('div')`
  font-weight: 700;
  font-size: medium;
`

const MenuIconLabel = styled('div')`
  font-weight: 800;
  font-size: small;
`

const Monospaced = styled('div')`
  font-family: monospace, monospace;
  white-space: pre-wrap;
`

export const CardDevices = ({ otherCard }) => {
  const appsTotal = useSelector((state) => state.assets?.allActiveAppIds?.length || 0)
  const sbTotal = useSelector((state) => state.assets?.allActiveSbIds?.length || 0)
  const tenant = useSelector((state) => state.tenants.current)
  const storeCardsDevices = useSelector((state) => state.cards.byId.devices, shallowEqual)
  const hasFeature = tenant.features?.includes('app')
  const appFloatingLicenses = tenant?.info?.appFloatingLicenses || 0
  const charging = storeCardsDevices.statusCounter?.charging?.sb || 0
  const available = storeCardsDevices.statusCounter?.available?.sb || 0
  const disconnected = (storeCardsDevices?.typesTotal?.sb?.counter || 0) - available - charging
  const iconCounters = {
    inUse: { app: appsTotal, sb: sbTotal },
    charging: { app: '----', sb: charging },
    available: { app: '----', sb: available },
    disconnected: { app: appFloatingLicenses - appsTotal, sb: disconnected },
  }

  const { t } = useTranslation()

  const title = storeCardsDevices.title

  const typesIds = storeCardsDevices.typesIds

  const histType = useSelector((state) => state.history.type)

  const [details, setDetails] = React.useState(true)
  let typesTotalProps
  return (
    <Card
      color="blue"
      style={{
        width: `470px`,
        marginTop: otherCard ? '5px' : histType ? '112px' : '62px',
        zIndex: '900',
        backgroundColor: 'rgb(255,255,255,0.6)',
      }}
    >
      <Card.Content>
        {typesIds.map((type) => {
          if (type === 'Smartphone') type = 'app'
          if (type === 'Smartbadge c/ GPS') type = 'sb'
          typesTotalProps = storeCardsDevices.typesTotal[type]
          return (
            <Statistic key={type} size={typesTotalProps.size} floated="right" color={typesTotalProps.color}>
              <Statistic.Value>
                <Icon name={typesTotalProps.icon} /> {typesTotalProps.counter.toString().padStart(4, '0')}
                <Statistic.Label>
                  <IconLabel>{typesTotalProps.label}</IconLabel>
                </Statistic.Label>
              </Statistic.Value>
            </Statistic>
          )
        })}

        <Card.Header onClick={() => setDetails(!details)} style={{ cursor: 'pointer' }}>
          {t(title)} {hasFeature && <Icon name={details ? 'caret down' : 'caret right'} color="black" />}
        </Card.Header>
        {details && (
          <Card.Description>
            <Menu widths={4} compact icon="labeled" size="mini" style={{ backgroundColor: 'rgb(255,255,255,0.6)' }}>
              {storeCardsDevices.statusIds.map((item) => (
                <Menu.Item key={item.label} name={item.label}>
                  <SvgIcon title={item.label} color={item.color} name={item.icon} />
                  <MenuIconLabel> {t(item.label)} </MenuIconLabel>

                  {hasFeature ? (
                    <Monospaced>{' APP: ' + iconCounters[item.id].app.toString().padStart(4, '0')}</Monospaced>
                  ) : (
                    ''
                  )}

                  <Monospaced>{'S.B.: ' + iconCounters[item.id].sb.toString().padStart(4, '0')}</Monospaced>
                </Menu.Item>
              ))}
            </Menu>
          </Card.Description>
        )}
      </Card.Content>
    </Card>
  )
}
export default CardDevices
