import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { GeometryUtil } from 'leaflet'

export const StatsBarFormGeoArea = ({ info }) => {
  const { t } = useTranslation()
  let formatedCoords = []
  const coords = info?.geometry?.coordinates?.[0]?.map((c) => {
    formatedCoords.push({ lat: c[1], lng: c[0] })
    return c
  })

  let mArea = Math.round(0.99 * GeometryUtil.geodesicArea(formatedCoords))
  if (mArea > 999999) {
    mArea = Math.round(mArea / 1000000)
    if (mArea > 999999) {
      mArea = mArea.toExponential(1) + ' km²'
    } else mArea = mArea + ' km²'
  } else mArea = mArea + ' m²'
  return (
    <Form>
      <Form.Field
        id="form-area"
        control={Input}
        label={t('Area')}
        placeholder={t('Area')}
        coord={coords}
        value={mArea || 'missing coords to calculate area'}
      />
    </Form>
  )
}
export default StatsBarFormGeoArea
