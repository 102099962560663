import React /*, { useState }*/ from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
//import { showConfirm, hideConfirm } from 'Store/actions/system-action'
import { updateWiP } from 'Store/actions/modules-action'
import { setFalse, setTrue } from 'Store/actions/toggle-action'

export const StatsBarActionsEdit = ({ id, moduleType, table }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const editMode = useSelector((state) => state[table][moduleType].wip?.editMode)
  const wipId = useSelector((state) => state[table][moduleType].wip?.id)
  const active = editMode && wipId === id

  const handleClick = () => {
    !active ? dispatch(setTrue('fencesEditionMode')) : dispatch(setFalse('fencesEditionMode'))
    dispatch(updateWiP({ moduleType: moduleType, changes: { id: id, editMode: !active } }))

    /* const data = {
      content: (
        <div style={{ width: '40vw', height: 'auto', fontSize: '1.5em', padding: '10px' }}>
          {' '}
          <span>{t('areYouSureYouWishToContinue')}</span>
        </div>
      ),
      onConfirm: () => {
        dispatch(deleteModules(id))
        dispatch(hideConfirm())
        dispatch(setFalse('showStatsBar'))
      },
      onCancel: () => dispatch(hideConfirm()),
      confirmHeader: t('moduleToDelete'),
    }
    dispatch(showConfirm(data))*/
  }
  return (
    <Icon
      id="icon-edit"
      name="edit"
      title={t('lbledit')}
      size="large"
      circular
      onClick={handleClick}
      style={{ backgroundColor: active ? '#21ba45' : '#4b72b2', color: 'white', cursor: 'pointer' }}
    />
  )
}
export default StatsBarActionsEdit
