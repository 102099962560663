import request from 'Apis/rest'
export const INITIAL_STORE = 'fencesInitialStore'
export const GEODADOS = 'geoData'
export const GEOFENCE = 'createFence'

export const fetchModuleType =
  (empresa, { id_tipo_modulo, type }) =>
  async (dispatch) => {
    const res = await request.get(`/organization/${empresa}/modulo`, {
      params: {
        id_tipo_modulo,
      },
    })
    dispatch({ type: type, payload: res.data })
  }

export const createFence = (geojson, empresa, name, email, type) => ({
  type: 'server/insere/modulo',
  fields: {
    nome: `'${name}'`,
    id_tipo_modulo: type,
    empresa: `'${empresa}'`,
    info: `'${geojson}'`,
    login: `'${email}'`,
  },
})

export const multiInsert = (k) => ({
  type: 'server/multi/ativo',

  fields: {
    nome: `'${k.name}'`,
    id_tipo_ativo: `'${k.assetTypeId}'`,
    empresa: `'${k.company}'`,
    login: `'${k.email}'`,
    info: `'{"color":"${k.color}","icone":"${k.icon}"}'`,
  },

  associates: [
    { name: 'ativo_empresa', fields: { id_empresa: k.companyId } },
    { name: 'ativo_sensor', fields: { id_sensor: k.sensorId } },
    { name: 'ativo_usuario', fields: { id_usuario: k.userId } },
  ],

  tables: [
    {
      name: 'dados',
      fields: {
        id_tipo_dados: 3,
        id_sensor: k.sensorId,
        info: `'{"lat":${Math.round(k.latlng.lat * 1000000) / 1000000},"lng":${
          Math.round(k.latlng.lng * 1000000) / 1000000
        }, "origem":"ref"}'`,
      },
    },
  ],
})

export const deleteManyFence = (ids_cercas) => {
  return {
    type: 'deletaCercas',
    payload: ids_cercas,
  }
}

export const deleteManyFenceServer = (cercas) => {
  return {
    type: 'server/deleta/modulo',
    where: ['id_modulo IN (' + cercas + ')'],
  }
}

export const updateFenceServer = (id, valor, campo, GeoJSONType) => {
  let campovalor = ''
  let objeto
  switch (campo) {
    case 'refId':
      objeto = { refId: valor }
      objeto = JSON.stringify(objeto)
      campovalor = { info: `'${objeto}'` }
      break
    case 'refTypeId':
      objeto = {
        properties: { refType: 'Beacon', idBeacon: valor.idBeacon, color: valor.color, beaconName: valor.name },
      }
      objeto = JSON.stringify(objeto)
      campovalor = { info: `'${objeto}'` }
      break
    case 'refType':
      objeto = { properties: { refType: valor.ref, color: valor.color } }
      objeto = JSON.stringify(objeto)
      campovalor = { info: `'${objeto}'` }
      break
    case 'label':
      objeto = { label: valor }
      objeto = JSON.stringify(objeto)
      campovalor = { info: `'${objeto}'` }
      break
    case 'Nome':
      campovalor = { nome: valor }
      break
    case 'Cor':
      campovalor = { info: `'{"properties":{"color": "${valor}"}}'` }
      break
    case 'Coords':
      objeto = { geometry: { coordinates: valor, type: GeoJSONType } }
      objeto = JSON.stringify(objeto)
      campovalor = { info: `'${objeto}'` }
      break
    case 'Radius':
      objeto = { geometry: { coordinates: valor[0], type: 'Point', mRadius: valor[1] } }
      objeto = JSON.stringify(objeto)
      campovalor = { info: `'${objeto}'` }
      break
    default:
      console.log('Campo "' + campo + '" não preparado para atualização, mudar na actions da Store')
  }
  return {
    type: 'server/atualiza/modulo',
    fields: campovalor,
    where: { id_modulo: id },
  }
}
export const updateFenceProp = (id, valor, campo) => {
  return {
    type: 'server/atualiza/modulo',
    fields: { info: `br_add(info,'properties',br_add(info->'properties','${campo}','${valor}'))::jsonb` },
    where: { id_modulo: id },
  }
}

export const updateFence = (id, valor, campo) => {
  return {
    type: 'atualizaCerca',
    id: id,
    payload: { valor: valor, campo: campo },
  }
}

export const insertAssociateRail = (idFence, idAssociatedRail) => {
  return {
    type: 'server/insere/modulo2',
    fields: { id_modulo: idFence, id_modulo2: idAssociatedRail },
  }
}
export const updateAssociateRail = (idFence, idAssociatedRail, oldIdAssociatedRail) => {
  return {
    type: 'server/atualiza/modulo2',
    fields: { id_modulo2: idAssociatedRail },
    where: { id_modulo: idFence, id_modulo2: oldIdAssociatedRail },
  }
}

export const fetchFences = () => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  const res = await request.get('/fences', {
    headers: {
      Authorization: 'Bearer ' + jwt,
    },
  })
  dispatch({ type: 'fencesInitialStore', payload: res.data })
}
