import React, { memo } from 'react'
import { Feed, Message } from 'semantic-ui-react'
import m from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { colorFormat } from 'Utils/colorsFormat'
import { stopEmergency } from 'Store/actions/notification-action'
import { feedbackMessage } from 'Store/actions/notification-action'
import styled from 'styled-components'
import { ItemDetails } from 'Components/Sidebar/Contents/Notification/ItemDetails'
import { useObjSelector } from 'Utils/hooks/useObjSelector'
import NotificationDetailsModal from 'Components/Sidebar/Contents/Notification/NotificationDetailsModal'
import TableSpan from 'Components/SidebarMenu/TableSpan'

function DropdownNotificationItem({ notificationId }) {
  const dispatch = useDispatch()

  const notification = useObjSelector((state) => state.notification.byId[notificationId])
  const assetLat = useSelector((state) => state.measures.byId[notification.assetId]?.info?.coords?.lat)
  const assetLng = useSelector((state) => state.measures.byId[notification.assetId]?.info?.coords?.lng)
  const refMap = useSelector((state) => state.login.refMap)
  const login = useSelector((state) => state.login.email)
  const currentTab = useSelector((state) => state.tables.current.tab)
  const assetNFC = useSelector((state) => state.assets.byId[notification.assetId]?.info?.nfc)
  const isInMap = currentTab === 'map'

  const sendFeedback = (notificationId, assetId, state) => {
    const currentTime = new Date().toISOString()
    let input = {
      id: notificationId,
      [state + 'By']: login,
      [state + 'At']: currentTime,
    }
    if (state === 'confirmed' && notificationId) dispatch(stopEmergency())
    let params = {
      msgId: notificationId,
      source: notification[notificationId]?.source,
      [state === 'confirmed' ? 'clicked' : 'closed']: currentTime,
      assetIds: [assetId],
      title: state === 'confirmed' ? 'Pedido de ajuda recebido!' : 'Atendimento concluído!',
      content: state === 'confirmed' ? 'A ajuda está a caminho.' : `Hora de Conclusão ${currentTime}`,
      level: 3,
      data: {
        level: 3,
      },
    }
    dispatch(feedbackMessage(params, input))
  }

  const fitNotificationBounds = () => {
    if (!notification.modulename && isInMap) {
      if (assetLat && assetLng) refMap.setView([assetLat, assetLng])
    }
  }

  const assetId = notification.assetId
  const help = notification?.type === 'help'
  const momentTime = m(notification.time)
  const styleMessage = notification.closedAt ? { opacity: '0.7' } : {}
  const contrastColor = '#002fb5'

  //Gambiarra para resolver personalizações entre tenants. Necessário repensar isso no futuro
  let assetNameLink
  let splitMsg = []
  let messageStart = ''
  let messageEnd = ''
  if (assetNFC && notification.message.includes(`Usuário ${assetNFC}`)) {
    assetNameLink = `Usuário ${assetNFC}`
  }
  if (!assetNameLink && assetId && notification.message.includes(`Usuário ${assetId}`)) {
    assetNameLink = `Usuário ${assetId}`
  }
  if (assetNameLink) {
    splitMsg = notification.message.split(assetNameLink)
    messageStart = splitMsg[0]
    messageEnd = splitMsg[1]
  }

  return (
    <Message
      key={notification.time}
      size="small"
      style={{
        ...styleMessage,
        backgroundColor: colorFormat(notification.profile?.bgColor || 'blue'),
        marginTop: '4px',
      }}
      onClick={() => fitNotificationBounds(notification)}
    >
      <Feed size="large">
        <Feed.Event>
          {notification.profile?.icon && (
            <FeedLabel
              icon={{
                name: notification.profile?.icon?.replace('_', ' '),
                circular: true,
                style: { color: notification.profile?.txtColor || 'black' },
              }}
            />
          )}
          <Feed.Content>
            <Feed.Summary style={{ color: notification.profile?.txtColor || 'black' }}>
              <TextBox title={notification.title} maxWidth={215}>
                {notification.title}
              </TextBox>
              <Feed.Date style={{ float: 'right', color: notification.profile?.txtColor || 'black' }}>
                {momentTime.fromNow()}
              </Feed.Date>
            </Feed.Summary>
            <Feed.Extra text style={{ color: notification.profile?.txtColor || 'black', display: 'inline-flex' }}>
              {assetNameLink ? (
                <div>
                  <span style={{ whiteSpace: 'break-spaces' }}>{`${messageStart}`}</span>
                  <ItemDetails
                    notificationId={notification.message}
                    dateRef={momentTime}
                    assetId={assetId}
                    assetName={assetNameLink}
                  />
                  <span style={{ whiteSpace: 'break-spaces' }}>{`${messageEnd}`}</span>
                </div>
              ) : (
                <>
                  <TextBox title={notification.message} maxWidth={450}>
                    {notification.message + ' '}
                  </TextBox>
                </>
              )}
            </Feed.Extra>

            {help && (
              <Feed.Extra>
                {!notification.confirmedAt ? (
                  notification.loadingState ? (
                    <span>Carregando...</span>
                  ) : (
                    <span
                      style={{ color: contrastColor, fontStyle: 'italic', cursor: 'pointer' }}
                      onClick={() => sendFeedback(notificationId, notification.assetId, 'confirmed')}
                    >
                      {'Enviar Confirmação de Ajuda'}
                    </span>
                  )
                ) : !notification.closedAt ? (
                  <div style={{ display: 'flex' }}>
                    <TableSpan
                      value={`Confirmação enviada por ${notification.confirmedBy} |`}
                      width="80%"
                      padding="0px"
                    ></TableSpan>
                    {notification.loadingState ? (
                      <span>{'  Carregando...'}</span>
                    ) : (
                      <div style={{ marginLeft: '3px' }}>
                        <span
                          style={{ color: contrastColor, fontStyle: 'italic', cursor: 'pointer' }}
                          onClick={() => sendFeedback(notificationId, notification.assetId, 'closed')}
                        >
                          {'Concluir'}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <span>
                    Atendimento Concluído em {m(notification.closedAt).format('DD/MM à HH:mm').replace('à', 'às')}
                  </span>
                )}
              </Feed.Extra>
            )}
            <Feed.Meta style={{ display: 'block' }}>
              <Feed.Like style={{ color: notification.profile?.txtColor || 'black' }}>
                {help
                  ? 'Solicitado em ' + momentTime.format('DD/MM à HH:mm').replace('à', 'às')
                  : momentTime.format('DD/MM à HH:mm').replace('à', 'às')}
              </Feed.Like>
              <div style={{ float: 'right' }}>
                {notification.type === 'help' ? (
                  <NotificationDetailsModal notificationId={notification.id} />
                ) : (
                  notification?.ruleId?.split('#')?.[1] || ''
                )}
              </div>
            </Feed.Meta>
          </Feed.Content>
        </Feed.Event>
      </Feed>
    </Message>
  )
}

const FeedLabel = styled(Feed.Label)`
  padding-top: 18px;
`

const TextBox = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: ${(props) => props.maxWidth}px;
  display: inline-block;
`

export default memo(DropdownNotificationItem)
