import React, { useEffect } from 'react'
//import { Icon, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { InputTemplate } from 'Utils/components/InputTemplate.js'
import colors from 'Utils/theme/colors'
import { fetchModules, saveGroup, insertDynamicData } from 'Store/actions/modules-action'
import { deleteDynamicData, updateDynamicData } from 'Store/actions/modules-action'
import ReactTable from 'Utils/reactTable'
import { threeColumns } from 'Utils/threeColumns'
import styled from 'styled-components'
import { saveColumnsPreference } from 'Store/actions/login-action'

//import { updateAsset } from 'Store/actions/assets-action'
const Label = styled.label`
  text-transform: capitalize !important;
`
//import { valuesIn } from 'lodash';
export const PeopleTypeContent = () => {
  const { t } = useTranslation()
  const tables = [
    {
      key: 't1',
      text: t('People'),
      value:
        'assets.people' /*, label: { color: 'green', empty: true, circular: true }, icon: { name: 'user', circular: true }*/,
    },
    {
      key: 't2',
      text: t('Assets'),
      value:
        'assets.assets' /*, label: { color: 'blue', empty: true, circular: true }, icon: { name: 'car', circular: true }*/,
    },
    {
      key: 't3',
      text: t('Fences'),
      value:
        'modules.fences' /*, label: { color: 'teal', empty: true, circular: true }, icon: { name: 'user', circular: true }*/,
    },
    {
      key: 't4',
      text: t('Refs'),
      value:
        'modules.refs' /*, label: { color: 'purple', empty: true, circular: true , icon: { name: 'marker', circular: true }} */,
    },
    {
      key: 't5',
      text: t('Routes'),
      value:
        'modules.routes' /*, label: { color: 'orange', empty: true, circular: true } , icon: { name: 'user', circular: true }*/,
    },
  ]
  const tableName = 'types'
  const [currentSort, setCurrentSort] = React.useState('info.label')

  const allIds = useSelector((state) => state.modules.types.allIds, shallowEqual)
  const byId = useSelector((state) => state.modules.types.byId, shallowEqual)

  const hiddenColumns = useSelector((state) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns)

  const groupedValuesColumns = useSelector(
    (state) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const dispatch = useDispatch()

  const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const fetchAction = () => dispatch(fetchModules())
  const insertAction = (body) => dispatch(insertDynamicData(body, { auth: 'standard' }, tableName))
  const groupAction = (groupName, selected, type) => dispatch(saveGroup(tableName, groupName, selected, type))
  const deleteAction = (ids) => dispatch(deleteDynamicData(ids, tableName))

  const columns = React.useMemo(
    () => [
      {
        Header: () => <Label>{t('id')}</Label>,
        accessor: 'id',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} ids`,
      },
      {
        Header: () => <Label>{t('code')}</Label>,
        accessor: 'info.code',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value}`,
        Cell: (props) => (
          <InputTemplate
            value={props.value?.slice(0, 8)}
            id={props?.cell?.row?.original?.id}
            action={(id, field, value) => {
              dispatch(updateDynamicData({ id: id, field: field, value: value?.slice(0, 8) }, 'types'))
            }}
            limitSize={8}
            field="info.code"
            table={tableName}
          />
        ),
      },
      {
        Header: () => <Label>{t('name')}</Label>,
        accessor: 'info.label',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} nomes`,
        Cell: (props) => (
          <InputTemplate
            value={props.value || '<<sem nome>>'}
            id={props?.cell?.row?.values?.id}
            action={(id, field, value) => dispatch(updateDynamicData({ id: id, field: field, value: value }, 'types'))}
            field={'info.label'}
            table={tableName}
          />
        ),
      },
      {
        Header: () => <Label>{t('tables')}</Label>,
        accessor: 'info.table',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} ${t('tables')}(s)`,
        Cell: (props) => {
          return (
            <InputTemplate
              options={tables}
              value={props.value || 'blue'}
              id={props?.cell?.row?.values?.id}
              action={(id, field, value) =>
                dispatch(updateDynamicData({ id: id, field: field, value: value }, 'types'))
              }
              field={'info.table'}
              table={tableName}
            />
          )
        },
      },

      {
        Header: () => <Label>{t('Groups')}</Label>,
        accessor: 'groupsText',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} grupos`,
      },
      {
        Header: () => <Label>{t('description')}</Label>,
        accessor: 'info.description',
        aggregate: 'count',
        Aggregated: ({ value }) => `${value} descrições`,
        Cell: (props) => (
          <InputTemplate
            value={props.value || '<<sem descrição>>'}
            id={props?.cell?.row?.values?.id}
            action={(id, field, value) => dispatch(updateDynamicData({ id: id, field: field, value: value }, 'types'))}
            field={'info.description'}
            table={tableName}
          />
        ),
      },
      {
        Header: () => <Label>{t('color')}</Label>,
        accessor: 'info.color',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} ${t('colors')}`,
        Cell: (props) => {
          return (
            <InputTemplate
              options={colors}
              value={props.value || 'blue'}
              id={props?.cell?.row?.values?.id}
              action={(id, field, value) =>
                dispatch(updateDynamicData({ id: id, field: field, value: value }, 'types'))
              }
              field={'info.color'}
              table={tableName}
            />
          )
        },
        disableGlobalFilter: true,
      },
      ...threeColumns(t),
    ],
    [t, dispatch, tables]
  )

  const rows = allIds

  const data = React.useMemo(
    () =>
      rows.map((id) => {
        let row = byId[id]
        return { ...row, groups: row?.groups, groupsText: row?.groups?.toString() }
      }),
    [rows, byId]
  )

  return (
    <ReactTable
      table={tableName}
      hiddenColumns={hiddenColumns}
      columns={columns}
      data={data}
      fetchAction={fetchAction}
      insertAction={insertAction}
      groupAction={groupAction}
      deleteAction={deleteAction}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      setCurrentSort={setCurrentSort}
      currentSort={currentSort}
    />
  )
}
