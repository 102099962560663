/*  
    Author: Luís Mourão
    Type: Smart
  Description: Draw, Save and Edit Modules (fences,refs and routes) 
    TODO: 
    - Dividir em Componentes Menores
*/

import React, { Component } from 'react'
import { FeatureGroup } from 'react-leaflet'
import { EditControl as Edit } from 'Libs/react-leaflet-draw/src/index'
import { connect } from 'react-redux'
import { updateFenceServer, updateFenceProp } from 'Store/actions/fences-action'
import {
  createModule,
  deleteModules,
  setCurrentModule,
  insertStarted,
  insertStopped,
} from 'Store/actions/modules-action'
import { updateRouteServer } from 'Store/actions/routes-action'
import L from 'leaflet'
import { draw } from 'Utils/MapUtils'
import getDistance from 'Utils/getDistance'
import { withTranslation } from 'react-i18next'
import getSafe from 'Utils/getSafe.js'
//import { innerPosition } from 'Components/Map/InnerMap/innerPosition'
//import { cloneDeep /*, findIndex, uniq */ } from 'lodash'
import { MapRefs } from 'Components/Map/MapModules/MapRefs.js'
import { MapRoutes } from 'Components/Map/MapModules/MapRoutes.js'
import { MapFences } from 'Components/Map/MapModules/MapFences.js'
import { setCurrentStatsBar } from 'Store/actions/system-action'
import { setTrue, toggle } from 'Store/actions/toggle-action'
import fitBounds from 'Utils/fitBounds.js'
import { EditingModule } from './EditingModule'

class PlotPolygon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      leafletId: undefined,
      geoInfo: [],
      geoId: undefined,
      removeL: false,
      isValid: '',
      feedback: '',
      fenceTool: 'eye',
      mArea: undefined,
      moduleType: null,
      stretch: undefined,
      dataOnClick: undefined,
      refCoords: null,
      geoEventStatus: null,
      selectChange: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { modules } = this.props
    const { wip } = modules

    if (wip.id && wip.id !== prevProps.modules.wip.id && wip.id !== prevState.geoId) {
      this.setState({ geoId: wip.id })
    }
  }

  roundCoords = (number) => {
    return Math.round(number * 1000000) / 1000000
  }

  geoEvent = (data, geoId) => {
    let geoData = data.layer.toGeoJSON()
    let mArea
    let moduleField
    switch (geoData.geometry.type) {
      case 'Polygon': {
        mArea = Math.round(0.99 * L.GeometryUtil.geodesicArea(data.layer._latlngs[0]))
        if (mArea > 999999) {
          mArea = Math.round(mArea / 1000000)
          if (mArea > 999999) {
            mArea = mArea.toExponential(1) + ' km²'
          } else mArea = mArea + ' km²'
        } else mArea = mArea + ' m²'
        moduleField = 'fences'
        break
      }
      case 'Point': {
        if (data.layer._mRadius) {
          geoData.geometry.mRadius = data.layer._mRadius
          moduleField = 'fences'
        } else {
          moduleField = 'refs'
        }
        break
      }
      case 'LineString': {
        let comprimento = 0
        geoData.geometry.coordinates.forEach((e, i) => {
          if (i < geoData.geometry.coordinates.length - 1) {
            comprimento += getDistance(
              [geoData.geometry.coordinates[i][1], geoData.geometry.coordinates[i][0]],
              [geoData.geometry.coordinates[i + 1][1], geoData.geometry.coordinates[i + 1][0]]
            )
          }
        })
        geoData.geometry.stretch = comprimento
        moduleField = 'routes'
        break
      }
      case 'MultiPolygon':
        moduleField = 'fences'
        break
      default:
        break
    }
    this.props.setCurrentModule(geoId, moduleField)
    this.props.setTrue('showStatsBar')
    this.props.setCurrentStatsBar(geoId, 'modules', moduleField)
    this.setState({
      geoInfo: geoData,
      geoId: geoId,
      mArea: mArea,
      moduleType: moduleField,
      dataOnClick: data,
      stretch: Math.round(geoData.geometry.stretch),
      refCoords: data.latlng,
      geoEventStatus: geoId ? 'Click' : 'Created',
    })
  }

  onGeoCreated(data) {
    this.geoEvent(data)
    let position

    switch (data.layer.toGeoJSON().geometry.type) {
      case 'Polygon': {
        let latBound
        let lngBound
        if (data.layer._bounds._northEast.lat + data.layer._bounds._southWest.lat > 0) {
          latBound = (data.layer._bounds._northEast.lat + data.layer._bounds._southWest.lat) / 2
          lngBound = (data.layer._bounds._northEast.lng + data.layer._bounds._southWest.lng) / 2
        } else {
          latBound = (data.layer._bounds._northEast.lat + data.layer._bounds._southWest.lat) / 2
          lngBound = (data.layer._bounds._northEast.lng + data.layer._bounds._southWest.lng) / 2
        }
        position = [latBound, lngBound]
        setTimeout(() => this.popupCreate(this.refFeature.leafletElement, position), 100)
        break
      }
      case 'Point': {
        let refPos = data.layer.editing._marker ? getSafe(() => data.layer.editing._marker._latlng, null) : null
        if (refPos) position = [refPos.lat, refPos.lng]
        else position = [data.layer._latlng.lat, data.layer._latlng.lng]
        setTimeout(() => this.popupCreate(this.refFeature.leafletElement, position), 100)
        break
      }
      case 'LineString': {
        position = [
          data.layer.editing.latlngs[0][data.layer.editing.latlngs[0].length - 1].lat,
          data.layer.editing.latlngs[0][data.layer.editing.latlngs[0].length - 1].lng,
        ]
        setTimeout(() => this.popupCreate(this.refFeature.leafletElement, position), 100)
        break
      }
      default:
        break
    }

    let geojson = data.layer.toGeoJSON()
    fitBounds(this.state.moduleType, geojson.geometry.coordinates, this.props.refMap)
    if (data.layer._mRadius) geojson.geometry.mRadius = data.layer._mRadius
    this.props.insertStarted({
      moduleType: this.state.moduleType,
      leafletId: data.layer._leaflet_id,
      layerType: data.layerType,
      refCoords: data.layer._latlng,
      geoEventStatus: 'Created',
      geoJson: geojson,
    })
    this.props.refMap.removeLayer(data.layer)
    this.setState({
      leafletId: data.layer._leaflet_id,
      layerType: data.layerType,
      refCoords: data.layer._latlng,
      geoEventStatus: 'Created',
    })
  }

  popupCreate(element, position) {
    element.openPopup(position)
  }

  changeFenceName = (i) => {
    this.setState({ fenceName: i.target.value })
  }

  removeLayer = (ref) => {
    let layers
    let featureGroup
    let filtrado
    let leafletId = this.state.leafletId ? this.state.leafletId.toString() : null
    if (ref) {
      this.layerRef = ref
      featureGroup = ref.leafletElement.options.edit.featureGroup
    }
    if (featureGroup !== undefined) layers = Object.keys(featureGroup._layers)
    if (layers && this.state.removeL) filtrado = layers.find((id) => id === leafletId)
    if (filtrado) {
      featureGroup.removeLayer(parseInt(filtrado))
      this.setState({ removeL: false })
    }
  }

  featureRef = (ref) => {
    if (ref) {
      this.refFeature = ref
    }
  }

  render() {
    const { t, modules, moduleType, statsbarId } = this.props
    const { fences, refs, routes } = modules

    L.drawLocal = draw(t)

    return (
      <FeatureGroup ref={(ref) => this.featureRef(ref)}>
        <MapFences ids={fences.allIds} click={this.geoEvent} />
        <MapRefs ids={refs.allIds} />
        <MapRoutes ids={routes.allIds} click={this.geoEvent} />
        {['routes', 'fences'].includes(moduleType) && this.props.show && modules[moduleType].wip?.editMode && (
          <EditingModule
            moduleType={moduleType}
            editId={statsbarId}
            dataOnClick={this.state.dataOnClick}
            refMap={this.props.refMap}
          />
        )}
        {this.props.show && (
          <Edit
            //ref={(ref) => this.removeLayer(ref)}
            position="topright"
            onCreated={(data) => this.onGeoCreated(data)}
            edit={{ remove: false, edit: false }}
            draw={{
              showRadius: true,
              marker: true,
              circle: {
                //showRadius: true,
                metric: ['km', 'm'],
              },
              rectangle: {
                //showArea: true,
                metric: ['ha'],
              },
              polygon: {
                showLength: true,
                metric: ['km', 'm'],
              },
              circlemarker: false,
              polyline: {
                showLength: true,
                metric: ['km', 'm'],
              },
            }}
          ></Edit>
        )}
      </FeatureGroup>
    )
  }
}
const mapStateToProps = (state) => ({
  modules: state.modules,
  empresa: state.login.empresa,
  email: state.login.email,
  perfil: state.login.perfil,
  companyId: state.login.companyId,
  assetsLen: state.assets.allIds.length,
  section: state.login.section,
  fencesPreferences: state.login.preferences.fences,
  fencesEditionMode: state.toggle.fencesEditionMode,
  missingSave: state.toggle.missingStatsBarSaveGeoJson,
  statsbarId: state.system?.statsbar?.id,
  moduleType: state.system?.statsbar?.moduleType,
  editionMode: state.system?.statsbar?.moduleType,
})

export default withTranslation()(
  connect(mapStateToProps, {
    insertStarted,
    insertStopped,
    deleteModules,
    updateFenceServer,
    createModule,
    updateFenceProp,
    updateRouteServer,
    setCurrentModule,
    setCurrentStatsBar,
    setTrue,
    toggle,
  })(PlotPolygon)
)
