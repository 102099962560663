import React, { useEffect } from 'react'
import { Form, Input, Select, TextArea } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCanAccess } from 'Utils/rbac'

const StatsBarFormMain = (data) => {
  const typesIds = useSelector((state) => state.modules.types.allIds)
  const types = useSelector((state) => state.modules.types.byId)

  const options = typesIds
    .filter((id) => types[id].info.table === data.table + '.' + data.moduleType)
    .map((id) => ({ key: id, text: types[id].info.label, value: id.toString() }))

  /*[
    { key: '1', text: 'Acidente de Trabalho', value: 'Acidente de Trabalho' },
    { key: '2', text: 'Extintor de Incêndio', value: 'Extintor de Incêndio' },
    { key: '3', text: 'Gateway LoRa', value: 'Gateway LoRa' },
    { key: '4', text: 'Localização Indoor', value: 'Localização Indoor' },
    { key: '5', text: 'Painel de Controle de Acesso', value: 'Controle de Acesso' },
    { key: '6', text: 'Ponto de Encontro', value: 'Ponto de Encontro' },
    { key: '7', text: 'Ponto Eletrônico', value: 'Ponto Eletrônico' },
    { key: '8', text: 'Portaria', value: 'Portaria ' },
    { key: '9', text: 'Trabalho em Altura', value: 'Trabalho em Altura' },
    { key: '10', text: 'Espaço Confinado', value: 'Espaço Confinado' },
    { key: '11', text: 'Outros', value: 'Outros' },
  ]*/

  const { changes, name, uniqueId, description, type, missingId, missingName, missingType } = data.state
  const { setChanges, setName, setUniqueId, setDescription, setType, setMissingId, setMissingName, setMissingType } =
    data.setState
  const canAccess = useCanAccess('WRITE')
  const { t } = useTranslation()

  // initial check
  useEffect(() => {
    setMissingId(uniqueId === '' || uniqueId == null)
    setMissingName(name === '' || name == null)
    setMissingType(type === '' || type == null)
  })

  const handleChange = (e, { value, id }) => {
    let newChanges = []
    data.setWip({ [id]: value })
    switch (id) {
      case 'form-name':
        setName(value.slice(0, 30))
        newChanges = changes
        if (!newChanges?.includes('name')) {
          newChanges.push('name')
          setChanges(newChanges)
        }
        setMissingName(value === '')
        break
      case 'form-uniqueID':
        setUniqueId(value.slice(0, 8))
        newChanges = changes
        if (!newChanges?.includes('uniqueId')) {
          newChanges.push('uniqueId')
          setChanges(newChanges)
        }
        setMissingId(value === '')
        break
      case 'form-type':
        setType(value)
        newChanges = changes
        if (!newChanges?.includes('type')) {
          newChanges.push('type')
          setChanges(newChanges)
        }
        setMissingType(value === '')
        break
      case 'form-description':
        setDescription(value.slice(0, 280))
        newChanges = changes
        if (!newChanges?.includes('description')) {
          newChanges.push('description')
          setChanges(newChanges)
        }
        break
      default:
    }
  }

  return (
    <Form style={{ marginLeft: '20px' }}>
      <Form.Group widths="6">
        <Form.Field
          required={true}
          readOnly={!canAccess}
          id="form-uniqueID"
          control={Input}
          label={t('Code')}
          placeholder={t('Code')}
          width="5"
          onChange={handleChange}
          value={uniqueId?.slice(0, 8)}
          error={missingId}
        />

        <Form.Field
          required={true}
          readOnly={!canAccess}
          id="form-name"
          control={Input}
          label={t('Name')}
          placeholder={t('Name')}
          width="10"
          value={name?.slice(0, 30)}
          onChange={handleChange}
          error={missingName}
        />
      </Form.Group>

      <Form.Field
        required={true}
        readOnly={!canAccess}
        control={!canAccess ? Input : Select}
        options={options}
        label={{ children: 'Tipos', htmlFor: 'form-select-control-gender' }}
        placeholder={t('SelectType')}
        id={'form-type'}
        value={type}
        search
        searchInput={{ id: 'form-type-search' }}
        width="15"
        onChange={handleChange}
        error={missingType}
      />
      <Form.Field
        readOnly={!canAccess}
        style={{ maxHeight: '20vh', minHeight: '7vh' }}
        id="form-description"
        control={TextArea}
        label={t('Description')}
        placeholder={t('Description')}
        width="15"
        value={description?.slice(0, 280)}
        onChange={handleChange}
      />
    </Form>
  )
}
export default StatsBarFormMain
