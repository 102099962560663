import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import schema from 'graphql/schema.json'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { setAutoUpdate, setShowModal } from 'Store/actions/tables-action'
import { GenericFormProps } from './types/GenericFormProps'
import { RootStore } from 'Store/initialStore'
import { NotificationProfilesSchema } from 'graphql/types'
import PeopleForm, { FieldsPeopleState } from './PeopleForm'
import AssetThingForm, { FieldsAssetThingState } from './AssetThingForm'

const AssetsForm = ({
  handleCancel,
  addOpen,
  table,
  confirmAction,
  setAddOpen,
  editId,
  insertAction,
  updateAction,
}: GenericFormProps) => {
  const dispatch = useDispatch()
  const { cudLoading, cudSuccess } = useSelector((state: RootStore) => state[table], shallowEqual)

  useEffect(() => {
    if (cudSuccess) {
      if (table === 'assets') {
        dispatch({ type: 'CLEAR_ASSET_STATE' })
      }
      if (table === 'people') {
        dispatch({ type: 'CLEAR_PEOPLE_STATE' })
      }
      setAddOpen(false)
    }
  }, [cudSuccess, dispatch, setAddOpen, table])

  useEffect(() => {
    dispatch(setAutoUpdate(table, false))
    dispatch(setShowModal(table, true))
    return () => {
      dispatch(setAutoUpdate(table, true))
      dispatch(setShowModal(table, false))
    }
  }, [dispatch, table])

  const row = useSelector(
    (state: RootStore) => (confirmAction === 'update' ? state.assets.byId[editId] : {}),
    shallowEqual
  )
  // const sensorsById = useSelector((state: RootStore) => state.sensors.byId)
  // const sensorsAllIds = useSelector((state: RootStore) => state.sensors.allIds)
  const typesById = useSelector((state: RootStore) => state.modules.types.byId, shallowEqual)
  const typesAllIds = useSelector((state: RootStore) => state.modules.types.allIds)

  // const tenant = useSelector((state) => state.login.empresa)
  const { t } = useTranslation()

  const [formObj, setFormObj] = useState<{ name?: string }>({})

  useEffect(() => {
    let schemaTypes = schema.data?.__schema?.types
    //@ts-ignore
    let notificationProfilesSchema: NotificationProfilesSchema[] =
      schemaTypes && schemaTypes.find((e) => e.name === 'NotificationProfile')?.fields
    const appVar = ['tenant', 'id', 'status', 'createdBy', 'createdAt', 'updatedAt']

    let formObj = {}
    if (notificationProfilesSchema) {
      notificationProfilesSchema
        .filter((each) => !appVar.includes(each.name))
        .forEach((e) => {
          const type = e.type.ofType?.name || e.type.name
          const kind = e.type.ofType?.kind || e.type.kind
          const required = e.type.kind
          formObj[e.name] = {
            title: t(e.name),
            value: e.name,
            type,
            options: kind === 'ENUM' ? schemaTypes.find((eachType) => eachType.name === type)?.enumValues : null,
            required: required === 'NON_NULL' || kind === 'ENUM' ? true : false,
          }
        })
      setFormObj(formObj)
    }
  }, [setFormObj, t])

  if (!formObj?.name) return null

  /* Função que salva as informação no banco dependendo se insert ou update*/
  const handleSave = (fields: FieldsPeopleState | FieldsAssetThingState) => {
    if (confirmAction === 'insert' && insertAction) insertAction(fields)
    else if (updateAction) {
      updateAction(fields.id, fields)
    }
  }

  const noType = '<<' + t('noAssignment') + '>>'

  if (table === 'assets')
    return (
      <AssetThingForm
        handleCancel={handleCancel}
        addOpen={addOpen}
        table={table}
        confirmAction={confirmAction}
        cudLoading={cudLoading}
        handleSave={handleSave}
        row={row}
        typesById={typesById}
        typesAllIds={typesAllIds}
        noType={noType}
      />
    )

  return (
    <PeopleForm
      row={row}
      cudLoading={cudLoading}
      typesAllIds={typesAllIds}
      typesById={typesById}
      noType={noType}
      confirmAction={confirmAction}
      handleSave={handleSave}
      handleCancel={handleCancel}
      addOpen={addOpen}
      table={table}
    />
  )
}

export default React.memo(AssetsForm)
