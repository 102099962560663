import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setTableProp,
  fetchCategoryMeasuresByTimeAgo,
  fetchCategoryMeasuresByAbsoluteTime,
} from 'Store/actions/tables-action'
import { useTranslation } from 'react-i18next'
import { jsonGroup } from 'Components/Map/PopupMarker/jsonGroup.js'

function MeasuresHistCategoriesDropdown() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentTableId = useSelector((state) => state.tables.current?.id ?? 'measuresHist')
  const categoriesOptions = useSelector((state) => state.tables.byId.measuresHist.categoriesOptions)
  const filterStepLoading = useSelector((state) => state.tables.byId.measuresHist.filterStepLoading)
  const selectedRelativeTime = useSelector((state) => state.tables.byId[currentTableId].selectedRelativeTime)
  const selectedAbsoluteStartTime = useSelector((state) => state.tables.byId[currentTableId].selectedAbsoluteStartTime)
  const selectedAbsoluteEndTime = useSelector((state) => state.tables.byId[currentTableId].selectedAbsoluteEndTime)
  const selectedAsset = useSelector((state) => state.tables.byId[currentTableId].selectedAsset)
  const selectedCategory = useSelector((state) => state.tables.byId[currentTableId].selectedCategory)
  const filterStepError = useSelector((state) => state.tables.byId[currentTableId].filterStepError)
  const loginProfile = useSelector((state) => state.login.perfil)

  const auxOptions = categoriesOptions.length > 0 ? categoriesOptions : selectedCategory
  const options = auxOptions
    .filter((each) => loginProfile === 'DEV' || jsonGroup[each]?.levelAccess === 'basic')
    .map((cat) => {
      return { key: cat, value: cat, text: t(cat), icon: jsonGroup[cat]?.getIcon() || 'bug' }
    })
    .sort((a, b) => a.text.localeCompare(b.text))

  function setFilter(value) {
    dispatch(setTableProp(`byId.${currentTableId}.selectedCategory`, [value]))
    dispatch(setTableProp(`byId.${currentTableId}.selectedMeasures`, []))
    if (value) {
      if (selectedAbsoluteStartTime && selectedAbsoluteEndTime) {
        dispatch(
          fetchCategoryMeasuresByAbsoluteTime(
            selectedAsset,
            value,
            new Date(selectedAbsoluteStartTime).toISOString(),
            new Date(selectedAbsoluteEndTime).toISOString()
          )
        )
      } else {
        dispatch(fetchCategoryMeasuresByTimeAgo(selectedAsset, value, selectedRelativeTime[0]))
      }
    }
  }

  return (
    <Dropdown
      selection
      search
      fluid
      placeholder="Selecionar Categoria"
      upward={false}
      options={options}
      onChange={(a, { value }) => setFilter(value)}
      noResultsMessage={
        filterStepLoading === 2 ? 'Carregando opções, aguarde...' : 'Nenhum resultado encontrado no filtro selecionado.'
      }
      loading={filterStepLoading === 2}
      value={selectedCategory[0]}
      error={filterStepError === 2}
    ></Dropdown>
  )
}

export default MeasuresHistCategoriesDropdown
