/*  
  Author: Luís Mourão
  Type: Action
  
  Description: 
    - Ações Dashboard Store
  
  TODO: 
    
*/

import request, { urlBase } from 'Apis/rest'

export const fetchDashboardStellantis =
  ({ time, noLoading }) =>
  async (dispatch) => {
    const jwt = localStorage.getItem('id_token')
    const headers = {
      headers: { Authorization: 'Bearer ' + jwt },
    }
    if (!noLoading) dispatch({ type: 'SET_FALSE', payload: 'showDashboard' })
    request.get(urlBase + `/assets/dashboard?time=${time}`, headers).then((res) => {
      //console.log("res", res.data)
      dispatch({ type: 'FETCH_DASHBOARD_STELLANTIS', payload: res.data })
      if (!noLoading) dispatch({ type: 'SET_TRUE', payload: 'showDashboard' })
    })
  }

export const setChart = (payload) => {
  return {
    type: 'SET_CHART_STELLANTIS',
    payload,
  }
}
