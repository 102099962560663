import React, { useEffect, useCallback } from 'react'
import Maps from '.'
import { saveLoginPreferences } from 'Store/actions/login-action'
import { useDispatch } from 'react-redux'
import { setTrue } from 'Store/actions/toggle-action'
import { setTableProp } from 'Store/actions/tables-action'

export const RenderMap = () => {
  const dispatch = useDispatch()
  const handleUnload = useCallback(
    (e) => {
      const message = 'o/'
      ;(e || window.event).returnValue = message //Gecko + IE
      dispatch(saveLoginPreferences())
      return message
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(setTableProp('current.tab', 'map'))
    dispatch(setTrue('displayingMap'))
    dispatch(setTrue('loadingScreen'))
  }, [dispatch])

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload)
    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [handleUnload])

  return <Maps />
}
