import request, { urlBase, URL_BASE } from 'Apis/rest'
import axios from 'axios'
/*global localStorage*/
export const sensorAction = {
  novoSensor(acao) {
    acao.dispatch({
      type: 'server/insere/sensor',
      fields: {
        nome: `'Sensor Novo'`,
        id_tipo_sensor: 0,
        info: `'{}'`,
        empresa: acao.empresa,
        login: acao.email,
      },
    })
  },
  deleteManySensor(ids_sensors) {
    return {
      type: 'deletaSensors',
      payload: ids_sensors,
    }
  },
  deleteManySensorServer(sensors) {
    return {
      type: 'server/deleta/sensor',
      where: ['id_sensor IN (' + sensors + ')'],
    }
  },
  updateSensorServer(id, valor, campo) {
    let campovalor = {}
    if (campo === 'info') {
      campovalor['info'][campo] = valor
    }
    campovalor[campo] = valor
    //console.log('Campo valor: ', campovalor)
    return {
      type: 'server/atualiza/sensor',
      fields: campovalor,
      where: { id_sensor: id },
    }
  },
}

export const clearSensorStates = () => {
  return {
    type: 'SENSOR_CLEAR_STATES',
  }
}

export const updateDeviceById =
  ({ id, ...newValues }) =>
  async (dispatchEvent) => {
    dispatchEvent({
      type: 'UPDATE_SENSOR_START',
    })
    const jwt = localStorage.getItem('id_token')
    const url = `${URL_BASE}/devices/details/${id}`
    const config = {
      headers: { Authorization: `Bearer ${jwt}` },
    }
    try {
      const res = await axios.put(url, newValues, config)
      dispatchEvent({
        type: 'UPDATE_SENSOR_SUCCESS',
        payload: res.data,
        id,
      })
    } catch (error) {
      dispatchEvent({
        type: 'UPDATE_SENSOR_ERROR',
      })
      console.log(error)
    }
  }

export const provisionDevice = (device) => async (dispatchEvent) => {
  dispatchEvent({
    type: 'PROVISION_DEVICE_START',
  })
  const jwt = localStorage.getItem('id_token')
  const url = `${URL_BASE}/devices/provision`
  const config = {
    headers: { Authorization: `Bearer ${jwt}` },
  }
  try {
    const res = await axios.post(url, device, config)
    console.log('res provision device', res)
    dispatchEvent({
      type: 'PROVISION_DEVICE_SUCCESS',
      payload: res.data,
    })
  } catch (error) {
    dispatchEvent({
      type: 'PROVISION_DEVICE_ERROR',
      payload: error.response.data,
    })
    console.log(error)
  }
}

export const disableFenceSensor = (sensor, cerca, login) => {
  return {
    type: 'server/atualiza/sensor_modulo',
    fields: { status: false, login: login, timeupdate: Date.now() },
    where: { id_sensor: sensor, id_modulo: cerca },
  }
}

export const enableFenceSensor = (sensor, cerca, login) => {
  return {
    type: 'server/insere/sensor_modulo',
    fields: { id_sensor: sensor, id_modulo: cerca, autor: login, login },
  }
}

export const SET_CALIBRATION = (id, index, newValues) => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  request
    .put(
      '/core/v0/sensors/calibration',
      { id, index, newValues },
      {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      }
    )
    .catch((e) => console.error('Error on set calibration', e))
}

export const SET_CALIBRATION_TRANSFORMATION = (params) => {
  const jwt = localStorage.getItem('id_token')
  axios
    .put('https://nodered.phygitall.com/transformation', params, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    .catch((e) => console.error('Error on set transformation', e))
}

export const toggleChannel = (params) => async (dispatch) => {
  try {
    const jwt = localStorage.getItem('id_token')
    dispatch({
      type: 'TOGGLE_CHANNEL',
      payload: params,
    })
    axios
      .post('https://nodered.phygitall.com/togglechannel', params, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((res) =>
        dispatch({
          type: 'TOGGLE_CHANNEL_SUCCESS',
          payload: res?.data,
        })
      )
  } catch (err) {
    console.error(err)
    dispatch({
      type: 'TOGGLE_CHANNEL_ERROR',
      payload: err,
    })
  }
}

export const fetchDevices = () => async (dispatch, getState) => {
  const jwt = localStorage.getItem('id_token')
  try {
    dispatch({ type: 'FETCH_DEVICES_START' })
    axios
      .get(`${urlBase}/devices`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
      .then((resp) => {
        if (!resp.data.body) {
          console.error('FETCH_DEVICES_FAILURE', resp.data)
          dispatch({ type: 'FETCH_DEVICES_FAILURE', payload: resp.data })
        } else {
          dispatch({ type: 'FETCH_DEVICES', payload: JSON.parse(resp.data.body) })
        }
      })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FETCH_DEVICES_FAILURE', payload: err })
  }
}

export const clearDevices = () => async (dispatch) => {
  dispatch({ type: 'CLEAR_DEVICES' })
}

export const updateDevice =
  ({ id, field, value }) =>
  async (dispatch, getState) => {
    const jwt = localStorage.getItem('id_token')
    try {
      if (!id) console.error('UpdateDevice without id:' + id)
      else {
        dispatch({ type: 'UPDATE_DEVICE_START' })
        axios
          .put(
            `${urlBase}/devices?id=` + id,
            { field: field, value: value },
            {
              headers: {
                Authorization: 'Bearer ' + jwt,
              },
            }
          )
          .then((resp) => {
            if (!resp.data.body) {
              console.error('UPDATE_DEVICE_FAILURE', resp.data)
              dispatch({ type: 'UPDATE_DEVICE_FAILURE', payload: resp.data })
            } else {
              dispatch({ type: 'UPDATE_DEVICE', payload: resp.data.body })
            }
          })
      }
    } catch (err) {
      console.error(err)
      dispatch({ type: 'FETCH_DEVICES_FAILURE', payload: err })
    }
  }
