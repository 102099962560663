import React from 'react'
import styled from 'styled-components'

const TableSpan = ({
  value,
  width = '350px',
  capitalize = false,
  padding = '10px',
  minWidth = '0px',
  noTitle = false,
}: {
  value: string
  width?: string
  capitalize?: boolean
  padding?: string
  minWidth?: string
  noTitle?: boolean
}) => {
  return (
    <StyledSpan
      title={noTitle ? null : value}
      width={width}
      capitalize={capitalize}
      padding={padding}
      minWidth={minWidth}
    >
      {value}
    </StyledSpan>
  )
}

const StyledSpan = styled.span`
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ width }) => width};
  display: inline-block;
  padding: ${({ padding }) => padding} !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : 'none')};
`

export default TableSpan
