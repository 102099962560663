import React from 'react'
import { Card, List, Image, Grid, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const SettingsCard = ({ title, image, links, goTo }) => (
  <Card>
    <Grid centered padded>
      <Grid.Row>
        <Image size="tiny" circular as={Link} to={goTo} src={image} wrapped />
      </Grid.Row>
      <Grid.Row>
        <Header as={Link} to={goTo} color="blue">
          {title}
        </Header>
      </Grid.Row>
    </Grid>
    <Card.Content>
      <Card.Description>Links uteis:</Card.Description>
      <List>
        {links &&
          links.map((link, i) => (
            <List.Item key={i} as="a">
              {link}
            </List.Item>
          ))}
      </List>
    </Card.Content>
  </Card>
)

export default SettingsCard
