import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { StatsBarFormGeoArea } from './StatsBarFormGeoArea'
import { StatsBarFormGeoLength } from './StatsBarFormGeoLength'

const FormStatsBarGeo = ({ info, moduleType }) => {
  const { t } = useTranslation()

  return moduleType === 'refs' ? (
    <Form>
      <Form.Field
        id="form-lat"
        control={Input}
        label={t('lat')}
        placeholder={t('lat')}
        value={info?.geometry?.coordinates[1] || 'missing Lat'}
      />
      <Form.Field
        id="form-lng"
        control={Input}
        label={t('lng')}
        placeholder={t('lng')}
        value={info?.geometry?.coordinates[0] || 'missing Lng'}
      />
    </Form>
  ) : moduleType === 'routes' ? (
    <StatsBarFormGeoLength info={info} />
  ) : (
    <StatsBarFormGeoArea info={info} />
  )
}
export default FormStatsBarGeo
