import React from 'react'
//import {  useDispatch } from 'react-redux'
import { Icon, Menu } from 'semantic-ui-react'
//import { setFalse } from 'Store/actions/toggle-action'
import { useTranslation } from 'react-i18next'

const StatsBarButtons = ({ handleChange, currentForm, buttons }) => {
  // const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <Menu compact icon="labeled">
      {buttons.map((b) => {
        return (
          <Menu.Item
            key={b.name}
            color={'green'}
            name={b.name}
            style={{ color: 'black', textTransform: 'capitalize' }}
            active={currentForm === b.name}
            onClick={handleChange}
            disabled={b.disabled || false}
            title={t(b.name)}
          >
            <Icon name={b.icon} />
            {t(b.name)}
          </Menu.Item>
        )
      })}
    </Menu>
  )
}
export default StatsBarButtons
