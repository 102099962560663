import React, { useEffect } from 'react'
import { Modal, Icon, List, Header, Button } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { saveLoginPreferences, setLoginProp } from 'Store/actions/login-action'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import PreferencesModalItem from './PreferencesModalItem'
import { useTranslation } from 'react-i18next'
import { notificationState, NotificationStatus } from 'Utils/notifications'

let gotInitialPanels = false
let initialPanelsIds

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <List divided verticalAlign="middle">
      {children}
    </List>
  )
})

const DragHandle = sortableHandle(() => <Icon name="bars" style={{ cursor: 'grab' }} />)

const SortableItem = sortableElement(({ value, index, firstItem }) => (
  <List.Item>
    <PreferencesModalItem value={value} DragHandle={DragHandle} index={index} firstItem={firstItem} />
  </List.Item>
))

const ContentStyle = {
  paddingTop: '0.1vh',
  paddingBottom: '0.1vh',
  paddingLeft: '2vw',
  paddingRight: '2vw',
  height: '70vh',
  overflowY: 'auto',
  borderTop: '0.5vh solid #4b72b3',
  borderBottom: '0.5vh solid #4b72b3',
}

const ActionsStyle = {
  textAlign: 'center',
  marginTop: '2vh',
  marginBottom: '2vh',
}

function PreferencesModal() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const cudLoading = useSelector((state) => state.login.cudLoading)
  const cudSuccess = useSelector((state) => state.login.cudSuccess)

  const routesPanelsIdsStr = useSelector((state) => JSON.stringify(state.login.preferences.routesPanels.allIds))
  let routesPanelsIds = JSON.parse(routesPanelsIdsStr)

  // set initial panels ids only once
  if (!gotInitialPanels) {
    gotInitialPanels = true
    initialPanelsIds = routesPanelsIds
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(setLoginProp('preferences.routesPanels.allIds', arrayMoveImmutable(routesPanelsIds, oldIndex, newIndex)))
  }

  const handleCancel = () => {
    // reset items' order
    dispatch(setLoginProp('preferences.routesPanels.allIds', initialPanelsIds))
  }

  const handleSave = () => {
    dispatch({ type: 'UPDATE_TAB_ACTION_START' })
    initialPanelsIds = routesPanelsIds
    dispatch(setLoginProp('preferences.routesPanels.allIds', routesPanelsIds))
    dispatch(saveLoginPreferences())
  }

  useEffect(() => {
    if (cudSuccess) {
      dispatch({ type: 'CLEAR_TAB_STATE' })
    }
  }, [dispatch, cudSuccess])

  if (cudSuccess) {
    notificationState.addNotification({
      title: 'preferences.saved',
      message: 'preferences.saved.successful',
      status: NotificationStatus.SUCCESS,
    })
  }

  return (
    <>
      <Modal.Header style={{ padding: '0' }}>
        <Header style={{ textTransform: 'capitalize', marginLeft: '2vw', marginTop: '2vh', marginBottom: '2vh' }}>
          {t('Preferences')}
        </Header>
      </Modal.Header>
      <Modal.Content scrolling style={ContentStyle}>
        <SortableContainer onSortEnd={onSortEnd} useDragHandle hideSortableGhost>
          {routesPanelsIds.map((value, index) => (
            <SortableItem key={`item-${value}`} index={index} value={value} firstItem={routesPanelsIds[0]} />
          ))}
        </SortableContainer>
      </Modal.Content>
      <Modal.Actions style={ActionsStyle}>
        <Button
          icon={{ name: 'cancel', style: { zIndex: '1' } }}
          style={{ width: '10vw', height: '6vh', marginRight: '10vw' }}
          content={t('Cancel')}
          onClick={handleCancel}
        />
        <Button
          icon={'check'}
          style={{ width: '10vw', height: '6vh' }}
          content={t('Save')}
          color={'green'}
          loading={cudLoading}
          disabled={cudLoading}
          onClick={handleSave}
        />
      </Modal.Actions>
    </>
  )
}

export default PreferencesModal
