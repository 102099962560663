import React from 'react'
import { useDispatch } from 'react-redux'
import { Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { showConfirm, hideConfirm } from 'Store/actions/system-action'
import { deleteModules } from 'Store/actions/modules-action'
import { setFalse } from 'Store/actions/toggle-action'

const StatsBarActionsDelete = ({ id, moduleType }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClick = () => {
    const data = {
      content: (
        <div style={{ width: '40vw', height: 'auto', fontSize: '1.5em', padding: '10px' }}>
          {' '}
          <span>{t('areYouSureYouWishToContinue')}</span>
        </div>
      ),
      onConfirm: () => {
        dispatch(deleteModules(id))
        dispatch(hideConfirm())
        dispatch(setFalse('showStatsBar'))
      },
      onCancel: () => dispatch(hideConfirm()),
      confirmHeader: t('moduleToDelete'),
    }
    dispatch(showConfirm(data))
  }
  return (
    <Icon
      id="icon-delete"
      name="trash alternate outline"
      title={t('lbldelete')}
      size="large"
      circular
      onClick={handleClick}
      style={{ backgroundColor: 'red', color: 'white', cursor: 'pointer' }}
    />
  )
}
export default StatsBarActionsDelete
