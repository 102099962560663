import React from 'react'
import { Button, Segment } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useCanAccess } from 'Utils/rbac'

const StatsBarFooter = ({ saveAction, cancelAction }) => {
  const canAccess = useCanAccess('WRITE')
  const { t } = useTranslation()

  return (
    <Segment style={{ width: '100%', bottom: 0, position: 'absolute', border: 'none' }}>
      <Button
        floated="left"
        icon={{ name: 'delete', style: { zIndex: '1' } }}
        fluid={!canAccess}
        id="form-button-cancel-statsbar"
        onClick={() => cancelAction()}
        content={canAccess ? t('Cancel') : t('Close')}
        style={{ minWidth: '120px' }}
        title={t('clickToCancel')}
      />
      {canAccess && (
        <Button
          floated="right"
          icon={'check'}
          id="form-button-save-statsbar"
          onClick={() => saveAction()}
          color="green"
          content={t('Save')}
          style={{ minWidth: '120px' }}
          title={t('clickToSave')}
        />
      )}
    </Segment>
  )
}
export default StatsBarFooter
