import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Segment, Icon, Header, Image, Grid, Modal } from 'semantic-ui-react'
import { setFalse, setTrue } from 'Store/actions/toggle-action'

const StatsBarImage = ({ name = '', url = '' }) => {
  const dispatch = useDispatch()
  const show = useSelector((state) => state.toggle.showStatsBarImage)

  return (
    <Modal
      onOpen={() => {
        dispatch(setTrue('showStatsBarImage'))
      }}
      onClose={() => {
        dispatch(setFalse('showStatsBarImage'))
      }}
      open={show}
      size="large"
      style={{ width: '60vw', height: '95vh' }}
      trigger={
        <Image
          circular
          src={url}
          size={'small'}
          style={{ cursor: 'pointer' }}
          onClick={() => dispatch(setTrue('showStatsBarImage'))}
        />
      }
    >
      <Segment style={{ width: '60vw', border: 'none' }}>
        <Grid columns={16}>
          {/* these widths need to be refactored */}
          <Grid.Column style={{ width: '56vw' }}>
            <Header as="h2">{name}</Header>
          </Grid.Column>

          {/* if this is greater than 1vw, then it goes "under" the Header */}
          <Grid.Column style={{ width: '1vw' }}>
            <Icon
              title="Fechar"
              name="close"
              position="right"
              onClick={() => dispatch(setFalse('showStatsBarImage'))}
              style={{ right: 0, cursor: 'pointer' }}
            />
          </Grid.Column>
        </Grid>
      </Segment>

      {/* TODO: allow various images */}
      <Image src={url} centered style={{ maxWidth: '95%', maxHeight: '85%' }} />
    </Modal>
  )
}

export default StatsBarImage
