import React, { useCallback } from 'react'
import { Modal, /*Icon,*/ List, Header, Button, Image, Grid, Placeholder } from 'semantic-ui-react'
import styled from 'styled-components'
import { logError } from 'Utils/analytics.js'
import { createImageFromInitials } from 'Utils/components/ImageInitials'
import { ItemDetailsField } from './ItemDetailsField.jsx'

const fieldOrder = [
  { field: 'name', label: 'Nome', type: 'string', desc: 'Nome e Sobrenome da pessoa' },
  { field: 'cardId', label: 'NFC', type: 'string', desc: 'Valor do NFC em decimal (escrito atrás do crachá plástico)' },
  {
    field: 'onBoarding',
    label: 'Ambientação',
    type: 'string',
    desc: 'Se recebeu treinamento de ambientação',
  },
  {
    field: 'lastRegister',
    label: 'Ultima atualização',
    type: 'timestamp',
    desc: 'Relativo ao tempo que esses dados foram salvos',
  },
  { field: 'email', label: 'E-mail', type: 'string', desc: 'Email da pessoa, se disponível' },
  { field: 'cellPhoneNumber', label: 'Telefone', type: 'string | null', desc: 'Telefone da pessoa, se disponível' },
  {
    field: 'registration',
    label: 'Matrícula',
    type: 'number | null',
    desc: 'Número da matrícula do funcionário, se disponível',
  },
  /* {
      field: 'certifications',
      label: 'Treinamentos',
      type: 'Array | null',
      desc: 'Lista de treinamentos da pessoa, se disponível',
    },*/
  { field: 'company', label: 'Empresa', type: 'string', desc: 'Empresa da pessoa, se disponível' },
  { field: 'position', label: 'Cargo', type: 'string | null', desc: 'Cargo da pessoa, se disponível' },
  {
    field: 'assignment',
    label: 'Assignment',
    type: 'enum | null',
    desc: 'Dentre as Atribuições possíveis da tabela de atribuições',
  },
  //{ field: 'lastIn', label: 'Último Horário de Entrada', type: 'string', desc: 'Horário de Entrada segundo API' },
  //{ field: 'lastOut', label: 'Último Horário de Saída', type: 'string', desc: 'Horário de Saída segundo API' },
]
const DivInfo = styled.div`
  margin-left: 40px;
  width: 800px;
`

const ItemDetailsModal = ({ id, tryPreviousDate, dateRef, value, setOpen, error, loadingButton }) => {
  const getPhotoUrl = useCallback((value) => {
    if (value.data?.photo?.content) {
      return `data:image/${value.data.photo.type};base64,${value.data.photo.content}`
    } else {
      return createImageFromInitials(500, value.data.name || 'NA', '#4b72b2')
    }
  }, [])
  if (value?.status === 204)
    return (
      <>
        <Modal.Header>{id} não foi encontrado</Modal.Header>
        <Modal.Content>
          <p>
            Código nfc {id} não foi encontrado no suricato no dia <BoldedDate>{dateRef}</BoldedDate>.
          </p>
          <Button onClick={tryPreviousDate} loading={loadingButton} disabled={loadingButton}>
            Tentar uma data anterior
          </Button>
        </Modal.Content>
      </>
    )
  if (error?.response?.status === 500) {
    logError(error)
    return (
      <>
        <Modal.Header>Ops! Algo deu errado na API do suricato.</Modal.Header>
        <Modal.Content>
          <p>Tente novamente mais tarde</p>
          <p>Caso o erro persista, consulte o suporte técnico informando o erro.</p>
        </Modal.Content>
      </>
    )
  }
  return (
    <>
      <Modal.Header>
        Detalhes do {id} - hora de referência: {dateRef}
      </Modal.Header>
      <Modal.Content image>
        {value ? (
          <Image size="medium" src={getPhotoUrl(value)} wrapped />
        ) : (
          <>
            <Placeholder square style={{ height: 209.45, width: 209.45 }}>
              <Placeholder.Image />
            </Placeholder>
          </>
        )}
        <DivInfo>
          <Header style={{ marginBottom: '34px' }}>Informações Pessoais</Header>
          <Grid columns={'equal'}>
            <Grid.Column width={6}>
              <List>
                {fieldOrder.map(
                  (li, i) =>
                    i % 2 === 0 && (
                      <ItemDetailsField key={`detailItem${li.field}`} li={li} value={value?.data} tenant={'Tiplam'} />
                    )
                )}
              </List>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={4}>
              <List>
                {fieldOrder.map(
                  (li, i) =>
                    i % 2 === 1 && (
                      <ItemDetailsField key={`detailItem${li.field}`} li={li} value={value?.data} tenant={'Tiplam'} />
                    )
                )}
              </List>
            </Grid.Column>
          </Grid>
        </DivInfo>
      </Modal.Content>
      <Modal.Actions>
        {/*<Button color='black' onClick={() => setOpen(false)}>
        Nope
        </Button>*/}
        <Button content="OK" labelPosition="right" icon="checkmark" onClick={() => setOpen(false)} positive />
      </Modal.Actions>
    </>
  )
}

export default ItemDetailsModal

const BoldedDate = styled.span`
  font-weight: bold;
`
