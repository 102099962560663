import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Icon, Card, Statistic, Menu } from 'semantic-ui-react'
import { SvgIcon } from 'Utils/components/SvgIcon.jsx'

export const CardLifeTotal = ({ fenceName }) => {
  const assetsIds = useSelector((state) => state.assets?.allActivePeopleIds || [], shallowEqual)
  const assets = useSelector((state) => state.assets.byId || {}, shallowEqual)

  const typesIds = useSelector((state) => state.modules.types.allIds || [], shallowEqual)
  const types = useSelector((state) => state.modules.types.byId || [])
  const countedTypes = (typeId) => assetsIds.filter((a) => parseInt(assets[a]?.info?.type) === parseInt(typeId)).length
  const noTypes = () => assetsIds.filter((a) => !assets[a]?.info?.type).length
  const xNoType = noTypes()
  const typesObj = typesIds
    .filter((tp) => types[tp].info.table === 'assets.people')
    .map((t) => ({ ...types[t], x: countedTypes(t) }))
    .sort((a, b) =>
      a.info.label && b.info.label ? a.info.label.toLowerCase().localeCompare(b.info.label.toLowerCase()) : 1
    )

  // const assets = useSelector((state) => state.assets.byId)
  const [details, setDetails] = React.useState(true)

  /*Margin calculations*/
  const histType = useSelector((state) => state.history.type)

  return (
    <Card
      color="blue"
      style={{
        width: `${(typesObj.length + (xNoType !== 0 ? 1 : 0)) * 70 + 70}px`,
        minWidth: '250px',
        marginTop: histType ? '112px' : '62px',
        zIndex: '900',
        backgroundColor: 'rgb(255,255,255,0.6)',
      }}
    >
      <Card.Content>
        <Statistic size="mini" floated="right" color={'black'}>
          <Statistic.Value>
            <Icon name={'users'} /> {assetsIds?.length?.toString()?.padStart(4, '0')}
          </Statistic.Value>
        </Statistic>

        <Card.Header onClick={() => setDetails(!details)} style={{ cursor: 'pointer' }}>
          Total de Vidas {typesObj?.length > 0 && <Icon name={details ? 'caret down' : 'caret right'} color="black" />}
        </Card.Header>
        {details && typesObj?.length > 0 && (
          <Card.Description>
            <Menu
              widths={typesObj.length + (xNoType ? 1 : 0)}
              compact
              icon="labeled"
              size="mini"
              style={{ backgroundColor: 'rgb(255,255,255,0.6)' }}
            >
              {typesObj.map((t) => (
                <Menu.Item key={t.info.label} name={t.info.label}>
                  <SvgIcon title={t.info.label} color={t.info.color} name={t.info.icon} compact />
                  {t.info.label.slice(0, 4) + ': ' + t.x.toString().padStart(3, '0')}
                </Menu.Item>
              ))}
              {xNoType !== 0 && (
                <Menu.Item key="semtipodefinido" name="close">
                  <SvgIcon title={'Sem tipo definido'} color={'gray'} name={'worker'} compact />
                  {'Indef: ' + xNoType}
                </Menu.Item>
              )}
            </Menu>
          </Card.Description>
        )}
      </Card.Content>
    </Card>
  )
}
export default CardLifeTotal
