import { TOUCH_NOTIFICATION, FETCH_NOTIFICATION_COUNT } from '../actions/notification-action'
import { union } from 'lodash'
import initialStore from 'Store/initialStore'
import { toast } from 'react-toastify'
import ToastItem from 'Utils/notifications/components/ToastItem.js'
import { playAudio } from 'Utils/playAudio'
import React from 'react'

const initialState = initialStore.notificationProfiles

export default function notificationReducer(state = initialState, { type, payload, login }) {
  switch (type) {
    case 'FETCH_NOTIFICATION_START':
      return {
        ...state,
        isFetching: payload === 0 ? true : false,
        pageFetching: payload > 0 ? true : false,
        currentPage: payload,
      }
    case 'FETCH_NOTIFICATION_SUCCESS':
      const byId = {
        ...state.byId,
        ...payload.items.reduce((res, notification) => {
          return { ...res, [notification.id]: notification }
        }, {}),
      }
      const allIds = union(
        state.allIds,
        payload.items.map((notification) => notification.id)
      )

      return {
        ...state,
        allIds,
        byId,
        assetsInEmergency: allIds.reduce((prev, id) => {
          const notification = byId?.[id]
          if (notification?.type === 'help' && !notification?.closedAt) {
            const assetId = notification?.assetId
            return prev.includes(assetId) ? prev : [...prev, assetId]
          } else return prev
        }, []),
        isFetching: false,
        pageFetching: false,
        initialFetch: false,
        allActiveNotifications: allIds.filter((id) => !byId[id]?.closedAt),
        nextToken: payload.nextToken,
        onGoingEmergency: allIds.some((id) => byId[id].type === 'help' && !byId[id].closedAt),
      }
    case 'FETCH_NOTIFICATION_FAILURE':
      return { ...state, success: false, isFetching: false }

    case TOUCH_NOTIFICATION:
      const notification = state.byId[payload.msgId]
      const readArray = notification.readBy.includes(payload.email)
        ? notification.readBy
        : [...notification.readBy, payload.email]
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.msgId]: {
            ...notification,
            readBy: readArray,
          },
        },
      }
    case FETCH_NOTIFICATION_COUNT:
      return { ...state, ...payload }
    case 'CHANGE_NOTIFICATION_PAGE':
      return { ...state, currentPage: payload }

    case 'NEW_NOTIFICATION': {
      const profile = payload.profile || {
        screenTime: '_5s',
        icon: 'warning',
        txtColor: 'black',
      }

      //tem confirmed e confirmedBy !== currentUser   -   Toast com msg diferente
      //tem confirmed e confirmedBy === currentUser   -   Não mostrar toast
      //não tem confirmed                             -   Mostra Toast com a msg normal
      const confirmedByCurrentUser = payload.confirmedBy === login
      if (profile && profile.screenTime !== 'noScreen' && !confirmedByCurrentUser && !payload.closedAt) {
        const { bgColor, screenTime, sound } = profile
        const options = {
          autoClose: screenTime === 'permanent' ? false : parseInt(screenTime.replace('_', '')) * 1000,
        }
        switch (bgColor) {
          case 'red':
            toast.error(<ToastItem profile={profile} payload={payload} />, options)
            break
          case 'green':
            toast.success(<ToastItem profile={profile} payload={payload} />, options)
            break
          case 'yellow':
            toast.warning(<ToastItem profile={profile} payload={payload} />, options)
            break
          default:
            toast.info(<ToastItem profile={profile} payload={payload} />, options)
            break
        }
        var audio
        if (sound && sound !== 'none') {
          if (sound === 'alert') audio = new Audio('./alert-sound-effect.mp3')
          if (sound === 'alarm') audio = new Audio('./alarm.mp3')
          if (sound === 'sos') audio = new Audio('./SOS.mp3')
          if (audio && !window.currentAudio) {
            window.currentAudio = audio
            playAudio(audio, screenTime === 'permanent')
          }
        }
      }

      const byId = { ...state.byId, [payload.id]: payload }
      const allIds = Object.values(byId)
        .sort((a, b) => (a.time < b.time ? 1 : -1))
        .map((notification) => notification.id)
      return {
        ...state,
        allIds,
        byId,
        assetsInEmergency: allIds.reduce((prev, id) => {
          const notification = byId?.[id]
          if (notification?.type === 'help' && !notification?.closedAt) {
            const assetId = notification?.assetId
            return prev.includes(assetId) ? prev : [...prev, assetId]
          } else return prev
        }, []),
        onGoingEmergency: payload.type === 'help' && !payload.closedAt,
      }
    }

    case 'NOTIFICATION_FEEDBACK_START': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...state.byId[payload.id], loadingState: true },
        },
      }
    }

    case 'MUTATE_NOTIFICATION_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: { ...payload, loadingState: false },
        },
      }
    }

    case 'EMERGENCY_STOP':
      if (window.currentAudio) {
        window.currentAudio.pause()
        delete window.currentAudio
      }
      return {
        ...state,
        onGoingEmergency: false,
      }

    case 'EMERGENCY_START': {
      return {
        ...state,
        onGoingEmergency: true,
      }
    }

    case 'SET_EMERGENCY_BOUNDS':
      return {
        ...state,
        onGoingEmergency: !state.onGoingEmergency,
      }

    default:
      return state
  }
}
/*** Selectors ***/

export const getNewNotifications = (state) => {
  const { newIds, byId } = state
  return newIds.sort((a, b) => b - a).map((id) => byId[id])
}

export const getIsFetchingNotifications = (state) => (state.isFetching ? state.isFetching : false)
export const getNextPage = (state) => state.pagination.nextPage
export const getTotalPages = (state) => state.pagination.totalPages
export const getCurrentPage = (state) => state.pagination.currentPage
