import axios from 'axios'
import { urlServer } from 'Utils/url'
import { notificationState, NotificationStatus } from 'Utils/notifications'

export const urlBase = 'https://app.phygitall.com/v0'

export const URL_BASE = process.env.URL_BASE ?? 'https://webapi.phygitall.com'

const api = axios.create({
  baseURL: urlServer(),
  timeout: 60000,
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      notificationState.addNotification({
        title: 'Session expired',
        message: 'Your session seems to be expired, please log in again...',
        status: NotificationStatus.ERROR,
        sticky: true,
        actions: {
          primary: [
            {
              run: () => document.location.reload(),
              label: 'Sign in',
            },
          ],
        },
      })
      return Promise.reject(error)
    }
    if (error?.response?.status !== 200 || error?.response?.status !== 201) {
      notificationState.addNotification({
        title: 'Erro no carregamento de dados',
        message: 'Aconteceu um problema, tente novamente mais tarde',
        status: NotificationStatus.ERROR,
      })
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

export default api
