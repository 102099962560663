import React from 'react'
import { Modal, Button, SemanticCOLORS } from 'semantic-ui-react'
import { ModalCloseIcon } from 'Utils/reactTable/components/ModalCloseIcon.jsx'

interface ModalTemplateProps {
  icon?: string
  onClose?: () => void
  open: boolean
  header: JSX.Element
  children: React.ReactNode
  size?: 'small' | 'large'
  loading?: boolean
  saveContent?: string
  saveColor?: SemanticCOLORS
  onSave?: () => void
  saveIcon?: string
  cancelContent?: string
  cancelColor?: SemanticCOLORS
  onCancel?: () => void
  cancelIcon?: string
  contentPXMargin?: number
  saveButton?: boolean
}

export const ModalTemplate = ({
  icon,
  onClose,
  open,
  header = <span>Cabeçalho</span>,
  children,
  size = 'small',
  loading,
  saveContent = 'Salvar',
  saveColor = 'blue',
  onSave,
  saveIcon = 'check',
  cancelContent = 'Cancelar',
  cancelColor,
  onCancel,
  cancelIcon = 'delete',
  contentPXMargin = 15,
  saveButton = true,
}: ModalTemplateProps) => {
  const [localOpen, setLocalOpen] = React.useState(false)
  if (open === undefined) open = localOpen
  const handleClose = () => (typeof onClose === 'function' ? onClose() : setLocalOpen(false))
  return (
    <Modal
      onClose={handleClose}
      icon={icon}
      open={open}
      size={size}
      closeIcon={<ModalCloseIcon action={handleClose} />}
      closeOnDimmerClick={false}
    >
      <Modal.Header>{header}</Modal.Header>
      <br />
      <Modal.Content style={{ padding: '0 1.6em', margin: `${contentPXMargin}px 0 ${contentPXMargin + 15}px 0` }}>
        {children}
      </Modal.Content>
      <Modal.Actions style={{ height: '65px' }}>
        {saveButton && (
          <Button
            icon={saveIcon}
            style={{ minWidth: '120px' }}
            loading={loading}
            disabled={loading}
            content={saveContent}
            color={saveColor}
            onClick={() => (typeof onSave === 'function' ? onSave() : setLocalOpen(false))}
          />
        )}
        <Button
          style={{ float: 'left', minWidth: '120px' }}
          icon={{ name: cancelIcon, style: { zIndex: '1' } }}
          content={cancelContent}
          color={cancelColor}
          onClick={() => (typeof onCancel === 'function' ? onCancel() : setLocalOpen(false))}
        />
      </Modal.Actions>
    </Modal>
  )
}
