import moment from 'moment'
import React, { Fragment } from 'react'
import { Button, Header, Loader, Message, Segment } from 'semantic-ui-react'

export interface IData {
  name: string
  email: string
  hash: string
  beginning: string
  expiration: string
}

interface IContent {
  handleClick: (hasApproved: boolean) => void
  success: boolean | undefined
  error: boolean
  isLoadingApprove: boolean
  isLoadingReject: boolean
  data: IData | undefined
  approved?: boolean
  errorMsg: string
}

export const Content = ({
  handleClick,
  success,
  data,
  error,
  isLoadingApprove,
  isLoadingReject,
  approved,
  errorMsg,
}: IContent) => {
  if (error) return <ErrorBox />
  if (!data) return <Loader active />
  if (success) return <SuccessBox name={data.name} hasApproved={approved} />
  return (
    <Fragment>
      <Header as="h2" color="teal" textAlign="center" content="Autorização de acesso" />
      <Segment>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Nome</Header>
          <span>{data.name}</span>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Email</Header>
          <span>{data.email}</span>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Data de início</Header>
          <span>{moment(Number(data.beginning)).format('LL')}</span>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Header sub>Data de fim</Header>
          <span>{moment(Number(data.expiration)).format('LL')}</span>
        </div>
        <Button
          basic
          color="green"
          onClick={handleClick.bind(null, true)}
          loading={isLoadingApprove}
          disabled={isLoadingApprove}
        >
          Aprovar
        </Button>
        <Button
          basic
          color="red"
          onClick={handleClick.bind(null, false)}
          loading={isLoadingReject}
          disabled={isLoadingReject}
        >
          Rejeitar
        </Button>
      </Segment>
      {errorMsg.length > 0 && <Message negative header="Erro" content={errorMsg} />}
    </Fragment>
  )
}

const SuccessBox = ({ name, hasApproved }: { name: string; hasApproved?: boolean }) => {
  if (hasApproved) {
    return (
      <Message positive header="Acesso Autorizado" content={`O envio do código de acesso de ${name} foi autorizado.`} />
    )
  } else {
    return (
      <Message
        warning
        header="Acesso Recusado"
        content={`O envio do código de acesso de ${name} não foi autorizado.`}
      />
    )
  }
}

const ErrorBox = () => <Message negative header="Link inválido" content="Verifique se o link inserido está correto." />
