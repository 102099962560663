import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from 'Utils/reactTable'
import { fetchModules, saveGroup } from 'Store/actions/modules-action'
import { deleteDynamicData, updateDynamicData } from 'Store/actions/modules-action'
import { InputTemplate } from 'Utils/components/InputTemplate.js'
import colors from 'Utils/theme/colors'
import risks from 'Utils/theme/risks'
import { fetchRules, clearRules, subscribeRuleChange, subscribeRuleDelete } from 'Store/actions/rules-action'
import { RootStore } from 'Store/initialStore'
import { DeleteAction, GroupActions, TableColumn } from '../types'
import { threeColumns } from 'Utils/threeColumns'
import { saveColumnsPreference } from 'Store/actions/login-action'

export const FencesContent = () => {
  const { t } = useTranslation()
  const tableName = 'fences'
  const [currentSort, setCurrentSort] = React.useState('info.properties.label')
  const tableLabel = 'Perímetros'
  const fencesStr = useSelector((state: RootStore) => JSON.stringify(state.modules[tableName]))
  const rulesStr = useSelector((state: RootStore) => JSON.stringify(state.rules))

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchRules())
    const subChange: any = dispatch(subscribeRuleChange())
    const subDelete: any = dispatch(subscribeRuleDelete())
    return () => {
      subChange.unsubscribe()
      subDelete.unsubscribe()
      dispatch(clearRules())
    }
  }, [dispatch])

  const fetchAction = () => dispatch(fetchModules())
  const groupAction: GroupActions = (groupName, [selected], action) =>
    dispatch(saveGroup('fences', groupName, [selected], action))
  const deleteAction: DeleteAction = (ids) => dispatch(deleteDynamicData(ids, 'fences'))

  // let hiddenColumns = ['Tiplam', 'TiplamTest', 'Netflix'].includes(empresa) ? ['id'] : ['id', 'info.properties.risk']
  const hiddenColumns = useSelector(
    (state: RootStore) => state.login.preferences.routesPanels.byId[tableName].hiddenColumns
  )
  // let hiddenButtons = ['new', 'edit', 'fullscreen', 'toggle fullscreen', 'calendar']

  const groupedValuesColumns = useSelector(
    (state: RootStore) => state.login.preferences.routesPanels.byId[tableName].groupedValuesColumns
  )

  const [currentGroup, setCurrentGroup] = React.useState(groupedValuesColumns || [])
  useEffect(() => {
    dispatch(saveColumnsPreference(tableName, false, currentGroup))
  }, [currentGroup, dispatch])

  const columns = React.useMemo(
    () =>
      [
        {
          Header: t('Code'),
          accessor: 'id',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} ids`,
        },
        {
          Header: t('Fences'),
          accessor: 'info.properties.label',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} ${t('Fences')}`,
          Cell: (props) => (
            <InputTemplate
              options={undefined} //enquanto InputTemplate não está em typescript
              value={props.value || '<<sem nome>>'}
              id={props?.cell?.row?.values?.id}
              action={(id: string, field: string, value: string) =>
                dispatch(updateDynamicData({ id: id, field: field, value: value }, 'fences'))
              }
              field={'info.properties.label'}
              table={tableName}
            />
          ),
        },
        {
          Header: t('Groups'),
          accessor: 'groupsText',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} ${'grupos'}`,
        },

        {
          Header: t('Rules'),
          accessor: 'rulesText',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} ${'regras'}`,
        },
        {
          Header: t('Color'),
          accessor: 'info.properties.color',
          aggregate: 'uniqueCount',
          Aggregated: ({ value }) => `${value} ${t('colors')}`,
          Cell: (props) => (
            <InputTemplate
              options={colors}
              value={props.value || 'blue'}
              id={props?.cell?.row?.values?.id}
              action={(id: string, field: string, value: string) =>
                dispatch(updateDynamicData({ id: id, field: field, value: value }, 'fences'))
              }
              field={'info.properties.color'}
              table={tableName}
            />
          ),
        },
        {
          Header: t('Risk'),
          accessor: 'info.properties.risk',
          aggregate: 'count',
          Aggregated: ({ value }) => `${value} risco(s)`,
          Cell: (props) => (
            <InputTemplate
              options={risks}
              value={props.value || 'Indefinido'}
              id={props?.cell?.row?.values?.id}
              action={(id: string, field: string, value: string) =>
                dispatch(updateDynamicData({ id: id, field: field, value: value }, 'fences'))
              }
              field={'info.properties.risk'}
              table={tableName}
            />
          ),
        },
        ...threeColumns(t),
      ] as TableColumn[],
    [t, dispatch]
  )

  const checkRule = (targetType?: string, targetId?: string[], id?: string, editable?: boolean | null) => {
    if (targetType === 'fence') {
      return id && targetId?.includes(id) && editable
    }
    return false
  }

  const data = React.useMemo(() => {
    const fences = JSON.parse(fencesStr)
    const rules = JSON.parse(rulesStr)
    return fences.allIds.map((id: any) => {
      let row = fences.byId[id]
      let groupsText = row?.groups
        //?.filter((g) => !g?.startsWith('R0'))
        ?.sort()
        ?.join(', ')
      let rulesText = rules.allIds
        .filter((f: string) =>
          checkRule(rules.byId?.[f]?.targetType, rules?.byId?.[f]?.targetId, id, rules?.byId?.[f]?.editable)
        )
        .map((m: string) => rules.byId[m]?.id)
        .sort()
        .join(', ')
      return { ...row, groupsText, rulesText }
    })
  }, [fencesStr, rulesStr])

  return (
    <ReactTable
      table={tableName}
      tableLabel={tableLabel}
      hiddenColumns={hiddenColumns}
      columns={columns}
      data={data}
      // hiddenButtons={hiddenButtons}
      fetchAction={fetchAction}
      groupAction={groupAction}
      deleteAction={deleteAction}
      currentGroup={currentGroup}
      setCurrentGroup={setCurrentGroup}
      setCurrentSort={setCurrentSort}
      currentSort={currentSort}
    />
  )
}
