import React, { useEffect, useState } from 'react'
import { Icon, Card, Grid, Header, Divider } from 'semantic-ui-react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { setSelectedCard } from 'Store/actions/dashboards-action'

const InteractionsDashboard = () => {
  const dispatch = useDispatch()

  const [groupState, setGroupState] = useState({})
  const [showCards, setShowCards] = useState(true)

  const assets = useSelector((state) => state.assets.byId, shallowEqual)
  const measures = useSelector((state) => state.measures.byId, shallowEqual)
  const fences = useSelector((state) => state.modules.fences, shallowEqual)
  const interactionsAllIds = useSelector((state) => state.interactions.allIds, shallowEqual)
  const interactions = useSelector((state) => state.interactions.byId, shallowEqual)

  const dashboardFilter = useSelector((state) => state.dashboards.filter)
  const selected = useSelector((state) => state.dashboards.byId['fences'].selected)

  useEffect(() => {
    let fenceInfo = {}
    fences.allIds.forEach((fenceId) => {
      const eachFence = fences.byId[fenceId]
      fenceInfo[fenceId] = {
        name: eachFence.info?.properties?.label || eachFence.name,
        count: 0,
        color: eachFence.info.properties.color || 'blue',
        id: fenceId,
      }
    })
    Object.entries(measures)
      .filter((eachAssetMeasure) => !assets[eachAssetMeasure[0]]?.invisible)
      .forEach((each) => {
        const locationAnalytics = each[1]?.info?.fencesLocation
        if (locationAnalytics) {
          Object.keys(locationAnalytics).forEach((eachLoc) => {
            if (fenceInfo[eachLoc]) fenceInfo[eachLoc].count = +1
          })
        }
      })
    setGroupState(fenceInfo)
  }, [measures, fences, dispatch, assets])

  //const culpados = ['Bruno Melo', 'Gustavo Nascimento', 'Ian Andrade']
  let nInteractions
  let nTimeTotal
  let theseInteractions
  let durations

  return (
    <>
      <Grid columns="equal">
        <Grid.Column verticalAlign="bottom" style={{ cursor: 'pointer' }}>
          <Header as="h3" onClick={() => setShowCards(!showCards)}>
            <Icon name={showCards ? 'angle down' : 'angle right'} size="mini" />
            Cards - Tempo Real
          </Header>
        </Grid.Column>
        <Grid.Column width="13">
          <Divider horizontal>
            <Header as="h4">
              <Icon name="vcard" />
            </Header>
          </Divider>
        </Grid.Column>
      </Grid>
      <br />
      <br />
      <br />
      {showCards && (
        <Card.Group>
          {Object.values(groupState)
            .filter((e) => e.name.toLowerCase().includes(dashboardFilter.toLowerCase()))
            .sort((a, b) => (b.count === a.count ? a.name.localeCompare(b.name) : b.count - a.count))
            .map((group, i) => {
              theseInteractions = interactionsAllIds
                ? interactionsAllIds.filter((id) => {
                    return interactions[id].location === group.name
                  })
                : []
              nInteractions = theseInteractions.length
              durations = theseInteractions.map((id) => interactions[id].duration)
              nTimeTotal =
                nInteractions === 0
                  ? 0
                  : durations.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
              return (
                <Card
                  color={group.color}
                  key={group.id}
                  centered
                  onClick={() => dispatch(setSelectedCard('fences', group.id))}
                >
                  <Card.Content>
                    <Icon
                      circular
                      inverted
                      color={group.color}
                      size="large"
                      style={{ float: 'right' }}
                      name={'clock'}
                    />
                    <Card.Header>{nInteractions}</Card.Header>
                    <Card.Meta>Interações</Card.Meta>
                    <Card.Description>
                      <p>Tempo Total: {nTimeTotal !== 1 ? nTimeTotal + ' minutos' : nTimeTotal + ' minuto'}</p>
                      {/*<p>
                      Maior Interação por vezes: {culpados[i % 3]} ({i * 7 + 1}%)
                    </p>
                    <p>
                      Maior Interação por tempo: {culpados[i % 2]} ({i * 6.5 + 1}%)
                    </p>*/}
                    </Card.Description>
                  </Card.Content>

                  <Card.Content extra style={{ height: '55px', paddingTop: '18px' }}>
                    <span
                      title={group.name}
                      style={{
                        maxWidth: '85%',
                        display: 'inline-block',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {group.name}
                    </span>
                    <Icon
                      name={selected.includes(group.id) ? 'check square outline' : 'square outline'}
                      size="large"
                      style={{
                        float: 'right',
                        color: selected.includes(group.id) ? 'green' : null,
                        marginRight: '12px',
                      }}
                    />
                  </Card.Content>
                </Card>
              )
            })}
        </Card.Group>
      )}
    </>
  )
}

export default InteractionsDashboard
