import React, { Fragment } from 'react'
import { Dropdown, Grid, Input, Icon, Label } from 'semantic-ui-react'
import { AssetItem } from './AssetItem'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { regExpEscape } from 'Utils/escape'
import { useSelector } from 'react-redux'

/*
 Front-end revisao 07/2022 by Thiago Porfirio
 Erros encontrados input, botão ocultar todos, tamanho dos geral dos cotainers.
 Consertado somente o input, outros erros consertar na tarefa de re-criar e padronizar o dropdown, diminuir o maximo de css possivel.
*/

export const DropdownGridHeader = styled(Grid)`
  width: 400px;
  padding-left: 10px;
  margin: 0px !important;
`

const DropdownGridInput = styled(Grid)`
  width: 217px;
  padding-left: 10px;
`

export const DropdownHeaderCol = styled(Grid.Column)`
  padding: 10px !important;
  right: 13px;
`

export const DropdownHeader = styled(Dropdown.Header)`
  font-size: 1.33em;
  font-weight: 800;
`
const DropdownGridName = styled(Grid.Column)`
  padding: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 14px;
  padding-right: 0;
`
const DropdownInputGrid = styled(Grid.Column)``

const DropdownDivider = styled(Dropdown.Divider)`
  // margin-bottom: 10px !important;
  // margin-left: 8px !important;
  // margin-right: 8px !important;
  // margin-top: 15px !important;
  margin: 12px 8px !important;
`
const DropdownMenu = styled(Dropdown.Menu)`
  border-top-width: 0px !important;
`

const ItemEyeGrid = styled(Grid.Column)`
  padding: 10px !important;
  left: 5px;
`

const AssetIcon = ({ assetId }) => {
  const time = useSelector((state) => state.measures.byId[assetId]?.info?.time)
  const color = getStatusColor(time)
  return <Icon name="circle" color={color} size="small" />
}

/* <Icon name="circle" color={color} size="small" style={{ marginRight: '5px' }} /> */

export const AssetContainer = ({
  activeAssetsStr,
  assetArrayStr,
  assetsStr,
  assetsTitle,
  clickItem,
  clickFav,
  favorites = [],
  handleSearch,
  searchValue,
  hideSearch,
  searchHide,
  type,
}) => {
  const spaceType = { icon: 'shield', name: type }
  const assetArray = JSON.parse(assetArrayStr)
  const activeAssets = JSON.parse(activeAssetsStr)
  const assets = JSON.parse(assetsStr)
  const { t } = useTranslation()
  if (assetArray.length === 0) return null

  return (
    <Fragment>
      <DropdownGridInput className="header">
        <DropdownInputGrid width={11}>
          <Input
            icon="user"
            iconPosition="left"
            placeholder={t(assetsTitle || type) + '...'}
            onChange={handleSearch}
            size="large"
          />
        </DropdownInputGrid>

        <ItemEyeGrid width={3} textAlign="right" floated="right" style={{ bottom: '1px' }}>
          <Label size="mini" style={{ marginLeft: '75px', marginTop: '-8px' }}>
            <Icon
              name={searchHide ? 'eye slash' : 'eye'}
              style={{ fontSize: '1.90em', cursor: 'pointer' }}
              // size="small"
              onClick={() => hideSearch(assetArray)}
            />

            <Label.Detail>
              <span style={{ fontSize: '1.9em' }}> {searchHide ? t('EyesAllShow') : t('EyesAllHide')}</span>
            </Label.Detail>
          </Label>
        </ItemEyeGrid>
      </DropdownGridInput>

      <DropdownDivider />

      <DropdownMenu scrolling>
        <DropdownGridHeader className="header" key={spaceType.name}>
          <DropdownGridName width={12}>
            <DropdownHeader key={spaceType.name} icon={spaceType.icon} content={spaceType.name} />
          </DropdownGridName>
        </DropdownGridHeader>

        {activeAssets
          .filter((data) => assets[data] && assets[data].name.search(new RegExp(regExpEscape(searchValue), 'i')) !== -1)
          .sort((prev, next) => assets[prev].name.localeCompare(assets[next].name))
          .map((assetId) => (
            <AssetItem
              key={'active' + assetId.toString()}
              favState={favorites.includes(assetId)}
              clickItem={clickItem}
              clickFav={clickFav}
              iconItem={<AssetIcon assetId={assetId} />}
              assetDetails={assets[assetId]}
              assetId={assetId.toString()}
            />
          ))}

        {activeAssets.length > 0 && <DropdownDivider />}

        {assetArray
          .filter(
            (id) =>
              assets[id].name.search(new RegExp(regExpEscape(searchValue), 'i')) !== -1 && !activeAssets.includes(id)
          )
          .sort((prev, next) => assets[prev].name.localeCompare(assets[next].name))
          .map((assetId) => (
            <AssetItem
              key={assetId.toString()}
              favState={favorites.includes(assetId)}
              clickItem={clickItem}
              clickFav={clickFav}
              assetDetails={assets[assetId]}
              assetId={assetId.toString()}
            />
          ))}
      </DropdownMenu>
    </Fragment>
  )
}

const getStatusColor = (time) => {
  if (time === undefined) return 'grey'
  const color = new Date(time).getTime() > Date.now() - 1000 * 60 * 5 ? 'green' : 'yellow'
  return color
}
