import React from 'react'
import { useSelector } from 'react-redux'
import { Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import fitBounds from 'Utils/fitBounds.js'

const StatsBarActionsSearch = ({ id, moduleType }) => {
  //const canAccess = useCanAccess('WRITE')
  //const dispatch = useDispatch()
  const { t } = useTranslation()
  //const [visible, setVisible] = useState(true)
  const coords = useSelector((state) => state.modules[moduleType].byId[id]?.info?.geometry?.coordinates)

  const refMap = useSelector((state) => state.login.refMap)
  const handleClick = () => {
    if (coords && refMap) fitBounds(moduleType, coords, refMap)
  }
  return (
    <Icon
      id="icon-center"
      name="crosshairs"
      title={t('searchCenter')}
      size="large"
      circular
      onClick={handleClick}
      style={{ backgroundColor: '#4b72b2', color: 'white', cursor: 'pointer' }}
    />
  )
}
export default StatsBarActionsSearch
