import request from 'Apis/rest'

export const fetchLog =
  (empresa, { page = 1, per_page = 5 }) =>
  async (dispatch) => {
    const res = await request.get('/organization/Ambev/control-access/logs', {
      params: {
        page,
        per_page,
      },
    })
    dispatch({ type: 'FETCH_LOG', payload: res.data })
  }
