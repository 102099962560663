import L from 'leaflet'
import { GeoJSON, Pane } from 'react-leaflet'
import React, { Fragment } from 'react'
import ReactDistortableImageOverlay from 'Libs/ImageOverlay/index'
//import {openModal} from '../Utilities/FileUpload';
import './ImageOverlay.css'
import { selectIndoor, updateIndoorServer, deleteIndoor, updateIndoor } from 'Store/actions/indoor-action'
import { connect } from 'react-redux'

const backfence = (element) => {
  const custom = element.info.fence
  if (custom) return custom
  return [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [element.info.corners[0].lng, element.info.corners[0].lat],
            [element.info.corners[1].lng, element.info.corners[1].lat],
            [element.info.corners[3].lng, element.info.corners[3].lat],
            [element.info.corners[2].lng, element.info.corners[2].lat],
          ],
        ],
      },
    },
  ]
}

class ImageOverlayRotated extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editMode: 'none',
      // || [{lat: -22.787105, lng: -45.647438},{lat: -22.787105, lng: -44.655915}, {lat: -23.780986, lng: -45.647438}, {lat: -23.780986, lng: -44.655915}],
      currentID: 0,
      showButtons: false,
    }
  }

  // shouldComponentUpdate(nextProps) {
  //   if (nextProps.indoor === this.props.indoor && this.props.center[0] === nextProps.center[0]) {
  //     return false
  //   } else return true
  // }

  /* static getDerivedStateFromProps(nextProps) {
    let bool = false
    const indoor = nextProps.indoor.hasOwnProperty('items') ? nextProps.indoor.items : []
    if (indoor.length > 0) {
      indoor.forEach((element, i) => {
        let corners = [
          new L.latLng([indoor[i].info.corners[0].lat, indoor[i].info.corners[0].lng]),
          new L.latLng([indoor[i].info.corners[1].lat, indoor[i].info.corners[1].lng]),
          new L.latLng([indoor[i].info.corners[2].lat, indoor[i].info.corners[2].lng]),
          new L.latLng([indoor[i].info.corners[3].lat, indoor[i].info.corners[3].lng])
        ]
        if (nextProps.refMap) {
          if (nextProps.refMap.leafletElement.getBounds().intersects(corners)) {
            bool = true
          }
        }
      })
    }
    if (nextProps.zoom > 15) return ({ showButtons: bool })
    else return ({ showButtons: false })
  } */

  clickSave = () => {
    this.setState({ editMode: 'none' })
    if (this.state.corners)
      //mu
      this.props.updateIndoorServer(
        this.state.currentID,
        this.state.corners.map((v) => JSON.stringify(v)),
        'Cantos'
      )
  }
  onCornersUpdate = (corners, id) => {
    this.props.updateIndoorServer(
      id,
      corners.map((v) => JSON.stringify(v)),
      'Cantos'
    )
  }
  onDelete = (item) => {
    this.props.deleteIndoor(item)
  }
  onSelect = (id) => {
    this.props.selectIndoor(id)
  }

  render() {
    const indoor = this.props.indoor ? (this.props.indoor.hasOwnProperty('items') ? this.props.indoor.items : []) : []
    return (
      <Fragment>
        {indoor.length > 0 &&
          this.props.zoom > 13 &&
          indoor
            .filter((e) => !this.props.indoorPreferences[e.id]?.invisible)
            .map((element, i) => {
              if (this.props.zoom < element.info.zoomLevel || element.info.displayOnInnerMapOnly) return null
              return (
                <Fragment key={element.id}>
                  <Pane name={'background' + element.id} style={{ zIndex: 201 }}>
                    <GeoJSON
                      key={'p' + element.id}
                      //color={element.info?.fence?.[0]?.properties?.color || '#DDDDDD'}
                      color="#FFFBF0"
                      fillOpacity={1}
                      data={backfence(element)}
                    />
                  </Pane>
                  <ReactDistortableImageOverlay
                    key={element.id}
                    id={element.id}
                    opacity={0.7}
                    onCornersUpdated={(corners) => this.onCornersUpdate(corners, element.id)}
                    onWellKnownTextUpdated={(wkt) => null}
                    onDelete={this.onDelete}
                    onSelection={this.onSelect}
                    keymapper={false}
                    suppressToolbar={false}
                    mode="lock"
                    url={element.info.url}
                    updateCorners={this.props.updateIndoor}
                    corners={[
                      new L.latLng([element.info.corners[0].lat, element.info.corners[0].lng]),
                      new L.latLng([element.info.corners[1].lat, element.info.corners[1].lng]),
                      new L.latLng([element.info.corners[2].lat, element.info.corners[2].lng]),
                      new L.latLng([element.info.corners[3].lat, element.info.corners[3].lng]),
                    ]}
                  />
                </Fragment>
              )
            })}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  indoor: state.indoor,
  refMap: state.login.refMap,
  indoorPreferences: state.login.preferences.indoor,
  updateIndoor: state.toggle.updateIndoor,
  inner: state.toggle.showInnerMap,
  perfil: state.login.perfil,
})

export default connect(mapStateToProps, { selectIndoor, updateIndoorServer, deleteIndoor, updateIndoor })(
  ImageOverlayRotated
)
