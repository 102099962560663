import request from 'Apis/rest'

export const INITIAL_STORE = 'IndoorsInitialStore'
export const GEODADOS = 'geoData'
export const GEOIndoor = 'saveGeoIndoor'

export const saveGeoIndoor = (geojson, empresa, name, email) => ({
  type: 'server/insere/modulo',
  fields: {
    nome: `'${name}'`,
    id_tipo_modulo: 2,
    empresa: `'${empresa}'`,
    info: `'${geojson}'`,
    login: `'${email}'`,
  },
})

export const updateIndoorServer = (id, valor, campo) => {
  let campovalor = ''
  switch (campo) {
    case 'Nome':
      campovalor = { nome: valor }
      break
    case 'Cantos':
      campovalor = { info: `'{"corners": [${valor}]}'` }
      break
    default:
      console.log('Campo não preparado para atualização, mudar na actions da Store')
  }
  return {
    type: 'server/atualiza/modulo',
    fields: campovalor,
    where: { id_modulo: id },
  }
}
export const updateIndoor = (id, valor, campo) => {
  return {
    type: 'atualizaIndoor',
    id: id,
    payload: { valor: valor, campo: campo },
  }
}
export const deleteIndoorDB = (image) => {
  return {
    type: 'server/deleta/modulo',
    where: ['id_modulo IN (' + image + ')'],
  }
}
export const deleteIndoor = (item) => {
  const { url, id } = item
  return {
    type: 'deleta imagem',
    payload: { id, url },
  }
}
export const selectIndoor = (id) => {
  return {
    type: 'seleciona imagem',
    payload: { id },
  }
}
export const fetchIndoor = () => async (dispatch) => {
  const jwt = localStorage.getItem('id_token')
  const res = await request.get('/indoor', {
    headers: {
      Authorization: 'Bearer ' + jwt,
    },
  })
  dispatch({ type: 'indoorInitialStore', payload: res.data })
}

export const updateIndoorTable = (data) => async (dispatch) => {
  dispatch({ type: 'UPDATE_INDOOR_START' })
  const jwt = localStorage.getItem('id_token')
  const body = {
    id: data.id,
    name: data.name,
    type: 4,
    info: {
      url: data.url,
      corners: [data.corner0, data.corner1, data.corner2, data.corner3],
      properties: { label: data.name },
    },
  }
  try {
    const res = await request.put('/core/v0/modules/units/' + data.id, body, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    dispatch({ type: 'UPDATE_INDOOR_SUCCESS', payload: res.data })
  } catch (err) {
    dispatch({ type: 'UPDATE_INDOOR_FAILURE', payload: err })
  }
}

export const createIndoorTable = (data) => async (dispatch) => {
  dispatch({ type: 'CREATE_MODULE_REQUEST', payload: { moduleTypes: 'units' } })
  const jwt = localStorage.getItem('id_token')
  const body = {
    name: data.name,
    type: 4,
    info: {
      url: data.url,
      corners: [data.corner0, data.corner1, data.corner2, data.corner3],
      properties: { label: data.name },
    },
  }
  try {
    const res = await request.post('/core/v0/modules/units', body, {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    })
    dispatch({ type: 'CREATE_MODULE_SUCCESSFULL', payload: res.data })
  } catch (err) {
    dispatch({ type: 'CREATE_MODULE_FAILURE', payload: err })
  }
}

export const setCurrentIndoor = (id, payload) => (dispatch, getState) => {
  dispatch({
    type: 'SET_CURRENT_INDOOR',
    id,
    payload,
    company: getState().login.empresa,
  })
}
